import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { format, startOfMonth, startOfToday } from 'date-fns';

import StackedLayout from '../../../layouts/StackedLayout';
import Table from '../../../components/tables/StackedTable';
import { Input } from '../../../components/forms/fields';
import { MenuItem, ActionMenu } from '../../../components/ActionMenu';
import Tabs from '../../../components/Tabs';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import { images } from '../../../constants';
import PaymentMethods from '../../../components/PaymentMethods';
import { forEach, omit } from 'lodash';
import { getNormalizedDateTime } from '../../../utils';
import { getInvoicePdf, getInvoices, getDuesForMonthlyPrograms } from '../../../services/invoice';
import { getPrograms } from '../../../services/program';
import { bkashCheckout } from '../../../services/payment';


const sidebarNavigation = [
  { name: 'হোম', href: '/learn/home' },
  { name: 'ক্লাস রুটিন', href: '/learn/routine' },
  { name: 'পরীক্ষার রেজাল্ট', href: '/learn/results' },
  { name: 'ফি পেমেন্ট', href: '/learn/payments' },
  { name: 'ট্রানজেকশন বিবরণী', href: '/learn/transactions' }
];

const paymentMethods = [
  { title: 'বিকাশ', value: 'bkash', icon: images.Bkash },
];

const tabs = ['এককালীন কোর্স ফি', 'মাসিক বেতন'];

export default function StudentPaymentPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const queryParams = new URLSearchParams(useLocation().search);
  const status = queryParams.get('status');
  const message = queryParams.get('message');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [programType, setProgramType] = useState('onetime');
  const [transactions, setTransactions] = useState([]);
  const [onetimeDueInvoices, setOnetimeDueInvoices] = useState([]);
  const [monthlyDueInvoices, setMonthlyDueInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [billingMonth, setBillingMonth] = useState(format(startOfMonth(startOfToday()), 'yyyy-MM'));
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [totalFees, setTotalFees] = useState(0);
  const [monthlyScholarship, setMonthlyScholarship] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [totalDues, setTotalDues] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        if (selectedTabIndex === 0) {
          const programs = await getPrograms({paymentCircle: 'onetime'});
          let programIds = [];
          forEach(programs, p => programIds.push(p._id));
          const params = {
            program: programIds,
            status: ['due', 'overdue'],
            user: currentUser._id,
            isPaging: true,
            page: currentPage,
            limit: 10,
            sortBy: 'dueDate'
          };
          const data = await getInvoices(params);
          setOnetimeDueInvoices(data?.docs || []);
          setPagingData(omit(data, 'docs'));
        } else if (selectedTabIndex === 1) {
          const programs = await getPrograms({paymentCircle: 'month'});
          let programIds = [];
          forEach(programs, p => programIds.push(p._id));
          const params = {
            programs: programIds,
            user: currentUser._id,
            billingMonth: billingMonth
          };
          const data = await getDuesForMonthlyPrograms(params);
          setMonthlyDueInvoices(data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();

  }, [
    selectedTab,
    currentPage,
    billingMonth
  ]);



  const handleTabChange = (event, index) => {
    event.preventDefault();
    if (processing) { return; }
    const tabIndex = index || event?.target?.selectedIndex || 0;
    setSelectedTabIndex(tabIndex);
    setSelectedTab(tabs[tabIndex]);
    setProgramType(tabIndex === 0 ? 'onetime' : 'month');
    setTotalDues(0);
    setTotalFees(0);
    setMonthlyScholarship(0);
    setDiscount(0);
    setSpecialDiscount(0);
    setSelectedInvoice(null);
    setSelectedInvoices([]);
  };

  const handleSelectInvoice = (item, actionName) => {
    if (processing) { return; }
    if (actionName === 'remove') {
      setSelectedItem(null);
      setSelectedInvoice(null);
      setSelectedInvoices([]);
      setTotalDues(0);
      setTotalFees(0);
      setMonthlyScholarship(0);
      setDiscount(0);
      setSpecialDiscount(0);
      setSelectedInvoice(null);
      setSelectedInvoices([]);
    } else {
      setSelectedItem(item._id);
      if (programType === 'onetime') {
        setSelectedInvoice(item._id);
        setTotalDues(item.amount);
        setTotalFees(item.originalFee);
        setDiscount(item.discount);
        setSpecialDiscount(item.specialDiscount);
      } else {
        setSelectedInvoices(item.invoices);
        setTotalFees(item.totalDues);
        const minCourseForScholarship = item?._id?.scholarshipMinSubjectCount - item?._id?.freeCourseCount;
        const monthlyScholarship = item.count >= minCourseForScholarship ? item?._id?.monthlyScholarship : 0;
        setMonthlyScholarship(monthlyScholarship);
        setTotalDues(getDuesForMonth(item));
      }
    }
  };

  const pay = async () => {
    try {
      setProcessing(true);
      if (selectedPaymentMethod === 'bkash') {
        const payload = {
          amount: totalDues,
          transactionType: 'studentInvoice',
          program: programType === 'onetime' ? selectedItem?.program?._id : selectedItem?._id?.program?._id,
          invoice: selectedInvoice,
          invoices: selectedInvoices,
        };
        const bkashData = await bkashCheckout(payload);
        if (bkashData?.transactionStatus === 'Initiated') {
          window.location.href = bkashData.bkashURL;
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setProcessing(false);
    }
  };

  const isSelected = (item) => {
    return selectedItem?.toString() === item?._id?.toString();
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="ফি পেমেন্ট"
    >
      {status === 'failed' && <ErrorAlert message={message} />}

      <div className="sm:grid sm:grid-cols-7 lg:gap-x-12 xl:gap-x-16">
        <div className="mb-20 sm:col-span-4">
          <div>
            <Tabs tabs={tabs} selectedTab={selectedTab} onChange={handleTabChange} />
            
            {programType === 'month' &&
            <dl className="mx-2 mt-6 space-y-4 text-sm leading-6">
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Month</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    <Input type="month" value={billingMonth} onChange={(event) => setBillingMonth(event.target.value)} />
                  </div>
                </dd>
              </div>
            </dl>}
          </div>

          <div className="mb-20 pb-20">
            {selectedTabIndex === 0 &&
            <Table
              headers={['Program', 'Course', 'Amount', 'Due date']}
              itemsCount={onetimeDueInvoices.length}
            >
              {onetimeDueInvoices.map((invoice) => (
                <tr key={invoice._id} className="text-center">
                  <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                    {invoice?.program?.name}
                  </td>
                  <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.course?.name}</td>
                  <td className="pr-3 py-4 text-sm text-gray-500">{invoice.amount}</td>
                  <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(invoice.dueDate, 'DD MMM YYYY')}</td>
                  <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <div className="flex justify-end items-center space-x-2">
                      <p
                        className={`text-${isSelected(invoice) ? 'red' : 'indigo'}-600 hover:text-${isSelected(invoice) ? 'red' : 'indigo'}-500 underline cursor-pointer`}
                        onClick={() => handleSelectInvoice(invoice, isSelected(invoice) ? 'remove' : 'pay')}
                      >
                        {isSelected(invoice) ? 'Remove' : 'Pay'}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </Table>}

            {selectedTabIndex === 1 &&
            <Table
              headers={['Program', 'Month', 'Amount']}
              itemsCount={monthlyDueInvoices.length}
            >
              {monthlyDueInvoices.map((invoice) => (
                <tr key={invoice._id} className="text-center">
                  <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                    {invoice?._id?.program?.nameBN || invoice?._id?.program?.name}
                  </td>
                  <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(billingMonth, 'MMM YYYY')}</td>
                  <td className="pr-3 py-4 text-sm text-gray-500">
                    {getDuesForMonth(invoice)}
                  </td>
                  <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <div className="flex justify-end items-center space-x-2">
                      <p
                        className={`text-${isSelected(invoice) ? 'red' : 'indigo'}-600 hover:text-${isSelected(invoice) ? 'red' : 'indigo'}-500 underline cursor-pointer`}
                        onClick={() => handleSelectInvoice(invoice, isSelected(invoice) ? 'remove' : 'pay')}
                      >
                        {isSelected(invoice) ? 'Remove' : 'Pay'}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </Table>}
          </div>
        </div>
        
        <div className="mt-10 sm:mt-0 sm:col-span-3">
          <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
          <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div className="flex items-center justify-between">
                <dt className="text-sm">Total fees</dt>
                <dd className="text-sm font-medium text-gray-900">
                  ৳{totalFees?.toLocaleString()}
                </dd>
              </div>
              
              {programType === 'month' &&
              <div className="flex items-center justify-between">
                <dt className="text-sm">Monthly scholarship(-)</dt>
                <dd className="text-sm font-medium text-gray-900">
                  ৳{monthlyScholarship?.toLocaleString()}
                </dd>
              </div>}

              {programType === 'onetime' &&
              <>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Discount(-)</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    ৳{discount?.toLocaleString()}
                  </dd>
                </div>
                {specialDiscount > 0 &&
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Special discount(-)</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    ৳{specialDiscount?.toLocaleString()}
                  </dd>
                </div>}
              </>}

              <div className="flex items-center justify-between border-t-2">
                <dt className="pt-2 text-sm font-semibold">Amount to pay</dt>
                <dd className="text-sm font-semibold text-gray-900">
                  ৳{totalDues?.toLocaleString()}
                </dd>
              </div>
            </dl>

            {totalDues > 0 &&
            <div className="mx-auto mt-2 sm:mt-0 w-full max-w-lg px-4 py-6 sm:px-6">
              <div className="pb-10">
                <PaymentMethods onSelect={(method) => setSelectedPaymentMethod(method)} />
              </div>

              <p className="mb-2 text-sm text-center underline text-indigo-600">
                <a href="/terms" target="_blank">এই পেমেন্ট করার জন্য আমি শর্তাবলী বুঝেছি</a>
              </p>
              <button
                type="button"
                disabled={!selectedPaymentMethod || processing || totalDues <= 0}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 py-2 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-offset-2 disabled:cursor-none disabled:bg-gray-100"
                onClick={() => pay()}
              >
                পেমেন্ট করুন
              </button>
            </div>}
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};

function getDuesForMonth(data) {
  const minSubjectCount = data?._id?.scholarshipMinSubjectCount - data?._id?.freeCourseCount;
  const monthlyScholarship = data?._id?.monthlyScholarship || 0;
  const admittedSubjectCount = data.count;

  if (monthlyScholarship > 0 && admittedSubjectCount >= minSubjectCount) {
    return data.totalDues - monthlyScholarship;
  } else {
    return data.totalDues;
  }
};