import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import ContentTabs from '../../../components/ContentTabs';
import EmptyData from '../../../components/EmptyData';
import TopicForm from './TopicForm';
import { getCourseContents, createCourseContents,
  updateCourseContents } from '../../../services/courseContent';


export default function BatchVideos(props) {
  const { courseId, batchId, contentType, programId } = props;
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [courseContentId, setCourseContentId] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
  }, [contentType]);

  const fetchData = async () => {
    try {
      let params = {
        course: courseId,
        contentType: contentType
      };
      if (batchId) { params.batch = batchId; }
      const data = await getCourseContents(params);
      if (data[0]) {
        const content = data[0];
        setCourseContentId(data[0]._id);
        const _topics = content?.topics || [];
        setTopics(_topics);
        setSelectedTopic(_topics[0]);
        setSelectedTopicIndex(0);
        formatTabs(_topics);
      } else {
        setCourseContentId(null);
        setTopics([]);
        setSelectedTopic(null);
        setSelectedTopicIndex(null);
        setTabs([]);
        setSelectedTab(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addTopic = () => {
    let _topics = cloneDeep(topics);
    _topics.push({ name: '' });
    const _selectedTopicIndex = _topics.length - 1;
    setTopics(_topics);
    setSelectedTopic(_topics[_selectedTopicIndex]);
    setSelectedTopicIndex(_selectedTopicIndex);
    formatTabs(_topics, _selectedTopicIndex);
  };

  const handleTopicChange = (topicIndex) => {
    const _topics = cloneDeep(topics);
    setSelectedTab(tabs[topicIndex].name);
    setSelectedTopic(() => _topics[topicIndex]);
    setSelectedTopicIndex(topicIndex);
  };

  const deleteTopic = async (event, _topic) => {
    try {
      event.preventDefault();
      let _topics = cloneDeep(topics);
      const nextTopicIndex = _topics.length >= selectedTopicIndex + 2 ? selectedTopicIndex : selectedTopicIndex - 1  ;
      _topics.splice(selectedTopicIndex, 1);

      if (_topic._id) {
        const payload = { topics : _topics };
        await updateCourseContents(courseContentId, payload);
      }

      setTopics(_topics);
      setSelectedTopic(_topics[nextTopicIndex]);
      setSelectedTopicIndex(nextTopicIndex);
      formatTabs(_topics, nextTopicIndex);
    } catch (error) {
      throw error;
    }
  };

  const saveTopic = async (event, topic) => {
    try {
      event.preventDefault();
      let _topics = cloneDeep(topics);
      _topics[selectedTopicIndex] = topic;
      let payload = { topics: _topics };
      
      let updatedContents;
      if (courseContentId) {
        updatedContents = await updateCourseContents(courseContentId, payload);
      } else {
        payload.course = courseId;
        payload.contentType = contentType;
        if (batchId) { payload.batch = batchId; }
        updatedContents = await createCourseContents(payload);
        setCourseContentId(updatedContents._id);
      }
      _topics[selectedTopicIndex] = updatedContents.topics[selectedTopicIndex];
      setTopics(_topics);
    } catch (error) {
      throw error
    }
  };

  const formatTabs = (_topics, _selectedTabIndex) => {
    _topics = _topics || topics;
    _selectedTabIndex = _selectedTabIndex || 0;
    let _tabs = [];

    for (let i = 0; i < _topics.length; i++) {
      _tabs.push({name: `Topic ${i+1}`})
    }

    setSelectedTab(_tabs[_selectedTabIndex]?.name);
    setTabs(_tabs);
  };

  return (
    <>
      {tabs.length > 0 &&
      <ContentTabs
        tabs={tabs} 
        showAddTypeButton={true}
        action="New topic"
        selectedTab={selectedTab}
        onTabChange={handleTopicChange}
        onAddTab={addTopic}
      />}

        {topics.length === 0 &&
        <EmptyData
          title="No topics"
          subTitle="Create new topic to add contents this chapter." 
          onCreate={addTopic}
        />}

      {topics.length > 0 &&
      <TopicForm
        programId={programId}
        topic={selectedTopic}
        topicCount={topics?.length || 0}
        contentType={contentType}
        onSave={saveTopic}
        onDelete={deleteTopic}
      />}
    </>
  )
};