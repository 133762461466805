import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import BatchContents from './components/BatchContents';
import Breadcrumb from '../../components/Breadcrumb';
import LiveClassRoutine from '../../components/LiveClassRoutine';
import { getCourse } from '../../services/course';


const navigation = ['Routine', 'Recorded Lectures', 'Online MCQ Exams', 'Online CQ Exams', 'Offline Exams', 'PDF Notes'];
const contentTypeMap = {
  Routine: null,
  'Recorded Lectures': 'lesson',
  'Online MCQ Exams': 'exam',
  'Online CQ Exams': 'cqExam',
  'Offline Exams': 'offlineExam',
  'PDF Notes': 'note',
};

export default function OfflineCourseContentPage () {
  const params = useParams();
  const courseId = params.courseId;
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [contentType, setContentType] = useState('');
  const [currentSidebarNavigation, setCurrentSidebarNavigation] = useState('Routine');
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();

  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const course = await getCourse(courseId);
      setCourse(course);
      const _breadcrumbPages = [
        { name: 'Courses', href: `/courses/offline`},
        { name: `${course?.name}`, current: true},
        { name: 'Contents', current: true}
      ];
      setBreadcrumbPages(_breadcrumbPages);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigationChange = (event, index) => {
    setCurrentSidebarNavigation(navigation[index]);
    setContentType(() => contentTypeMap[navigation[index]]);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={navigation}
      currentSidebarNavigation={currentSidebarNavigation}
      onNavigationChange={handleNavigationChange}
    >
      
      <Breadcrumb pages={breadcrumbPages} />

      <div>
        {currentSidebarNavigation !== 'Routine' &&
        <BatchContents
          programId={course?.program?._id}
          courseId={course._id}
          contentType={contentType}
        />}

        {currentSidebarNavigation === 'Routine' &&
        <LiveClassRoutine course={course} courseType="offline" />
        }
      </div>

    </StackedLayout>
  )
}