import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { capitalize } from 'lodash';
import { CheckIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../../layouts/StackedLayout';
import Table from '../../../components/tables/StackedTable';
import { SubmitButton, Button } from '../../../components/buttons';
import { getInvoicePdf, getInvoices } from '../../../services/invoice';
import { getTransaction } from '../../../services/transaction';


const headers = ['Payment for', 'Branch', 'Amount', 'Status'];

export default function StudentInvoicePaymentSuccessPage() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const transactionId = queryParams.get('transactionId');
  const backUrl = `/learn/payments`;
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [isBulkPayment, setIsBulkPayment] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      if (!transactionId) {
        return navigate(-1);
      }
      const transaction = await getTransaction(transactionId);

      if (transaction?.invoices?.length > 0) {
        setIsBulkPayment(true);
        try {
          const _invoices = await getInvoices({_id: transaction?.invoices});
          setInvoices(_invoices); 
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        setInvoiceId(transaction.invoice);
      }
    };
    _fetchData();
  }, []);

  const handlePrintInvoice = async (_invoiceId) => {
    try {
      _invoiceId = _invoiceId || invoiceId;
      const pdfUrl = await getInvoicePdf(_invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  return (
    <StackedLayout>
      <div className="flex flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Payment collected successfully.
            </h3>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 space-x-4">
          <SubmitButton
            label="Go back"
            onClick={() => navigate(backUrl)}
          />
          
          <Button
            label="Download invoice"
            onClick={() => handlePrintInvoice(isBulkPayment ? invoices[0]._id : invoiceId)}
          />
        </div>

        {isBulkPayment &&
        <div className="mt-5 sm:mt-6">
          <h2 className="text-xl text-center">Invoices</h2>

          <Table
            itemsCount={invoices.length}
            headers={headers}
          >
            {invoices.map((invoice) => (
              <tr key={invoice._id} className="text-center">
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
                  {invoice?.title || invoice?.course?.name}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {invoice.status === 'paid' ? invoice.paidAmount :invoice.amount}
                </td>
                <td 
                  className={`pr-3 py-4 text-sm ${invoice.status === 'overdue' ? 'text-red-500' : 'text-gray-500'}`}
                >
                  {capitalize(invoice?.status)}
                </td>
              </tr>
            ))}
          </Table>
        </div>}
      </div>
    </StackedLayout>
  )
};