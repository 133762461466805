import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep, orderBy } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';

import { SubmitButton } from '../../../../components/buttons';
import { getTestimonials } from '../../../../services/testimonial';
import { convertToBanglaNumber } from '../../../../utils';


const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const _testimonials = await getTestimonials({ status: 'active'});
        setTestimonials(orderBy(_testimonials, 'displayOrder'));
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  return (
      <div className="bg-white">
        <div className="py-10 sm:py-12 space-y-3 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center sm:space-y-5 tracking-wide ">
          <h2 className="text-base  font-semibold leading-6 text-blue-900">
              আমাদের সাফল্য গাঁথা
          </h2>
          <h2 className="text-3xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-4xl">
              কেন আমরা শিক্ষার্থী ও অভিভাবকদের
          </h2>
          <h1 className="text-3xl font-extrabold leading-10 tracking-tight text-blue-500 sm:text-4xl">অন্যতম পছন্দ</h1>

          <div>
            <ul className="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-4">
              {(showMore ? testimonials : testimonials.slice(0, 3)).map((testimonial, index) => (
                <li key={`testimonial-index[${index}]`} className="bg-gray-50 rounded shadow">
                  <div className="mx-auto ">
                    <div className="flex-col justify-center items-center mt-9 gap-x-6">
                      {testimonial.photo ?
                      <div className="flex items-center text-center justify-center">
                        <img className="h-24 w-24 rounded-full ring-4 ring-indigo-600" src={testimonial.photo} alt="" />
                      </div>
                      : 
                      <div className="flex items-center text-center justify-center">
                        <UserIcon className="h-16 w-16 ring-2 rounded-full" />
                      </div>}
                      <div className="pt-2 text-center">
                        <h3 className="text-base font-medium leading-6 tracking-tight text-gray-900">{testimonial.name}</h3>
                        <p className="text-sm font-medium leading-6 text-gray-500">সেশন: {convertToBanglaNumber(testimonial.session)}</p>
                        {testimonial?.results?.map((result, index) => (
                          <p key={`result-index[${index}]`} className="font-medium leading-6 text-purple-700">{result}</p>
                        ))}
                      </div>
                    </div>

                    <div className="mx-4 mt-6 pb-4 text-center text-gray-700">
                      <p>{testimonial.comment}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-2">
            <SubmitButton color="indigo" label={showMore ? 'সংক্ষেপ করুন' : 'আরও দেখুন'} onClick={() => setShowMore(!cloneDeep(showMore))} />
          </div>
        </div>
      </div>
  )
};

export default Testimonials;