
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { Container } from './Container';
import { CourseCardGrid } from '../../../../components/courseCards';
import { getCourses } from '../../../../services/course';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { orderBy } from 'lodash';
import { LinkButton } from './LinkButton';


export default function CardSection(props) {
	const { currentInstitute } = props;
	const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;
	const [selectedGrade, setSelectedGrade] = useState('ssc');
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		async function _fetchInitialData() {
			await fetchCourses();
		};
		_fetchInitialData();
	}, [selectedGrade]);

	const fetchCourses = async () => {
		try {
			const params = {
				isFeatured: true,
				status: 'active'
			};
			const _courses = await getCourses(params);
			const orderedCourses = orderBy(_courses, 'createdAt', 'desc');
			setCourses(orderedCourses);
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<div id="allCourse" className='bg-gray-100'>
			<Container className="max-w-7xl px-4 sm:px-6">
				<div className="py-10">
					<div className="lg:py-12 py-8 space-y-3 text-center sm:space-y-5 tracking-wide ">
						{/* <h2 className="text-base  font-semibold leading-6 text-blue-900">
								কোর্সসমূহ
						</h2> */}
						<h2 className="text-3xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-4xl">
							আমাদের সবচেয়ে <span style={{ color: brandColor }} className="text-blue-500">জনপ্রিয় কোর্সসমূহ</span>
						</h2>
						<h2 className="text-xl leading-7 font-semibold text-gray-500 lg:w-1/2 mx-auto">
							অনলাইন এবং অফলাইন এর সমন্বয়ে দেশ সেরা কোর্স  সমূহে যুক্ত হও এখনই
						</h2>
					</div>

					{/* <SelectMenus selectedGrade={selectedGrade} onChange={setSelectedGrade} />

					<div className="hidden sm:flex border-b-2 border-gray-400 border-opacity-30 mb-4 md:mb-9 w-full space-x-4">
						<TabButton hadleClick={() => setSelectedGrade('ssc')} active={selectedGrade === 'ssc'} >এসএসসি</TabButton>
						<TabButton hadleClick={() => setSelectedGrade('hsc')} active={selectedGrade === 'hsc'}>এইচএসসি</TabButton>
						<TabButton hadleClick={() => setSelectedGrade('admission')} active={selectedGrade === 'admission'}>ভর্তি প্রস্তুতি</TabButton>
					</div> */}

					<div>
						<ul className="grid grid-cols-1 sm:grid-cols-3 gap-x-10 gap-y-10">
							{courses?.map((course, index) => (
								<li key={`course-index[${index}]`} className="bg-gray-50 rounded shadow">
									<CourseCardGrid course={course} />
								</li>
							))}
						</ul>
					</div>

					<div className="flex justify-center mt-6">
						<LinkButton brandColor={brandColor} actionName="সকল কোর্স দেখুন" linkTo="/learn/courses" />
					</div>
				</div>
			</Container>
		</div>
	)
}
