import { useEffect, useState } from 'react';
import {
  PlayIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  ListBulletIcon
} from '@heroicons/react/24/outline';

import ContentPicker from '../../../components/ContentPicker';
import { ActionMenu, MenuItem } from '../../../components/ActionMenu';
import ContentScheduleModal from '../../../components/modals/ContentScheduleModal';
import { ActionButton } from '../../../components/buttons';
import { filter, forEach, includes } from 'lodash';
import { getBanglaDate, getNormalizedDateTime } from '../../../utils';


const titleMapping = {
  exam: 'Select exams',
  cqExam: 'Select exams',
  offlineExam: 'Select exams',
  lesson: 'Select lessons',
  note: 'Select ebooks'

};
export default function ContentList(props) {
  const {
    contents,
    contentTypes,
    programId,
    onAdd,
    onSchedule,
    onRemove
  } = props;
  const [contentType, setContentType] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [openContentPicker, setOpenContentPicker] = useState(false);
  const [selectedContentIndex, setSelectedContentIndex] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  useEffect(() => {
    let _actionsItems = [];
    if (includes(contentTypes, 'lesson')) {
      _actionsItems.push({ name: 'Add lesson', value: 'lesson', Icon: PlayIcon  });
    }
    if (includes(contentTypes, 'exam')) {
      _actionsItems.push({ name: 'Add mcq exam', value: 'exam', Icon: ClipboardDocumentListIcon  });
    }
    if (includes(contentTypes, 'cqExam')) {
      _actionsItems.push({ name: 'Add cq exam', value: 'cqExam', Icon: ClipboardDocumentListIcon  });
    }
    if (includes(contentTypes, 'offlineExam')) {
      _actionsItems.push({ name: 'Add offline exam', value: 'offlineExam', Icon: ClipboardDocumentListIcon  });
    }
    if (includes(contentTypes, 'note')) {
      _actionsItems.push({ name: 'Add note', value: 'note', Icon: DocumentTextIcon  });
    }
    setActionItems(_actionsItems);
  }, [contentTypes]);

  const getSelectedContents = () => {
    let selectedContents = [];
    const _contents = filter(contents, content => content.contentType === contentType);
    forEach(_contents, content => {
      if (content.contentType === 'lesson') {
        selectedContents.push(content.lesson._id);
      } else if (content.contentType === 'exam') {
        selectedContents.push(content.exam._id);
      } else if (content.contentType === 'note') {
        selectedContents.push(content.note._id);
      } else if (content.contentType === 'offlineExam') {
        selectedContents.push(content.offlineExam._id);
      }
    });
    return selectedContents;
  };

  if (openContentPicker) {
    return (
      <ContentPicker
        title={titleMapping[contentType]}
        contentType={contentType}
        selectedContents={getSelectedContents()}
        programId={programId}
        onSelect={onAdd}
        onCancel={() => {
          setContentType(null);
          setOpenContentPicker(false);
        }}
      />
    )
  }

  if (showScheduleModal) {
    return (
      <ContentScheduleModal
        content={contents[selectedContentIndex]}
        onSchedule={(event, startTime, endTime) => {
          onSchedule(event, selectedContentIndex, startTime, endTime)
        }}
        onCancel={() => {
          setSelectedContentIndex(null);
          setShowScheduleModal(false);
        }}
      />
    )
  }

  return (
    <div className="sm:col-span-full">
      <div className="flex flex-col w-full">
        {contents?.map((content, index) => (
          <ContentFields 
            key={`content-index[${index}]`}
            index={index}
            content={content}
            onSchedule={() => {
              setSelectedContentIndex(index);
              setShowScheduleModal(true);
            }}
            onRemove={onRemove}
          />
        ))}
      </div>

      <div className="col-span-3">
        <ActionButton
          label="Add content"
          items={actionItems}
          onClick={(item) => {
            setContentType(item.value);
            setOpenContentPicker(true);
          }}
        />
      </div>
    </div>
  )
};

function ContentFields(props) {
  const { content, index, onSchedule, onRemove } = props;

  if (!content) { return; }

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="">
        <div className="min-w-0 flex-auto">
          <p className="flex text-sm font-semibold items-center space-x-1 leading-6 text-gray-900">
            <span>
              {content.contentType === 'lesson' &&
              <PlayIcon className="h-5 w-5" />}
              {includes(['exam', 'cqExam', 'offlineExam'], content.contentType) &&
              <ClipboardDocumentListIcon className="h-5 w-5" />}
              {content.contentType === 'note' &&
              <DocumentTextIcon className="h-5 w-5" />}
            </span>
            <span>
              {content.contentType === 'lesson' ? content.lesson.title : content[content.contentType].name}
            </span>
          </p>
          
          {/* {content.contentType === 'lesson' &&
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Status: {content.lesson.status}  | Duration: {content.lesson.formattedDuration}
          </p>}
          {includes(['exam', 'cqExam', 'offlineExam'], content.contentType) &&
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Marks: {content[content.contentType].totalMarks} | Questions: {content[content.contentType].totalQuestions}
          </p>} */}
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Start Time: {content?.startTime ? getNormalizedDateTime(content.startTime) : 'N/A'}
            {content.endTime && <span> | End Time: {content.endTime ? getNormalizedDateTime(content.endTime) : 'N/A'}</span>}
          </p>
          {content.contentType === 'note' &&
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Pages: {content?.note?.pages}
          </p>}
        </div>
      </div>
      <div>
        <ActionItems
          onSchedule={onSchedule}
          onRemove={(event) => onRemove(event, index)}
        />
      </div>
      {/* <div>
        <button
          className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
          onClick={(event) => onRemove(event, index)}
        >
          Remove
        </button>
      </div> */}
    </div>
  )
};

function ActionItems(props) {
  const { onSchedule, onRemove } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Schedule" onClick={onSchedule} />
      </div>
      <div className="py-1">
        <MenuItem label="Remove" onClick={onRemove} />
      </div>
    </ActionMenu>
  )
};