import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { cloneDeep, orderBy } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../../layouts/StackedLayout';
import { Button } from '../../../components/buttons';
import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import {
  Input,
  Select,
  RadioGroupHorizontal,
  UploadIcon,
  InlineCheckbox
} from '../../../components/forms/fields';
import { handleUploadPublicImage } from '../../../utils';
import { createBranchUser } from '../../../services/user';
import { getBranches } from '../../../services/branch';


const genderOptions = [
  {name: 'Male', value: 'male'},
  {name: 'Female', value: 'female'}
];

export default function BranchUserCreatePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [gender, setGender] = useState('male');
  const [profilePicture, setProfilePicture] = useState(null);
  const [role, setRole] = useState(null);
  const [canViewContents, setCanViewContents] = useState(false);
  const [canManageRoutines, setCanManageRoutines] = useState(false);
  const [canManageStudentAdmissions, setCanManageStudentAdmissions] = useState(false);
  const [canManageStudentPayments, setCanManageStudentPayments] = useState(false);
  const [canManageCompetitionParticipants, setCanManageCompetitionParticipants] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchBranches() {
      try {
        if (currentUser?.branch) {
          setBranch(currentUser.branch._id);
        } else {
          const _branches = await getBranches();
          setBranches(orderBy(_branches, 'name'));
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      }
    };
    fetchBranches();
    setLoading(false);
  }, []);

  useEffect(() => {
    setCanViewContents(false);
    setCanManageRoutines(false);
    setCanManageStudentAdmissions(false);
    setCanManageStudentPayments(false);
    setCanManageCompetitionParticipants(false);
  }, [role]);

  const saveUser = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        branch,
        name,
        mobile,
        gender,
        profilePicture,
        role: role || 'staff',
        canViewContents,
        canManageRoutines,
        canManageStudentAdmissions,
        canManageStudentPayments,
        canManageCompetitionParticipants
      };
      await createBranchUser(payload);
      toast.success('User created successfully');
      goBack();
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  const handleProfilePictureUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setProfilePicture(imageUrl);
      return imageUrl;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const goBack = () => {
    navigate(`/branch/users`);
  };

  const isDisabled = () => {
    return updating || !branch || !name || !mobile;
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            Create new user
          </h1>
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        disabled={isDisabled()}
        submitText="Create"
        onSubmit={saveUser}
        onCancel={goBack}
      >
        {!currentUser?.branch &&
        <FieldSet
          title="Branch"
          subTitle="Select branch."
        >
          <Select label="Branch" value={branch} options={branches} onChange={(event) => setBranch(event.target.value)} />
        </FieldSet>}

        <FieldSet
          title="Personal information"
          subTitle="Set user name, mobile and other information."
        >
          <Input col={3} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={2} label="Mobile" value={mobile} onChange={(event) => setMobile(event.target.value)} />
          <RadioGroupHorizontal label="Gender" value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
          <UploadIcon label="Profile picture" image={profilePicture} Icon={UserIcon} actionName="Change" onUpload={(files) => handleProfilePictureUpload(files)} />
        </FieldSet>

        <FieldSet
          title="Permissions"
          subTitle="Set permissions for this user."
        >
          <InlineCheckbox
            label="Branch admin"
            description="Can create batches, users, view reports and all activities for this branch."
            checked={role === 'branchAdmin'}
            onChange={() => setRole(role === 'branchAdmin' ? null : 'branchAdmin')}
          />

          <InlineCheckbox
            label="View contents"
            description="Can view questins, exams, lessons, live classes, ebooks."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canViewContents}
            onChange={() => setCanViewContents(!cloneDeep(canViewContents))}
          />

          <InlineCheckbox
            label="Manage class routines"
            description="Can add/edit online and offline class routines."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageRoutines}
            onChange={() => setCanManageRoutines(!cloneDeep(canManageRoutines))}
          />

          <InlineCheckbox
            label="Manage student admissions"
            description="Can add new students and assign new courses to the students."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageStudentAdmissions}
            onChange={() => setCanManageStudentAdmissions(!cloneDeep(canManageStudentAdmissions))}
          />

          <InlineCheckbox
            label="Manage student payments"
            description="Can collect payments, manage dues and print invoices."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageStudentPayments}
            onChange={() => setCanManageStudentPayments(!cloneDeep(canManageStudentPayments))}
          />

          <InlineCheckbox
            label="Manage competition participants"
            description="Can add new participants and download participant lists for competitions."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageCompetitionParticipants}
            onChange={() => setCanManageCompetitionParticipants(!cloneDeep(canManageCompetitionParticipants))}
          />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
