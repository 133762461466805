import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CountDown from 'count-down-react';
import toast from 'react-hot-toast';

import StackedLayout from '../../../layouts/StackedLayout';
import { images } from '../../../constants';
import { Button, SubmitButton } from '../../../components/buttons';
import Loader from '../../../components/Loader';
import CompetitionRegisterForm from './components/CompetitionRegisterForm';
import {
  getCompetitions,
  getCompetitionParticipant,
  registerCurrentParticipant
} from '../../../services/competition';
import { convertToBanglaNumber } from '../../../utils';


export default function CompetitionStudentPage() {
  const navigate = useNavigate();
  const params = useParams();
  const competitionSlug = params.competitionSlug;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [competition, setCompetition] = useState(null);
  const [competitionParticipant, setCompetitionParticipant] = useState(null);
  const [examOver, setExamOver] = useState(false);
  const [examTaken, setExamTaken] = useState(false);
  const [examStarted, setExamStarted] = useState(false);
  const [openRegistrationForm, setOpenRegistrationForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const primaryColor = currentInstitute?.brandColor ? `[${currentInstitute?.brandColor}]`  : 'indigo-600';

  useEffect(() => {
    async function _fetchData() {
      try {
        const params = {
          slug: competitionSlug,
          status: 'active'
        };
        const competitions = await getCompetitions(params);
        if (competitions.length == 0) {
          navigate(-1);
        } else {
          const _competition = competitions[0];
          if (Date.now() >= new Date(_competition.endsAt)) {
            setExamOver(true);
          }
          setCompetition(_competition);

          if (currentUser) {
            const _participant = await getCompetitionParticipant(_competition._id);
            setCompetitionParticipant(_participant);
          }
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleRegistration = () => {
    if (!currentUser) {
      navigate(`/auth/login`, {
        state: {
          redirectUrl: window.location.pathname
        }
      });
    } else {
      setOpenRegistrationForm(true);
    }
  }

  const handleSaveRegistration = async (event, payload) => {
    try {
      event.preventDefault();
      const participant = await registerCurrentParticipant(competition._id, payload);
      toast.success('Your registration is successful');
      setCompetitionParticipant(participant);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const CoundownRenderer = ({ days, hours, minutes, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      setExamStarted(true);
      // handleStartExam();
      return;
    }
    return (
      <div className="grid grid-cols-4 gap-x-6">
        <div>
          <p className="text-5xl">{convertToBanglaNumber(days)}</p>
          <p className="text-xl text-gray-600">দিন</p>
        </div>
        <div>
          <p className="text-5xl">{convertToBanglaNumber(hours)}</p>
          <p className="text-xl text-gray-600">ঘণ্টা</p>
        </div>
        <div>
          <p className="text-5xl">{convertToBanglaNumber(minutes)}</p>
          <p className="text-xl text-gray-600">মিনিট</p>
        </div>
        <div>
          <p className="text-5xl">{convertToBanglaNumber(seconds)}</p>
          <p className="text-xl text-gray-600">সেকেন্ড</p>
        </div>
      </div>
    )
  };

  return (
    <StackedLayout
      loading={loading}
      allowAnonymous={true}
      hideFooter={true}
    >
      {openRegistrationForm &&
      <CompetitionRegisterForm
        currentUser={currentUser}
        competition={competition}
        onSave={handleSaveRegistration}
        onClose={() => setOpenRegistrationForm(false)}
      />}

      <div className="flex">
        <div className="px-6 sm:px-8 lg:w-1/2 xl:pr-16">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className={`block xl:inline text-indigo-600`}>{competition?.name}</span>
            {/* <span className="block text-indigo-600 xl:inline">online business</span> */}
          </h1>
          <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {!examOver && !examTaken && Date.now() < new Date(competition?.startsAt) &&
            <p className="pt-6 text-gray-500">পরীক্ষা শুরু হতে আর বাকি</p>}
            
            {!examOver && !examTaken && Date.now() < new Date(competition?.startsAt) &&
            <div className="pt-6 flex justify-start items-center">
              <CountDown date={new Date(competition?.startsAt)} renderer={CoundownRenderer} />
            </div>}
          </p>

          {competitionParticipant && !examOver && !examTaken &&
          <p className="pt-6 text-indigo-600">আপনি সফলভাবে রেজিস্ট্রেশন করেছেন।</p>}

          {(!currentUser || !competitionParticipant) &&
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <button
                className={`flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:opacity-80 md:px-10 md:py-4 md:text-lg`}
                onClick={handleRegistration}
              >
                {`${currentUser ? 'রেজিস্ট্রেশন করুন' : 'রেজিস্ট্রেশন করতে লগইন করুন' }`}
              </button>
            </div>
          </div>}
        </div>

        <div className="lg:w-1/2">
          <img
            className="w-full"
            src={competition?.logo}
            alt=""
          />
        </div>
      </div>
    </StackedLayout>
  )
};

