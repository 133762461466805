import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

// import Link from 'next/link'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from './Button';
import { Container } from './Container'
// import { Logo } from '@/components/Logo'
import { NavLinks } from './NavLinks'
// import Image from 'next/image';
import { getCompetitions } from '../../../../services/competition';


function MenuIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function ChevronUpIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17 14l-5-5-5 5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function MobileNavLink(props) {
  return (
    <Popover.Button
      as={Link}
      className="block text-base leading-7 tracking-tight font-medium text-gray-500"
      {...props}
    />
  )
}

export default function Header(props) {
  const currentInstitute = props.currentInstitute;
  const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    async function fetchCompetitions() {
      try {
        const _competitions = await getCompetitions({ status: 'active' });
        setCompetitions(_competitions);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchCompetitions();
  }, []);

  return (
    <header className='shadow fixed w-full z-20 bg-white' >
      <nav>
        <Container className="relative z-50 flex justify-between max-w-7xl py-3 sm:py-4 px-4 sm:px-6">

          <div className="hidden sm:block">
            <a href="/">
              <img className="h-14 w-auto" src={currentInstitute?.logo} alt='Logo' />
            </a>
          </div>

          <div className="sm:hidden">
            <a href="/">
              <img className="h-8 w-auto" src={currentInstitute?.icon} alt='Icon' />
            </a>
          </div>

          <div className="relative z-10 flex items-center gap-16">
            {/* <Link href="/" aria-label="Home">
              <Logo className="h-10 w-auto" />
            </Link> */}


            <div className="hidden sm:flex sm:gap-10">
              <NavLinks competitions={competitions} />
            </div>

          </div>
          <div className="flex items-center gap-6">
            {/* <div className="">
              <a href="/">
                <img className="h-8 w-auto" src={currentInstitute?.icon} alt='Icon' />
              </a>
            </div> */}

            <Popover className="sm:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <ChevronUpIcon className="h-6 w-6" />
                      ) : (
                        <MenuIcon className="h-6 w-6" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-10 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            <a href="/">
                              <img className='h-8 w-auto' src={currentInstitute?.icon} alt='Logo' />
                            </a>
                            <MobileNavLink to="/learn/courses">
                              সকল কোর্স
                            </MobileNavLink>
                            <MobileNavLink to="/about-us">
                              আমাদের সম্পর্কে
                            </MobileNavLink>
                            <MobileNavLink to="/learn/branches">
                              যোগাযোগ
                            </MobileNavLink>
                            {competitions?.map((competition) => (
                              <MobileNavLink key={competition._id} to={`/learn/competitions/${competition.slug}`}>
                                {competition.menuName}
                              </MobileNavLink>
                            ))}
                          </div>
                          <div className="mt-8 flex flex-col gap-4">
                            {/* <Button className="shadow text-blue-900 font-semibold" href="/login" variant="outline">
                              লগ ইন
                            </Button> */}
                            <Button className="bg-blue-900 text-white" to="/auth/login">
                              লগ ইন
                            </Button>
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            {/* <Button href="/login" variant="outline" className="hidden lg:block text-blue-900">
              লগ ইন
            </Button> */}
            <Button style={{ backgroundColor: brandColor }} variant="solid" to="/auth/login" className="hidden sm:block bg-blue-900 text-white">
              লগ ইন
            </Button>
          </div>

        </Container>
      </nav>
    </header>
  )
}
