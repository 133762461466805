import { Input } from '../../../components/forms/fields';


export default function CourseReasonField(props) {
  const reason = props.reason;
  const index = props.index;
  const reasonsCount = props.reasonsCount;
  const onChange = props.onFieldChange;
  const onDelete = props.onDelete;

  return (
    <>
      <Input col={4} label={`Reason ${index+1}`} name="reason" value={reason} onChange={(event) => onChange(event, index)} />

      {reasonsCount > 1 &&
      <div className="mt-9 col-span-1">
        <a
          href="#"
          className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
          onClick={(event) => onDelete(event, index)}
        >
          Remove
        </a>
      </div>}
    </>
  )
}
