import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit, isEmpty, includes, cloneDeep, findIndex } from 'lodash';
import { PhotoIcon } from '@heroicons/react/24/outline';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { getCompetitions,changeCompetitionStatus } from '../../services/competition';
import { getNormalizedDateTime } from '../../utils';


const filters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  }
];
const headers = ['Name', 'Slug', 'Starts at', 'Ends at', 'Status'];

export default function CompetitionsPage() {
  const currentUser = useSelector((state) => state.user)?.userInfo;
  const currentBranch = useSelector((state) => state.branch)?.branchInfo;
  const [loading, setLoading] = useState(true);
  const [competitions, setCompetitions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const hasAccess = () => {
    return includes(['owner', 'admin'], currentUser?.role) ||
    currentUser?.permissions?.canManageCompetitions
  };

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    queryString,
    statusFilters,
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        search: queryString,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      if (statusFilters.length > 0) {
        params.status = statusFilters.length === 1 ? statusFilters[0] : statusFilters;
      }

      const competitions = await getCompetitions(params);
      setCompetitions(competitions.docs);
      setPagingData(omit(competitions, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setLoading(true);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    }
  };

  const handleChangeClientStatus = async (event) => {
    event.preventDefault();
    try {
      await changeCompetitionStatus(selectedCompetition._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title="Competitions"
      subTitle="List of all competitions"
      filters={filters}
      onFilterChange={applyFilterChanges}
      hideCreateNewButton={!hasAccess()}
      onCreate={() => window.location.href = '/competitions/create'}
    >
      {showStatusModal &&
      <ConfirmModal
        title={selectedCompetition.isActive ? 'Disable competition' : 'Enable competition'} 
        description={`Are you sure to ${selectedCompetition.isActive ? 'disable' : 'enable'} this competition? Please double check before performing this action.`}
        actionName={selectedCompetition.isActive ? 'Disable' : 'Enable'}
        onConfirm={handleChangeClientStatus}
        onCancel={() => {
          setSelectedCompetition(null);
          setShowStatusModal(false);
        }}
      />}

      {/* {showDownloadResultModal&&
      <DownloadCompetitionResultModal
        competition={selectedCompetition}
        onCancel={() => {
          setSelectedCompetition(null);
          setShowDownloadResultModal(false);
        }}
      />} */}

      {/* {openUploadParticipantsForm &&
      <UploadParticipantsForm
        competition={selectedCompetition}
        onClose={() => {
          setSelectedCompetition(null);
          setOpenUploadParticipantsForm(false);
        }}
      />} */}

      <Table 
        headers={headers}
        itemsCount={competitions?.length}
        pagingData={pagingData}
        queryString={queryString}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {competitions?.map((competition) => (
          <tr key={competition._id} className="text-sm text-center">
            <td className="py-4 pl-4 pr-3 text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {competition.name}
            </td>
            <td className="pr-3 py-4 text-gray-500">{competition.slug}</td>
            <td className="pr-3 py-4 text-gray-500">{getNormalizedDateTime(competition.startsAt)}</td>
            <td className="pr-3 py-4 text-gray-500">{getNormalizedDateTime(competition.endsAt)}</td>
            <td className="pr-3 py-4 text-gray-500">{competition.status}</td>
            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right font-medium sm:pr-0">
              <ActionItems 
                competition={competition}
                currentUser={currentUser}
                onStatusChange={() => {
                  setSelectedCompetition(competition);
                  setShowStatusModal(true);
                }}
              />
            </td>
          </tr>
        ))}
      </Table>
    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const { currentUser, competition, onStatusChange } = props;
  return (
    <ActionMenu>
      {competition.status === 'active' &&
      <div className="py-1">
        <MenuItem label="View landing page" href={`/learn/competitions/${competition.slug}`} />
      </div>}
      {(includes(['owner', 'admin'], currentUser?.role) || currentUser?.permissions?.canManageCompetitions) &&
      <div className="py-1">
        <MenuItem label="Edit" href={`/competitions/${competition._id}/edit`} />
      </div>}
      <div className="py-1">
        <MenuItem label="Participants" href={`/competitions/${competition._id}/participants`} />
      </div>
      {/* <div className="py-1">
        <MenuItem label="Download result" onClick={onDownloadResult} />
      </div> */}

      {includes(['owner', 'admin'], currentUser?.role) &&
      <div className="py-1">
        <MenuItem label={competition.status === 'active' ? 'Disable' : 'Enable'} isDanger={competition.status === 'active'} onClick={onStatusChange} />
      </div>}
    </ActionMenu>
  )
};

