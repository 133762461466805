import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { orderBy } from 'lodash';
import {add, startOfToday, startOfWeek, startOfMonth, endOfWeek} from 'date-fns';

import Loader from '../Loader';
import Calendar from '../Calendar';
import LiveClassForm from './LiveClassForm';
import RecurringLiveClassForm from './RecurringLiveClassForm';
import {
  getLiveClasses,
  createLiveClass,
  createRecurringLiveClass,
  updateLiveClass,
  deleteLiveClass
} from '../../services/liveClass';


export default function LiveClassRoutinePage(props) {
  const { course, batch, courseType } = props;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [liveClasses, setLiveClasses] = useState([]);
  const [selectedLiveClass, setSelectedLiveClass] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openRecurringEventForm, setOpenRecurringEventForm] = useState(false);
  const [startDate, setStartDate] = useState(startOfToday());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    }
    _fetchData();
  }, [course, batch]);

  const fetchData = async (_startDate) => {
    try {
      _startDate = _startDate || startDate;
      const startDayCurrentMonth = startOfMonth(_startDate);
      const startDayNextMonth = add(startDayCurrentMonth, {months: 1});
      let params = {
        startDate: startOfWeek(startDayCurrentMonth, {weekStartsOn: 6}),
        endDate: endOfWeek(startDayNextMonth, {weekStartsOn: 6})
      };

      if (courseType) { params.courseType = courseType };
      if (course) { params.course = course._id; }
      if (batch) { params.batch = batch._id; }
      if (courseType === 'offline' && currentUser?.branch) {
        params.branch = currentUser?.branch?._id
      }
      const _liveClasses = await getLiveClasses(params);
      setLiveClasses(orderBy(_liveClasses, 'startsAt'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveLiveClass = async (event, payload) => {
    try {
      event.preventDefault();

      if (selectedLiveClass && selectedLiveClass?._id) {
        await updateLiveClass(selectedLiveClass._id, payload);
      } else {
        if (courseType === 'offline') {
          payload.branch = payload.branch || currentUser?.branch._id;
        } else {
          delete payload?.branch;
        }
        await createLiveClass(payload);
      }
      await fetchData();
      toast.success(`Event ${selectedLiveClass ? 'updated' : 'created'} successfully.`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedLiveClass(null);
    }
  };

  const saveRecurringLiveClass = async (event, payload) => {
    try {
      event.preventDefault();

      if (courseType === 'offline') {
        payload.branch = payload.branch || currentUser?.branch._id;
      } else {
        delete payload?.branch;
      }
      await createRecurringLiveClass(payload);
      await fetchData();
      toast.success(`Event ${selectedLiveClass ? 'updated' : 'created'} successfully.`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedLiveClass(null);
    }
  };

  const handleEventStatusChange = async (event, liveClassId, _status) => {
    try {
      event.preventDefault();

      const payload = { status: _status };
      await updateLiveClass(liveClassId, payload);
      await fetchData();
      toast.success('Event updated successfully.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteLiveClass = async (event, liveClassId) => {
    try {
      event.preventDefault();
      await deleteLiveClass(liveClassId);
      await fetchData();
      toast.success('Event deleted successfully.')
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMonthChange = async (_startDate) => {
    setStartDate(_startDate);
    await fetchData(_startDate);
  };

  if (loading) {
    return (
      <Loader />
    )
  }
  
  return (
    <>
      {openForm &&
      <LiveClassForm
        liveClass={selectedLiveClass}
        course={course}
        batch={batch}
        userBranch={currentUser?.branch}
        courseType={courseType}
        onSave={saveLiveClass}
        onClose={() => setOpenForm(false)}
      />}
      {openRecurringEventForm &&
      <RecurringLiveClassForm
        course={course}
        batch={batch}
        userBranch={currentUser?.branch}
        courseType={courseType}
        onSave={saveRecurringLiveClass}
        onClose={() => setOpenRecurringEventForm(false)}
      />}

      <Calendar
        events={liveClasses}
        courseType={courseType}
        onEditEvent={(item) => {
          setSelectedLiveClass(item);
          setOpenForm(true);
        }}
        onAddEvent={() => {
          setSelectedLiveClass(null);
          setOpenForm(true);
        }}
        onAddRecurringEvent={() => {
          setSelectedLiveClass(null);
          setOpenRecurringEventForm(true);
        }}
        onEventStatusChange={(event, liveClassId, status) => {
          handleEventStatusChange(event, liveClassId, status);
        }}
        onMonthChange={handleMonthChange}
        onDelete={handleDeleteLiveClass}
      />
    </>
  )
}