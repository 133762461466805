import http from '../helpers/http';


export const createCreativeQuestion = async (payload) => {
  try {
    const { data } = await http.post(`/api/creative-questions`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCreativeQuestion = async (id) => {
  try {
    const { data } = await http.get(`/api/creative-questions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateCreativeQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/creative-questions/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteCreativeQuestion = async (id) => {
  try {
    const { data } = await http.delete(`/api/creative-questions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCreaiveQuestions = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/creative-questions`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
