import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Input } from '../forms/fields';
import { SubmitButton, Button } from '../buttons';
import { getNormalizedDateTime } from '../../utils';
import { includes } from 'lodash';


export default function ContentScheduleModal(props) {
  const {
    content,
    onSchedule,
    onCancel
  } = props;
  
  const [startTime, setStartTime] = useState(content?.startTime);
  const [endTime, setEndTime] = useState(content?.endTime);
  const [open, setOpen] = useState(true);

  const handleSchedule = (event, ) => {
    onSchedule(event, startTime, endTime);
    handleCancel();
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Schedule {content?.contentType}
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        <Input type="datetime-local" label="Start time" value={startTime ? getNormalizedDateTime(startTime, 'yyyy-MM-DDTHH:mm') : ''} onChange={(event) => setStartTime(event.target.value)} />
                      </div>
                      {content?.contentType && !includes(['lesson', 'note'], content?.contentType) &&
                      <div className="mt-2 w-full">
                        <Input type="datetime-local" label="End time" value={endTime ? getNormalizedDateTime(endTime, 'yyyy-MM-DDTHH:mm') : ''} onChange={(event) => setEndTime(event.target.value)} />
                      </div>}
                    </div>

                  </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-x-4">
                  <SubmitButton label="Schedule" onClick={handleSchedule} />
                  <Button onClick={handleCancel} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
