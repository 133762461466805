import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import TextEditor from '../../components/TextEditor';
import { SubmitButton } from '../../components/buttons';
import {
  getAboutUsContent,
  createWebsiteContent,
  updateWebsiteContent
} from '../../services/websiteContent';


const sidebarNavigation = [
  { name: 'Web banners', href: '/website-contents/web-banners' },
  { name: 'Mobile banners', href: '/website-contents/mobile-banners' },
  { name: 'Testimonials', href: '/website-contents/testimonials' },
  { name: 'About us', href: '/website-contents/about-us' },
  { name: 'Terms & Privacy', href: '/website-contents/terms' },
];

export default function AboutUsContentPage() {
  const [loading, setLoading] = useState(true);
  const [aboutUsContent, setAboutUsContent] = useState(null);
  const [content, setContent] = useState('');
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _websiteContent = await getAboutUsContent();
        setAboutUsContent(_websiteContent);
        setContent(_websiteContent?.content || '');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const saveContent = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = { content: content };
      if (aboutUsContent?._id) {
        await updateWebsiteContent(aboutUsContent?._id, payload);
        toast.success('Content updated successfully.');
      } else {
        payload.contentType = 'aboutUs';
        const websiteContent = await createWebsiteContent(payload);
        setAboutUsContent(websiteContent);
        toast.success('Content updated successfully.');
      }

    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="About us"
    >
      <div>
        <h3>About us</h3>

        <div>
          <TextEditor
            id={`about-us-editor`} 
            value={content}
            onChange={(value) => setContent(value)}
          />
        </div>

        {/* <p>{content}</p> */}

        <div>
          <SubmitButton disabled={updating} label="Save" onClick={saveContent} />
        </div>

      </div>

    </StackedLayout>
  )
};
