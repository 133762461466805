import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PhotoIcon, DocumentIcon } from '@heroicons/react/24/solid';
import { round } from 'lodash';

import PlaceholderLoader from '../../../components/PlaceholderLoader';
import { Button } from '../../buttons';
import { getFileSize } from '../../../utils';


export default function UploadWithDragDrop(props) {
  let {
    col,
    label,
    actionName,
    onUpload,
    contentType,
    content,
    showCancelButton,
    onCancel
  } = props;
  col = col ? col.toString() : 'full';
  contentType = contentType || 'image';
  const [uploadedContent, setUploadedContent] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setUploadedContent(content);
  }, [content]);
  
  let acceptContent  = {};
  if (contentType === 'image') {
    acceptContent['image/*'] = ['.jpg', '.jpeg', '.png', '.gif'];
  } else if (contentType === 'pdf') {
    acceptContent['application/pdf'] = ['.pdf'];
  } else if (contentType === 'excel') {
    acceptContent['text/csv'] = ['.csv'];
    acceptContent['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = ['.xlsx'];
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptContent,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setIsUploading(true);
      const _uploadedContent = await onUpload(acceptedFiles);
      setUploadedContent(_uploadedContent);
      setIsUploading(false);
    },
  });

  return (
    <div className={`col-span-${col}`}>
      {label &&
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>}
      <div 
        className={`${label ? 'my-2' : ''} flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10`}
        {...getRootProps()}
      >
        {isUploading && <PlaceholderLoader />}

        {!isUploading &&
        <div className="text-center">
          {!uploadedContent && contentType === 'image' && <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />}
          {!uploadedContent && contentType !== 'image' && <DocumentIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />}
          
          {uploadedContent && contentType === 'image' &&
          <img className="mx-auto h-32" src={uploadedContent} aria-hidden="true" />}
          {uploadedContent && contentType === 'excel' &&
          <p className="text-sm text-gray-600">{uploadedContent.name} ({getFileSize(uploadedContent.size)})</p>}
          
          <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
            <input {...getInputProps()} />
            <p>
              <span className="relative cursor-pointer rounded-md bg-white font-semibold text-green-600  hover:text-green-500">
                {actionName}
              </span>
              &nbsp;or drag and drop files here
            </p>
          </div>
          <p className="text-xs leading-5 text-gray-600">
            {contentType === 'image' ? 'PNG, JPG, JPEG only' : contentType === 'excel' ? 'EXCEL, CSV only' : 'PDF documents only'}
          </p>
        </div>}
      </div>

      {showCancelButton &&
      <Button className="float-right"
        action="Cancel"
        onClick={onCancel}
      />}
    </div>
  )
};
