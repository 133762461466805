import { useState } from 'react';
import { CircleStackIcon } from '@heroicons/react/24/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function UploadIcon(props) {
  const { label, actionName, onUpload } = props;
  const col = props?.col?.toString() || 'full';
  const Icon = props.Icon || CircleStackIcon;
  const [image, setImage] = useState(props.image);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (event) => {
    try {
      event.preventDefault();
      setUploading(true);
      const files = event.target.files;
      const _image = await onUpload(files);
      setImage(_image);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <div 
      className={classNames(
        props?.className || '',
        `col-span-${col}`
      )}
    >
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className={`${label ? 'mt-2' : ''} flex items-center gap-x-3`}>
        {!image && <Icon className="h-8 w-8 rounded-full text-gray-300" aria-hidden="true" />}
        {image && <img className="rounded-full h-12 w-12" src={image} />}

        <label
          className={`rounded-md cursor-pointer bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${uploading && 'opacity-30'}`}
        >
          <span>{actionName}</span>
          <input disabled={uploading} type="file" className="sr-only" accept="image/*" onChange={handleUpload} />
        </label>
      </div>
    </div>
  )
}
