import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { ChevronRightIcon, ClipboardDocumentListIcon, FolderIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { findIndex, forEach, isEmpty, orderBy, capitalize, includes } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import FolderBreadcrumb from '../../components/FolderBreadcrumb';
import { ButtonWithIcon } from '../../components/buttons';
import InputModal from '../../components/modals/InputModal';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { getFolder, createFolder, getFolders, updateFolder, deleteFolder } from '../../services/folder';
import { getExams, deleteExam, changeExamStatus } from '../../services/exam';
import { getCreativeExams, deleteCreativeExam, changeCreativeExamStatus } from '../../services/creativeExam';
import { getOfflineExams, deleteOfflineExam } from '../../services/offlineExam';


const sidebarNavigation = [
  { name: 'MCQ Exam Papers', value: 'mcq', href: '/contents/exams/mcq' },
  { name: 'CQ Exam Papers', value: 'cq', href: '/contents/exams/cq' },
  { name: 'Offline Exams', value: 'offline', href: '/contents/exams/offline' },
];
const branchSidebarNavigation = [
  { name: 'MCQ Exam Papers', value: 'mcq', href: '/contents/exams/mcq' },
  { name: 'CQ Exam Papers', value: 'cq', href: '/contents/exams/cq' },
];
const folderTypeMapping = {
  mcq: 'mcqExam',
  cq: 'cqExam',
  offline: 'offlineExam'
};
const questionTypeLabels = {
  mcq: 'MCQ',
  cq: 'Written',
  mcqCq: 'Wriiten + MCQ'
};
const headers = ['Name', 'Created by', 'Questions', 'Marks', 'Status'];
const offlineHeaders = ['Name', 'Created by', 'Question type', 'Marks'];

export default function ExamsPage(props) {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const params = useParams();
  const navigate = useNavigate();
  const folderId = params?.folderId;
  const { examType } = props;
  const folderType = folderTypeMapping[examType];
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentNaviation, setCurrentNavigation] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState(false);
  const [showExamDeleteModal, setShowExamDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitialData() {
      const navIndex = findIndex(sidebarNavigation, item => item.value === examType);
      setCurrentNavigation(sidebarNavigation[navIndex]);

      if (folderId) {
        try {
          const _folder = await getFolder(folderId);
          setCurrentFolder(_folder);
        } catch (error) {
          navigate(-1);
        }
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    examType,
    queryString,
    currentFolder
  ]);

  const fetchData = async () => {
    try {
      const exams = await fetchExams();
      let folders = await fetchFolders();
      forEach(folders, folder => folder.isFolder = true);
      const _items = folders.concat(exams);
      setItems(_items);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchExams = async () => {
    try {
      let params = {
        search: queryString
      };

      if (currentFolder) {
        params.folder = currentFolder._id;
      }

      let data = [];
      if (examType === 'mcq') {
        data = await getExams(params);
      } else if (examType === 'cq') {
        data = await getCreativeExams(params);
      } else {
        data = await getOfflineExams(params);
      }
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFolders = async () => {
    try {
      let params = {
        contentType: folderType,
        search: queryString,
      };

      if (currentFolder) {
        params.parentFolder = currentFolder._id;
      } else {
        params.isRoot = true;
      }
      const data = await getFolders(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteExam = async (event) => {
    try {
      event.preventDefault();
      if (examType === 'mcq') {
        await deleteExam(selectedExam._id);
      } else if (examType === 'cq') {
        await deleteCreativeExam(selectedExam._id);
      } else {
        await deleteOfflineExam(selectedExam._id);
      }
      toast.success('Exam deleted successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleExamStatusChange = async (event) => {
    try {
      event.preventDefault();
      if (examType === 'mcq') {
        await changeExamStatus(selectedExam._id);
      } else if (examType === 'cq') {
        await changeCreativeExamStatus(selectedExam._id);
      }
      toast.success('Exam updated successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString : null;
    setQueryString(q);
    setLoading(true);
  };

  const saveFolder = async (event, name) => {
    try {
      event.preventDefault();
      if (selectedFolder) {
        const payload = { name: name };
        await updateFolder(selectedFolder._id, payload);
        toast.success('Folder updated successfully.');
      } else {
        let payload = { name: name, contentType: folderType };
        if (currentFolder) { payload.parentFolder = currentFolder._id };
        await createFolder(payload);
        toast.success('Folder created successfully');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleDeleteFolder = async (event) => {
    try {
      event.preventDefault();
      await deleteFolder(selectedFolder._id);
      toast.success('Folder deleted successfully');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedFolder(null);
    }
  };

  const handleCreateNew = (itemName) => {
    if (itemName === 'folder') {
      setOpenFolderModal(true);
    } else {
      navigate(`/contents/exams/${examType}/create`, {
        state: {
          folderId: folderId
        }
      });
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={currentUser.branch ? branchSidebarNavigation : sidebarNavigation}
      currentSidebarNavigation={currentNaviation?.name}
    >
      {openFolderModal &&
        <InputModal
          label={selectedFolder ? 'Rename folder' : 'New folder name'}
          currentValue={selectedFolder?.name}
          actionName={selectedFolder ? 'Rename' : 'Create'}
          onSubmit={saveFolder}
          onCancel={() => setOpenFolderModal(false)}
        />}

      {showStatusModal &&
        <ConfirmModal
          title={selectedExam?.status === 'active' ? 'Disable exam' : 'Enable exam'}
          description={`Are you sure to ${selectedExam?.status === 'active' ? 'disable' : 'enable'} this exam? Please double check before performing this action.`}
          actionName={selectedExam?.status === 'active' ? 'Disable' : 'Enable'}
          onConfirm={handleExamStatusChange}
          onCancel={() => {
            setSelectedExam(null);
            setShowStatusModal(false);
          }}
        />}

      {showExamDeleteModal &&
        <ConfirmModal
          title="Delete exam"
          description="Are you sure to delete this exam? Please double check before performing this action."
          actionName="Delete"
          onConfirm={handleDeleteExam}
          onCancel={() => {
            setSelectedExam(null);
            setShowExamDeleteModal(false);
          }}
        />}

      {showFolderDeleteModal &&
        <ConfirmModal
          title="Delete folder"
          description={`Are you sure to delete folder? Please note that all the child folders and contents for this folder will be deleted as well.`}
          actionName="Delete"
          onConfirm={handleDeleteFolder}
          onCancel={() => {
            setSelectedFolder(null);
            setShowFolderDeleteModal(false);
          }}
        />}

      {!currentUser.branch &&
        <div className="mx-2 flex justify-end space-x-4">
          <ButtonWithIcon Icon={FolderIcon} label="New folder" onClick={() => handleCreateNew('folder')} />
          <ButtonWithIcon Icon={ClipboardDocumentListIcon} label="New exam" onClick={() => handleCreateNew('exam')} />
        </div>}

      {currentFolder &&
        <div className="px-4 sm:px-6 lg:px-8">
          <FolderBreadcrumb currentFolder={currentFolder} homeUrl={currentNaviation.href} />
        </div>}

      <div className="flex flex-col">
        <Table
          itemsCount={items.length}
          headers={examType === 'offline' ? offlineHeaders : headers}
          queryString={queryString}
          onSearch={onSearch}
        >
          {items?.map((item) => (
            <tr key={item._id} className=" text-sm text-center">
              <td className="py-4 pl-4 pr-3 text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                <div className="flex items-center">
                  <div>
                    {item.isFolder ?
                      <FolderIcon className="h-5 w-5" />
                      : <ClipboardDocumentListIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      <a
                        href={item.isFolder ? `/contents/exams/${examType}/folder/${item._id}` : `/contents/exams/${examType}/${item._id}/edit`}
                        className="hover:underline"
                      >
                        {item?.name}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
              <td className="pr-3 py-4 text-gray-500">
                <div className="flex items-center justify-center">
                  <div>
                    {item?.createdBy?.profilePicture ?
                      <img className="h-5 w-5 rounded-full" src={item.createdBy.profilePicture} />
                      : <UserCircleIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item?.createdBy?.name.split(' ').slice(0, 2).join(' ')}
                    </div>
                  </div>
                </div>
              </td>
              <td className="pr-3 py-4 text-gray-500">
                {item.isFolder ? '-' : examType === 'offline' ? questionTypeLabels[item?.questionType] : (item?.totalQuestions || item?.questions?.length)}
              </td>
              <td className="pr-3 py-4 text-gray-500">
                {item.isFolder ? '-' : includes(['mcq', 'offline'], examType) ? item?.totalMarks : getTotalMarks(item)}
              </td>
              {examType !== 'offline' &&
                <td className={`pr-3 py-4`}>
                  {item?.status ? capitalize(item.status) : '-'}
                </td>}

              {currentUser.branch && item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-sm font-medium sm:pr-0">
                  <div className="flex justify-end">
                    <a href={`/contents/exams/${examType}/folder/${item._id}`}>
                      <ChevronRightIcon className="h-4 w-4" />
                    </a>
                  </div>
                </td>}

              {!currentUser.branch && item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <FolderActionItems
                    currentUser={currentUser}
                    onRename={() => {
                      setSelectedFolder(item);
                      setOpenFolderModal(true);
                    }}
                    onDelete={() => {
                      setSelectedFolder(item);
                      setShowFolderDeleteModal(true);
                    }}
                  />
                </td>}

              {!item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <ActionItems
                    currentUser={currentUser}
                    exam={item}
                    examType={examType}
                    onEdit={() => {
                      navigate(`/contents/exams/${examType}/${item._id}/edit`, {
                        state: {
                          folderId: folderId
                        }
                      })
                    }}
                    onManageQuestions={() => navigate(`/contents/exams/${examType}/${item._id}/questions`)}
                    onStatusChange={() => {
                      setSelectedExam(item);
                      setShowStatusModal(true);
                    }}
                    onDelete={() => {
                      setSelectedExam(item);
                      setShowExamDeleteModal(true);
                    }}
                  />
                </td>}
            </tr>
          ))}
        </Table>
      </div>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    currentUser,
    exam,
    examType,
    onEdit,
    onManageQuestions,
    onStatusChange,
    onDelete
  } = props;

  return (
    <ActionMenu>
      {!currentUser.branch &&
        <>
          <div className="py-1">
            <MenuItem label="Edit" onClick={onEdit} />
          </div>

          {examType !== 'offline' &&
            <>
              <div className="py-1">
                <MenuItem label="Manage questions" onClick={onManageQuestions} />
              </div>
              <div className="py-1">
                <MenuItem label={exam.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
              </div>
            </>}
        </>}

      {examType !== 'offline' &&
        <div className="py-1">
          <MenuItem label="Download" onClick={() => { }} />
        </div>}

      {!currentUser.branch &&
        <div className="py-1">
          <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
        </div>}
    </ActionMenu>
  )
};

function FolderActionItems(props) {
  const { currentUser, onRename, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Rename" onClick={onRename} />
      </div>

      {includes(['owner', 'admin'], currentUser?.role) &
        <div className="py-1 text-red-600">
          <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
        </div>}
    </ActionMenu>
  )
};

function getTotalMarks(exam) {
  let totalMarks = 0;
  forEach(exam.questions, q => totalMarks = totalMarks + q.totalMarks);
  return totalMarks;
}
