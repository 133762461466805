import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid'


export default function Breadcrumb(props) {
  const { pages, homeUrl } = props;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href={homeUrl || '/services'} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages?.map((page, index) => (
          <li key={`${page.name}[${index}]`}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {page.current ? 
              <p
                className="ml-4 text-sm font-medium text-gray-400"
                aria-current="page"
              >
                {page.name}
              </p>
              :
              <a
                href={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </a>}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}