import { useState } from 'react';
import { cloneDeep, forEach, isEmpty, map } from 'lodash';
import Latex from 'react-latex';

import RichTextEditor from '../../../components/TextEditor';
import { PlusButton, MinusButton } from '../../../components/buttons';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { alphabet } from '../../../utils';
import { Input } from '../../../components/forms/fields';


const newSubQuestion = {question: '', marks: 0};
const newQuestion = {
  name: '',
  comprehension: '',
  totalMarks: 0,
  questionSet: [
    {question: '', marks: 0}
  ],
};

export default function CreativeQuestionEditor(props) {
  const {
    onClose,
    onSave
  } = props;
  const [question, setQuestion] = useState(props.question || newQuestion);
  const [updating, setUpdating] = useState(false);
  const [editingComprehension, setEditingComprehension] = useState(true);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);

  const handleNameChange = (event) => {
    let _question = cloneDeep(question);
    _question.name = event.target.value;
    setQuestion(_question);
  };

  const handleComprehensionInput = (value) => {
    let _question = cloneDeep(question);
    _question.comprehension = value;
    setQuestion(_question);
  };

  const handleQuestionChange = (value, questionIndex) => {
    let _question = cloneDeep(question);
    _question.questionSet[questionIndex].question = value;
    setQuestion(_question);
  };

  const handleQuestionMarksChange = (marks, questionIndex) => {
    let _question = cloneDeep(question);
    _question.questionSet[questionIndex].marks = marks;

    let totalMarks = 0;
    forEach(_question.questionSet, q => totalMarks = totalMarks + (parseInt(q.marks) || 0));
    _question.totalMarks = totalMarks;
    setQuestion(_question);
  };

  const addSubQuestion = () => {
    const _question = cloneDeep(question);
    let subQuestions = _question.questionSet || [];
    subQuestions.push(newSubQuestion);
    _question.questionSet = subQuestions;
    setQuestion(() => _question);
    setEditingQuestionIndex(_question.questionSet.length - 1);
    setEditingComprehension(false);
  };

  const removeSubQuestion = (index) => {
    const _question = cloneDeep(question);
    let questionSet = _question.questionSet;
    questionSet.splice(index, 1);
    _question.questionSet = questionSet;
    setQuestion(() => _question);
    if (editingQuestionIndex === index) {
      setEditingQuestionIndex(null);
    }
  };

  const handleSaveQuestion = async (event) => {
    try {
      setUpdating(true);
      await onSave(event, question);
      onClose();
    } catch {
      setUpdating(false);
    }
  };

  const editComprehension = () => {
    setEditingComprehension(true);
    setEditingQuestionIndex(null);
  };

  const editQuestion = (index) => {
    setEditingQuestionIndex(index);
    setEditingComprehension(false);
  };

  const isDisabled = () => {
    return updating || isEmpty(question?.name) ||
    isEmpty(question?.comprehension) || question?.comprehension === '<p><br></p>' ||
    question?.questionSet?.some(q => isEmpty(q.question) || q.question === '<p><br></p>') ||
    question?.questionSet?.some(q => isEmpty(q.marks.toString()) || q.marks === 0);
  };

  return (
    <Sidepanel
      title="Question editor"
      subTitle="You are editing a question. Please save the question once editing is done."
      updating={updating}
      disabled={isDisabled()}
      onSave={handleSaveQuestion}
      onClose={onClose}
    >
      <SidepanelField
        label="Name"
      >
        <Input value={question.name} onChange={handleNameChange} />

      </SidepanelField>

      <SidepanelField
        label="Total marks"
      >
        <p>{question.totalMarks}</p>

      </SidepanelField>

      <SidepanelField
        col={3}
        label="Comprehension"
      >
        {!editingComprehension &&
        <TextPanel text={question?.comprehension || ''} onEdit={editComprehension} />}

        {editingComprehension &&
        <RichTextEditor
          id={`comprehension-editor`} 
          value={question?.comprehension || ''}
          onChange={(value) => handleComprehensionInput(value)}
        />}
      </SidepanelField>

      {question?.questionSet?.map((sunQuestion, questionIndex) => (
        <SidepanelField
          key={`question-index[${questionIndex}]`}
          col={3}
          label={`Question ${alphabet[questionIndex]}`}
        >
          {editingQuestionIndex !== questionIndex &&
          <TextPanel text={sunQuestion?.question || ''} onEdit={() => editQuestion(questionIndex)} />}

          {editingQuestionIndex === questionIndex &&
          <RichTextEditor
            id={`question-editor-index[${questionIndex}]`} 
            value={sunQuestion?.question || ''}
            onChange={(value) => handleQuestionChange(value, questionIndex)}
          />}

          <div className="flex">
            <div className="flex items-center space-x-2">
              <span>Marks: </span>
              <Input value={sunQuestion.marks} onChange={(event) => handleQuestionMarksChange(event.target.value, questionIndex)} />
            </div>
          </div>
          <div className="mt-4 flex space-x-3">
            {questionIndex > 0 &&
            <MinusButton label="Remove" onClick={() => removeSubQuestion(questionIndex)} />}

            {questionIndex === (question.questionSet.length - 1) &&
            <PlusButton label="Add question" onClick={addSubQuestion} />}
          </div>
        </SidepanelField>
      ))}
    </Sidepanel>
  )
};

function TextPanel(props) {
  const { text, onEdit } = props;
  return (
    <span className="flex questions-answer-wrapper text-sm leading-6 text-gray-900 line-clamp-2">
      {text.includes("$$") ? 
      <Latex>{text}</Latex>
      : <span dangerouslySetInnerHTML={{ __html: text }}/>}
      <span 
        className="text-green-600 hover:text-green-900 cursor-pointer" 
        onClick={onEdit}
      >
        {'...Edit'}
      </span>
    </span>
  )
};
