import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { UploadWithDragDrop } from '../../../components/forms/fields'
import { handleUploadLocalFile } from '../../../utils';
import { addMarksheet } from '../../../services/takenExam';


export default function UploadMarksheetForm(props) {
  const {
    course,
    exam,
    onClose
  } = props;
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [updating, setUpdating] = useState(false);

  const handleSelectFile = async (files) => {
    setSelectedFiles(files);
    return files[0];
  };

  const handleUploadMarksheet = async () => {
    try {
      setUpdating(true);
      const filePath = await handleUploadLocalFile(selectedFiles);
      const payload = {
        courseId: course._id,
        examId: exam?._id,
        filePath: filePath
      };
      await addMarksheet(payload);
      toast.success('Marksheet is being proccessed.');
      onClose();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Sidepanel
      title="Upload marksheet"
      subTitle={`Course: ${course?.name}; Exam: ${exam?.name}`}
      action="Upload"
      disabled={!selectedFiles || updating}
      updating={updating}
      onSave={handleUploadMarksheet}
      onClose={onClose}
    >
      <SidepanelField
        col={3}
        label="Upload marksheet"
      >
        <UploadWithDragDrop
          actionName="Upload marksheet"
          contentType="excel"
          content={selectedFiles && selectedFiles[0]}
          onUpload={handleSelectFile}
        />
      </SidepanelField>

    </Sidepanel>
  )
};