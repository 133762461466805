import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { forEach, includes } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import { ButtonWithIcon } from '../../components/buttons';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import UploadMarksheetForm from './components/UploadMarksheetForm';
import ConfirmModal from '../../components/modals/ConfirmModal';
import DownloadMeriList from '../../components/modals/DownloadMeritList';
import { getCourse } from '../../services/course';
import { getCourseContents } from '../../services/courseContent';
import { publishOfflineExam } from '../../services/takenExam';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Offline exams', value: 'offline' },
  { name: 'Online exams', value: 'online' },
];
const headers = ['Exam', 'Marks', 'Status', 'Attendees', 'Last updated'];

export default function CourseResultExamsPage() {
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.courseId;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [course, setCourse] = useState(null);
  const [currentSidebarNavigation, setCurrentSidebarNavigation] = useState(sidebarNavigation[0]);
  const [contentType, setContentType] = useState('offlineExam');
  const [contents, setContents] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [openMarksheetForm, setOpenMarksheetForm] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _course = await getCourse(courseId);
        await fetchExamContents(_course);
        setCourse(_course);

        setBreadcrumbPages([
          { name: 'Results', href: '/results/courses' },
          { name: 'Courses', current: true },
          { name: _course.name, current: true },
        ])
      } catch (error) {
        navigate(-1);
      }
    };
    _fetchData();
    setLoading(false);
  }, []);

  const fetchExamContents = async (_course) => {
    try {
      _course = _course || course;
      const params = {
        course: _course._id,
        contentType: contentType
      }
      const courseContents = await getCourseContents(params);
      let _contents = [];
      forEach(courseContents[0]?.topics, topic => {
        forEach(topic?.contents, content => {
          _contents.push(content)
        });
      });
      setContents(_contents);
    } catch (error) {
      toast.error(error.message);
      setContents([]);
    }
  };

  const handlePublishResult = async (event) => {
    try {
      event.preventDefault();
      const payload = {
        courseId: courseId,
        examId: selectedExam._id
      };
      await publishOfflineExam(payload);
      toast.success('Merit list is being updated.');
      await fetchExamContents();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleNavigationChange = (event, index) => {
    const _currentSidebarNavigation = sidebarNavigation[index];
    setCurrentSidebarNavigation(_currentSidebarNavigation);

    if (_currentSidebarNavigation.value === 'offline') {
      setContentType('offlineExam');
    } else {
      setContentType('exam');
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      onNavigationChange={handleNavigationChange}
      currentSidebarNavigation={currentSidebarNavigation?.name}
      breadcrumbPages={breadcrumbPages}
    >
      {showDownloadModal &&
      <DownloadMeriList
        course={course}
        exam={selectedExam}
        onCancel={() => setShowDownloadModal(false)}
      />}
      {openMarksheetForm &&
      <UploadMarksheetForm
        course={course}
        exam={selectedExam}
        onClose={() => {
          setSelectedExam(null);
          setOpenMarksheetForm(false);
        }}
      />}
      {showPublishModal &&
      <ConfirmModal
        title="Publish result"
        description="Are you sure to publish result for this exam? Once result is published, no branch can upload marksheet for this exam."
        actionName="Publish"
        onConfirm={handlePublishResult}
        onCancel={() => {
          setSelectedExam(null);
          setShowPublishModal(false);
        }}
      />}
      <SectionHeader
        title={course?.name}
        subTitle="See results for all exams of this course."
      >
        <div>
          <div className="flex space-x-4">
            <ButtonWithIcon label="View results" Icon={EyeIcon} onClick={() => window.location.href = `/results/course/${courseId}/meritlists`} />
            <ButtonWithIcon label="Download results" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadModal(true)} />
          </div>
        </div>
      </SectionHeader>

      <Table
        headers={headers}
        itemsCount={contents.length}
      >
        {contents.map((content, index) => (
          <tr 
            key={`content-index[${index}]`}
            className="text-sm text-gray-500 text-center"
          >
            <td className="py-4 pl-4 pr-3 font-medium sm:pl-0 text-ellipsis text-left">
              {content?.offlineExam?.name}
            </td>
            <td className="pr-3 py-4">{content?.offlineExam?.totalMarks}</td>
            <td className="pr-3 py-4">{content?.resultStatus}</td>
            <td className="pr-3 py-4">{content?.takenExamCount || '-'}</td>
            <td className="pr-3 py-4">
              {content.resultUpdatedAt ? getNormalizedDateTime(content.resultUpdatedAt, 'DD-MM-YYYY hh:mm A') : '-'}
            </td>

            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems
                currentUser={currentUser}
                exam={content}
                onView={() => window.location.href = `/results/course/${courseId}/exam/${content?.offlineExam._id}`}
                onAddMarksheet={() => {
                  setSelectedExam(content?.offlineExam);
                  setOpenMarksheetForm(true);
                }}
                onPublish={() => {
                  setSelectedExam(content?.offlineExam);
                  setShowPublishModal(true);
                }}
              />
            </td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { currentUser, exam, onView, onAddMarksheet, onPublish } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="View results" onClick={onView} />
      </div>

      {exam.resultStatus === 'pending' &&
      <div className="py-1">
        <MenuItem label="Import marksheet" onClick={onAddMarksheet} />
      </div>}

      {exam.resultStatus === 'pending' && includes(['owner', 'admin'], currentUser.role) &&
      <div className="py-1">
        <MenuItem label="Publish result" onClick={onPublish} />
      </div>}
    </ActionMenu>
  )
};
