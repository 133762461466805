import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { cloneDeep, forEach, keys, findIndex } from 'lodash';
import {
  ChevronRightIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/solid';

import StackedLayout from '../../../layouts/StackedLayout';
import Loader from '../../../components/Loader';
import ResultPageHeader from './components/ResultPageHeader';
import ResultCard from './components/ResultCard';
import AnswerSection from './components/AnswerSection';
import SubjectTabs from './components/SubjectTabs';
import { SubmitButton } from '../../../components/buttons';
import { getTakenExam } from '../../../services/takenExam';
import { getExam } from '../../../services/exam';



export default function TakenExamResultPage() {
  const navigate = useNavigate();
  const params = useParams();
  const takenExamId = params.id;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [takenExam, setTakenExam] = useState(null);
  const [exam, setExam] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [showSolution, setShowSolution] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) { return navigate('/auth/login'); }

    async function _fetchData() {
      try {
        const _takenExam = await getTakenExam(takenExamId);
        if (!_takenExam) { return navigate(-1); }
        setTakenExam(_takenExam);

        const subjectNames = keys(_takenExam.questions);
        let _subjects = [];
        forEach(subjectNames, subject => {
          _subjects.push({
            name: subject,
            answered: _takenExam.questions[subject].correctAnswers + _takenExam.questions[subject].wrongAnswers,
            count: _takenExam.questions[subject].noOfQuestions
          });
        });
        setSubjects(_subjects);
        setSelectedSubjectIndex(0);
        const _questions = _takenExam.questions[subjectNames[0]].questions;
        setQuestions(_questions);
        setSelectedQuestion(_questions[0]);

        const _exam = await getExam(_takenExam.exam);
        setExam(_exam);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      }
    };
    _fetchData();
  }, []);

  const onNextQuestion = () => {
    const currentQuestionIndex = cloneDeep(selectedQuestionIndex);
    if (currentQuestionIndex === (questions.length - 1)) { return; }
    const _selectedQuestionIndex = currentQuestionIndex + 1;
    setSelectedQuestionIndex(_selectedQuestionIndex);
    setSelectedQuestion(questions[_selectedQuestionIndex]);
  };

  const onPreviousQuestion = () => {
    const currentQuestionIndex = cloneDeep(selectedQuestionIndex);
    if (currentQuestionIndex === 0) { return; }
    const _selectedQuestionIndex = currentQuestionIndex - 1;
    setSelectedQuestionIndex(_selectedQuestionIndex);
    setSelectedQuestion(questions[_selectedQuestionIndex]);
  };

  const handleSubjectChange = (subjectName) => {
    const _selectedSubjectIndex = findIndex(subjects, s => s.name === subjectName);
    setSelectedSubjectIndex(_selectedSubjectIndex);
    setSelectedQuestionIndex(0);
    const questionsForSubject = takenExam.questions[subjectName].questions;
    setQuestions(questionsForSubject);
    setSelectedQuestion(questionsForSubject[0]);
  };

  return (
    <StackedLayout
      loading={loading}
      hideFooter={true}
    >
      <ResultPageHeader exam={exam} takenExam={takenExam} />

      <div>
        <SubmitButton
          label={showSolution ? 'নম্বরপত্র দেখুন' : 'সমাধান দেখুন'}
          className="w-full sm:w-auto"
          onClick={() => setShowSolution(!cloneDeep(showSolution))}
        />
      </div>

      {!showSolution && <ResultCard takenExam={takenExam} />}

      {showSolution &&
      <div className="mt-4 w-full md:w-1/2">
        <SubjectTabs subjects={subjects} subjectIndex={selectedSubjectIndex} onChange={handleSubjectChange} />

        <div className="flex mt-6 space-x-3 items-center">
          <span>
            <button
              disabled={selectedQuestionIndex === 0}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-30"
              onClick={onPreviousQuestion}
            >
              <ChevronLeftIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              পূর্ববর্তী প্রশ্ন
            </button>
          </span>

          <span >
            <button
              disabled={selectedQuestionIndex === (questions.length - 1)}
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-30"
              onClick={onNextQuestion}
            >
              পরবর্তী প্রশ্ন
              <ChevronRightIcon className="-mr-0.5 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </span>
        </div>

        <AnswerSection question={selectedQuestion} questionIndex={selectedQuestionIndex} />
      </div>}

    </StackedLayout>
  )
};
