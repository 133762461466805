import { useState } from 'react';

import { Input, SelectDistrict } from '../../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../../components/Sidepanel';


const genderLabels = {
  male: 'ছাত্র',
  female: 'ছাত্রী'
};

export default function CompetitionRegisterForm(props) {
  const {
    currentUser,
    competition,
    onSave,
    onClose
  } = props;
  const [district, setDistrict] = useState('');
  const [institute, setInstitute] = useState('');
  const [updating, setUpdating] = useState(false);

  const handleRegistration = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        district,
        institute
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !district || !institute;
  };

  return (
    <Sidepanel
      title="রেজিস্ট্রেশন ফরম"
      subTitle={competition.name}
      action="জমা দিন"
      cancelText="বাতিল"
      onSave={handleRegistration}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="আপনার নাম"
      >
        <p>{currentUser.name}</p>
      </SidepanelField>

      <SidepanelField
        label="মোবাইল"
      >
        <p>{currentUser.mobile}</p>
      </SidepanelField>

      <SidepanelField
        label="শিক্ষার্থীর ধরণ"
      >
        <p>{genderLabels[currentUser.gender]}</p>
      </SidepanelField>

      <SidepanelField
        label="জেলার নাম"
      >
        <SelectDistrict col={2} label="District" value={{label: district, value: district}} onChange={(item) => setDistrict(item.value)}/>
      </SidepanelField>

      <SidepanelField
        label="শিক্ষা প্রতিষ্ঠানের নাম"
      >
        <Input name="institute" value={institute} onChange={(event) => setInstitute(event.target.value)} />
      </SidepanelField>
    </Sidepanel>
  );
};
