import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { pdf } from "@react-pdf/renderer";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import PlaceholderLoader from '../PlaceholderLoader';
import { Select, RadioGroupHorizontal } from '../forms/fields';
import { SubmitButton, Button } from '../buttons';
import PdfMeritList from '../PdfMeritList';
import { getBranches } from '../../services/branch';
import { getMeritLists } from '../../services/meritList';
import { getTakenExams } from '../../services/takenExam';
import toast from 'react-hot-toast';
import { orderBy } from 'lodash';


const documentTypes = [
  { name: 'Excel', value: 'excel' },
  // { name: 'Pdf', value: 'pdf' },
]
export default function DownloadMeriList(props) {
  const {
    course,
    exam,
    onCancel

  } = props;
  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [documentType, setDocumentType] = useState('excel');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _branches = await getBranches({status: 'active'});
        setBranches(_branches);

        await fetchData();

        setLoading(false);
      } catch (error) {
        handleCancel();
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchMeritList() {
      setUpdating(true);
      await fetchData();
      setUpdating(false);
    };
    _fetchMeritList();
  }, [selectedBranch]);

  const fetchData = async () => {
    try {
      if (exam) {
        let params = {
          course: course._id
        };
        if (course.courseType === 'offline') {
          params.offlineExam = exam._id;
        } else {
          params.exam = exam._id;
        }
        if (selectedBranch) {
          params.branch = selectedBranch;
        }
        const takenExams = await getTakenExams(params);
        setData(orderBy(takenExams, 'totalMarksObtained', 'desc'));
      } else {
        let params = {
          course: course
        };
        if (selectedBranch) {
          params.branch = selectedBranch;
        }
        const meritLists = await getMeritLists(params);
        setData(meritLists);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDowload = async () => {
    setLoading(true);
    if (documentType === 'pdf') {
      await downloadPdf();
      handleCancel();
    } else {
      await exportExcel();
      handleCancel();
    }
    setLoading(false);
  };

  const exportExcel = async () => {
    try {
      let excelData = [];
      for (let i = 0; i < data?.length; i++) {
        const resultData = data[i];
        excelData.push({
          'Student ID': resultData?.user?.registrationNo,
          'Name': resultData?.user?.name,
          'Branch': resultData?.branch?.name,
          'MCQ Obtained': resultData?.mcqMarksObtained,
          'Written Obtained': resultData?.cqMarksObtained,
          'Total Obtained': resultData?.marksObtained || resultData?.totalMarksObtained,
          'Branch Position': resultData?.branchPosition,
          'Central Position': resultData?.centralPosition
        });
      };

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [['Merit List']], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(ws, [['Course', course?.name]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(ws, [['Branch', selectedBranch ? excelData[0]?.Branch : 'All']], { origin: 'A3' });
      if (exam) {
        XLSX.utils.sheet_add_aoa(ws, [['Exam', exam?.name]], { origin: 'A4' });
      }
      XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A6' });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Merit List");
      XLSX.writeFile(wb, `MeritList.xlsx`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const downloadPdf = async () => {
    const fileName = 'MeritList.pdf';
    const blob = await pdf(<PdfMeritList data={data} />).toBlob();
    saveAs(blob, fileName);
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {loading && 
                <div className="py-10">
                  <PlaceholderLoader />
                </div>}
                
                {!loading &&
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Download results
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        <Select label="Branch" value={selectedBranch} options={branches} onChange={(event) => setSelectedBranch(event.target.value)} />
                        <p className="mt-1 text-xs text-gray-500">Keep branch empty to download central merit list.</p>
                      </div>
                      <div className="mt-2 w-full">
                        <RadioGroupHorizontal label="Document type" value={documentType} options={documentTypes} onChange={(event) => setDocumentType(event.target.value)} />
                      </div>
                    </div>

                  </div>
                </div>}

                {/* {!loading &&
                <div className="flex px-4 pb-4 sm:px-9 text-sm text-orange-700 space-x-1 items-center">
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <p>It may take long time to create pdf for large data.</p>
                </div>} */}
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-x-4">
                  <SubmitButton disabled={loading || updating} label="Download" onClick={handleDowload} />
                  <Button onClick={handleCancel} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
