import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { DocumentIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, Select, UploadWithDragDrop } from '../../components/forms/fields';
import { uploadPrivateDocument } from '../../services/upload';
import { getDownloadableLink } from '../../services/download';
import {
  getOfflineExam,
  createOfflineExam,
  updateOfflineExam
} from '../../services/offlineExam';
import { includes } from 'lodash';


const questionTypes = [
  { name: 'MCQ', value: 'mcq' },
  { name: 'Written', value: 'cq' },
  { name: 'MCQ + Written', value: 'mcqCq' },
];
export default function OfflineExamEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const examId = params.id;
  const folderId = location?.state?.folderId;
  const [exam, setExam] = useState(null);
  const [name, setName] = useState(null);
  const [totalMarks, setTotalMarks] = useState(0);
  const [questionType, setQuestionType] = useState(null);
  const [mcqMarks, setMcqMarks] = useState(0);
  const [cqMarks, setCqMarks] = useState(0);
  const [totalMcqQuestions, setTotalMcqQuestions] = useState(0);
  const [marksPerMcqQuestion, setMarksPerMcqQuestion] = useState(1);
  const [negativeMarksPerMcqQuestion, setNegativeMarksPerMcqQuestion] = useState(0);
  const [solution, setSolution] = useState(null);
  const [changeSolution, setChangeSolution] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (examId) {
          const _exam = await getOfflineExam(examId);
          setExam(_exam);
          setName(_exam.name);
          setTotalMarks(_exam?.totalMarks);
          setQuestionType(_exam.questionType);
          setMcqMarks(_exam?.mcqMarks);
          setCqMarks(_exam?.cqMarks);
          setTotalMcqQuestions(_exam?.totalMcqQuestions);
          setMarksPerMcqQuestion(_exam?.marksPerMcqQuestion);
          setNegativeMarksPerMcqQuestion(_exam?.negativeMarksPerMcqQuestion);
          setSolution(_exam.solution);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    setTotalMarks(parseInt(cqMarks) + parseInt(mcqMarks));
  }, [cqMarks, mcqMarks]);

  useEffect(() => {
    setMcqMarks(parseInt(totalMcqQuestions) * parseInt(marksPerMcqQuestion));
  }, [totalMcqQuestions, marksPerMcqQuestion]);

  useEffect(() => {
    setCqMarks(exam?.cqMarks || 0);
    setTotalMcqQuestions(exam?.totalMcqQuestions || 0);
    setMarksPerMcqQuestion(exam?.marksPerMcqQuestion || 1);
    setNegativeMarksPerMcqQuestion(exam?.negativeMarksPerMcqQuestion || 0);
  }, [exam, questionType]);

  const handleSolutionUpload = async (files) => {
    try {
      const data = new FormData();
      if (files.length > 0) {
        data.append('file', files[0]);
        const responseData = await uploadPrivateDocument(data);
        const documentLink = responseData.documentLink;
        setSolution(documentLink);
        setChangeSolution(false);
        return documentLink;
      } else {
        throw new Error('No file attached');
      }
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleSolutionPreview = async (event) => {
    try {
      event.preventDefault();
      const responseData = await getDownloadableLink({ privateLink: solution });
      window.open(responseData.publicLink);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveOfflineExam = async (event) => {
    try { 
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        solution,
        totalMarks,
        questionType
      };

      if (includes(['mcq', 'mcqCq'], questionType)) {
        payload.mcqMarks = mcqMarks;
        payload.totalMcqQuestions = totalMcqQuestions;
        payload.marksPerMcqQuestion = marksPerMcqQuestion;
        payload.negativeMarksPerMcqQuestion = negativeMarksPerMcqQuestion;
      }
      
      if (includes(['cq', 'mcqCq'], questionType)) {
        payload.cqMarks = cqMarks;
      }

      if (folderId) {
        payload.folder = folderId;
      }
      if (examId) {
        await updateOfflineExam(examId, payload);
        toast.success(`Exam updated successfully.`);
      } else {
        await createOfflineExam(payload);
        toast.success(`Exam created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    }
    finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !questionType || !totalMarks || totalMarks <= 0;
  }

  const goBack = () => {
    if (folderId || exam?.folder) {
      navigate(`/contents/exams/offline/folder/${folderId || exam?.folder}`);
    } else {
      navigate(`/contents/exams/offline`);
    }
  };

  return (
    <StackedLayout
      loading={loading}
    >
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {exam?.name || 'Create new exam'}
          </h1>
          {exam &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this exam.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={exam ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveOfflineExam}
        onCancel={goBack}
      >
        <FieldSet
          title="Offline exam info"
          subTitle="Give CQ exam name, set grade and group."
        >
          <Input col={3} label="Name" value={name} onChange={(event) => setName(event.target.value)}/>
          <Select col={2} label="Question type" value={questionType} options={questionTypes} onChange={(event) => setQuestionType(event.target.value)} />
          <Input col={2} disabled type="number" label="Total marks" value={totalMarks} />
        </FieldSet>

        {includes(['cq', 'mcqCq'], questionType) &&
        <FieldSet
          title="Writtent part info"
          subTitle="Set marks for written part."
        >
          <Input col={2} label="Written marks" value={cqMarks} onChange={(event) => setCqMarks(event.target.value)}/>
        </FieldSet>}

        {includes(['mcq', 'mcqCq'], questionType) &&
        <FieldSet
          title="MCQ part info"
          subTitle="Set details for mcq part."
        >
          <Input col={2} disabled label="MCQ marks" value={mcqMarks} />
          <Input col={2} label="Total questions" value={totalMcqQuestions} onChange={(event) => setTotalMcqQuestions(event.target.value)} />
          <Input col={2} label="Marks per question" value={marksPerMcqQuestion} onChange={(event) => setMarksPerMcqQuestion(event.target.value)} />
          <Input col={2} label="Negative Marks per question" value={negativeMarksPerMcqQuestion} onChange={(event) => setNegativeMarksPerMcqQuestion(event.target.value)} />
        </FieldSet>}

        <FieldSet
          title="Upload solution"
          subTitle="Upload pdf file for solution."
        >
          {solution && !changeSolution &&
            <div className="flex col-span-full justify-between">
              <div className="flex justify-start space-x-4">
                <DocumentIcon className="h-6 w-6 text-gray-500" />
                <p>Solution PDF</p>
              </div>

              <div className="justify-end space-x-4">
                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={(event) => handleSolutionPreview(event)}
                >
                  Preview
                </button>

                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                  onClick={() => setChangeSolution(true)}
                >
                  Change
                </button>
              </div>
            </div>
          }

          {(!solution || changeSolution) &&
          <UploadWithDragDrop
            contentType="pdf"
            content={solution} 
            label="Solution (PDF)" 
            actionName="Upload a file"
            onUpload={(files) => handleSolutionUpload(files)}
            showCancelButton={changeSolution}
            onCancel={() => setChangeSolution(false)}
          />}
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
