import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { DocumentIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, UploadWithDragDrop } from '../../components/forms/fields';
import { uploadPrivateDocument } from '../../services/upload';
import { getDownloadableLink } from '../../services/download';
import {
  getCreativeExam,
  createCreativeExam,
  updateCreativeExam
} from '../../services/creativeExam';


export default function CreativeExamEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const examId = params.id;
  const folderId = location?.state?.folderId;
  const [exam, setExam] = useState(null);
  const [name, setName] = useState(null);
  const [durationValue, setDurationValue] = useState(1);
  const [solution, setSolution] = useState(null);
  const [changeSolution, setChangeSolution] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (examId) {
          const _exam = await getCreativeExam(examId);
          setExam(_exam);
          setName(_exam.name);
          setDurationValue(_exam?.duration?.value);
          setSolution(_exam.solution);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleSolutionUpload = async (files) => {
    try {
      const data = new FormData();
      if (files.length > 0) {
        data.append('file', files[0]);
        const responseData = await uploadPrivateDocument(data);
        const documentLink = responseData.documentLink;
        setSolution(documentLink);
        setChangeSolution(false);
        return documentLink;
      } else {
        throw new Error('No file attached');
      }
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleSolutionPreview = async (event) => {
    try {
      event.preventDefault();
      const responseData = await getDownloadableLink({ privateLink: solution });
      window.open(responseData.publicLink);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveCQExam = async (event) => {
    try { 
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        solution,
        duration: {
          value: durationValue,
          type: 'minutes'
        }
      };
      if (folderId) {
        payload.folder = folderId;
      }
      if (examId) {
        await updateCreativeExam(examId, payload);
        toast.success(`Exam updated successfully.`);
      } else {
        await createCreativeExam(payload);
        toast.success(`Exam created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    }
    finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !durationValue;
  }

  const goBack = () => {
    if (folderId || exam?.folder) {
      navigate(`/contents/exams/cq/folder/${folderId || exam?.folder}`);
    } else {
      navigate(`/contents/exams/cq`);
    }
  };

  return (
    <StackedLayout
      loading={loading}
    >
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {exam?.name || 'Create new exam'}
          </h1>
          {exam &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this exam.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={exam ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveCQExam}
        onCancel={goBack}
      >
        <FieldSet
          title="CQ exam info"
          subTitle="Give CQ exam name, set grade and group."
        >
          <Input col={4} label="Name" name="name" value={name} onChange={(event) => setName(event.target.value)}/>
          <Input type="number" col={2} label="Duration (minutes)" name="durationValue" value={durationValue} onChange={(event) => setDurationValue(event.target.value)}/>
        </FieldSet>

        <FieldSet
          title="Upload solution"
          subTitle="Upload pdf file for solution."
        >
          {solution && !changeSolution &&
            <div className="flex col-span-full justify-between">
              <div className="flex justify-start space-x-4">
                <DocumentIcon className="h-6 w-6 text-gray-500" />
                <p>Solution PDF</p>
              </div>

              <div className="justify-end space-x-4">
                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={(event) => handleSolutionPreview(event)}
                >
                  Preview
                </button>

                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                  onClick={() => setChangeSolution(true)}
                >
                  Change
                </button>
              </div>
            </div>
          }

          {(!solution || changeSolution) &&
          <UploadWithDragDrop
            contentType="pdf"
            content={solution} 
            label="Solution (PDF)" 
            actionName="Upload a file"
            onUpload={(files) => handleSolutionUpload(files)}
            showCancelButton={changeSolution}
            onCancel={() => setChangeSolution(false)}
          />}
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
