import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';
import Table from '../../../components/tables/StackedTable';
import { getTransactions } from '../../../services/transaction';
import { getInvoicePdf } from '../../../services/invoice';
import {
  convertToBanglaNumber,
  getNormalizedDateTime,
  gatewayLabelMapping,
  statusLabelMapping,
  statusColorMapping,
  getBanglaDate,
  getBanglaTime
} from '../../../utils';


const sidebarNavigation = [
  { name: 'হোম', href: '/learn/home' },
  { name: 'ক্লাস রুটিন', href: '/learn/routine' },
  { name: 'পরীক্ষার রেজাল্ট', href: '/learn/results' },
  { name: 'ফি পেমেন্ট', href: '/learn/payments' },
  { name: 'ট্রানজেকশন বিবরণী', href: '/learn/transactions' }
];

export default function StudentTransactionPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;

  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const params = {
          user: currentUser._id,
          isPaging: true,
          page: currentPage,
          limit: 10,
          sortBy: 'createdAt',
          sortOrder: 'desc',
        };
        const data = await getTransactions(params);
        setTransactions(data?.docs || []);
        setPagingData(omit(data, 'docs'));
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();

  }, [currentPage]);

  const handlePrintInvoice = async (invoiceId) => {
    try {
      const pdfUrl = await getInvoicePdf(invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="ট্রানজেকশন বিবরণী"
    >
      <Table
        title="ট্রানজেকশন বিবরণী"
        headers={['সময়', 'প্রোগ্রাম', 'টাকার পরিমাণ', 'মাধ্যম', 'স্ট্যাটাস']}
        itemsCount={transactions.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >
        {transactions.map((transaction) => (
          <tr key={transaction._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {getBanglaDate(transaction.createdAt)}{', '}{getBanglaTime(transaction.createdAt)}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{transaction?.program?.nameBN || transaction?.program?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{convertToBanglaNumber(transaction.paidAmount)}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{gatewayLabelMapping[transaction.paymentMethod]}</td>
            <td className="pr-3 py-4 text-sm">
              <span className={`text-${statusColorMapping[transaction.status]}-600`}>
                {statusLabelMapping[transaction.status]}
              </span>
            </td>
            <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              {transaction.status === 'success' &&
                <div className="flex justify-end items-center space-x-2">
                  <p
                    className="text-indigo-600 hover:text-indigo-500 underline cursor-pointer"
                    onClick={() => handlePrintInvoice(transaction?.invoice || transaction?.invoices[0])}
                  >
                    প্রিন্ট
                  </p>
                </div>}
            </td>

          </tr>
        ))}
      </Table>
    </StackedLayout>
  )
};