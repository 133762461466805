import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, RadioGroupHorizontal, UploadIcon, InlineCheckbox } from '../../components/forms/fields';
import { handleUploadPublicImage } from '../../utils';
import { createInstituteUser } from '../../services/user';


const genderOptions = [
  {name: 'Male', value: 'male'},
  {name: 'Female', value: 'female'}
];

export default function InstituteUserCreatePage() {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [gender, setGender] = useState('male');
  const [profilePicture, setProfilePicture] = useState(null);
  const [role, setRole] = useState(null);
  const [canManageCoaching, setCanManageCoaching] = useState(false);
  const [canManageVideos, setCanManageVideos] = useState(false);
  const [canManageNotes, setCanManageNotes] = useState(false);
  const [canManageQuestions, setCanManageQuestions] = useState(false);
  const [canManageExams, setCanManageExams] = useState(false);
  const [canManageCourses, setCanManageCourses] = useState(false);
  const [canManageBilling, setCanManageBilling] = useState(false);
  const [canManageCompetitions, setCanManageCompetitions] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setCanManageCoaching(false);
    setCanManageVideos(false);
    setCanManageNotes(false);
    setCanManageQuestions(false);
    setCanManageExams(false);
    setCanManageCourses(false);
    setCanManageBilling(false);
    setCanManageCompetitions(false);
  }, [role]);

  const saveUser = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        name,
        mobile,
        gender,
        profilePicture,
        role: role || 'staff',
        canManageCoaching,
        canManageVideos,
        canManageNotes,
        canManageQuestions,
        canManageExams,
        canManageCourses,
        canManageBilling,
        canManageCompetitions
      };
      await createInstituteUser(payload);
      toast.success('User created successfully');
      goBack();
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  const handleProfilePictureUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setProfilePicture(imageUrl);
      return imageUrl;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const goBack = () => {
    navigate(`/settings/users`);
  };

  const isDisabled = () => {
    return updating || !name || !mobile;
  };

  return (
    <StackedLayout>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            Create new user
          </h1>
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        disabled={isDisabled()}
        submitText="Create"
        onSubmit={saveUser}
        onCancel={goBack}
      >
        <FieldSet
          title="Personal information"
          subTitle="Set user name, mobile and other information."
        >
          <Input col={3} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={2} label="Mobile" value={mobile} onChange={(event) => setMobile(event.target.value)} />
          <RadioGroupHorizontal label="Gender" value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
          <UploadIcon label="Profile picture" image={profilePicture} Icon={UserIcon} actionName="Change" onUpload={(files) => handleProfilePictureUpload(files)} />
        </FieldSet>

        <FieldSet
          title="Permissions"
          subTitle="Set permissions for this user."
        >
          <InlineCheckbox
            label="Admin"
            description="An admin can perform all actions like an owner but can't remove the owner."
            checked={role === 'admin'}
            onChange={() => setRole(role === 'admin' ? null : 'admin')}
          />

          <InlineCheckbox
            label="Manage videos"
            description="Can create/edit video lessons."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageVideos}
            onChange={() => setCanManageVideos(!cloneDeep(canManageVideos))}
          />

          <InlineCheckbox
            label="Manage notes"
            description="Can create/edit pdf notes/ebooks."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageNotes}
            onChange={() => setCanManageNotes(!cloneDeep(canManageNotes))}
          />

          <InlineCheckbox
            label="Manage questions"
            description="Can create/edit all type of questions."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageQuestions}
            onChange={() => setCanManageQuestions(!cloneDeep(canManageQuestions))}
          />

          <InlineCheckbox
            label="Manage exams"
            description="Can create/edit all type of exams."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageExams}
            onChange={() => setCanManageExams(!cloneDeep(canManageExams))}
          />

          <InlineCheckbox
            label="Manage courses"
            description="Can create/edit online/offline courses and their contents."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageCourses}
            onChange={() => setCanManageCourses(!cloneDeep(canManageCourses))}
          />

          <InlineCheckbox
            label="Manage coaching settings"
            description="Can add/edit coaching branches, programs, courses, batches, etc."
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageCoaching}
            onChange={() => setCanManageCoaching(!cloneDeep(canManageCoaching))}
          />

          <InlineCheckbox
            label="Manage Billing"
            description="Can manage billing related activities"
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageBilling}
            onChange={() => setCanManageBilling(!cloneDeep(canManageBilling))}
          />

          <InlineCheckbox
            label="Manage competitions"
            description="Can add/edit competitions and it's participants"
            disabled={role === 'admin'}
            checked={role === 'admin' || canManageCompetitions}
            onChange={() => setCanManageCompetitions(!cloneDeep(canManageBilling))}
          />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
