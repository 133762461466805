import axios from 'axios';


const baseURL = process.env.REACT_APP_API_BASE_URL;
const instance = axios.create({ 
    baseURL: baseURL,
    withCredentials: true  
});

instance.interceptors.request.use(function (config) {
  const currentUserSession = JSON.parse(localStorage.getItem('currentUser'));
  config.headers.sessionId = currentUserSession?.sessionId;
  return config;
});

instance.interceptors.response.use(
  response => (response), 
  error => (Promise.reject(error))
)

export default instance;
