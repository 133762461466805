import React, { useState, useEffect } from 'react';

import Header from '.././spondon/components/Header';
import Footer from '../spondon/components/Footer';
import HeroSection from '../spondon/components/Hero';
import StudyCategory from '../spondon/components/StudyCategory';
import StatsSection from '../spondon/components/Stats';
import CardSection from '../spondon/components/CardSection';
import StudyMaterial from '../spondon/components/StudyMaterial';
import Teachers from '../spondon/components/Teachers';
import ContactSection from '../spondon/components/Contact';
import Testimonials from '../spondon/components/Testimonials'

 

export default function SpondonHomePage(props) {
  const currentInstitute = props.currentInstitute;




  return (
    <div>
      <Header currentInstitute={currentInstitute} />
      <HeroSection />
      <StudyCategory />
      <CardSection />
      <StudyMaterial />
      {/* <StatsSection /> */}
      {/* <CardSection /> */}
      <Teachers />
      <Testimonials />
      {/* <ContactSection /> */}
      <Footer />
    </div>
  )
};