import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input } from '../../../components/forms/fields';
import { PlusButton, MinusButton } from '../../../components/buttons';


const defaultSection = {
  name: '',
  liveCount: 0,
  lectureCount: 0,
  recordCount: 0,
  examCount: 0,
  noteCount: 0
}


export default function ChapterForm(props) {
  const {
    courseType,
    courseName,
    onSave,
    onCancel
  } = props;
  const [chapters, setChapters] = useState(props.chapters);
  const [updating, setUpdating] = useState(false);
  
  useEffect(() => {
    if (isEmpty(chapters)) {
      setChapters([defaultSection]);
    }
  }, []);

  const onFieldChange = (event, index) => {
    let _chapters = cloneDeep(chapters);
    _chapters[index][event.target.name] = event.target.value;
    setChapters(_chapters);
  };

  const addChapter = (event) => {
    event.preventDefault();
    let _chapters = cloneDeep(chapters);
    _chapters.push(defaultSection);
    setChapters(_chapters);
  };

  const deleteChapter = (event, index) => {
    event.preventDefault();
    let _chapters = cloneDeep(chapters);
    _chapters.splice(index, 1);
    setChapters(_chapters);
  };

  const saveChapters = async (event) => {
    try {
      setUpdating(true);
      const payload = { chapters };
      await onSave(event, payload);
      onCancel();
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      <div className="border-b border-gray-200 pb-5 mb-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Course outline: {courseName}
        </h3>
      </div>

      <Form
        submitText="Save"
        disabled={updating}
        onSubmit={saveChapters}
        onCancel={onCancel}
      >
      
        {chapters.map((chapter, index) => (
        <FieldSet
          key={`course-chapter-index[${index}]`}
          title={`Section ${index+1}`}
          subTitle="Add chapter/section name and content counts."
        >
          <Input col={4} label="Name" name="name" value={chapter.name || ''}
            onChange={(event) => onFieldChange(event, index)}
          />
          <span></span><span></span>

          {courseType === 'live' &&
          <Input type="number" col={1} label="Lives" name="liveCount" value={chapter.liveCount}
            onChange={(event) => onFieldChange(event, index)}
          />}

          {courseType === 'offline' &&
          <Input type="number" col={1} label="Lectures" name="lectureCount" value={chapter.lectureCount}
            onChange={(event) => onFieldChange(event, index)}
          />}

          <Input type="number" col={1} label="Records" name="recordCount" value={chapter.recordCount}
            onChange={(event) => onFieldChange(event, index)}
          />
          <Input type="number" col={1} label="Exams" name="examCount" value={chapter.examCount}
            onChange={(event) => onFieldChange(event, index)}
          />
          <Input type="number" col={1} label="Notes" name="noteCount" value={chapter.noteCount}
            onChange={(event) => onFieldChange(event, index)}
          />

          <span></span><span></span>
          <div className="col-span-3 space-x-4">
            {chapters.length > 1 &&
            <MinusButton action="Remove" onClick={(event) => deleteChapter(event, index)} />}
            {index === (chapters.length - 1) &&
            <PlusButton label="Add section" onClick={addChapter} />}
          </div>
        </FieldSet>
        ))}
      </Form>
    </>
  )
}