import { createSlice } from '@reduxjs/toolkit';


const branchInitialState = { branchInfo: null };

const branchSlice = createSlice({
  name: 'branch',
  initialState: branchInitialState,
  reducers: {
    setBranchInfo(state, action) {
      state.branchInfo = action.payload;
    },
    resetBranchInfo(state) {
      state.branchInfo = null;
    },
  },
});

const branchActions = branchSlice.actions;
const branchReducer = branchSlice.reducer;

export { branchActions, branchReducer };
