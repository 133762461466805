import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { cloneDeep, forEach, capitalize, omit, isEmpty, findIndex } from 'lodash';
import toast from 'react-hot-toast';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import { getCourse } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getOnlineEnrolledCourses } from '../../services/enrolledCourse';
import { getNormalizedDateTime } from '../../utils';


const defaultFilters = [
  {
    fieldName: 'status',
    name: 'Status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Expired' },
    ],
  },
  {
    fieldName: 'isFree',
    name: 'Payment type',
    options: [
      { value: 'paid', label: 'Paid' },
      { value: 'free', label: 'Free' },
    ],
  }
];
const breadcrumbPages = [
  { name: 'Courses', href: '/online-courses' },
  { name: 'Students', current: true },
];
const headers = ['Name', 'Mobile', 'District', 'Gender', 'Fee', 'Date', 'status'];

export default function OnlineCourseStudentsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const courseId = params.courseId;
  const [course, setCourse] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [initialState, setInitialState] = useState(true);
  const [paymentTypeFilters, setPaymentTypeFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [batchFilters, setBatchFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _course = await getCourse(courseId);
        setCourse(_course);
        if (_course.courseType === 'live') {
          const batches = await getBatches({course: courseId});
          let batchFilters = { fieldName: 'batchId', name: 'Batches', options: [] };
          forEach(batches, batch => batchFilters.options.push({ value: batch._id.toString(), label: batch.name }));
          let _filters = cloneDeep(filters);
          _filters.push(batchFilters);
          setFilters(_filters);
        }
        await fetchData();
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchStudents() {
      await fetchData();
    };
    _fetchStudents();
    setLoading(false);
  }, [
    currentPage,
    queryString,
    paymentTypeFilters,
    statusFilters,
    batchFilters
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        search: queryString,
        courseId: courseId,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      if (paymentTypeFilters.length === 1) {
        params.isFree = paymentTypeFilters[0] === 'free';
      }

      if (statusFilters.length > 0) {
        params.status = statusFilters;
      }

      if (batchFilters.length > 0) {
        params.batchId = batchFilters;
      }


      const data = await getOnlineEnrolledCourses(params);
      setEnrolledCourses(data.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action, params) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    setInitialState(false);
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q)
    setCurrentPage(1);
    setPaymentTypeFilters([]);
    setBatchFilters([]);
    setLoading(true);
  };

  const applyFilterChanges = async (event, filterIndex) => {
    const _value = event.target.value;
    const _paylemtTypeFilters = cloneDeep(paymentTypeFilters);
    const _statusFilters = cloneDeep(statusFilters);
    const _batchFilters = cloneDeep(batchFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'isFree') {
      const valueIndex = findIndex(_paylemtTypeFilters, t => t === _value);
      if (valueIndex === -1) {
        _paylemtTypeFilters.push(_value);
      } else {
        _paylemtTypeFilters.splice(valueIndex, 1);
      }
      setPaymentTypeFilters(_paylemtTypeFilters);
    } else if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, t => t === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    } else if (fieldName === 'batchId') {
      const valueIndex = findIndex(_batchFilters, t => t === _value);
      if (valueIndex === -1) {
        _batchFilters.push(_value);
      } else {
        _batchFilters.splice(valueIndex, 1);
      }
      setBatchFilters(_batchFilters);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      breadcrumbPages={breadcrumbPages}
      title={course?.name}
      subTitle="See all student data for this course."
      filters={filters}
      onFilterChange={applyFilterChanges}
    >
      <Table
        headers={headers}
        itemsCount={enrolledCourses.length}
        pagingData={pagingData}
        queryString={queryString}
        initialState={initialState}
        searchPlaceholder="Search by name or mobile"
        onPageChange={onPageChange}
        onSearch={onSearch}
      >
        {enrolledCourses.map((enrolledCourse, index) => (
          <tr key={`enrolled-course-index[${index}]`}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {enrolledCourse?.user?.name}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.user?.mobile}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.user?.district}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{capitalize(enrolledCourse?.user?.gender)}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.isFree ? 'Free' : enrolledCourse?.transaction?.paidAmount}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(enrolledCourse?.createAt, 'DD MMM YYYY')}</td>
            <td 
              className={`pr-3 py-4 text-sm ${enrolledCourse.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(enrolledCourse.status)}
            </td>
          </tr>
        ))}
      </Table>
    </CategoryListingLayout>
  )
};