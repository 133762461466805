import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import images from '../constants/images';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const paymentMethods = [
  { title: 'bKash', value: 'bkash', icon: images.Bkash },
];

export default function PaymentMethods(props) {
  const { onSelect } = props;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    onSelect(selectedPaymentMethod?.value);
  }, [selectedPaymentMethod]);

  return (
    <div className="w-full">
      <RadioGroup value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}>
        <RadioGroup.Label className="text-lg font-medium text-gray-900">Payment methods</RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {paymentMethods.map((paymentMethod) => (
            <RadioGroup.Option
              key={paymentMethod.value}
              value={paymentMethod}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'ring-2 ring-green-500' : '',
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="flex items-center space-x-1 text-sm font-medium text-gray-900">
                        <img className="h-6 text-green-600" src={paymentMethod.icon} alt="" />
                        <span>{paymentMethod.title}</span>
                      </RadioGroup.Label>
                    </span>
                  </span>
                  {checked ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                  ) : null}
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-green-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
};