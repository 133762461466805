import { useState } from 'react';
import { cloneDeep } from 'lodash';

import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import { InlineCheckbox } from '../../../components/forms/fields';
import BranchInputWithList from '../../../components/BranchInputWithList';


export default function BranchForm(props) {
  const {
    course,
    onSave,
    onCancel
  } = props;
  const [allowAllBranches, setAllowAllBranches] = useState(course.allowAllBranches);
  const [branches, setBranches] = useState(course.branches || []);
  const [updating, setUpdating] = useState(false);

  const saveBranches = async (event) => {
    try {
      setUpdating(true);
      const payload = { allowAllBranches, branches };
      await onSave(event, payload);
      onCancel();
    } finally {
      setUpdating(false);
    }
  };

  const handleBranchSelect = (item) => {
    let _branches = cloneDeep(branches);
    _branches.push(item.value);
    setBranches(_branches);
  };

  const removeBranch = (event, index) => {
    event.preventDefault();
    let _branches = cloneDeep(branches);
    _branches.splice(index, 1);
    setBranches(_branches);
  };

  return (
    <>
      <div className="border-b border-gray-200 pb-5 mb-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {course?.name}
        </h3>
      </div>

      <Form
        submitText="Save"
        disabled={updating}
        onSubmit={saveBranches}
        onCancel={onCancel}
      >
      
        <FieldSet
          title="Allowed branches"
          subTitle="Add branches that are allowed for this program."
        >
          <InlineCheckbox
            label="Allow all branches"
            description="Allow all braches to run this program."
            checked={allowAllBranches}
            onChange={() => setAllowAllBranches(!cloneDeep(allowAllBranches))}
          />
          
          {!allowAllBranches &&
          <BranchInputWithList
            col={3}
            branches={branches}
            onSelect={handleBranchSelect}
            onRemove={removeBranch}
          />}
        </FieldSet>
      </Form>
    </>
  )
};
