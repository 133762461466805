import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { findIndex, forEach, includes, orderBy } from 'lodash';
import {
  FolderIcon,
  QuestionMarkCircleIcon,
  PlayIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  ListBulletIcon,
  ArrowLeftIcon,
  CheckCircleIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';

import PlaceholderLoader from './PlaceholderLoader';
import LatexText from './LatexText';
import { Button, SubmitButton } from './buttons';
import { getFolders } from '../services/folder';
import { getQuestions } from '../services/question';
import { getCreaiveQuestions } from '../services/creativeQuestion';
import { getLessons } from '../services/lesson';
import { getEbooks } from '../services/ebook';
import { getExams } from '../services/exam';
import { getCreativeExams } from '../services/creativeExam';
import { getOfflineExams } from '../services/offlineExam';
import { getProgramExams } from '../services/program';


const folderTypes = {
  mcq: 'mcq',
  cq: 'cq',
  exam: 'mcqExam',
  cqExam: 'cqExam',
  offlineExam: 'offlineExam',
  lesson: 'lesson',
  note: 'ebook',
  ebook: 'ebook'
};

export default function ContentPicker(props) {
  const {
    title,
    contentType,
    programId,
    onSelect,
    onCancel,
    selectedContents
  } = props;
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [open, setOpen] = useState(true);
  const [fetchingData, setFetchingData] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
    setFetchingData(false);
  }, [
    contentType,
    currentFolder
  ]);

  const fetchData = async () => {
    let folders = await fetchFolders();
    forEach(folders, folder => folder.isFolder = true);

    let contents = [];
    if (contentType === 'mcq') {
      contents = await fetchQuestions();
    } else if (contentType === 'cq') {
      contents = await fetchCreativeQuestions();
    } else if (contentType === 'lesson') {
      contents = await fetchLessons();
    } else if (includes(['note', 'ebook'], contentType)) {
      contents = await fetchEbooks();
    } else if (contentType === 'exam') {
      contents = await fetchMcqExams();
    } else if (contentType === 'cqExam') {
      contents = await fetchCreativeExams();
    } else if (contentType === 'offlineExam') {
      contents = await fetchOfflineExams();
    }

    if (programId && contentType === 'offlineExam') {
      setItems(contents);
    } else {
      const _items = folders.concat(contents);
      setItems(_items);
    }
  };

  const fetchFolders = async () => {
    try {
      let params = {
        contentType: folderTypes[contentType]
      };
      if (currentFolder) {
        params.parentFolder = currentFolder._id;
      } else {
        params.isRoot = true;
      }
      const data = await getFolders(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchQuestions = async () => {
    try {
      let params = {};
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getQuestions(params);
      return orderBy(data, 'createdAt', 'asc');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchCreativeQuestions = async () => {
    try {
      let params = {};
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getCreaiveQuestions(params);
      return orderBy(data, 'createdAt', 'asc');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchLessons = async () => {
    try {
      let params = { lessonType: 'lesson' };
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getLessons(params);
      return orderBy(data, 'createdAt');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchEbooks = async () => {
    try {
      let params = {};
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getEbooks(params);
      return orderBy(data, 'createdAt');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchMcqExams = async () => {
    try {
      let params = {};
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getExams(params);
      return orderBy(data, 'createdAt');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchCreativeExams = async () => {
    try {
      let params = {};
      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getCreativeExams(params);
      return orderBy(data, 'createdAt');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchOfflineExams = async () => {
    try {
      let params = {};
      let data;
      if (programId) {
        data = await getProgramExams(programId, 'offline');
      } else {
        if (currentFolder) {
          params.folder = currentFolder._id;
        }
        data = await getOfflineExams(params);
      }

      return orderBy(data, 'createdAt');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelect = async (event, item, contentType) => {
    try {
      setUpdating(true);
      await onSelect(event, item, contentType);
    } finally {
      setUpdating(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {fetchingData && <PlaceholderLoader />}

        {!fetchingData &&
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>

                    {!selectedContent &&
                    <div className="mt-2">
                      {currentFolder &&
                      <div 
                        className="flex items-center space-x-2 border-b pb-2 hover:underline cursor-pointer"
                        onClick={() => setCurrentFolder(currentFolder?.parentFolder)}
                      >
                        <ArrowLeftIcon className="h-4 w-4" />
                        <span className="flex items-center space-x-1">
                          <FolderIcon className="h-5 w-5" />
                          <span>{currentFolder.name}</span>
                        </span> 
                      </div>
                      }
                      <ul className="divide-y space-y-2">
                        {items?.map((item, index) => (
                          <li
                            key={`content-index[${index}]`}
                            className="p-2 flex flex-col hover:bg-gray-50"
                          >
                            
                            {item.isFolder &&
                            <div
                              className="flex justify-between items-center hover:underline cursor-pointer"
                              onClick={() => setCurrentFolder(item)}
                            >
                              <div className="flex items-center space-x-2">
                                <FolderIcon className="h-5 w-5" />
                                <p>{item.name}</p>
                              </div>
                              <div>
                                <ChevronRightIcon className="h-4 w-4" />
                              </div>
                            </div>}

                            {!item.isFolder &&
                            <div className="flex justify-between items-center">
                              <div className="flex items-center space-x-2">
                                {includes(['mcq', 'cq'], contentType) &&
                                <QuestionMarkCircleIcon className="h-5 w-5" />}
                                {includes(['note', 'ebook'], contentType) &&
                                <DocumentTextIcon className="h-5 w-5" />}
                                {includes(['exam', 'cqExam'], contentType) &&
                                <ClipboardDocumentListIcon className="h-5 w-5" />}
                                {contentType === 'lesson' &&
                                <PlayIcon className="h-5 w-5" />}
                                {contentType === 'playlist' &&
                                <ListBulletIcon className="h-5 w-5" />}
                                <LatexText text={item.name || item.title || item.question} />
                              </div>

                              <div className="flex text-sm items-center space-x-2">
                                {findIndex(selectedContents, content => content?.toString() === item?._id?.toString()) === -1 ?
                                <>
                                  {includes(['mcq', 'cq'], contentType) &&
                                  <span
                                    className="underline cursor-pointer"
                                    onClick={() => setSelectedContent(item)}
                                  >
                                    View
                                  </span>}
                                  {updating &&
                                  <span className="underline text-gray-500">Add</span>}
                                  
                                  {!updating &&
                                  <span
                                    className="underline cursor-pointer text-indigo-600"
                                    onClick={(event) => handleSelect(event, item, contentType)}
                                  >
                                    Add
                                  </span>}
                                </>
                                : <CheckCircleIcon className="h-5 w-5 text-green-600" />}

                              </div>
                            </div>}
                          </li>
                        ))}
                      </ul>
                    </div>}

                    {selectedContent && contentType === 'mcq' &&
                    <McqView
                      question={selectedContent}
                      onAdd={(event) => {
                        handleSelect(event, selectedContent);
                      }}
                      onClose={() => setSelectedContent(null)}
                    />}

                    {selectedContent && contentType === 'cq' &&
                    <CreativeQuestionView
                      question={selectedContent}
                      onAdd={(event) => {
                        handleSelect(event, selectedContent);
                      }}
                      onClose={() => setSelectedContent(null)}
                    />}
                  </div>
                </div>

                {!selectedContent &&
                <div className="flex mt-5 pl-4">
                  <Button label="Close" onClick={handleCancel} />
                </div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>}
      </Dialog>
    </Transition.Root>
  )
};

const optionLabels = ['A', 'B', 'C', 'D', 'E'];
function McqView(props) {
  const {question, onAdd, onClose} = props;

  return (
    <div className="mt-2">
      <p>
        <LatexText text={question.question} />
      </p>

      <ul className="mt-2 space-y-2">
        {question?.answers?.map((answer, index) => (
          <li
            key={`answer-index[${index}]`}
            className="border px-2 py-3 rounded-md"
          >
            <div className="flex justify-between items-center">
              <p className="flex flex-1 items-center space-x-1">
                <span>{optionLabels[index]}{'.'}</span>
                <LatexText text={answer.value} />
              </p>
              {answer.isCorrect &&
              <CheckCircleIcon className="h-5 w-5 text-green-600" />}
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-2">
        <p className="font-medium">Solution:</p>
        <p>
          <LatexText text={question.solution} />
        </p>

      </div>

      <div className="mt-4 space-x-4">
        <Button label="Close" onClick={onClose} />
        <SubmitButton label="Add" onClick={onAdd} />
      </div>
    </div>
  )
};

function CreativeQuestionView(props) {
  const {question, onAdd, onClose} = props;

  return (
    <div className="mt-2">
      <div className="flex justify-between">
        <LatexText text={question.comprehension} />
        <span>Marks: {question.totalMarks}</span>
      </div>

      <ul className="mt-2 space-y-2">
        {question?.questionSet?.map((subQuestion, index) => (
          <li
            key={`question-index[${index}]`}
            className="border px-2 py-3 rounded-md"
          >
            <div className="flex justify-between items-center">
              <div className="flex flex-1 justify-between items-center space-x-1">
                <p className="flex space-x-1">
                  <span>{optionLabels[index]}{'.'}</span>
                  <LatexText text={subQuestion.question} />
                </p>
                <span>Marks: {subQuestion.marks}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-4 space-x-4">
        <Button label="Close" onClick={onClose} />
        <SubmitButton label="Add" onClick={onAdd} />
      </div>
    </div>
  )
};