import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { includes } from 'lodash';

import StackedLayout from '../../../../layouts/StackedLayout';
import ContentAccordion from '../../../../components/ContentAccordion';
import LiveClassInstruction from '../../../../components/LiveClassInstruction';
import LiveClassRoutine from '../../../../components/LiveClassRoutine/StudentClassRoutine';
import { getCourseContent } from '../../../../services/courseContent';


const sidebarNavigation = [
  { name: 'ক্লাস রুটিন', value: 'routine' },
  { name: 'রেকর্ডেড ক্লাস', value: 'lesson' },
  { name: 'অনলাইন MCQ পরীক্ষা', value: 'exam' },
  // { name: 'সৃজনশীল/লিখিত পরীক্ষা', value: 'cqExam' },
  { name: 'নোট/গাইড', value: 'note' },
  // { name: 'লাইভ ক্লাসের নির্দেশনা', value: 'liveInstruction' },
];

export default function EnrolledOfflineCoursePage(props) {
  const { enrolledCourse, currentUser } = props;
  const [enrolledCourseData, setEnrolledCourseData] = useState({});
  const [selectedContentType, setSelectedContentType] = useState(sidebarNavigation[0]);
  const [topics, setTopics] = useState([]);
  const [courseContentId, setCourseContentId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        setEnrolledCourseData({
          courseName: enrolledCourse.course.name,
          courseType: enrolledCourse.course.courseType,
          thumbnail: enrolledCourse.course.thumbnail,
          branchName: enrolledCourse.branch.name,
          batchName: enrolledCourse.batch.name,
          rollNo: enrolledCourse.rollNo,
          expiresAt: enrolledCourse.expiresAt
        });
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, []);

  const fetchCourseContent = async (contentType) => {
    try {
      const params = {
        course: enrolledCourse.course._id,
        contentType: contentType
      };
      const _contents = await getCourseContent(params);
      setTopics(_contents?.topics || []);
      setCourseContentId(_contents._id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSidebarNavigationChange = async (event, index) => {
    event.preventDefault();
    const _selectedContentType = sidebarNavigation[index];
    setSelectedContentType(_selectedContentType);

    const contentType = _selectedContentType.value;
    if (includes(['lesson', 'exam', 'cqExam', 'note'], contentType)) {
      await fetchCourseContent(contentType);
    } else {
      setTopics([]);
      setCourseContentId(null);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      hideFooter={true}
      page="course"
      enrolledCourseData={enrolledCourseData}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={selectedContentType.name}
      onNavigationChange={handleSidebarNavigationChange}
    >

      {selectedContentType.value === 'routine' &&
      <LiveClassRoutine courseId={enrolledCourse.course._id} batchId={enrolledCourse.batch._id} />}

      {/* {selectedContentType.value === 'liveInstruction' &&
      <LiveClassInstruction currentUser={currentUser} batch={enrolledCourse.batch} />} */}

      {includes(['lesson', 'exam', 'note'], selectedContentType.value) &&
      <div>
        <h3 className="hidden sm:block ml-2 pb-4">{selectedContentType?.name}</h3>
        <ContentAccordion courseId={enrolledCourse.course._id} courseContentId={courseContentId} topics={topics} />
      </div>}

    </StackedLayout>
  )
};


