import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { forEach, cloneDeep } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, MultiSelect, UploadIcon } from '../../components/forms/fields';
import EducationField from './components/IntructorEducationField';
import ExperienceField from './components/InstructorExperienceField';
import { PlusButton } from '../../components/buttons';
import Badge from '../../components/Badge';
import { handleUploadPublicImage } from '../../utils';
import { getInstructor, createInstructor, updateInstructor } from '../../services/instructor';


const gradeOptions = [
  { label: 'SSC', value: 'ssc' },
  { label: 'HSC', value: 'hsc' },
  { label: 'Admission', value: 'admission' },
  { label: 'Job preparation', value: 'job' }
];

const gradeLabelMap = {
  ssc: 'SSC',
  hsc: 'HSC',
  admission: 'Admission',
  job: 'Job preparation'
};

export default function InstructorEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const instructorId = params.id;
  const [instructor, setInstructor] = useState(null);
  const [name, setName] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [grades, setGrades] = useState([]);
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [facebookUrl, setFacebookUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [displayOrder, setDisplayOrder] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (instructorId) {
          const _instructor = await getInstructor(instructorId);
          setInstructor(_instructor);
          setName(_instructor.name);
          setDesignation(_instructor.designation);
          setEducations(_instructor.educations);
          setExperiences(_instructor.experiences);
          setFacebookUrl(_instructor.facebookUrl);
          setLinkedinUrl(_instructor.linkedinUrl);
          setDisplayOrder(_instructor.displayOrder);
          setPhoto(_instructor.photo);

          // let _grades = [];
          // forEach(_instructor.grades, grade => {
          //   _grades.push({ label: gradeLabelMap[grade], value: grade});
          // });
          // setGrades(_grades);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const addEducation = (event) => {
    event.preventDefault();
    const _educations = cloneDeep(educations);
    _educations.push('');
    setEducations(() => _educations);
  };

  const deleteEducation = (event, index) => {
    event.preventDefault();
    const _educations = cloneDeep(educations);
    _educations.splice(index, 1);
    setEducations(() => _educations);
  };

  const handleEducationFieldChange = (event, index) => {
    event.preventDefault();
    const _educations = cloneDeep(educations);
    _educations[index] = event.target.value;
    setEducations(() => _educations);
  };

  const addExperience = (event) => {
    event.preventDefault();
    const _experiences = cloneDeep(experiences);
    _experiences.push('');
    setExperiences(() => _experiences);
  };

  const deleteExperience = (event, index) => {
    event.preventDefault();
    const _experiences = cloneDeep(experiences);
    _experiences.splice(index, 1);
    setExperiences(() => _experiences);
  };

  const handleExperienceFieldChange = (event, index) => {
    event.preventDefault();
    const _experiences = cloneDeep(experiences);
    _experiences[index] = event.target.value;
    setExperiences(_experiences);
  };

  const handlePhotoUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setPhoto(imageUrl);
      return imageUrl;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveInstructor = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        designation,
        photo,
        educations,
        experiences,
        facebookUrl,
        linkedinUrl,
        displayOrder
      };
      // let _grades = [];

      // forEach(grades, grade => _grades.push(grade.value));
      // payload.grades = _grades;
      
      if (instructorId) {
        await updateInstructor(instructorId, payload);
        toast.success(`instructor updated successfully.`);
      } else {
        await createInstructor(payload);
        toast.success(`instructor created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const removeGrade = (event, index) => {
    event.preventDefault();
    let _grades = cloneDeep(grades);
    _grades.splice(index, 1);
    setGrades(_grades);
  };

  const goBack = () => {
    navigate(`/instructors`);
  };

  const isDisabled = () => {
    return updating || !name || !designation;
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {instructor?.name || 'Create new instructor'}
          </h1>
          {instructor &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this instructor.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        disabled={isDisabled()}
        submitText={instructor ? 'Save' : 'Create'}
        onSubmit={saveInstructor}
        onCancel={goBack}
      >
        <FieldSet
          title="Personal information"
          subTitle="Set instructor name, designation."
        >
          <Input col={4} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={2} label="Display order" type="number" value={displayOrder} onChange={(event) => setDisplayOrder(event.target.value)} />
          <Input col={4} label="Designation" value={designation} onChange={(event) => setDesignation(event.target.value)} />
          {/* <MultiSelect
            col={4}
            label="Grades"
            options={gradeOptions}
            value={grades}
            controlShouldRenderValue={false}
            onChange={(values) => setGrades(values)}
          />
          <div className="flex -mt-4 col-span-full justify-start space-x-4">
            {grades.length > 0 && grades.map((_grade, index) => (
            <Badge
              key={`grade-badge-index[${index}]`}
              label={_grade.label}
              index={index}
              showRemove={true}
              onRemove={removeGrade}
            />))}
          </div> */}
          <UploadIcon label="Photo" image={photo} actionName="Change" onUpload={(files) => handlePhotoUpload(files)} />
        </FieldSet>

        <FieldSet
          title="Academic information"
          subTitle="Set institution name with academic degree. ex: EEE, BUET."
        >
          {educations.map((education, index) => (
            <EducationField 
            key={`instructor-experience-index[${index}]`} 
            education={education} 
            index={index} 
            educationsCount={educations.length}
            onFieldChange={handleEducationFieldChange}
            onDelete={deleteEducation}
          />
          ))}

          <div className="col-span-3">
            <PlusButton label="Add more" onClick={addEducation} />
          </div>
        </FieldSet>

        <FieldSet
          title="Experiences"
          subTitle="Set experiences for this instructor. ex: Worked at ABC coaching for 10 years."
        >
          {experiences.map((experience, index) => (
            <ExperienceField 
              key={`instructor-experience-index[${index}]`} 
              experience={experience} 
              index={index} 
              experiencesCount={experiences.length}
              onFieldChange={handleExperienceFieldChange}
              onDelete={deleteExperience}
            />
          ))}

          <div className="col-span-3">
            <PlusButton label="Add more" onClick={addExperience} />
          </div>
        </FieldSet>

        <FieldSet
          title="Social networks"
          subTitle="Set social newtwork links for instructor."
        >
          <Input col={4} label="Facebook URL" value={facebookUrl} onChange={(event) => setFacebookUrl(event.target.value)} />
          <Input col={4} label="LinkedIn URL" value={linkedinUrl} onChange={(event) => setLinkedinUrl(event.target.value)} />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
