import { Input } from '../../../components/forms/fields';


export default function InstructorEducationField(props) {
  const education = props.education;
  const index = props.index;
  const educationsCount = props.educationsCount;
  const onChange = props.onFieldChange;
  const onDelete = props.onDelete;

  return (
    <>
      <Input col={4} name="education" value={education} onChange={(event) => onChange(event, index)} />

      {educationsCount > 1 &&
      <div className="mt-4 col-span-1">
        <a
          href="#"
          className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
          onClick={(event) => onDelete(event, index)}
        >
          Remove
        </a>
      </div>}
    </>
  )
};
