
import { useState, useEffect } from 'react';
import { LuExternalLink } from 'react-icons/lu';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import toast from 'react-hot-toast';

import { Container } from '../components/Container';
import { CourseCardGrid } from '../../../../components/courseCards';
import { getCourses } from '../../../../services/course';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { orderBy } from 'lodash';


const TabButton = ({ hadleClick, active, children, count }) => (
	<button onClick={hadleClick} className={` -mb-0.5 ${active ? 'border-b-[3px] border-blue-700 text-blue-700' : ''} py-4 px-2 text-gray-500  flex h-full justify-center cursor-pointer`}>
		<h3 className={`  ${active ? 'text-blue-800' : ''} text-opacity-100  text-color5 text-sm md:text-base 2xl:text-xl font-semibold`}>
			{children}  {count && <span className="inline-block bg-indigo-100 text-blue-800 rounded-full px-2 text-sm ml-1">{count}</span>}
		</h3>
	</button>
);


export default function CardSection() {
	const [selectedGrade, setSelectedGrade] = useState('ssc');
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		async function _fetchInitialData() {
			await fetchCourses();
		};
		_fetchInitialData();
	}, [selectedGrade]);

	const fetchCourses = async () => {
		try {
			const params = {
				isFeatured: true,
				status: 'active'
			};
			const _courses = await getCourses(params);
			const orderedCourses = orderBy(_courses, 'createdAt', 'desc');
			setCourses(orderedCourses);
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<div id="allCourse" className='bg-gray-100'>
			<Container className="max-w-7xl px-4 sm:px-6">
				<div className="py-10">
					<div className="lg:py-12 py-8 space-y-3 text-center sm:space-y-5 tracking-wide ">
						{/* <h2 className="text-base  font-semibold leading-6 text-blue-900">
								কোর্সসমূহ
						</h2> */}
						<h2 className="text-3xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-4xl">
							আমাদের সবচেয়ে <span className="text-blue-500">জনপ্রিয় কোর্সসমূহ</span>
						</h2>
						<h2 className="text-xl leading-7 font-semibold text-gray-500 lg:w-1/2 mx-auto">
							অনলাইন এবং অফলাইন এর সমন্বয়ে দেশ সেরা কোর্স  সমূহে যুক্ত হও এখনই
						</h2>
					</div>

					{/* <SelectMenus selectedGrade={selectedGrade} onChange={setSelectedGrade} />

					<div className="hidden sm:flex border-b-2 border-gray-400 border-opacity-30 mb-4 md:mb-9 w-full space-x-4">
						<TabButton hadleClick={() => setSelectedGrade('ssc')} active={selectedGrade === 'ssc'} >এসএসসি</TabButton>
						<TabButton hadleClick={() => setSelectedGrade('hsc')} active={selectedGrade === 'hsc'}>এইচএসসি</TabButton>
						<TabButton hadleClick={() => setSelectedGrade('admission')} active={selectedGrade === 'admission'}>ভর্তি প্রস্তুতি</TabButton>
					</div> */}

					<div>
            <ul className="grid grid-cols-1 sm:grid-cols-3 gap-x-10 gap-y-10">
              {courses?.map((course, index) => (
                <li key={`course-index[${index}]`} className="bg-gray-50 rounded shadow">
                  <CourseCardGrid course={course} />
                </li>
              ))}
            </ul>
          </div>

					{/* <Swiper
						slidesPerView={1}
						spaceBetween={10}
						autoplay={{
								delay: 3000,
								disableOnInteraction: false,
						}}
						// pagination={{
						//     clickable: true,
						// }}
						navigation={true}
						modules={[Autoplay, Pagination, Navigation]}
						className="mySwiper"
						breakpoints={{
								300: {
										slidesPerView: 1, // 1 slide for mobile devices
								},
								640: {
										slidesPerView: 2, // 2 slides for tablets
								},
								768: {
										slidesPerView: 2, // 3 slides for desktop
								},
								1024: {
										slidesPerView: 3, // 4 slides for desktop
								},
								// 1536: {
								//     slidesPerView: 5, // 5 slides for desktop
								// },
							}}>
						{courses.map((course, index) => (
							<SwiperSlide key={`course-item-index[${index}]`} className='py-10'>
								<CourseCardGrid course={course} />
							</SwiperSlide>
						))}
					</Swiper> */}

					<div className="flex justify-center">
						<button
							className="mt-10 w-fit text-base leading-6 flex justify-center items-center text-blue-800 font-medium bg-blue-100 hover:bg-blue-200 py-3.5 px-6 rounded-[6px]"
							onClick={() => window.location.href = `/learn/courses`}
						>
							সকল কোর্স দেখুন
							<LuExternalLink className="ml-3 text-xl" />
						</button>
					</div>
				</div>
			</Container>
		</div>
	)
}
