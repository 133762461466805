import {
  CheckIcon,
  CheckCircleIcon,
  XCircleIcon,
  QuestionMarkCircleIcon,
  CircleStackIcon,
  ClockIcon
} from '@heroicons/react/24/solid';

import { convertToBanglaNumber } from '../../../../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function QuestionPageHeader(props) {
  const { exam, examStarted, duration, onEndExam } = props;
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="mb-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
          {exam?.name}
        </h2>
        <div className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CircleStackIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            পূর্ণমান: {convertToBanglaNumber(exam?.totalMarks || (exam.totalQuestions * exam.marksPerQuestion))}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <QuestionMarkCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            সর্বমোট প্রশ্ন: {convertToBanglaNumber(exam?.totalQuestions)}
          </div>

          {!examStarted &&
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              সময়: {convertToBanglaNumber(exam?.duration?.value)} মিনিট
          </div>}
          
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            প্রতি প্রশ্নের মান: {convertToBanglaNumber(exam?.marksPerQuestion)}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            প্রতি ভুল উত্তরের জন্য কর্তন: {convertToBanglaNumber(exam?.negativeMarksPerQuestion || '0')}
          </div>
        </div>
      </div>

      {examStarted &&
      <div className="mt-5 flex lg:ml-4 lg:mt-0 items-center">
        {duration &&
        <span 
          className={classNames(
            'text-3xl',
            (parseInt(duration.hour) === 0 && parseInt(duration.minutes) < 5 && parseInt(duration.minutes) >= 2) && 'text-yellow-500',
            (parseInt(duration.hour) === 0 && parseInt(duration.minutes) < 2) && 'text-red-600',
          )}
        >
          {`${duration.hour}:${duration.minutes}:${duration.seconds}`}
        </span>}

        <span className="ml-3">
          <button
            className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={onEndExam}
          >
            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            পরীক্ষা শেষ করুন
          </button>
        </span>
      </div>}
    </div>
  )
};