import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import {
  Input,
  Select,
  InlineCheckbox
} from '../../components/forms/fields';
import { getProgram, createProgram, updateProgram } from '../../services/program';


const grades = [
  {name: 'SSC', value: 'ssc'},
  {name: 'HSC', value: 'hsc'},
  {name: 'Admission', value: 'admission'},
  {name: 'Job preparation', value: 'job'}
];

const groups = [
  {name: 'All', value: 'all'},
  {name: 'Science', value: 'science'},
  {name: 'Business Studies', value: 'commerce'},
  {name: 'Humanities', value: 'humanities'}
];

const paymentCircles = [
  {name: 'Onetime', value: 'onetime'},
  {name: 'Monthly', value: 'month'},
];

export default function ProgramEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const programId = params.programId;
  const [program, setProgram] = useState(null);
  const [name, setName] = useState(null);
  const [nameBN, setNameBN] = useState(null);
  // const [grade, setGrade] = useState(null);
  const [paymentCircle, setPaymentCircle] = useState('onetime');
  const [admissionFeeEnabled, setAdmissionFeeEnabled] = useState(false);
  const [admissionFee, setAdmissionFee] = useState(0);
  const [programRollCode, setProgramRollCode] = useState('');
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (programId) {
          const _program = await getProgram(programId);
          setProgram(_program);
          setName(_program.name);
          setNameBN(_program.nameBN);
          // setGrade(_program.grade);
          setPaymentCircle(_program.paymentCircle);
          setAdmissionFeeEnabled(_program.admissionFeeEnabled);
          setAdmissionFee(_program.admissionFee);
          setProgramRollCode(_program.programRollCode);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    if (!admissionFeeEnabled) { setAdmissionFee(0); }
  }, [admissionFeeEnabled])

  const saveProgram = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        nameBN,
        // grade,
        paymentCircle,
        admissionFeeEnabled,
        admissionFee,
        programRollCode
      };
      
      if (programId) {
        await updateProgram(programId, payload);
        toast.success(`Program updated successfully.`);
      } else {
        await createProgram(payload);
        toast.success(`Program created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || (admissionFeeEnabled && admissionFee <= 0) || (programRollCode && (parseInt(programRollCode) < 10) || parseInt(programRollCode > 99));
  };

  const goBack = () => {
    navigate(`/programs`);
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {program?.name || 'Create new program'}
          </h1>
          {program &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this program.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={program ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveProgram}
        onCancel={goBack}
      >
        <FieldSet
          title="Program info"
          subTitle="Give a program name."
        >
          <Input col={4} label="Name (English)" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={4} label="Name (Bangla)" value={nameBN} onChange={(event) => setNameBN(event.target.value)} />
          <span></span>
          <Input
            col={2}
            label="Program roll code (optional)"
            value={programRollCode}
            onChange={(event) => setProgramRollCode(event.target.value)}
            helpText="Set an unique code between 10 and 99"
          />
          {/* <Select col={2} label="Grade" value={grade} options={grades} onChange={(event) => setGrade(event.target.value)} /> */}
          {/* <Select disabled={grade === 'job'} label="Group" value={group} options={groups} onChange={(event) => setGroup(event.target.value)} /> */}
        </FieldSet>
        <FieldSet
          title="Payment info"
          subTitle="Set payment circle and admission fees."
        >
          <Select col={2} label="Payment circle" value={paymentCircle} options={paymentCircles} onChange={(event) => setPaymentCircle(event.target.value)} />
          <InlineCheckbox
            col='full'
            label="Admission fee enabled"
            checked={admissionFeeEnabled}
            onChange={() => setAdmissionFeeEnabled(!cloneDeep(admissionFeeEnabled))}
          />
          {admissionFeeEnabled && <Input col={2} type="number" label="Admission fee" value={admissionFee} onChange={(event) => setAdmissionFee(event.target.value)} />}
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
