import { useState, useEffect } from 'react';

import StackedLayout from '../layouts/StackedLayout';
import { getTermsContent } from '../services/websiteContent';
import TextPanel from '../components/TextPanel';
import { getNormalizedDateTime } from '../utils';


export default function TermsPage() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const _content = await getTermsContent();
      setContent(_content);
    };
    fetchData();
  }, []);
  return (
    <StackedLayout
      allowAnonymous={true}
      hideFooter={true}
    >
      <h1 className="text-xl">Terms & Privacy</h1>
      {content && <p className="text-sm">Last updated on {getNormalizedDateTime(content?.updatedAt, 'DD MMM, YYYY')}</p>}

      <div>
        <p className="text-sm text-gray-600">
          <TextPanel text={content?.content} />
        </p>
      </div>
    </StackedLayout>
  )
};
