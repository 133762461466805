import { includes } from 'lodash';
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';


export default function VideoPlayer(props) {
  const {
    uri,
    playerRef,
    onEnded
  } = props;
  const dimensions = useRefDimensions(playerRef);

  return (
    <div className="relative w-full">
      <ReactPlayer
        width={`${dimensions.width}px`}
        height={`${dimensions.width * (9 / 16)}px`}
        url={uri}
        onEnded={onEnded}
        controls
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,            // Disable autoplay
              controls: 1,           // Hide the controls
              modestbranding: 1,      // Remove YouTube logo
              rel: 0,                 // Hide related videos
              iv_load_policy: 3,      // Disable annotations
              fs: 0,                  // Hide fullscreen button
              cc_load_policy: 0,      // Disable captions by default
              disablekb: 1,           // Disable keyboard controls
            },
          },
        }}
      />
      {includes(uri, 'youtube.com') &&
        <>
          <div className="absolute top-0 left-0 w-full h-20 bg-black opacity-0"></div>
          <div className="absolute bottom-0 right-0 w-1/2 h-20 bg-black opacity-0"></div>
        </>
      }
    </div>
  )
};

const useRefDimensions = (playerRef) => {
  const getDimensions = () => ({
    width: playerRef?.current?.offsetWidth,
    height: playerRef?.current?.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (playerRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [playerRef])

  return dimensions;
};
