import StackedLayout from '../../layouts/StackedLayout';
import LiveClassRoutine from '../../components/LiveClassRoutine';


const sidebarNavigation = [
  { name: 'Offline course routines', href:'/routines/offline' },
  { name: 'Online course routines', href:'/routines/online' },
];

export default function LiveClassRoutinePage(props) {
  const courseType = props.courseType;

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={courseType === 'online' ? 'Online course routines' : 'Offline course routines'}
    >
      <LiveClassRoutine courseType={courseType} />
    </StackedLayout>
  )
};
