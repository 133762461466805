import { useEffect, useState, useRef } from 'react';
import { forEach, cloneDeep } from 'lodash';

import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { Input } from '../../../components/forms/fields';
import { PlusButton } from '../../../components/buttons';
import ContentPicker from '../../../components/ContentPicker';
import InstructorInputWithList from '../../../components/InstructorInputWithList';
import LessonInputWithList from '../../../components/LessonInputWithList';
import VideoPlayer from '../../../components/VideoPlayer';
import { getVideo } from '../../../services/video';



export default function LessonForm(props) {
  const {
    lesson,
    lessonType,
    onClose,
    onSave
  } = props;
  const [title, setTitle] = useState(lesson?.title || '');
  const [instructors, setInstructors] = useState(lesson?.instructors || []);
  const [lessons, setLessons] = useState(lesson?.lessons || []);
  const [videoData, setVideoData] = useState(null);
  const [openContentPicker, setOpenContentPicker] = useState(false);
  const [updating, setUpdating] = useState(false);
  const playerRef = useRef();

  useEffect(() => {
    async function _fetchVideo() {
      if (lessonType === 'lesson' && lesson?.streamingPlatform === 'gotipath' && lesson?.videoId) {
        const data = await getVideo(lesson.videoId);
        setVideoData(data);
        if (data.status !== lesson.status) {
          onSave(null, {status: data.status})
        }
      }
    };
    _fetchVideo();
  }, []);

  const handleSaveChanges = async (event) => {
    try {
      setUpdating(true);
      let payload = { title, lessonType };

      if (lessonType === 'lesson') {
        let instructorIds = [];
        forEach(instructors, instructor => instructorIds.push(instructor._id));
        payload.instructors = instructorIds;
      } else if (lessonType === 'playlist') {
        let lessonIds = [];
        forEach(lessons, lesson => lessonIds.push(lesson._id));
        payload.lessons = lessonIds;
      }

      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const handleIntructorSelect = (item) => {
    let _instructors = cloneDeep(instructors);
    _instructors.push(item.value);
    setInstructors(_instructors);
  };

  const removeInstructor = (event, index) => {
    event.preventDefault();
    let _instructors = cloneDeep(instructors);
    _instructors.splice(index, 1);
    setInstructors(_instructors);
  };

  const handleLessonSelect = (event, item) => {
    let _lessons = cloneDeep(lessons);
    _lessons.push(item);
    setLessons(_lessons);
  };

  const removeLesson = (event, index) => {
    event.preventDefault();
    let _lessons = cloneDeep(lessons);
    _lessons.splice(index, 1);
    setLessons(_lessons);
  };

  const getSelectedLessons = () => {
    const selectedContents = [];
    forEach(lessons, _lesson => selectedContents.push(_lesson._id));
    return selectedContents;
  };

  const isDisabled = () => {
    return updating || !title;
  };

  if (openContentPicker) {
    return (
      <ContentPicker
        title="Select lessons"
        contentType="lesson"
        selectedContents={getSelectedLessons()}
        onSelect={handleLessonSelect}
        onCancel={() => setOpenContentPicker(false)}
      />
    )
  }

  return (
    <Sidepanel
      title={`Video ${lessonType}`}
      subTitle={`Set up video ${lessonType} information.`}
      onClose={onClose}
      onSave={handleSaveChanges}
      disabled={isDisabled()}
      updating={updating}
    >
      
      <SidepanelField
        label="Type"
      >
        <p className="text-sm text-gray-500">{lessonType}</p>
      </SidepanelField>

      {videoData &&
      <SidepanelField label={videoData.status === 'playable' ? 'Video' : 'Video status'}>

        {videoData.status === 'playable' ? 
        <div className="rounded-md overflow-hidden" ref={playerRef}>
          <VideoPlayer uri={videoData.manifest_url} playerRef={playerRef} />
        </div>
        : <p className="text-sm text-gray-500">{videoData?.status === 'draft' ? 'No video' : videoData.status}</p>}
      </SidepanelField>
      }
      
      <SidepanelField
        label={lessonType === 'lesson' ? 'Title' : 'Playlist name'}
      >
        <Input name="title" value={title} onChange={(event) => setTitle(event.target.value)} />
      </SidepanelField>

      {lessonType === 'lesson' &&
      <SidepanelField
        label="Instructors"
      >
        <InstructorInputWithList
          instructors={instructors}
          onSelect={handleIntructorSelect}
          onRemove={removeInstructor}
        />
      </SidepanelField>}

      {lessonType === 'playlist' &&
      <SidepanelField
        label="Lessons"
        col={3}
      >
        <div>
          <ul className="divide-y divide-gray-100">
            {lessons?.map((lesson, index) => (
              <li
                key={`lesoon-index[${index}]`}
                className="flex sm:span-col-full items-center justify-between gap-x-6 py-5"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{lesson?.title}</p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">Status: {lesson.status}  | Duration: {lesson.formattedDuration}</p>
                  </div>
                </div>
                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                  onClick={(event) => removeLesson(event, index)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
        <PlusButton label="Add lesson" onClick={() => setOpenContentPicker(true)} />
      </SidepanelField>}
    </Sidepanel>
  )
};
