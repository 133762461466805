import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, capitalize } from 'lodash';
import toast from 'react-hot-toast';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { Input, UploadIcon, RadioGroupHorizontal } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { userActions } from '../../store/reducers/userReducers'
import { updateUser } from '../../services/user';
import { handleUploadPublicImage } from '../../utils';


const sidebarNavigationEN = [
  {name: 'Profile', href: '/user/profile' },
  {name: 'Change Password', href: '/user/change-password' },
];
const sidebarNavigationBN = [
  {name: 'প্রোফাইল', href: '/user/profile' },
  {name: 'পাসওয়ার্ড পরিবর্তন', href: '/user/change-password' },
];

const defaultUpdateStates = {
  name: false,
  gender: false
};

const genderOptionsEN = [
  {name: 'Male', value: 'male'},
  {name: 'Female', value: 'female'}
];
const genderOptionsBN = [
  {name: 'ছাত্র', value: 'male'},
  {name: 'ছাত্রী', value: 'female'}
];
const genderLabels = {
  male: 'ছাত্র',
  female: 'ছাত্রী'
};

export default function UserProfilePage() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [name, setName] = useState(currentUser?.name);
  const [gender, setGender] = useState(currentUser?.gender);
  const [profilePicture, setProfilePicture] = useState(currentUser?.profilePicture);
  const [updateStates, setUpdateState] = useState(defaultUpdateStates);
  const language = currentUser?.role === 'student' ? 'bn' : 'en';
  const genderOptions = language === 'bn' ? genderOptionsBN : genderOptionsEN;
  const sidebarNavigation = language === 'bn' ? sidebarNavigationBN : sidebarNavigationEN;

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'name') { setName(currentUser.name); }
    else if (fieldName === 'gender') { setGender(currentUser.gender); }
  };

  const onSave = async (event, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {};
      payload[fieldName] = value;
      await saveUser(payload);
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleProfilePicture = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setProfilePicture(imageUrl);
      const payload = {profilePicture: imageUrl};
      await saveUser(payload);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const saveUser = async (payload) => {
    const updatedUser = await updateUser(currentUser._id, payload);
    dispatch(userActions.setUserInfo(updatedUser));
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={language === 'bn' ? 'প্রোফাইল' : 'Profile'}
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">{language === 'bn' ? 'প্রোফাইল' : 'Profile'}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          {language === 'bn' ? 'আপনার ব্যক্তিগত তথ্য আপডেট করুন' : 'Set your personal information'}
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          {currentUser?.role === 'student' &&
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {language === 'bn' ? 'রেজিস্ট্রেশন নাম্বার' : 'Registration number'}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentUser?.registrationNo}</div>
            </dd>
          </div>}
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {language === 'bn' ? 'মোবাইল' : 'Mobile'}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentUser?.mobile}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {language === 'bn' ? 'নাম' : 'Name'}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.name && <div className="text-gray-900">{currentUser?.name}</div>}
              {updateStates.name &&
              <div className="w-full">
                <Input value={name} onChange={(event) => setName(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label={language === 'bn' ? 'সাবমিট' : 'Update'} onClick={(event) => onSave(event, 'name', name)} />
                  <Button label={language === 'bn' ? 'বাতিল' : 'Update'} onClick={(event) => onCancel(event, 'name')} />
                </div>
              </div>}
              
              {!updateStates.name &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'name')}
              >
                {language === 'bn' ? 'পরিবর্তন' : 'Update'}
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {language === 'bn' ? 'প্রোফাইল ফটো' : 'Profile picture'}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                <UploadIcon actionName={language === 'bn' ? 'পরিবর্তন' : 'Change'} Icon={UserIcon} image={profilePicture} onUpload={handleProfilePicture} />
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              {language === 'bn' ? 'শিক্ষার্থীর ধরণ' : 'Gender'}
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.gender && <div className="text-gray-900">
                {language === 'bn' ? genderLabels[currentUser?.gender] : capitalize(currentUser?.gender)}
              </div>}
              {updateStates.gender &&
              <div className="w-full">
                <RadioGroupHorizontal value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
                <div className="mt-4 space-x-4">
                  <SubmitButton label={language === 'bn' ? 'সাবমিট' : 'Update'} onClick={(event) => onSave(event, 'gender', gender)} />
                  <Button label={language === 'bn' ? 'বাতিল' : 'Cancel'} onClick={(event) => onCancel(event, 'gender')} />
                </div>
              </div>}
              
              {!updateStates.gender &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'gender')}
              >
                {language === 'bn' ? 'পরিবর্তন' : 'Update'}
              </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
