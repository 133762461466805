import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import Loader from '../../../components/Loader';
import NotEnrolledCoursePage from './components/NotEnrolledCoursePage';
import EnrolledLiveCoursePage from './components/EnrolledLiveCoursePage';
import { getUserEnrolledCourse } from '../../../services/enrolledCourse';
import EnrolledCoursePage from './components/EnrolledCoursePage';
import { includes } from 'lodash';
import EnrolledOfflineCoursePage from './components/EnrolledOfflineCoursePage';


export default function CourseDetailsPage() {
  const params = useParams();
  const courseId = params.courseId;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [enrolledCourse, setEnrolledCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      async function _fetchData() {
        try {
          const _enrolledCourse = await getUserEnrolledCourse(courseId);
          setEnrolledCourse(_enrolledCourse);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);
        }
      };
      _fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Loader />
    )
  }

  if (enrolledCourse && enrolledCourse.course.courseType === 'live') {
    return (
      <EnrolledLiveCoursePage enrolledCourse={enrolledCourse} currentUser={currentUser} />
    )
  }

  if (enrolledCourse && enrolledCourse.course.courseType === 'offline') {
    return (
      <EnrolledOfflineCoursePage enrolledCourse={enrolledCourse} currentUser={currentUser} />
    )
  }

  if (enrolledCourse &&  includes(['recorded', 'exam'], enrolledCourse.course.courseType)) {
    return (
      <EnrolledCoursePage enrolledCourse={enrolledCourse} currentUser={currentUser} />
    )
  }

  if (!currentUser || !enrolledCourse) {
    return (
      <NotEnrolledCoursePage courseId={courseId} currentUser={currentUser} />
    )
  }
};