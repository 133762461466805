import Logo from '../assets/images/bg-bn-logo-horizontal.png';
import BGLogoEN from '../assets/images/bg-en-logo-horizontal.png';
import Favicon from '../assets/images/bg-favicon-32.png';
import BGIcon from '../assets/images/bg-icon.png';
import Bkash from '../assets/images/bkash.svg';
import Nagad from '../assets/images/nagad.svg';
import CreditCard from '../assets/images/credit-card.svg';
import Visa from '../assets/images/visa.svg';
import MasterCard from '../assets/images/master.svg';
import Amex from '../assets/images/amex.svg';
import Cash from '../assets/images/cash.svg';
import HeroImage from '../assets/images/hero-image.jpg';
import Note from '../assets/images/note.svg';
import Video from '../assets/images/video.svg';
import Exam from '../assets/images/exam.svg';


const images = {
  Logo,
  BGLogoEN,
  BGIcon,
  Favicon,
  Bkash,
  Nagad,
  CreditCard,
  Visa,
  MasterCard,
  Amex,
  Cash,
  HeroImage,
  Note,
  Video,
  Exam
};

export default images;