import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import LatexText from '../../../components/LatexText';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';


const optionLabels = ['A', 'B', 'C', 'D', 'E'];

export default function ExamQuestionView(props) {
  const { question, onClose } = props;

  return (
    <Sidepanel
      title="Question view"
      onClose={onClose}
      hideActions={true}
    >
      <SidepanelField
        label="Question"
      >
        <LatexText text={question.question} />
      </SidepanelField>
      <SidepanelField
        label="Options"
      >
        <ul className="mt-2 space-y-2">
          {question?.answers?.map((answer, index) => (
            <li
              key={`answer-index[${index}]`}
              className="border px-2 py-3 rounded-md"
            >
              <div className="flex justify-between items-center">
                <p className="flex flex-1 items-center space-x-1">
                  <span>{optionLabels[index]}{'.'}</span>
                  <LatexText text={answer.value} />
                </p>
                {answer.isCorrect &&
                <CheckCircleIcon className="h-5 w-5 text-green-600" />}
              </div>
            </li>
          ))}
        </ul>
      </SidepanelField>
      <SidepanelField
        label="Solution"
      >
        <LatexText text={question.solution} />
      </SidepanelField>
    </Sidepanel>
  )
};
