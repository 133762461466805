import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input } from '../../../components/forms/fields';
import ContentList from '../../contents/components/ContentList';
import moment from 'moment';


export default function ChapterTopicForm(props) {
  const {
    contentType,
    topicCount,
    programId,
    onSave,
    onDelete
  } = props;
  const defaultTopic = props.topic;
  const [topic, setTopic] = useState({});
  const [contentTypes, setContentTypes] = useState([]);

  useEffect(() => {
    setTopic(defaultTopic);
  }, [defaultTopic]);

  useEffect(() => {
    const _contentTypes = contentType ? [contentType] : ['lesson', 'exam', 'note'];
    setContentTypes(_contentTypes);
  }, [contentType])

  const handleFieldChange = (event) => {
    event.preventDefault();
    let _topic = cloneDeep(topic);
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    if (fieldName === 'name') {
      _topic.name = fieldValue;
    }
    setTopic(_topic);
  };

  const handleSave = async (event) => {
    try {
      await onSave(event, topic);
      toast.success('Content updated successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteTopic = async (event) => {
    try {
      await onDelete(event, topic);
      toast.success('Topic deleted successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddContent = (event, content, contentType) => {
    event.preventDefault();
    let _topic = cloneDeep(topic);
    let contents = _topic?.contents || [];
    const newContent = { contentType: contentType, [contentType]: content};
    contents.push(newContent);
    _topic.contents = contents
    setTopic(_topic);
  };

  const handleScheduleContent = (event, index, startTime, endTime) => {
    event.preventDefault();
    let _topic = cloneDeep(topic);
    _topic.contents[index].startTime = startTime ? moment(startTime).zone('+06:00').format() : null;
    _topic.contents[index].endTime = endTime ? moment(endTime).zone('+06:00').format() : null;
    setTopic(_topic);
  }

  const handleRemoveContent = (event, index) => {
    event.preventDefault();
    let _topic = cloneDeep(topic);
    let contents = _topic?.contents;
    contents.splice(index, 1);
    _topic.contents = contents
    setTopic(_topic);
  }

  return (
    <Form
     toitle="Topic"
     submitText="Save"
     showDeleteButton={topicCount > 1}
     hideCancelButton={true}
     onSubmit={handleSave}
     onDelete={handleDeleteTopic}
    >
      <FieldSet
        title="Topic info"
      >
        <Input col={4} label="Topic name" name="name" value={topic?.name || ''} 
          onChange={(event) => handleFieldChange(event)} />
      </FieldSet>

      <FieldSet
        title={`Contents`}
      >
        <ContentList
          contents={topic?.contents || []}
          contentTypes={contentTypes}
          programId={programId}
          onAdd={handleAddContent}
          onSchedule={handleScheduleContent}
          onRemove={handleRemoveContent}
        />
      </FieldSet>
    </Form>
  )
};
