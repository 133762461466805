import { useState, useEffect } from 'react';

import StackedLayout from '../layouts/StackedLayout';
import { getAboutUsContent } from '../services/websiteContent';
import TextPanel from '../components/TextPanel';


export default function AboutUsPage() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const _content = await getAboutUsContent();
      setContent(_content);
    };
    fetchData();
  }, []);
  return (
    <StackedLayout
      allowAnonymous={true}
      showCustomFooter={true}
    >
      <h1 className="text-xl">আমাদের সম্পর্কে</h1>
      {/* {content && <p className="text-sm">Last updated on {getNormalizedDateTime(content?.updatedAt, 'DD MMM, YYYY')}</p>} */}

      <div>
        <p className="text-sm text-gray-600">
          <TextPanel text={content?.content} />
        </p>
      </div>
    </StackedLayout>
  )
};
