import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { forEach } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import EmptyData from '../../components/EmptyData';
import Breadcrumb from '../../components/Breadcrumb';
import ContentTabs from '../../components/ContentTabs';
import ContentPicker from '../../components/ContentPicker';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { getCreativeExam, addCreativeExamQuestion, removeCreativeExamQuestion } from '../../services/creativeExam';
import LatexText from '../../components/LatexText';
import { DangerButton } from '../../components/buttons';


const optionLabels = ['A', 'B', 'C', 'D', 'E'];

export default function CQExamQuestionsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const cqExamId = params.id;
  const [exam, setExam] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [openContentPicker, setOpenContentPicker] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchInitialData() {
      try {
        const _cqExam = await getCreativeExam(cqExamId);
        setExam(_cqExam);

        const listUrl = _cqExam.folder ? `/contents/exams/cq/folder/${_cqExam.folder}` : `/contents/exams/cq`;
        setBreadcrumbPages([
          { name: 'CQ Exams', href: listUrl, current: false },
          { name: _cqExam?.name, current: true },
          { name: 'Questions', current: true }
        ]);

        const _questions = _cqExam.questions || [];
        const _selectedQuestionIndex = 0;
        setQuestions(_questions);
        setSelectedQuestion(_questions[_selectedQuestionIndex]);
        setSelectedQuestionIndex(_selectedQuestionIndex);
      } catch (error) {
        navigate(-1);
      }
    };
    _fetchInitialData();
    setLoading(false);
  }, []);

  useEffect(() => {
    formatTabs();
    setSelectedQuestion(questions[selectedQuestionIndex]);
  }, [
    questions,
    selectedQuestionIndex
  ]);

  const formatTabs = () => {
    let _tabs = [];

    for (let i = 0; i < questions.length; i++) {
      _tabs.push({ name: `Question ${i + 1}` })
    }

    setSelectedTab(_tabs[selectedQuestionIndex]?.name);
    setTabs(_tabs);
  };

  const handleQuestionChange = (questionIndex) => {
    setSelectedTab(tabs[questionIndex].name);
    setSelectedQuestion(questions[questionIndex]);
    setSelectedQuestionIndex(questionIndex);
  };

  const handleAddQuestion = async (event, question) => {
    try {
      event.preventDefault();
      const payload = { questionId: question._id };
      const updatedExam = await addCreativeExamQuestion(exam._id, payload);
      setSelectedQuestionIndex(updatedExam.questions.length - 1);
      setQuestions(updatedExam.questions);
      toast.success('Question added successfully.');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleRemoveQuestion = async (event) => {
    try {
      event.preventDefault();
      const payload = { questionId: selectedQuestion._id };
      const updatedExam = await removeCreativeExamQuestion(exam._id, payload);
      const nextQuestionIndex = selectedQuestionIndex === questions.length - 1 ? selectedQuestionIndex - 1 : selectedQuestionIndex;
      setSelectedQuestionIndex(nextQuestionIndex);
      setQuestions(updatedExam.questions);
      toast.success('Question removed successfully.');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const getSelectedQuestions = () => {
    let selectedQuestions = [];
    forEach(questions, q => selectedQuestions.push(q._id));
    return selectedQuestions;
  };

  return (
    <StackedLayout loading={loading}>
      <Breadcrumb pages={breadcrumbPages} />

      {openContentPicker &&
        <ContentPicker
          title="Select questions"
          contentType="cq"
          selectedContents={getSelectedQuestions()}
          onSelect={handleAddQuestion}
          onCancel={() => setOpenContentPicker(false)}
        />}

      {showDeleteModal &&
        <ConfirmModal
          title="Remove question"
          description="Are you sure to remove this question?"
          action="Remove"
          onConfirm={handleRemoveQuestion}
          onCancel={() => setShowDeleteModal(false)}
        />}

      {questions.length === 0 &&
        <EmptyData
          title="No questions"
          action="Add new question"
          onCreate={() => setOpenContentPicker(true)}
        />}

      {tabs.length > 0 &&
        <ContentTabs
          tabs={tabs}
          selectedTab={selectedTab}
          action="Add question"
          showAddTypeButton={true}
          onTabChange={handleQuestionChange}
          onAddTab={() => setOpenContentPicker(true)}
        />}

      {selectedQuestion &&
        <div className="sm:w-1/2">
          <div>
            <p className="font-medium">Comprehension:</p>
            <p><LatexText text={selectedQuestion.comprehension} /></p>
            <p className="mt-2">Total marks: {selectedQuestion.totalMarks}</p>
          </div>

          <ul className="mt-4 space-y-2">
            {selectedQuestion.questionSet?.map((subQuestion, index) => (
              <li
                key={`question-index[${index}]`}
                className="border px-2 py-3 rounded-md"
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-1 justify-between items-center space-x-1">
                    <p className="flex space-x-1">
                      <span>{optionLabels[index]}{'.'}</span>
                      <LatexText text={subQuestion.question} />
                    </p>
                    <span>Marks: {subQuestion.marks}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="mt-4">
            <DangerButton label="Remove" onClick={() => setShowDeleteModal(true)} />
          </div>
        </div>}
    </StackedLayout>
  )
};
