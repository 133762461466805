import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { utils, writeFile } from 'xlsx';
import { forEach, omit, cloneDeep, findIndex, orderBy } from 'lodash';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import ActivityProgressBar from '../../components/ActivityProgrssBar';
import { getBranches } from '../../services/branch';
import { getCourse } from '../../services/course';
import { getMeritLists } from '../../services/meritList';


const headers = ['Student ID', 'Name', 'Branch', 'MCQ', 'Written', 'Total', 'Branch Position', 'Central Position'];

export default function CourseMeritListPage() {
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.courseId;
  const [course, setCourse] = useState(null);
  const [meritLists, setmeritLists] = useState([]);
  const [pagingData, setPagingData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [branchFilters, setBranchFilters] = useState([]);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const [showProgressMessage, setShowprogressMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const _branches = await getBranches({status: 'active'});
        let filterOptions = [];
        forEach(orderBy(_branches, 'name'), branch => {
          filterOptions.push({
            label: branch.name,
            value: branch._id
          })
        });
        setFilters([{
          name: 'Branch',
          fieldName: 'branch',
          options: filterOptions
        }])

        const course = await getCourse(courseId);
        setCourse(course);

        setBreadcrumbPages([
          { name: 'Courses', href: `/results/offline` },
          { name: course?.name, href: `/results/course/${courseId}` },
          { name: 'Merit list', current: true },
        ])
      } catch (error) {
        navigate(-1);
      }
    };
    fetchInitialData();
    setLoading(false);
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
  }, [
    currentPage,
    branchFilters
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        course: courseId,
        sortBy: branchFilters.length === 1 ? 'branchPosition' : 'centralPosition'
      };
      if (branchFilters.length > 0) {
        params.branch = branchFilters.length === 1 ? branchFilters[0] : branchFilters;
      }
      const data = await getMeritLists(params);
      setmeritLists(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _branchFilters = cloneDeep(branchFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'branch') {
      const valueIndex = findIndex(_branchFilters, item => item === _value);
      if (valueIndex === -1) {
        _branchFilters.push(_value);
      } else {
        _branchFilters.splice(valueIndex, 1);
      }
      setBranchFilters(_branchFilters);
    }
    setCurrentPage(1);
  };

  const exportExcel = async () => {
    try {
      setShowprogressMessage(true);
      let params = {
        course: courseId
      };

      const meritLists = await getMeritLists(params);
      let excelData = [];
      for (let i = 0; i < meritLists?.length; i++) {
        const resultData = meritLists[i];
        excelData.push({
          'Student ID': resultData?.user?.registrationNo,
          'Name': resultData?.user?.name,
          'Branch': resultData?.branch?.name,
          'MCQ Obtained': resultData?.mcqMarksObtained,
          'Written Obtained': resultData?.cqMarksObtained,
          'Total Obtained': resultData?.marksObtained,
          'Branch Position': resultData?.branchPosition,
          'Central Position': resultData?.centralPosition
        });
      };
      
      const ws = utils.json_to_sheet(excelData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Merit List");
      writeFile(wb, `MeritList-${course.name}.xlsx`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowprogressMessage(false);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title="Merit lists"
      subTitle={`Course: ${course?.name}`}
      breadcrumbPages={breadcrumbPages}
      filters={filters}
      onFilterChange={applyFilterChanges}
      createButtonText="Download results"
      onCreate={exportExcel}
    >
      <Table
        headers={headers}
        itemsCount={meritLists.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >
        {meritLists.map((meritList) => (
          <tr
            key={meritList._id}
            className="text-center text-sm text-gray-500"
          >
            <td className="py-4 pl-4 pr-3 font-medium sm:pl-0">
              {meritList?.user.registrationNo}
            </td>
            <td className="pr-3 py-4">{meritList?.user?.name}</td>
            <td className="pr-3 py-4">{meritList?.branch?.name}</td>
            <td className="pr-3 py-4">{meritList?.mcqMarksObtained || '-'}</td>
            <td className="pr-3 py-4">{meritList?.cqMarksObtained || '-'}</td>
            <td className="pr-3 py-4">{meritList?.marksObtained || '-'}</td>
            <td className="pr-3 py-4">{meritList?.branchPosition || '-'}</td>
            <td className="pr-3 py-4">{meritList?.centralPosition || '-'}</td>
          </tr>
        ))}

      </Table>

      {showProgressMessage && <ActivityProgressBar message="Merit list is processing..." />}
    </CategoryListingLayout>
  )
};