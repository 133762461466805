import { PlusIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

import { getBanglaDate } from '../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function Sidebar(props) {
  const {
    page,
    enrolledCourseData,
    navigation,
    secondaryNavigation,
    currentNavigation,
    currentSecondaryNavigation,
    onNavigationChange,
    onSecondaryNavigationChange,
    onAddSecondaryNavigation,
    onEditSecondaryNavigation
  } = props;


  const handleNavigationChange = (event, index) => {
    const item = navigation[index];
    if (item.href) {
      window.location.href = item.href;
    } else {
      onNavigationChange(event, index);
    }
  }; 

  return (
    <nav className="flex flex-1 flex-col py-4 lg:block lg:w-64 lg:flex-none lg:border-0" aria-label="Sidebar">
      {page === 'course' &&
      <CourseCard enrolledCourseData={enrolledCourseData} />}

      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {navigation?.length > 0 && navigation.map((item, index) => (
              <li key={`sidebar-nav-index[${index}]`}>
                <button
                  className={classNames(
                    (item?.name === currentNavigation || item === currentNavigation)
                      ? 'bg-gray-50 text-green-600'
                      : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                    'group w-full flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                  )}
                  onClick={(event) => handleNavigationChange(event, index)}
                >
                  {item?.name || item}
                </button>
              </li>
            ))}
          </ul>
        </li>

        {secondaryNavigation?.length > 0 &&
        <li>
          <div className="flex text-xs font-semibold leading-6 justify-between items-center">
            <span className="text-gray-400">Batches</span>
            <button 
              className="inline-flex items-center text-green-500"
              onClick={onAddSecondaryNavigation}
            >
              <PlusIcon className="h-4 w-4" />
              New
            </button>
          </div>
          <ul role="list" className="-mx-2 mt-2 space-y-1">
            {secondaryNavigation.map((item, index) => (
              <li key={`sidebar-sec-nav-index[${index}]`}>
                <div
                  className={classNames(
                    (item?.name === currentSecondaryNavigation || item === currentSecondaryNavigation)
                      ? 'bg-gray-50 text-green-600'
                      : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                    'group w-full flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                  )}
                  onClick={(event) => onSecondaryNavigationChange(event, index)}
                >
                  <div className="flex flex-1 items-center justify-between">
                    <span className="truncate">{item?.name || item}</span>
                    {(item?.name === currentSecondaryNavigation || item === currentSecondaryNavigation) &&
                    <span>
                      <PencilSquareIcon 
                        className="h-4 z-10 text-green-900 cursor-pointer"
                        onClick={onEditSecondaryNavigation}
                      />
                    </span>}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </li>}
      </ul>
    </nav>
  )
};

function CourseCard(props) {
  const { enrolledCourseData } = props;
  
  return (
    <div className='m-0 flex flex-col rounded-md bg-gray-50 py-4 mb-4 content-center justify-center items-center'>
      <div className='flex flex-row items-center'>
          <div className="inline-flex flex-col px-2 space-y-0.5 items-start justify-end">
              <p className="text-gray-900">
                {enrolledCourseData?.courseName}
              </p>
              {enrolledCourseData?.branchName &&
              <p className="text-sm text-gray-400">
                শাখা: {enrolledCourseData?.branchName}
              </p>}
              {enrolledCourseData?.batchName &&
              <p className="text-sm text-gray-400">
                ব্যাচ: {enrolledCourseData?.batchName}
              </p>}
              {enrolledCourseData?.rollNo &&
              <p className="text-sm text-gray-400">
                রোল: {enrolledCourseData?.rollNo}
              </p>}
              {enrolledCourseData?.expiresAt &&
              <p className="text-sm text-indigo-400">
                কোর্সটির মেয়াদ {getBanglaDate(enrolledCourseData.expiresAt)} পর্যন্ত
              </p>}
          </div>
      </div>
  </div>
  )
};
