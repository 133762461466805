import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { isEmpty, capitalize, orderBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import { searchInvoices, getInvoicePdf } from '../../services/invoice';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Collect payment', href: '/student-payments/collect' },
  { name: 'All due payments', href: '/student-payments/due' },
  { name: 'All paid payments', href: '/student-payments/paid' },
  { name: 'Other branch payments', href: '/student-payments/central' },
];
const headers = ['Reg. no', 'Student Name', 'Payment for', 'Branch', 'Amount', 'Status', 'Due Date'];

export default function CentralPaymentsPage() {
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [invoices, setInvoices] = useState([]);
  const [queryString, setQueryString] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchInvoices = async (_queryString) => {
    try {
      if (isEmpty(_queryString)) {
        setInvoices([]);
        return;
      }
      let params = {
        studentId: _queryString,
        status: ['due', 'overdue']
      };

      const data = await searchInvoices(params);
      setInvoices(orderBy(data, 'dueDate'));
    } catch (error) {
      toast.error(error.message);
      setInvoices([]);
    }
  };

  const onSearch = async (event, _queryString) => {
    try {
      event.preventDefault();
      setLoading(true);
      const q = !isEmpty(_queryString) ? _queryString: null;
      setQueryString(q);
      await fetchInvoices(q);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePrintInvoice = async (invoiceId) => {
    try {
      const pdfUrl = await getInvoicePdf(invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Other branch payments"
    >
      {!currentBranch?.permissions?.canCollectCentralPayments &&
      <div className="grid min-h-full place-items-center bg-white px-6 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-red-600">Sorry!</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Permissions denied</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Your branch is not allowed to collect central payments.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* <a
              href="/supports"
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Contact support
            </a> */}
            {/* <a href="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a> */}
          </div>
        </div>
      </div>}

      {currentBranch?.permissions?.canCollectCentralPayments &&
      <Table
        headers={headers}
        itemsCount={invoices.length}
        queryString={queryString}
        onSearch={onSearch}
        searchPlaceholder="Search by student registration no"
      >
        {invoices?.map((invoice) => (
          <tr key={invoice._id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {invoice?.user?.registrationNo}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.user?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.title || invoice?.course?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {invoice.status === 'paid' ? invoice.paidAmount :invoice.amount}
            </td>
            <td 
              className={`pr-3 py-4 text-sm ${invoice.status === 'overdue' ? 'text-red-500' : 'text-gray-500'}`}
            >
              {capitalize(invoice?.status)}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {getNormalizedDateTime(invoice.dueDate, 'DD MMM YYYY')}
            </td>
            <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <div className="flex justify-end items-center space-x-2">
                <p
                  className="text-gray-600 hover:text-gray-500 underline cursor-pointer"
                  onClick={() => handlePrintInvoice(invoice._id)}
                >
                  Print
                </p>
                {invoice.status !== 'paid' &&
                <a
                  href={`/student-payments/${invoice._id}/payment`}
                  className="text-indigo-600 hover:text-indigo-900 underline"
                >
                  Collect
                </a>}
              </div>
            </td>
          </tr>
        ))}

      </Table>}

    </StackedLayout>
  )
};
