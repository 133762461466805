import http from '../helpers/http';


export const createVideo = async (payload) => {
  try {
    const { data } = await http.post(`/api/videos`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getVideo = async (id) => {
  try {
    const { data } = await http.get(`/api/videos/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteVideo = async (id) => {
  try {
    const { data } = await http.delete(`/api/videos/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getVimeoDownloadLink = async (id) => {
  try {
    
  } catch (error) {
    
  }
};

export const getGotipathVideo = async (videoId) => {
  try {
    const { data } = await http.get(`/api/videos/${videoId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
