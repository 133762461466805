export default function ActivityProgressBar(props) {
  const { message } = props;

  return (
    <div className="px-4 fixed mx-w-xs bottom-20 right-10 bg-white text-sm overflow-hidden rounded-lg shadow">
      <div
        className="flex flex-1 border-b border-gray-200 justify-between items-center py-2 cursor-pointer"
      >
        <h3 className="font-semibold leading-6 text-gray-900 mr-10">
          {message} 
        </h3>
      </div>
    </div>
  )
};
