import { useState } from 'react';
import { cloneDeep, isEmpty, map } from 'lodash';
import Latex from 'react-latex';

import RichTextEditor from '../../../components/TextEditor';
import { PlusButton, MinusButton } from '../../../components/buttons';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { alphabet } from '../../../utils';


const newAnswerOption = { value: '', isCorrect: false };
const answerCorrectOptions = [
  {id: 'correct', title: 'Correct'},
  {id: 'wrong', title: 'Wrong'}
];
const newQuestion = {
  question: '',
  answers: [
    {value: '', isCorrect: false},
    {value: '', isCorrect: false},
    {value: '', isCorrect: false},
    {value: '', isCorrect: false}
  ],
  solution: ''
};

export default function QuestionEditor(props) {
  const {
    onClose,
    onSave
  } = props;
  const [question, setQuestion] = useState(props.question || newQuestion);
  const [updating, setUpdating] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(true);
  const [editingSolution, setEditingSolution] = useState(false);
  const [editingAnswerIndex, setEditingAnswerIndex] = useState(null);

  const handleQuestionInput = (value) => {
    let _question = cloneDeep(question);
    _question.question = value;
    setQuestion(_question);
  };

  const handleAnswerOptionChange = (value, answerIndex) => {
    let _question = cloneDeep(question);
    _question.answers[answerIndex].value = value;
    setQuestion(_question);
  };

  const toggleAnswerCorrectOption = (answerIndex, isCorrect) => {
    let _question = cloneDeep(question);
    _question.answers[answerIndex].isCorrect = isCorrect === 'correct';
    setQuestion(_question);
  };

  const handleSolutionChange = (value) => {
    let _question = cloneDeep(question);
    _question.solution = value;
    setQuestion(_question);
  };

  const addAnswerOption = () => {
    const _question = cloneDeep(question);
    let answerOptions = _question.answers || [];
    answerOptions.push(newAnswerOption);
    _question.answers = answerOptions;
    setQuestion(() => _question);
    setEditingAnswerIndex(_question.answers.length - 1);
    setEditingQuestion(false);
    setEditingSolution(false);
  };

  const removeAnswerOption = (index) => {
    const _question = cloneDeep(question);
    let answerOptions = _question.answers;
    answerOptions.splice(index, 1);
    _question.answers = answerOptions;
    setQuestion(() => _question);
    if (editingAnswerIndex === index) {
      setEditingAnswerIndex(null);
    }
  };

  const checkAnswerCorrectOptionSelected = (answerIndex, optionType) => {
    const _isCorrect = question.answers[answerIndex].isCorrect;
    return optionType === 'correct' ? _isCorrect : !_isCorrect;
  }

  const handleSaveQuestion = async (event) => {
    try {
      setUpdating(true);
      await onSave(event, question);
      onClose();
    } catch {
      setUpdating(false);
    }
  };

  const editQuestion = () => {
    setEditingQuestion(true);
    setEditingAnswerIndex(null);
    setEditingSolution(false);
  };

  const editAnswer = (index) => {
    setEditingAnswerIndex(index);
    setEditingQuestion(false);
    setEditingSolution(false);
  };

  const editSolution = () => {
    setEditingSolution(true);
    setEditingQuestion(false);
    setEditingAnswerIndex(null);
  };

  const isDisabled = () => {
    return updating || isEmpty(question?.question) || question?.question === '<p><br></p>' || question?.answers?.some(a => isEmpty(a.value) || a.value === '<p><br></p>');
  };

  return (
    <Sidepanel
      title="Question editor"
      subTitle="You are editing a question. Please save the question once editing is done."
      updating={updating}
      disabled={isDisabled()}
      onSave={handleSaveQuestion}
      onClose={onClose}
    >
      <SidepanelField
        col={3}
        label="Question"
      >
        {!editingQuestion &&
        <TextPanel text={question?.question || ''} onEdit={editQuestion} />}

        {editingQuestion &&
        <RichTextEditor
          id={`question-editor`} 
          value={question?.question || ''}
          onChange={(value) => handleQuestionInput(value)}
        />}
      </SidepanelField>

      {question?.answers?.map((answer, answerIndex) => (
        <SidepanelField
          key={`question-answer-option-index[${answerIndex}]`}
          col={3}
          label={`Option ${alphabet[answerIndex]}`}
        >
          {editingAnswerIndex !== answerIndex &&
          <TextPanel text={answer?.value || ''} onEdit={() => editAnswer(answerIndex)} />}

          {editingAnswerIndex === answerIndex &&
          <RichTextEditor
            id={`answer-editor-index[${answerIndex}]`} 
            value={answer?.value || ''}
            onChange={(value) => handleAnswerOptionChange(value, answerIndex)}
          />}

          <div className="flex justify-between">
            <fieldset>
              <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                {map(answerCorrectOptions, (_option) => (
                <div key={`answer-index[${answerIndex}]-${_option.id}`} className="flex items-center">
                  <input
                    id={`answer-index[${answerIndex}]-${_option.id}-id`}
                    name={`answer-index[${answerIndex}]-name`}
                    type="radio"
                    defaultChecked={checkAnswerCorrectOptionSelected(answerIndex, _option.id)}
                    onClick={() => toggleAnswerCorrectOption(answerIndex, _option.id)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor={_option.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                    {_option.title}
                  </label>
                </div>
                ))}
              </div>
            </fieldset>

            <div className="flex justify-end space-x-3">
              {answerIndex > 3 &&
              <MinusButton label="Remove" onClick={() => removeAnswerOption(answerIndex)} />}

              {answerIndex === (question.answers.length - 1) &&
              <PlusButton label="Add answer" onClick={addAnswerOption} />}
            </div>
          </div>
        </SidepanelField>
      ))}

      <SidepanelField
        col={3}
        label="Solution"
      >
        {!editingSolution &&
        <TextPanel text={question?.solution || ''} onEdit={editSolution} />}

        {editingSolution &&
        <RichTextEditor
          id={`question-solution-editor`} 
          value={question?.solution || ''}
          onChange={(value) => handleSolutionChange(value)}
        />}
      </SidepanelField>
    </Sidepanel>
  )
};

function TextPanel(props) {
  const { text, onEdit } = props;
  return (
    <span className="flex questions-answer-wrapper text-sm leading-6 text-gray-900 line-clamp-2">
      {text.includes("$$") ? 
      <Latex>{text}</Latex>
      : <span dangerouslySetInnerHTML={{ __html: text }}/>}
      <span 
        className="text-green-600 hover:text-green-900 cursor-pointer" 
        onClick={onEdit}
      >
        {'...Edit'}
      </span>
    </span>
  )
};
