import http from '../helpers/http';


export const getPrograms = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/programs`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getProgram = async (id) => {
  try {
    const { data } = await http.get(`/api/programs/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getProgramExams = async (id, examType) => {
  try {
    const { data } = await http.get(`/api/programs/${id}/exams/${examType}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const removeProgramExam = async (id, examType, examId) => {
  try {
    const { data } = await http.put(`/api/programs/${id}/exams/${examType}/${examId}/remove`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createProgram = async (payload) => {
  try {
    const { data } = await http.post(`/api/programs`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateProgram = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/programs/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeProgramStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/programs/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getProgramsForBranch = async (branchId, params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/programs/branches/${branchId}`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
