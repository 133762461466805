import { FiGlobe } from "react-icons/fi";
import { LuExternalLink } from 'react-icons/lu';


const features = [
  {
    name: 'SSC একাডেমিক + মডেল টেস্ট',
    description:
      'বেসিক যখন শক্ত , প্রস্তুতি তখন পাকাপোক্ত',
    // icon: LockClosedIcon,
    background: "#EEF2FF",
    boxColor: "#6366F1"
  },
  {
    name: 'HSC একাডেমিক + মডেল টেস্ট',
    description:
      'স্বপ্ন দেখার শুরু এখন থেকেই',
    // icon: CloudArrowUpIcon,
    background: "#ECFDF5",
    boxColor: "#10B981"
  },
  {
    name: 'ইঞ্জিনিয়ারিং ভর্তি প্রোগ্রাম',
    description:
      'স্বপ্ন যখন প্রকৌশলী হবার, সাথে আছি পথচলার',
    // icon: ArrowPathIcon,
    background: "#FFF7ED",
    boxColor: "#F97316"
  },
  {
    name: 'ভার্সিটি ক ভর্তি প্রোগ্রাম',
    description:
      'প্রিয় ক্যাম্পাসে পৌঁছে যেতে, প্রস্তুতি হোক স্পন্দনের সাথে',
    // icon: FingerPrintIcon,
    background: "#ECFEFF",
    boxColor: "#06B6D4"
  },
  {
    name: 'ক্যাডেট ভ্যাকেশন',
    description:
      'আনন্দময় ভ্যাকেশন আর সাথে স্পন্দন',
    // icon: FingerPrintIcon,
    background: "#FEF2F2",
    boxColor: "#EF4444"
  },
]

export default function StudyCategory() {
  return (
    <div className="bg-white py-12 sm:py-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-900">আমাদের প্রোগ্রামসমূহ</h2>
          <p className="mt-2 text-3xl font-extrabold	 tracking-tight text-gray-900 sm:text-4xl">
            <span className="text-blue-500">সেরা প্রোগ্রামের, সেরা কোর্সে</span> <span className="">যুক্ত হোন আজই</span>
          </p>

        </div>
        <div className="mx-auto mt-8">
          <dl className="flex flex-wrap gap-4 mx-auto place-content-center ">
            {features.map((feature) => (
              <div key={feature.name} style={{background:feature.background}} className="px-6 py-6 w-full md:w-fit rounded-lg">
                <div  className="relative px-4">
                  <div className="flex items-center space-x-4 text-lg text-left font-semibold text-gray-900 ">
                    <div style={{background:feature.boxColor}}  className="flex h-10 w-10 items-center justify-center rounded-lg ">
                      <FiGlobe className="text-2xl text-white" />
                    </div>
                    <div>
                      <p>{feature.name}</p>
                      <p className="text-sm text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                  {/* <div className="mt-2 text-base font-normal leading-6 text-left text-gray-500 ">{feature.description}</div> */}
                </div>
              </div>
            ))}
          </dl>
        </div>

        <div className="flex justify-center">
          <button
            className="mt-10 w-fit text-base leading-6 flex justify-center items-center text-blue-800 font-medium bg-blue-100 hover:bg-blue-200 py-3.5 px-6 rounded-[6px]"
            onClick={() => window.location.href = `/learn/courses`}
          >
            সকল কোর্স দেখুন
            <LuExternalLink className="ml-3 text-xl" />
          </button>
        </div>
      </div>
    </div>
  )
}
