import { findIndex } from 'lodash';

import { PlusButton } from './buttons';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ContentTabs(props) {
  const {
    tabs,
    selectedTab,
    action,
    showAddTypeButton,
    onAddTab,
    onTabChange,
    contentType
  } = props;

  const handleTabChange = (tabName) => {
    const tabIndex = findIndex(tabs, tab => tab.name === tabName);
    onTabChange(tabIndex);
  }

  return (
    <div className="border-b border-gray-200 pb-5 mb-5 sm:pb-0">
      <div className="md:flex md:items-center justify-end">
      {showAddTypeButton &&
        <PlusButton
          label={ action || 'New type'}
          onClick={onAddTab}
        />}
      </div>
      <div className="mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600"
            defaultValue={`Type ${tabs.find((tab) => tab?.name === selectedTab)?.name}`}
            onChange={(event) => handleTabChange(event.target.value)}
          >
            {tabs.map((tab, index) => (
              <option key={`chapter-topic-index-[${index}]`} value={tab.name}>{contentType === 'question' ? 'Type ': ''}{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex overflow-x-auto space-x-8">
            {tabs.map((tab, index) => (
              <a
                key={`chapter-topic-index-[${index}]`}
                href="#"
                className={classNames(
                  tab.name === selectedTab
                    ? 'border-green-500 text-green-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => handleTabChange(tab.name)}
              >
                {contentType === 'question' ? 'Type ': ''}{tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.name === selectedTab ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}