import {CircleStackIcon} from '@heroicons/react/24/outline';
import { PlusButton, Button } from './buttons';


export default function EmptyData(props) {
  const title = props.title || 'No data found.';
  const subTitle = props.subTitle || 'Get started by creating a new item.';
  const action = props.action || 'Create new';
  const actionType = props.actionType || 'create';
  const hideNewButton = props.hideNewButton;
  const onCreate = props.onCreate;
  const onView = props.onView;

  return (
    <div className="mt-3 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <CircleStackIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      {!hideNewButton && actionType === 'create' && <p className="mt-1 text-sm text-gray-500">{subTitle}</p>}
      {!hideNewButton && actionType === 'create' &&
      <div className="mt-6">
        <PlusButton label={action} onClick={onCreate} />
      </div>}
      {!hideNewButton && actionType === 'view' &&
      <div className="mt-6">
        <Button label={action} onClick={onView} />
      </div>}
    </div>
  )
}

