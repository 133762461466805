import { Input } from '../../../components/forms/fields';


export default function EbookAuthorForm(props) {
  const {
    authorCount,
    author,
    index,
    onFieldChange,
    onDelete
  } = props;

  return (
    <>
      <Input col={4} label="Name" name="author" value={author.name}
        onChange={(event) => onFieldChange(event, index)}
      />

      {/* <Input type="number" col={2} label="Starting page" name="startPage" value={chapter.startPage}
        onChange={(event) => onFieldChange(event, index)}
      /> */}

      {authorCount === 1 &&
      <span></span>
      }

      {authorCount > 1 &&
      <div className="mt-9 col-span-1">
        <a
          href="#"
          className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
          onClick={(event) => onDelete(event, index)}
        >
          Remove
        </a>
      </div>}
    </>
  )
}
