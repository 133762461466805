const stats = [
  { id: 1, name: 'বছর', value: '5+' },
  { id: 2, name: 'শাখা ', value: '11' },
  { id: 3, name: 'ইন্সট্রাক্টরস', value: '70+' },
  // { id: 4, name: 'Paid out to creators', value: '$70M' },
]

export default function StatsSection() {
  return (
    <div className="bg-blue-900 py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold leading-10 tracking-tight text-white sm:text-4xl">
              ৬৪ টিরও বেশি জেলার ছাত্র ও ছাত্রীদের আস্থা
            </h2>
            <p className="mt-4 text-xl font-normal leading-8 text-indigo-200">
            শিক্ষার মাধ্যমে আপনি আরো সুন্দর মানুষ হিসেবে গড়ে উঠুন
            </p>
          </div>
          <dl className="mt-4 md:mt-9 mx-auto flex flex-col sm:flex-row justify-center sm:w-3/5">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col justify-center mx-auto p-8">
                <dt className="text-center mt-2 text-lg font-medium leading-6 text-indigo-200">{stat.name}</dt>
                <dd className="text-center order-first text-5xl font-semibold tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
