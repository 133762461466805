import { useState } from 'react';
import { cloneDeep } from 'lodash';

import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import CourseInputWithList from '../../../components/CourseInputWithList';


export default function AssoicatedCoursesForm(props) {
  const {
    course,
    onSave,
    onCancel
  } = props;
  const [associatedCourses, setAssociatedCourses] = useState(course.associatedCourses || []);
  const [updating, setUpdating] = useState(false);

  const handleSaveCourse = async (event) => {
    try {
      setUpdating(true);
      const payload = { associatedCourses };
      await onSave(event, payload);
      onCancel();
    } finally {
      setUpdating(false);
    }
  };

  const onAddCourse = (event, course) => {
    event.preventDefault();
    let _associatedCourses = cloneDeep(associatedCourses);
    _associatedCourses.push(course);
    setAssociatedCourses(_associatedCourses);
  };

  const removeCourse = (event, index) => {
    event.preventDefault();
    let _associatedCourses = cloneDeep(associatedCourses);
    _associatedCourses.splice(index, 1);
    setAssociatedCourses(_associatedCourses);
  };

  return (
    <>
      <div className="border-b border-gray-200 pb-5 mb-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {course?.name}
        </h3>
      </div>

      <Form
        submitText="Save"
        disabled={updating}
        onSubmit={handleSaveCourse}
        onCancel={onCancel}
      >
      
        <FieldSet
          title="Associated courses"
          subTitle="Add online courses that are associated with course."
        >
          
          <CourseInputWithList
            col={3}
            courses={associatedCourses}
            onAdd={onAddCourse}
            onRemove={removeCourse}
          />
        </FieldSet>
      </Form>
    </>
  )
};
