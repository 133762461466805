import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { sendForgotPasswordOTP } from '../../services/userOTP';
import Layout from './components/Layout';


export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isStaff = location?.state?.isStaff;
  const redirectUrl = location?.state?.redirectUrl;
  const [mobile, setMobile] = useState('');
  // const [registrationNo, setRegistrationNo] = useState('');
  const [updating, setUpdating] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = { mobile, isStaff };
      const data = await sendForgotPasswordOTP(payload);
      navigate(`/auth/otp`, {
        state: {
          userId: data.userId,
          mobile: data.mobile,
          otpFor: 'password',
          redirectUrl: redirectUrl
        }
      });
    } catch (error) {
      setUpdating(false);
      toast.error(error.message);
    }
  };

  return (
    <Layout
      title="আপনি কি পাসওয়ার্ড ভুলে গেছেন?"
      subTitle={`পাসওয়ার্ড নতুন করে সেট করতে আপনার ${isStaff ? 'নিবন্ধিত মোবাইল নাম্বার' : 'রেজিস্ট্রেশন নাম্বার'} দিন`}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          <div>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="মোবাইল"
                onChange={(event) => setMobile(event.target.value)}
              />
            </div>
          </div>
          
          {/* {!isStaff &&
          <div>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="রেজিস্ট্রেশন নাম্বার"
                onChange={(event) => setRegistrationNo(event.target.value)}
              />
            </div>
          </div>} */}
          <div>
            <button
              type="submit"
              disabled={updating || !mobile}
              className="flex w-full justify-center rounded-md bg-[#27ac1f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSubmit}
            >
              সাবমিট করুন
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
};

