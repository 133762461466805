import { useState } from 'react';

import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { Input, Select } from '../../../components/forms/fields';
import { getNormalizedDateTime } from '../../../utils';


const statuses = [
  { name: 'Inactive', value: 'inactive' },
  { name: 'Active', value: 'active' },
  { name: 'Completed', value: 'completed' }
];

const liveClassPlatforms = [
  { name: 'Zoom', value: 'zoom' },
  { name: 'Facebook group', value: 'facebook' },
  { name: 'Google meet', value: 'meet' },
];

export default function BatchForm(props) {
  const {batch, onClose, onSave } = props;
  const [name, setName] = useState(batch?.name || '');
  const [batchTime, setBatchTime] = useState(batch?.batchTime || '');
  const [startDate, setStartDate] = useState(batch?.startDate || '');
  const [capacity, setCapacity] = useState(batch?.capacity || 0);
  const [status, setStatus] = useState(batch?.status || 'inactive');
  const [liveClassPlatform, setLiveClassPlaform] = useState(batch.liveClassPlatform);
  const [facebookGroup, setFacebookGroup] = useState(batch.facebookGroup);
  const [zoomMeetingLink, setZoomMeetingLink] = useState(batch.zoomMeetingLink);
  const [googleMeetLink, setGoogleMeetLink] = useState(batch.googleMeetLink);
  const [updating, setUpdating] = useState(false);

  const handleSaveCourse = async (event) => {
    try {
      setUpdating(true);
      const batchId = batch?._id;
      let payload = {
        name,
        capacity,
        batchTime,
        startDate,
        status,
        liveClassPlatform,
        zoomMeetingLink,
        facebookGroup,
        googleMeetLink
      };
      
      await onSave(event, payload, batchId);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDiabled = () => {
    return updating || !name || !liveClassPlatform || (liveClassPlatform === 'facebook' && !facebookGroup);
  };

  return (
    <Sidepanel
      title={batch ? 'Update batch' : 'Create batch'}
      subTitle={batch ? `Enrolled: ${batch.enrolledCount}/${capacity}` : `Set neccessary information for batch.`}
      updating={updating}
      disabled={isDiabled()}
      onSave={handleSaveCourse}
      onClose={onClose}
    >
      <SidepanelField label="Name">
        <Input name="name" value={name} onChange={(event) => setName(event.target.value)} />
      </SidepanelField>

      <SidepanelField label="Status">
        <Select value={status} options={statuses} onChange={(event) => setStatus(event.target.value)} />
      </SidepanelField>

      <SidepanelField label="Batch schedule">
        <Input name="batchTime" value={batchTime} onChange={(event) => setBatchTime(event.target.value)} />
      </SidepanelField>

      <SidepanelField label="Start date">
        <Input type="date" name="startDate" value={getNormalizedDateTime(startDate, 'YYYY-MM-DD')} onChange={(event) => setStartDate(event.target.value)} />
      </SidepanelField>

      <SidepanelField label="Capacity">
        <Input type="number" name="capacity" value={capacity} onChange={(event) => setCapacity(event.target.value)} />
      </SidepanelField>

      <SidepanelField label="Live class platform">
        <Select value={liveClassPlatform} options={liveClassPlatforms} onChange={(event) => setLiveClassPlaform(event.target.value)} />
      </SidepanelField>

      {liveClassPlatform === 'zoom' &&
      <SidepanelField label="Zoom meeting link">
        <Input name="zoomMeetingLink" value={zoomMeetingLink} onChange={(event) => setZoomMeetingLink(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'facebook' &&
      <SidepanelField label="Facebook group link">
        <Input name="facebookGroup" value={facebookGroup} onChange={(event) => setFacebookGroup(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'meet' &&
      <SidepanelField label="Google meet link">
        <Input name="googleMeetLink" value={googleMeetLink} onChange={(event) => setGoogleMeetLink(event.target.value)} />
      </SidepanelField>}
    </Sidepanel>
  )
};
