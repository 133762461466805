import { useState } from 'react';
import { ProgressBar } from '@uppy/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';
import '@uppy/progress-bar/dist/style.css';


export default function UploadProgressPanel(props) {
  const { activeUploads } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="px-4 fixed mx-w-xs bottom-20 right-10 bg-white text-sm overflow-hidden rounded-lg shadow">
      <div
        className="flex flex-1 border-b border-gray-200 justify-between items-center py-2 cursor-pointer"
        onClick={() => setIsCollapsed(!cloneDeep(isCollapsed))}
      >
        <h3 className="font-semibold leading-6 text-gray-900 mr-10">
          Active uploads ({activeUploads.length})
        </h3>
        <ChevronDownIcon className={`h-4 w-4 ${isCollapsed ? 'rotate-180' : ''}`} /> 
      </div>
      
      {!isCollapsed &&
      <ul className="divide-y divide-gray-200">
        {activeUploads?.map((uploadItem, index) => (
          <li 
            key={`upload-video-id[${uploadItem.videoId}]-index[${index}]`}
            className="py-2"
          >
            <h3 className="pr-10 text-gray-500">{uploadItem.title}</h3>
            
            {uploadItem.uppy &&
            <div className="mt-2 w-full">
              <ProgressBar uppy={uploadItem.uppy} />
            </div>}
          </li>
        ))}
      </ul>}
    </div>
  )
};
