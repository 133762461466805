import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, forEach } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import EmptyData from '../../components/EmptyData';
import ContentTabs from '../../components/ContentTabs';
import Loader from '../../components/Loader';
import ChapterTopicForm from './components/TopicForm';
import Breadcrumb from '../../components/Breadcrumb';
import { getCourse } from '../../services/course';
import { getCourseContents, createCourseContents, updateCourseContents } from '../../services/courseContent';
import { getExams } from '../../services/exam';


export default function CourseContentPage () {
  const params = useParams();
  const courseId = params.courseId;
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [courseContentId, setCourseContentId] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [secondaryMenuItems, setSecondaryMenuItems] = useState(null);
  const [topics, setTopics] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();

  }, []);

  const fetchData = async () => {
    try {
      const course = await getCourse(courseId);
      setCourse(course);
      setSelectedChapter(course.chapters[0]);

      const _breadcrumbPages = [
        { name: 'Courses', href: `/courses/online`},
        { name: `${course.name}`, current: true}
      ];
      setBreadcrumbPages(_breadcrumbPages);

      let _secMenuItems = [];
      forEach(course.chapters, chapter => {
        _secMenuItems.push(chapter.name);
      });
      setSecondaryMenuItems(_secMenuItems);
      await fetchContents(course._id, course.chapters[0]._id);
      await fetchExams();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchContents = async (courseId, chapterId) => {
    try {
      const params = { course: courseId, chapterId: chapterId };
      const data = await getCourseContents(params);
      let courseContent = {};

      if (data.length > 0) {
        courseContent = data[0];
      }

      setCourseContentId(courseContent?._id);
      formatTabs(courseContent?.topics || []);
      setTopics(courseContent?.topics || []);

      const _selectedTopicIndex = courseContent?.topics?.length > 0 ? 0 : null;
      const _seletedTopic = courseContent?.topics?.length ? courseContent?.topics[_selectedTopicIndex] : null;
      setSelectedTopic(_seletedTopic);
      setSelectedTopicIndex(_selectedTopicIndex);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formatTabs = (_topics, _selectedTabIndex) => {
    _topics = _topics || topics;
    _selectedTabIndex = _selectedTabIndex || 0;
    let _tabs = [];

    for (let i = 0; i < _topics.length; i++) {
      _tabs.push({name: `Topic ${i+1}`})
    }

    setSelectedTab(_tabs[_selectedTabIndex]?.name);
    setTabs(_tabs);
  };

  const fetchExams = async () => {
    try {
      const exams = await getExams({grade: course.grade, group: course.group});
      setExams(exams);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChapterSelect = async (event, index) => {
    event.preventDefault();
    const _selectedChapter = course.chapters[index];
    setSelectedChapter(_selectedChapter);

    await fetchContents(course._id, _selectedChapter._id);
  };

  const handleTopicChange = (topicIndex) => {
    const _topics = cloneDeep(topics);
    setSelectedTab(tabs[topicIndex].name);
    setSelectedTopic(() => _topics[topicIndex]);
    setSelectedTopicIndex(topicIndex);
  };

  const saveTopic = async (event, topic) => {
    try {
      event.preventDefault();
      let _topics = cloneDeep(topics);
      _topics[selectedTopicIndex] = topic;
      let payload = { topics: _topics };
      
      let updatedContents;
      if (courseContentId) {
        updatedContents = await updateCourseContents(courseContentId, payload);
      } else {
        payload.course = course._id;
        payload.chapterId = selectedChapter._id;
        updatedContents = await createCourseContents(payload);
        setCourseContentId(updatedContents._id);
      }
      _topics[selectedTopicIndex] = updatedContents.topics[selectedTopicIndex];
      setTopics(_topics);
    } catch (error) {
      throw error
    }
  };

  const addTopic = () => {
    let _topics = cloneDeep(topics);
    _topics.push({ name: '' });
    const _selectedTopicIndex = _topics.length - 1;
    setTopics(_topics);
    setSelectedTopic(_topics[_selectedTopicIndex]);
    setSelectedTopicIndex(_selectedTopicIndex);
    formatTabs(_topics, _selectedTopicIndex);
  };

  const deleteTopic = async (event, _topic) => {
    try {
      event.preventDefault();
      let _topics = cloneDeep(topics);
      const nextTopicIndex = _topics.length >= selectedTopicIndex + 2 ? selectedTopicIndex : selectedTopicIndex - 1;
      _topics.splice(selectedTopicIndex, 1);

      if (_topic._id) {
        const payload = { topics : _topics };
        await updateCourseContents(courseContentId, payload);
      }

      setTopics(_topics);
      setSelectedTopic(_topics[nextTopicIndex]);
      setSelectedTopicIndex(nextTopicIndex);
      formatTabs(_topics, nextTopicIndex);
    } catch (error) {
      throw error;
    }
  };

  return (
    <StackedLayout loading={loading}
      sidebarNavigation={secondaryMenuItems}
      currentSidebarNavigation={selectedChapter?.name}
      onNavigationChange={handleChapterSelect}
    >
      
      <Breadcrumb pages={breadcrumbPages} />
      <div>
        {topics.length === 0 &&
        <EmptyData
          title="No topics"
          subTitle="Create new topic to add contents this chapter." 
          onCreate={addTopic}
        />}
        {tabs.length > 0 &&
        <ContentTabs
          tabs={tabs}
          selectedTab={selectedTab}
          showAddTypeButton={true}
          onTabChange={handleTopicChange}
          onAddTab={addTopic}
        />}

        {topics.length > 0 &&
        <ChapterTopicForm 
          topic={selectedTopic} 
          course={course}
          exams={exams}
          onSave={saveTopic}
          onDelete={deleteTopic}
          topicCount={topics.length}
        />}
      </div>

    </StackedLayout>
  )
}