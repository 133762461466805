import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {omit, isEmpty, findIndex, cloneDeep, capitalize, forEach, orderBy, includes } from 'lodash';

import CategoryListingLayout from '../../../layouts/CategoryListingLayout';
import Table from '../../../components/tables/StackedTable';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import ChangeBatchScheduleForm from './components/ChangeBatchScheduleForm';
import { ActionMenu, MenuItem } from '../../../components/ActionMenu';
import { getBranches } from '../../../services/branch';
import { getPrograms } from '../../../services/program';
import {
  getOfflineBatches,
  changeBatchStatus,
  changeBatchStartMonth,
  changeBatchEndMonth
} from '../../../services/batch';


const defaultFilters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  }
];
const headers = ['Name', 'Branch', 'Program', 'Course', 'Status'];

export default function OfflineBatchesPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [batches, setBatches] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [branchFilters, setBranchFilters] = useState([]);
  const [programFilters, setProgramFilters] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(defaultFilters);
  const [openScheduleChangeForm, setOpenScheduleChangeForm] = useState(false);
  const [schedulePoint, setSchedulePoint] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      const _filters = cloneDeep(filters);
      const _programs = await getPrograms({ status: 'active' });
      if (_programs.length > 0) {
        const programFilterOptions = [];
        forEach(_programs, program => programFilterOptions.push({
          label: program.name,
          value: program._id.toString()
        }));
        _filters.push({
          name: 'Programs',
          fieldName: 'program',
          options: orderBy(programFilterOptions, 'label')
        });
      }

      if (includes(['owner', 'admin'], currentUser.role)) {
        const _branches = await getBranches({ status: 'active' });
        if (_branches.length > 0) {
          const branchFilterOptions = [];
          forEach(_branches, branch => branchFilterOptions.push({
            label: branch.name,
            value: branch._id.toString()
          }));
          _filters.push({
            name: 'Branches',
            fieldName: 'branch',
            options: orderBy(branchFilterOptions, 'label')
          });
        }
      }
      setFilters(_filters);
    }
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    }
    _fetchData();
  }, [
    statusFilters,
    branchFilters,
    programFilters,
    queryString,
    currentPage
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: queryString,
      };
      
      if (statusFilters.length > 0) {
        params.status = statusFilters;
      }

      if (branchFilters.length > 0) {
        params.branch = branchFilters.length === 1 ? branchFilters[0] : branchFilters;
      }

      if (programFilters.length > 0) {
        params.program = programFilters.length === 1 ? programFilters[0] : programFilters;
      }

      const data = await getOfflineBatches(params);
      setBatches(data.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setBranchFilters([]);
    setProgramFilters([]);
    setLoading(true);
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeBatchStatus(selectedBatch._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleScheduleChange= async (event, payload) => {
    try {
      event.preventDefault();
      if (schedulePoint === 'startMonth') {
        await changeBatchStartMonth(selectedBatch._id, payload);
      } else {
        await changeBatchEndMonth(selectedBatch._id, payload);
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const _branchFilters = cloneDeep(branchFilters);
    const _programFilters = cloneDeep(programFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    } else if (fieldName === 'branch') {
      const valueIndex = findIndex(_branchFilters, item => item === _value);
      if (valueIndex === -1) {
        _branchFilters.push(_value);
      } else {
        _branchFilters.splice(valueIndex, 1);
      }
      setBranchFilters(_branchFilters);
    } else if (fieldName === 'program') {
      const valueIndex = findIndex(_programFilters, item => item === _value);
      if (valueIndex === -1) {
        _programFilters.push(_value);
      } else {
        _programFilters.splice(valueIndex, 1);
      }
      setProgramFilters(_programFilters);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title="Offline batches"
      subTitle={`List of offline batches for your coaching.`}
      filters={filters}
      onFilterChange={applyFilterChanges}
      onCreate={() => window.location.href = `/branch/batches/create`}
    >
        {showStatusModal &&
        <ConfirmModal
          title={selectedBatch?.status === 'active' ? 'Disable batch' : 'Enable batch'} 
          description={`Are you sure to ${selectedBatch?.status === 'active' ? 'disable' : 'enable'} this batch? Please double check before performing this action.`}
          actionName={selectedBatch?.status === 'active' ? 'Disable' : 'Enable'}
          onConfirm={handleStatusChange}
          onCancel={() => {
            setSelectedBatch(null);
            setShowStatusModal(false);
          }}
        />}

        {openScheduleChangeForm &&
        <ChangeBatchScheduleForm
          batch={selectedBatch}
          schedulePoint={schedulePoint}
          onSave={handleScheduleChange}
          onClose={() => {
            setSelectedBatch(null);
            setSchedulePoint(null);
            setOpenScheduleChangeForm(false);
          }}

        />}

        <Table
          headers={headers}
          items={batches}
          itemsCount={batches.length}
          queryString={queryString}
          pagingData={pagingData}
          onSearch={onSearch}
          onPageChange={onPageChange}
        >
          {batches.map((batch) => (
          <tr key={batch._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {batch.name}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {batch.branch.name}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {batch.program.name}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {batch.course.name}
            </td>
            <td 
              className={`px-3 py-4 text-sm ${batch.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(batch.status)}
            </td>

            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems 
                batch={batch}
                onStatusChange={() => {
                  setSelectedBatch(batch);
                  setShowStatusModal(true);
                }}
                onChangeBatchSchedule={(_schedulePoint) => {
                  setSelectedBatch(batch);
                  setSchedulePoint(_schedulePoint);
                  setOpenScheduleChangeForm(true);
                }}
              />
            </td>
          </tr>
        ))}
        </Table>
    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const {
    batch,
    onChangeBatchSchedule,
    onStatusChange
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/branch/batches/${batch._id.toString()}/edit`} />
      </div>

      {batch.program.paymentCircle === 'month' &&
      <div className="py-1">
        <MenuItem label="Edit start month" onClick={() => onChangeBatchSchedule('startMonth')} />
        <MenuItem label="Edit end month" onClick={() => onChangeBatchSchedule('endMonth')} />
      </div>}
      <div className="py-1">
        <MenuItem label={batch?.status === 'active' ? 'Disable' : 'Enable'} isDanger={batch?.status === 'active'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )

};
