import http from '../helpers/http';


export const getInstructors = async (params) => {
  try {
    const config = { params };
    const { data } = await http.get(`/api/instructors`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInstructor = async (id) => {
  try {
    const { data } = await http.get(`/api/instructors/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createInstructor = async (payload) => {
  try {
    const { data } = await http.post(`/api/instructors`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateInstructor = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/instructors/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeInstrutorStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/instructors/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
