import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { UserIcon } from '@heroicons/react/24/outline';
import { cloneDeep } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';
import { Button, PlusButton } from '../../../components/buttons';
import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, TextArea, UploadIcon } from '../../../components/forms/fields';
import TestimonialResultField from './components/TestimonialResultField';
import {
  getTestimonial,
  createTestimonial,
  updateTestimonial
} from '../../../services/testimonial';
import { handleUploadPublicImage } from '../../../utils';


export default function TestimonialEditPage() {
  const navigate = useNavigate();
  const params = useParams();
  const testimonialId = params.id;
  const [testimonial, setTestimonial] = useState(null);
  const [name, setName] = useState('');
  const [session, setSession] = useState('');
  const [photo, setPhoto] = useState('');
  const [comment, setComment] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [displayOrder, setDisplayOrder] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (testimonialId) {
          const _testimonial = await getTestimonial(testimonialId);
          setTestimonial(_testimonial);
          setName(_testimonial.name);
          setSession(_testimonial.session);
          setComment(_testimonial.comment);
          setVideoLink(_testimonial.videoLink);
          setResults(_testimonial.results);
          setDisplayOrder(_testimonial.displayOrder);
          setPhoto(_testimonial.photo);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handlePhotoUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setPhoto(imageUrl);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleResultFieldChange = (event, index) => {
    event.preventDefault();
    const _results = cloneDeep(results);
    _results[index] = event.target.value;
    setResults(_results);
  };

  const addResult = (event) => {
    event.preventDefault();
    const _results = cloneDeep(results);
    _results.push('');
    setResults(() => _results);
  };

  const deleteResult = (event, index) => {
    event.preventDefault();
    const _results = cloneDeep(results);
    _results.splice(index, 1);
    setResults(() => _results);
  };

  const saveTestimonial = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);

      let payload = {
        name,
        session,
        comment,
        videoLink,
        photo,
        displayOrder,
        results
      };

      if (testimonialId) {
        await updateTestimonial(testimonialId, payload);
        toast.success('Testimonial updated successfully');
      } else {
        await createTestimonial(payload);
        toast.success('Testimonial created successfully');
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !comment;
  };

  const goBack = () => {
    navigate(`/website-contents/testimonials`)
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {testimonial?.name || 'Create new instructor'}
          </h1>
          {testimonial &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this testimonial.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        disabled={isDisabled()}
        submitText={testimonial ? 'Save' : 'Create'}
        onSubmit={saveTestimonial}
        onCancel={goBack}
      >
        <FieldSet
          title="Personal Information"
          subTitle="Set name, photo for the student."
        >
          <Input col={4} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <span></span>
          <Input col={2} label="Session" value={session} onChange={(event) => setSession(event.target.value)} />
          <Input col={2} type="number" label="Display order" value={displayOrder} onChange={(event) => setDisplayOrder(event.target.value)} />
          <UploadIcon
            actionName="Change"
            Icon={UserIcon}
            image={photo}
            onUpload={handlePhotoUpload}
          />
        </FieldSet>

        <FieldSet
          title="Comment"
          subTitle="Set the the comment by the student."
        >
          <TextArea label="Comment" value={comment} onChange={(event) => setComment(event.target.value)} />
          <Input label="Video link (youtube)" value={videoLink} onChange={(event) => setVideoLink(event.target.videoLink)} />
        </FieldSet>

        <FieldSet
          title="Results"
          subTitle="Set results achieved by this student."
        >
          {results.map((result, index) => (
            <TestimonialResultField 
              key={`result-index[${index}]`} 
              result={result} 
              index={index} 
              experiencesCount={result.length}
              onFieldChange={handleResultFieldChange}
              onDelete={deleteResult}
            />
          ))}

          <div className="col-span-3">
            <PlusButton label="Add result" onClick={addResult} />
          </div>

        </FieldSet>


      </Form>

    </StackedLayout>
  )
};