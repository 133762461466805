import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Button, SubmitButton } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import Badge from '../../components/Badge';
import {
  Input,
  TextArea,
  SelectDistrict
} from '../../components/forms/fields';
import { getBranch, createBranch, updateBranch } from '../../services/branch';


export default function BranchEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const branchId = params.branchId;
  const [branch, setBranch] = useState(null);
  const [branchCode, setBranchCode] = useState('');
  const [name, setName] = useState(null);
  const [bnName, setBnName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);
  const [district, setDistrict] = useState(null);
  const [address, setAddress] = useState(null);
  const [bkashNumbers, setBkashNumbers] = useState([]);
  const [nagadNumbers, setNagadNumbers] = useState([]);
  const [bkashNumber, setBkashNumber] = useState(null);
  const [nagadNumber, setNagadNumber] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (branchId) {
          const _branch = await getBranch(branchId);
          setBranch(_branch);
          setBranchCode(_branch.branchCode);
          setName(_branch.name);
          setBnName(_branch.bnName);
          setMobile(_branch.mobile);
          setEmail(_branch.email);
          setDistrict(_branch.district);
          setAddress(_branch.address);
          setBkashNumbers(_branch.bkashNumbers);
          setNagadNumbers(_branch.nagadNumbers);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const saveBranch = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        branchCode,
        name,
        bnName,
        mobile, 
        email, 
        district,
        address,
        bkashNumbers,
        nagadNumbers
      };
      
      if (branchId) {
        await updateBranch(branchId, payload);
        toast.success(`Branch updated successfully.`);
      } else {
        await createBranch(payload);
        toast.success(`Branch created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const addBkashNumber = (event) => {
    event.preventDefault();
    let _bkashNumbers = cloneDeep(bkashNumbers);
    _bkashNumbers.push(bkashNumber);
    setBkashNumbers(_bkashNumbers);
    setBkashNumber('');
  };

  const removeBkashNumber = (event, index) => {
    event.preventDefault();
    let _bkashNumbers = cloneDeep(bkashNumbers);
    _bkashNumbers.splice(index, 1);
    setBkashNumbers(_bkashNumbers);
  };

  const addNagadNumber = (event) => {
    event.preventDefault();
    let _nagadNumbers = cloneDeep(nagadNumbers);
    _nagadNumbers.push(nagadNumber);
    setNagadNumbers(_nagadNumbers);
    setNagadNumber('');
  };

  const removeNagadNumber = (event, index) => {
    event.preventDefault();
    let _nagadNumbers = cloneDeep(nagadNumbers);
    _nagadNumbers.splice(index, 1);
    setNagadNumbers(_nagadNumbers);
  };

  const isDisabled = () => {
    return updating || !name || !branchCode || branchCode < 10 || branchCode > 99;
  };

  const goBack = () => {
    navigate(`/branches`);
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {branch?.name || 'Create new branch'}
          </h1>
          {branch &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this branch.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={branch ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveBranch}
        onCancel={goBack}
      >
        <FieldSet
          title="Branch info"
          subTitle="Give a branch name."
        >
          <Input
            col={3}
            label="Branch code"
            value={branchCode}
            onChange={(event) => setBranchCode(event.target.value)}
            helpText="Set an unique branch code between 10 and 99"
          />
          <Input col={4} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={4} label="Name (in Bangla)" value={bnName} onChange={(event) => setBnName(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Branch contacts"
          subTitle="Set contact information for this branch."
        >
          <Input col={2} label="Mobile" value={mobile} onChange={(event) => setMobile(event.target.value)} />
          <Input label="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Branch location"
          subTitle="Set address for this branch."
        >
          <SelectDistrict col={2} label="District" value={{label: district, value: district}} onChange={(item) => setDistrict(item.value)}/>
          <TextArea label="Address" value={address} onChange={(event) => setAddress(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="bKash merchants"
          subTitle="Set merchant numbers for bkash."
        >
          <Input placeholder="bkash merchant number" value={bkashNumber} onChange={(event) => setBkashNumber(event.target.value)} />
          <div>
            <SubmitButton disabled={!bkashNumber} className="" label="Add" onClick={addBkashNumber} />
          </div>

          <div className="block-inline -mt-4 col-span-full space-x-4 space-y-2">
            {bkashNumbers.length > 0 && bkashNumbers.map((_bkashNumber, index) => (
              <Badge
                key={`bkash-index[${index}]`}
                label={_bkashNumber}
                showRemove={true}
                onRemove={(event) => removeBkashNumber(event, index)}
              />
            ))}
          </div>
        </FieldSet>

        <FieldSet
          title="Nagad merchants"
          subTitle="Set merchant numbers for nagad."
        >
          <Input placeholder="nagad merchant number" value={nagadNumber} onChange={(event) => setNagadNumber(event.target.value)} />
          <div>
            <SubmitButton disabled={!nagadNumber} className="" label="Add" onClick={addNagadNumber} />
          </div>

          <div className="block-inline -mt-4 col-span-full space-x-4 space-y-2">
            {nagadNumbers.length > 0 && nagadNumbers.map((_nagadNumber, index) => (
              <Badge
                key={`nagad-index[${index}]`}
                label={_nagadNumber}
                showRemove={true}
                onRemove={(event) => removeNagadNumber(event, index)}
              />
            ))}
          </div>
        </FieldSet>
      </Form>
      
    </StackedLayout>
  )
};
