import http from '../helpers/http';


export const getLiveClasses = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/liveclasses`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getLiveClassesForUser = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/liveclasses/user`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createLiveClass = async (payload) => {
  try {
    const { data } = await http.post(`/api/liveclasses`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createRecurringLiveClass = async (payload) => {
  try {
    const { data } = await http.post(`/api/liveclasses/recurring`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateLiveClass = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/liveclasses/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteLiveClass = async (id) => {
  try {
    const { data } = await http.delete(`/api/liveclasses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
