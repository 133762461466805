import toast from 'react-hot-toast';
import { forEach } from 'lodash';

import { SelectAsync } from './forms/fields';
import { getInstructors } from '../services/instructor';


export default function InstructorInputWithList(props) {
  let {
    col,
    instructors,
    onSelect,
    onRemove
  } = props;
  col= col ? col?.toString() : 'full';

  const searchInstructor = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let excludeInstructors = [];
        forEach(instructors, instructor => excludeInstructors.push(instructor._id));
        let params = { search: queryString, status: 'active' };
        if (excludeInstructors.length > 0) {
          params.excludeInstructors = excludeInstructors;
        }
        const _instructors = await getInstructors(params);
        let instructorOptions = [];
        forEach(_instructors, instructor => {
          instructorOptions.push({
            label: `${instructor.name} (${instructor.designation})`,
            value: instructor
          });
        });
        return instructorOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <SelectAsync
        col={col}
        placeholder="Search by name..."
        onSearch={searchInstructor}
        onChange={onSelect}
      />

      {instructors.length > 0 &&
      <div className={`col-span-full`}>
        <ul className="divide-y divide-gray-100">
          {instructors.map((instructor, index) => (
            <li key={`instructor-index[${index}]`} className="flex sm:span-col-full items-center justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{instructor?.name}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{instructor?.designation}</p>
                </div>
              </div>
              <button
                className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                onClick={(event) => onRemove(event, index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>}
    </>
  )
};
