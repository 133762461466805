import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import TuitionFeeForm from './components/TuitionFeeForm';
import toast from 'react-hot-toast';
import { createTuitionFee, getTuitionFees, updateTuitionFee } from '../../services/tuitionFee';
import { getCourse } from '../../services/course';
import { getNormalizedDateTime } from '../../utils';


const filters = [
  {
    name: 'Fee type',
    fieldName: 'feeType',
    options: [
      { label: 'Admission fee', value: 'admissionFee' },
      { label: 'Tiotion fee', value: 'tuitionFee' },
      { label: 'Exam fee', value: 'examFee' },
    ]
  }
];
const headers = ['Name', 'Type', 'Fee', 'Due Date'];

export default function CourseTuitionFeePage() {
  const params = useParams();
  const navigate = useNavigate();
  const courseId = params.courseId;
  const [course, setCourse] = useState(null);
  const [tuitionFees, setTuitionFees] = useState([]);
  const [selectedTuitionFee, setSelectedTuitionFee] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    async function _fetchInititalData() {
      try {
        const _course = await getCourse(courseId);
        setCourse(_course);
      } catch (error) {
        navigate(-1);
      }
    };
    _fetchInititalData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
  }, [course])

  const fetchData = async () => {
    try {
      let params = {
        course: courseId
      };
      const data = await getTuitionFees(params);
      setTuitionFees(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveTuitionFee = async (event, payload) => {
    try {
      event.preventDefault();
      if (selectedTuitionFee) {
        await updateTuitionFee(selectedTuitionFee._id, payload);
        toast.success('Fee updated successfully');
      } else {
        payload.course = courseId;
        await createTuitionFee(payload);
        toast.success('Fee created successfully');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  return (
    <CategoryListingLayout
      title="Tuition Fees"
      subTitle="Manage fees for this course"
      filters={filters}
      onCreate={() => setOpenForm(true)}
    >
      {openForm &&
      <TuitionFeeForm
        tuitionFee={selectedTuitionFee}
        onSave={saveTuitionFee}
        onClose={() => {
          setSelectedTuitionFee(null);
          setOpenForm(false);
        }}
      />}

      <Table
        headers={headers}
        itemsCount={tuitionFees.length}
      >
        {tuitionFees.map((tuitionFee) => (
          <tr key={tuitionFee._id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {tuitionFee.name}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{tuitionFee.feeType}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{tuitionFee.fee}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(tuitionFee.dueDate, 'DD MMM YYYY')}</td>

            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              
            </td>
          </tr>
        ))}

      </Table>

    </CategoryListingLayout>
  )
};