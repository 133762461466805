import http from '../helpers/http';


export const getTuitionFees = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/tuition-fees`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTuitionFee = async (id) => {
  try {
    const { data } = await http.get(`/api/tuition-fees/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createTuitionFee = async (payload) => {
  try {
    const { data } = await http.post(`/api/tuition-fees`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateTuitionFee = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/tuition-fees/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const removeTuitionFee = async (id) => {
  try {
    const { data } = await http.delete(`/api/tuition-fees/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
