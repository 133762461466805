export default function InlineCheckbox(props) {
  const {
    label,
    description,
    onChange,
    name,
    checked,
    disabled
  } = props;
  const col = props?.col?.toString() || '4';

  return (
    <div className={`sm:col-span-${col}`}>
      <div className="flex gap-x-3">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            disabled={disabled}
            name={name}
            checked={checked}
            className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
            onChange={onChange}
          />
        </div>
        <div className="text-sm leading-6">
          <label className="font-medium text-gray-900">
            {label}
          </label>
          <p className="text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  )
};
