import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';
import { includes, orderBy } from 'lodash';

import PlaceholderLoader from '../PlaceholderLoader';
import { Input, Select, RadioGroupHorizontal } from '../forms/fields';
import { SubmitButton, Button } from '../buttons';
import { getBranches } from '../../services/branch';
import { getCompetitionParticipants } from '../../services/competition';
import { getNormalizedDateTime } from '../../utils';


const documentTypes = [
  { name: 'Excel', value: 'excel' },
  // { name: 'Pdf', value: 'pdf' },
]
export default function DownloadCompetitionParticipantsModal(props) {
  const {
    currentUser,
    competition,
    onCancel
  } = props;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [branches, setBranches] = useState([]);
  const [documentType, setDocumentType] = useState('excel');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        if (includes(['owner', 'admin'], currentUser.role) || currentBranch.branchType === 'principanBranch') {
          const _branches = await getBranches();
          setBranches(orderBy(_branches, 'name'));
        } else {
          setSelectedBranch(currentBranch);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchInitialData();
  }, []);

  const fetchData = async () => {
    try {
      let params = {};
      if (fromDate) { params.fromDate = moment(fromDate).startOf('day').zone('+06:00').format(); }
      if (toDate) { params.toDate = moment(toDate).endOf('day').zone('+06:00').format(); }

      if (selectedBranch) {
        params.branch = selectedBranch._id;
        params.branchType = selectedBranch.branchType;
      }
      const participants = await getCompetitionParticipants(competition._id, params);
      return participants;
    } catch (error) {
      throw error;
    }
  };

  const handleDowload = async () => {
    try {
      setLoading(true);
      const data = await fetchData();
      await exportExcel(data);
      handleCancel();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async (data) => {
    try {
      let excelData = [];
      for (let i = 0; i < data?.length; i++) {
        const item = data[i];
        const participant = item?.user;
        excelData.push({
          'Created at': getNormalizedDateTime(item?.createdAt, 'DD-MM-YYYY'),
          'Name': participant?.name,
          'Mobile': participant.mobile,
          'Gender': item.gender,
          'District': item.district,
          'Institute': item.academicInstitute,
          'Registration branch': item?.branch?.name || 'Online',
        });
      };

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [['Competition participants list']], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(ws, [['Competition', competition.name]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(ws, [['Branch', selectedBranch ? selectedBranch.name : 'All']], { origin: 'A3' });
      XLSX.utils.sheet_add_aoa(ws, [['From Date', fromDate ? getNormalizedDateTime(fromDate, 'DD-MM-YYYY') : '']], { origin: 'A4' });
      XLSX.utils.sheet_add_aoa(ws, [['To Date', toDate ? getNormalizedDateTime(toDate, 'DD-MM-YYYY') : '']], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(ws, [['Dowload time', getNormalizedDateTime(new Date(), 'DD-MM-YYYY hh:mm A')]], { origin: 'A5' });
      XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A7' });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Participant-List");
      XLSX.writeFile(wb, `Participant-List.xlsx`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {loading && 
                <div className="py-10">
                  <PlaceholderLoader />
                </div>}
                
                {!loading &&
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Download students
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        {(includes(['owner', 'admin'], currentUser.role) || currentBranch?.branchType === 'principalBranch') ?
                        <Select label="Branch" value={selectedBranch?._id} options={branches} onChange={(event) => setSelectedBranch(branches[event.target.selectedIndex -1])} />
                        : <p className="text-sm font-medium">Branch: {selectedBranch?.name}</p>}
                      </div>
                      <div className="mt-2 w-full grid grid-cols-2 gap-x-4">
                        <Input col={1} type="date" label="From date" value={fromDate} onChange={(event) => setFromDate(event.target.value)} />
                        <Input col={1} type="date" label="To date" value={toDate} onChange={(event) => setToDate(event.target.value)} />
                      </div>
                      <div className="mt-2 w-full">
                        <RadioGroupHorizontal label="Document type" value={documentType} options={documentTypes} onChange={(event) => setDocumentType(event.target.value)} />
                      </div>
                    </div>

                  </div>
                </div>}

                {/* {!loading &&
                <div className="flex px-4 pb-4 sm:px-9 text-sm text-orange-700 space-x-1 items-center">
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <p>It may take long time to create pdf for large data.</p>
                </div>} */}
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-x-4">
                  <SubmitButton disabled={loading} label="Download" onClick={handleDowload} />
                  <Button onClick={handleCancel} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
