import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StackedLayout from '../../../layouts/StackedLayout';
// import LiveClassRoutine from '../../../components/LiveClassRoutine';
import StudentClassRoutine from '../../../components/LiveClassRoutine/StudentClassRoutine';


const sidebarNavigation = [
  { name: 'হোম', href: '/learn/home' },
  { name: 'ক্লাস রুটিন', href: '/learn/routine' },
  { name: 'পরীক্ষার রেজাল্ট', href: '/learn/results' },
  { name: 'ফি পেমেন্ট', href: '/learn/payments' },
  { name: 'ট্রানজেকশন বিবরণী', href: '/learn/transactions' }
];

export default function StudentRoutinePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const grade = currentUser?.preference?.grade;

  useEffect(() => {
    // if (!currentUser) {
    //   navigate('/auth/login');
    // } else if (!currentUser.isOnboarded) {
    //   navigate('/onboarding/personal-info');
    // }
  }, []);

  const handleChangeNavigation = (event, index) => {
    event.preventDefault();
    const navItem = sidebarNavigation[index];
    if (navItem?.value === 'freeCourses') {
      navigate(`/courses/programs/${grade}/free`);
    }

  };

  return (
    <StackedLayout
      page="learn"
      hideFooter={true}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="ক্লাস রুটিন"
      onNavigationChange={handleChangeNavigation}
    >
      <StudentClassRoutine />

    </StackedLayout>
  )
};