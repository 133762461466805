import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';
import { capitalize, forEach, includes, orderBy } from 'lodash';

import PlaceholderLoader from '../PlaceholderLoader';
import { Input, Select, RadioGroupHorizontal } from '../forms/fields';
import { SubmitButton, Button } from '../buttons';
import { getBranches } from '../../services/branch';
import { getPrograms } from '../../services/program';
import { getCourses } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getInvoices, getDuesGroupedByStudent } from '../../services/invoice';
import { getNormalizedDateTime } from '../../utils';


const documentTypes = [
  { name: 'Excel', value: 'excel' },
  // { name: 'Pdf', value: 'pdf' },
];

const dueAmountTypes = [
  { name: 'Single invoice', value: 'singleDue' },
  { name: 'Grouped by student', value: 'combinedDue' },
];


export default function DownloadInvoiceModal(props) {
  const {
    currentUser,
    status,
    onCancel
  } = props;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [branches, setBranches] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [documentType, setDocumentType] = useState('excel');
  const [dueAmountType, setDueAmountType] = useState('singleDue');
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedProgram, setSelectecProgram] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        if (includes(['owner', 'admin'], currentUser.role)) {
          const _branches = await getBranches();
          setBranches(orderBy(_branches, 'name'));
        } else {
          setSelectedBranch(currentBranch);
        }
        const _programs = await getPrograms();
        setPrograms(orderBy(_programs, 'name'));
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    setSelectecProgram(null);
  }, [selectedBranch])

  useEffect(() => {
    if (selectedProgram) {
      async function _fetchCourses() {
        try {
          let params = { program: selectedProgram._id };
          const _courses = await getCourses(params);
          setCourses(orderBy(_courses, 'name'));
        } catch (error) {
          toast.error(error.message);
        }
      };
      _fetchCourses();
    } else {
      setCourses([]);
    }
    setSelectedCourse(null);
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedCourse && includes(['live', 'offline'], selectedCourse.courseType)) {
      async function _fetchBatches() {
        try {
          let params = { course: selectedCourse._id };
          if (selectedCourse.courseType === 'offline' && selectedBranch) {
            params.branch = selectedBranch._id;
          }
          const _batches = await getBatches(params);
          if (selectedCourse.courseType === 'offline' && !selectedBranch) {
            forEach(_batches, b => b.name = `${b.name} - ${b?.branch?.name}`);
          }
          setBatches(orderBy(_batches, 'name'));
        } catch (error) {
          toast.error(error.message);
        }
      };
      _fetchBatches();
    } else {
      setBatches([]);
    }
    setSelectedBatch(null);
  }, [selectedCourse]);

  const fetchData = async () => {
    try {
      let params = { status: status === 'paid' ? 'paid' : ['due', 'overdue'] };
      if (selectedBranch) { params.branch = selectedBranch._id; }
      if (selectedProgram) { params.program = selectedProgram._id; }
      if (selectedCourse) { params.course = selectedCourse._id; }
      if (selectedCourse && includes(['live', 'offline'], selectedCourse.courseType) && selectedBatch) {
        params.batch = selectedBatch._id;
      }
      if (fromDate) { params.fromDate = moment(fromDate).startOf('day').zone('+06:00').format(); }
      if (toDate) {params.toDate = moment(toDate).endOf('day').zone('+06:00').format(); }

      if (dueAmountType === 'singleDue') {
        const invoices = await getInvoices(params);
        return invoices;
      } else {
        const dueData = await getDuesGroupedByStudent(params);
        return dueData;
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDowload = async () => {
    try {
      setLoading(true);
      const data = await fetchData();
      await exportExcel(data);
      handleCancel();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async (data) => {
    try {
      let excelData = [];
      for (let i = 0; i < data?.length; i++) {
        const invoice = data[i];
        const studentData = dueAmountType === 'singleDue' ? invoice.user : invoice._id;
        if(status === 'paid') {
          excelData.push({
            'Payment date': getNormalizedDateTime(invoice?.paymentDate, 'DD-MM-YYYY hh:mm A'),
            'Student ID': invoice?.user?.registrationNo,
            'Student name': invoice?.user?.name,
            'Invoice branch': invoice?.branch?.name,
            'Payment branch': invoice?.paymentBranch?.name,
            'Payment for': invoice?.invoiceFor === 'tuitionFee' ? `${invoice?.invoiceFor} - ${getNormalizedDateTime(invoice.billingDate, 'MMMM YYYY')}` : invoice?.invoiceFor,
            'Program': invoice?.program?.name,
            'Course': invoice?.course?.name,
            'Batch': invoice?.batch?.name || '',
            'Collected by': invoice?.paymentBy?.name,
            'Original fee': invoice.originalFee || invoice.amount,
            'Discount': invoice.discount || 0,
            'Invoice amount': invoice?.amount,
            'Additional Discount': invoice.additionalDiscount,
            'Paid amount': invoice?.paidAmount,
            'Due amount': invoice?.dueAmount,
            'Due date': invoice?.dueDate ? getNormalizedDateTime(invoice?.dueDate, 'DD-MM-YYYY') : '',
            'Reference': invoice.reference,
            'Additional discount reference': invoice.additionalDiscountReference
          });
        } else {
          if (dueAmountType === 'singleDue') {
            excelData.push({
              'Invoice date': getNormalizedDateTime(invoice?.billingDate || invoice.createdAt, 'DD-MM-YYYY hh:mm A'),
              'Student ID': studentData.registrationNo,
              'Student name': studentData.name,
              'Student mobile': studentData.mobile,
              'Father mobile': studentData.fatherMobile,
              'Mother mobile': studentData.motherMobile,
              'Invoice branch': invoice?.branch?.name,
              'Payment for': invoice?.invoiceFor === 'tuitionFee' ? `${invoice?.invoiceFor} - ${getNormalizedDateTime(invoice.billingDate, 'MMMM YYYY')}` : invoice?.invoiceFor,
              'Program': invoice?.program?.name,
              'Course': invoice?.course?.name,
              'Batch': invoice?.batch?.name || '',
              'Original fee': invoice.originalFee || invoice.amount,
              'Invoice amount': invoice?.amount,
              'Discount': invoice.discount,
              'Due date': invoice?.dueDate ? getNormalizedDateTime(invoice?.dueDate, 'DD-MM-YYYY') : '',
              'Reference': invoice.reference
            });
          } else {
            excelData.push({
              'Student ID': studentData.registrationNo,
              'Student name': studentData.name,
              'Student mobile': studentData.mobile,
              'Father mobile': studentData.fatherMobile,
              'Mother mobile': studentData.motherMobile,
              'Amount': invoice?.amount
            });
          }
        }
      };

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [[`${capitalize(status)} Invoices`]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(ws, [['Branch', selectedBranch ? selectedBranch.name : 'All']], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(ws, [['Program', selectedProgram ? selectedProgram.name : 'All']], { origin: 'A3' });
      XLSX.utils.sheet_add_aoa(ws, [['Course', selectedCourse ? selectedCourse.name : 'All']], { origin: 'A4' });
      XLSX.utils.sheet_add_aoa(ws, [['Batch', selectedBatch ? selectedBatch.name : 'All']], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(ws, [['From Date', fromDate ? getNormalizedDateTime(fromDate, 'DD-MM-YYYY') : '']], { origin: 'A6' });
      XLSX.utils.sheet_add_aoa(ws, [['To Date', toDate ? getNormalizedDateTime(toDate, 'DD-MM-YYYY') : '']], { origin: 'A7' });
      XLSX.utils.sheet_add_aoa(ws, [['Dowload time', getNormalizedDateTime(new Date(), 'DD-MM-YYYY hh:mm A')]], { origin: 'A8' });
      XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A10' });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${status}-invoices`);
      XLSX.writeFile(wb, `${status}-invoices.xlsx`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {loading && 
                <div className="py-10">
                  <PlaceholderLoader />
                </div>}
                
                {!loading &&
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Download {status} invoices
                      </Dialog.Title>
                      <div className="mt-2 w-full">
                        {includes(['owner', 'admin'], currentUser.role) ?
                        <Select label="Branch" value={selectedBranch?._id} options={branches} onChange={(event) => setSelectedBranch(branches[event.target.selectedIndex -1])} />
                        : <p className="text-sm font-medium">Branch: {selectedBranch?.name}</p>}
                      </div>
                      <div className="mt-2 w-full">
                        <Select label="Program" value={selectedProgram?._id} options={programs} onChange={(event) => setSelectecProgram(programs[event.target.selectedIndex - 1])} />
                      </div>
                      <div className="mt-2 w-full">
                        <Select label="Course" value={selectedCourse?._id} options={courses} onChange={(event) => setSelectedCourse(courses[event.target.selectedIndex - 1])} />
                      </div>
                      <div className="mt-2 w-full">
                        <Select disabled={!includes(['live', 'offline'], selectedCourse?.courseType)} label="Batch" value={selectedBatch?._id} options={batches} onChange={(event) => setSelectedBatch(batches[event.target.selectedIndex - 1])} />
                      </div>
                      <div className="mt-2 w-full grid grid-cols-2 gap-x-4">
                        <Input col={1} type="date" label="From date" value={fromDate} onChange={(event) => setFromDate(event.target.value)} />
                        <Input col={1} type="date" label="To date" value={toDate} onChange={(event) => setToDate(event.target.value)} />
                      </div>
                      {/* <div className="mt-2 w-full">
                        <RadioGroupHorizontal label="Document type" value={documentType} options={documentTypes} onChange={(event) => setDocumentType(event.target.value)} />
                      </div> */}
                      
                      {status !== 'paid' &&
                      <div className="mt-2 w-full">
                        <RadioGroupHorizontal label="Output type" value={dueAmountType} options={dueAmountTypes} onChange={(event) => setDueAmountType(event.target.value)} />
                      </div>}
                    </div>

                  </div>
                </div>}

                {/* {!loading &&
                <div className="flex px-4 pb-4 sm:px-9 text-sm text-orange-700 space-x-1 items-center">
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <p>It may take long time to create pdf for large data.</p>
                </div>} */}
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-x-4">
                  <SubmitButton disabled={loading} label="Download" onClick={handleDowload} />
                  <Button onClick={handleCancel} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
