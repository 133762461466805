import http from '../helpers/http';


export const createWebsiteContent = async (payload) => {
  try {
    const { data } = await http.post(`/api/website-contents`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getWebsiteBanners = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/website-contents/banners`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getAboutUsContent = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/website-contents/about-us`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTermsContent = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/website-contents/terms`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateWebsiteContent = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/website-contents/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
