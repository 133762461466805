import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex, forEach } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import EmptyData from '../../components/EmptyData';
import Loader from '../../components/Loader';
import BatchForm from './components/BatchForm';
import BatchContents from './components/BatchContents';
import Breadcrumb from '../../components/Breadcrumb';
import LiveClassRoutine from '../../components/LiveClassRoutine';
import { getCourse } from '../../services/course';
import { createBatch, getBatches, updateBatch } from '../../services/batch';


const navigation = ['Routine', 'Recorded Lectures', 'MCQ Exams', 'CQ Exams', 'PDF Notes'];
const contentTypeMap = {
  Routine: null,
  'Recorded Lectures': 'lesson',
  'MCQ Exams': 'exam',
  'CQ Exams': 'cqExam',
  'PDF Notes': 'note',
};

export default function CourseContentPage () {
  const params = useParams();
  const courseId = params.courseId;
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [contentType, setContentType] = useState('');
  const [sidebarSecondaryNavigation, setSidebarSecondaryNavigation] = useState([]);
  const [currentSidebarNavigation, setCurrentSidebarNavigation] = useState('Routine');
  const [openBatchForm, setOpenBatchForm] = useState(false);
  const [editBatch, setEditBatch] = useState(false);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();

  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const course = await getCourse(courseId);
      setCourse(course);

      const _batches = await fetchBatches(courseId);
      let _sidebarSecondaryNavigation = [];
      forEach(_batches, _batch => {
        _sidebarSecondaryNavigation.push(_batch?.name);
      });
      setSidebarSecondaryNavigation(_sidebarSecondaryNavigation);
      setSelectedBatch(_batches[0]);

      const _breadcrumbPages = [
        { name: 'Courses', href: `/courses/online`},
        { name: `${course?.name}`, current: true},
        { name: `${_batches[0]?.name}`, current: true}
      ];
      setBreadcrumbPages(_breadcrumbPages);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBatches = async (courseId) => {
    try {
      const batches = await getBatches({course: courseId});
      setBatches(batches);
      return batches;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleNavigationChange = (event, index) => {
    setCurrentSidebarNavigation(navigation[index]);
    setContentType(contentTypeMap[navigation[index]]);
  };

  const handleSecondaryNavigationChange = (event, index) => {
    setSelectedBatch(() => batches[index]);
    setCurrentSidebarNavigation('Routine');
    updateBreadcrumpPages(batches[index]);
  };

  const saveBatch = async (event, payload, batchId) => {
    try {
      event.preventDefault();
      payload.course = course._id;
      let _batches = cloneDeep(batches);
      let _sidebarSecondaryNavigation = cloneDeep(sidebarSecondaryNavigation);

      let updatedBatch;
      if (batchId) {
        updatedBatch = await updateBatch(batchId, payload);
        const batchIndex = findIndex(_batches, batch => batch._id.toString() === updatedBatch._id.toString());
        _batches[batchIndex] = updatedBatch;
        setSelectedBatch(updatedBatch);
        _sidebarSecondaryNavigation[batchIndex] = updatedBatch?.name;
        toast.success('Batch info updated successfully');
      } else {
        updatedBatch = await createBatch(payload);
        _batches.push(updatedBatch);
        setSelectedBatch(_batches[_batches.length - 1]);
        setCurrentSidebarNavigation('Routine');
        _sidebarSecondaryNavigation.push(updatedBatch?.name);
        toast.success('Batch created successfully');
      }
      setBatches(_batches);
      setSidebarSecondaryNavigation(_sidebarSecondaryNavigation);
      updateBreadcrumpPages(updatedBatch);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const updateBreadcrumpPages = (batch) => {
    const _breadcrumbPages = cloneDeep(breadcrumbPages);
    _breadcrumbPages[2].name = batch?.name;
    setBreadcrumbPages(_breadcrumbPages);
  };

  return (
    <StackedLayout
      loading={loading}
      hideSidebar={batches.length === 0}
      sidebarNavigation={navigation}
      currentSidebarNavigation={currentSidebarNavigation}
      sidebarSecondaryNavigation={sidebarSecondaryNavigation}
      currentSidebarSecondaryNavigation={selectedBatch?.name}
      onNavigationChange={handleNavigationChange}
      onSecondaryNavigationChange={handleSecondaryNavigationChange}
      onAddSecondaryNavigation={() => {
        setEditBatch(false);
        setOpenBatchForm(true);
      }}
      onEditSecondaryNavigation={() => {
        setEditBatch(true);
        setOpenBatchForm(true);
      }}
    >
      
      <Breadcrumb pages={breadcrumbPages} />

      {batches.length === 0 &&
      <EmptyData
        title="No batches"
        subTitle="Create new batch for this course"
        onCreate={() => {
          setEditBatch(false);
          setOpenBatchForm(true);
        }}
      />}
      
      {openBatchForm &&
      <BatchForm
        batch={editBatch && selectedBatch}
        onSave={saveBatch}
        onClose={() => {
          setEditBatch(false);
          setOpenBatchForm(false);
        }}
      />}

      <div>
        {selectedBatch && currentSidebarNavigation !== 'Routine' &&
        <BatchContents
          courseId={course._id}
          batchId={selectedBatch._id}
          contentType={contentType}
        />}

        {selectedBatch && currentSidebarNavigation === 'Routine' &&
        <LiveClassRoutine course={course} batch={selectedBatch} />
        }
      </div>

    </StackedLayout>
  )
}