export default function TwoColumnFieldSet(props) {
  const title = props.title;
  const subTitle = props.subTitle;
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{subTitle}</p>
      </div>

      <div className="grid grid-cols-6 gap-x-6 gap-y-6 col-span-2">
        {props.children}
      </div>
    </div>
  )
};
