import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module'

import './App.css';
import { images } from './constants';
import Loader from './components/Loader';
import PageNotFound from './pages/accessControlPages/PageNotFound';
import LoginPage from './pages/auth/LoginPage';
import ServicePage from './pages/ServicePage';
import DashboardPage from './pages/reports/DashboardPage';
import ExamsPage from './pages/contents/ExamsPage';
import MCQExamQuestionsPage from './pages/contents/ExamQuestionsPage';
import ClassRoutinePage from './pages/routines/ClassRoutinePage';
import EbooksPage from './pages/contents/EbooksPage';
import CQExamQuestionsPage from './pages/contents/CQExamQuestionsPage';
import VideoLessonsPage from './pages/contents/VideoLessonsPage';
import CoursesPage from './pages/courses/CoursesPage';
import CourseContentPage from './pages/courses/CourseContentPage';
import CourseBatchPage from './pages/courses/CourseBatchPage';
import InstructorsPage from './pages/instructors/InstructorsPage';
import CourseEditPage from './pages/courses/CourseEditPage';
import MCQExamEditPage from './pages/contents/MCQExamEditPage';
import CreativeExamEditPage from './pages/contents/CreativeExamEditPage';
import EbookEditPage from './pages/contents/EbookEditPage';
import InstructorEditPage from './pages/instructors/InstructorEditPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import OTPInput from './pages/auth/OTPPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import UserProfilePage from './pages/userProfile/UserProfilePage';
import PasswordChangePage from './pages/userProfile/PasswordChangePage';
import BranchesPage from './pages/branches/BranchesPage';
import BranchEditPage from './pages/branches/BranchEditPage';
import ProgramsPage from './pages/programs/ProgramsPage';
import ProgramEditPage from './pages/programs/ProgramEditPage';
import OfflineBatchesPage from './pages/branch/batches/OfflineBatchesPage';
import OfflineBatchEditPage from './pages/branch/batches/OfflineBatchEditPage';
import StudentInvoicesPage from './pages/studentPayments/StudentInvoicesPage';
import CollectPaymentPage from './pages/studentPayments/CollectPaymentPage';
import StudentPaymentSuccessPage from './pages/studentPayments/StudentPaymentSuccessPage';
import OnlineCoureseStudentsPages from './pages/onlineStudents/CourseStudentsPage';
import StudentsPage from './pages/students/StudentsPage';
import StudentRegistrationPage from './pages/students/StudentRegistrationPage';
import StudentAdmissionPage from './pages/students/StudentAdmissionPage';
import StudentAdmissionSuccessPage from './pages/students/StudentAdmissionSuccessPage';
import StudentCoursesPage from './pages/students/StudentCoursesPage';
import QuestionsPage from './pages/contents/QuestionsPage';
import OfflineCourseContentPage from './pages/courses/OfflineCourseContentPage';
import CourseTuitionFeePage from './pages/courses/CourseTuitionFeePage';
import InstituteUsersPage from './pages/settings/InstituteUsersPage';
import InstituteUserCreatePage from './pages/settings/InstituteUserCreatePage';
import BranchUsersPage from './pages/branch/users/BranchUsersPage';
import BranchUserCreatePage from './pages/branch/users/BranchUserCreatePage';
import { includes } from 'lodash';
import PermissionDeniedPage from './pages/accessControlPages/PermissionDeniedPage';
import SupportPage from './pages/SupportPage';
import ContactSalesPage from './pages/ContactSales';
import LMSNotFoundPage from './pages/accessControlPages/LMSNotFound';
import BranchPermissionDeniedPage from './pages/accessControlPages/BranchPermissionDenied';
import CentralPaymentsPage from './pages/studentPayments/CentralPaymentPage';
import OfflineExamEditPage from './pages/contents/OfflineExamEditPage';
import ResultsCoursesPage from './pages/results/ResultsCoursesPage';
import CourseResultExamsPage from './pages/results/CourseResultExamsPage';
import ExamResultPage from './pages/results/ExamResultPage';
import CourseMeritListPage from './pages/results/CourseMeritListPage';
import DefaultHomePage from './pages/home/HomePage';
import CourseListPage from './pages/studentPages/courses/CourseListPage';
import CourseDetailsPage from './pages/studentPages/courses/CourseDetailsPage';
import BranchListPage from './pages/studentPages/branches/BranchListPage';
import SmsSettingsPage from './pages/sms/SmsSettingsPage';
import SmsRechargePage from './pages/sms/SmsRechargePage';
import SmsPaymentSuccessPage from './pages/sms/SmsPayementSuccessPage';
import InstituteInfoPage from './pages/settings/InstituteInfoPage';
import CourseEnrollSuccessPage from './pages/studentPages/courses/CourseEnrollSuccessPage';
import LearnHomePage from './pages/studentPages/learnHome/LearnHomePage';
import StudentRoutinePage from './pages/studentPages/routine/StudentRoutinePage';
import RegistrationPage from './pages/auth/RegistrationPage';
import TermsPage from './pages/TermsPage';
import PlaylistPage from './pages/studentPages/lesson/PlaylistPage';
import MCQExamPage from './pages/studentPages/exam/MCQExamPage';
import TakenExamResultPage from './pages/studentPages/exam/TakenExamResultPage';
import EbookViewPage from './pages/studentPages/ebook/EbookViewPage';
import WebBannerPage from './pages/websiteContents/WebBannerPage';
import InstructorsListPage from './pages/studentPages/instructors/InstructorsListPage';
import StudentDuePaymentsPage from './pages/studentPayments/StudentDuePayments';
import TestimonialsPage from './pages/websiteContents/testimonals/TestimonialsPage';
import TestimonialEditPage from './pages/websiteContents/testimonals/TestimonalEditPage';
import AboutUsContentPage from './pages/websiteContents/AboutUsContentPage';
import TermsContentPage from './pages/websiteContents/TermsContentPage';
import AboutUsPage from './pages/AboutUsPage';
import TransactionsPage from './pages/reports/TransactionsPage';
import StudentAdmissionCancelPage from './pages/students/StudentAdmissionCancelPage';
import ResultUploadPage from './pages/results/ResultUploadPage';
import ProgramExamsPage from './pages/programs/ProgramExamsPage';
import BkashCredentialsPage from './pages/settings/BkashCredentialsPage';
import CourseCheckoutPage from './pages/studentPages/courses/CourseCheckoutPage';
import MeritlistBuilderPage from './pages/results/MeritlistBuilderPage';
import SmsHistoryPage from './pages/reports/SmsHistoryPage';
import StudentResultPage from './pages/studentPages/results/StudentResultPage';
import StudentPaymentPage from './pages/studentPages/payment/StudentPaymentPage';
import StudentTransactionPage from './pages/studentPages/payment/StudentTransactionsPage';
import StudentInvoicePaymentSuccessPage from './pages/studentPages/payment/StudentInvoicePaymentSuccessPage';
import AnalyticsConfigurationPage from './pages/settings/AnalyticsConfigurationPage';
import CompetitionsPage from './pages/competitions/CompetitionsPage';
import CompetitionEditPage from './pages/competitions/CompetitionEditPage';
import CompetitionStudentPage from './pages/studentPages/competition/CompetitionStudentPage';
import CompetitionParticipantsPage from './pages/competitions/CompetitionParticipantsPage';


function App() {
  const [loading, setLoading] = useState(true);
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo || null;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;

  
  if (currentInstitute?.googleTagManagerId) {
    TagManager.initialize({ gtmId: currentInstitute?.googleTagManagerId });
    window.dataLayer.push({
      event: 'pageview'
    });
  }

  useEffect(() => {
    document.title = currentInstitute?.name || 'LMS | Bidyaguru';

    let favIconLink = document.createElement('link');
    favIconLink.rel = 'icon';
    favIconLink.href = currentInstitute?.icon || images.Favicon;
    document.getElementsByTagName('head')[0].appendChild(favIconLink);

    let metaColor = document.createElement('meta');
    metaColor.name = 'theme-color';
    metaColor.content = currentInstitute?.brandColor || '#16a34a';
    document.getElementsByTagName('head')[0].appendChild(metaColor);

    let metaTitle = document.createElement('meta');
    metaTitle.name = 'title';
    metaTitle.content = currentInstitute?.name;
    document.getElementsByTagName('head')[0].appendChild(metaTitle);
    
    let metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = currentInstitute?.description || currentInstitute?.name;
    document.getElementsByTagName('head')[0].appendChild(metaDescription);

    setLoading(false);
  }, []);

  if (loading) {
    return (
      <Loader />
    )
  }

  if (!currentInstitute) {
    return (
      <div className="App font-gilroy">
        <Routes>
          <Route index path="/contact-sales" element={<ContactSalesPage />} />
          <Route path="*" element={<LMSNotFoundPage />} />
        </Routes>
      </div>
    )
  }

  if (!currentInstitute.isActive) {
    return (
      <div className="App font-gilroy">
        <Routes>
          <Route index path="/support" element={<SupportPage />} />
          <Route path="*" element={<PermissionDeniedPage />} />
        </Routes>
      </div>
    )
  }

  if (currentUser?.branch && (currentUser?.branch?.status !== 'active' || !currentInstitute?.permissions?.offlineCoachingEnabled)) {
    return (
      <div className="App font-gilroy">
        <Routes>
          <Route index path="/" element={<LoginPage />} />
          <Route path="/auth" >
            <Route index path="forgot-password" element={<ForgotPasswordPage />} />
            <Route index path="otp" element={<OTPInput />} />
            <Route index path="reset-password" element={<ResetPasswordPage />} />
          </Route>
          <Route path="*" element={<BranchPermissionDeniedPage />} />
        </Routes>
      </div>
    )
  }

  return (
    <div className="App font-gilroy">
      <Routes>
        {currentInstitute?.permissions?.websiteEnabled ?
        <Route index path="/" element={<DefaultHomePage />} />
        :<Route index path="/" element={<LoginPage />} />}


        <Route path="/auth" >
          <Route index path="login" element={<LoginPage />} />
          <Route index path="register" element={<RegistrationPage />} />
          <Route index path="forgot-password" element={<ForgotPasswordPage />} />
          <Route index path="otp" element={<OTPInput />} />
          <Route index path="reset-password" element={<ResetPasswordPage />} />
        </Route>

        {currentUser?.role !== 'student' &&
        <Route index path="/services" element={<ServicePage />} />}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
        currentUser?.permissions?.canManageRoutines) &&
        <Route path="/routines">
          <Route path="offline" element={<ClassRoutinePage courseType="offline" />} />
          <Route path="online" element={<ClassRoutinePage courseType="online" />} />
        </Route>}

        <Route path="/contents">
          <Route index element={<PageNotFound />} />

          {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageExams ||
          (currentBranch?.permissions?.canViewContents && currentUser?.permissions?.canViewContents)) &&
          <Route path="exams">
            <Route path="mcq">
              <Route index element={<ExamsPage examType="mcq" />} />
              <Route index path="folder/:folderId" element={<ExamsPage examType="mcq" />} />
              {!currentUser?.permissions?.canViewContents &&
              <>
                <Route path="create" element={<MCQExamEditPage />} />
                <Route path=":id/edit" element={<MCQExamEditPage />} />
                <Route path=":id/questions" element={<MCQExamQuestionsPage />} />
              </>}
            </Route>

            <Route path="cq">
              <Route index element={<ExamsPage examType="cq" />} />
              <Route index path="folder/:folderId" element={<ExamsPage examType="cq" />} />
              {!currentUser?.permissions?.canViewContents &&
              <>
                <Route path="create" element={<CreativeExamEditPage />} />
                <Route path=":id/edit" element={<CreativeExamEditPage />} />
                <Route path=":id/questions" element={<CQExamQuestionsPage />} />
              </>}
            </Route>

            {!currentUser?.permissions?.canViewContents &&
            <Route path="offline">
              <Route index element={<ExamsPage examType="offline" />} />
              <Route index path="folder/:folderId" element={<ExamsPage examType="offline" />} />
              <Route path="create" element={<OfflineExamEditPage />} />
              <Route path=":id/edit" element={<OfflineExamEditPage />} />
            </Route>}
          </Route>}

          {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageQuestions) &&
          <Route path="questions">
            <Route index path="mcq" element={<QuestionsPage questionType="mcq" />} />
            <Route index path="mcq/folder/:folderId" element={<QuestionsPage questionType="mcq" />} />

            <Route index path="cq" element={<QuestionsPage questionType="cq" />} />
            <Route index path="cq/folder/:folderId" element={<QuestionsPage questionType="cq" />} />
          </Route>}

          {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageNotes ||
          (currentBranch?.permissions?.canViewContents && currentUser?.permissions?.canViewContents)) &&
          <Route path="documents">
            <Route index element={<EbooksPage />} />
            <Route index path="folder/:folderId" element={<EbooksPage />} />
            {!currentUser?.permissions?.canViewContents &&
            <>
              <Route path="create" element={<EbookEditPage />} />
              <Route path=":id/edit" element={<EbookEditPage />} />
            </>}
          </Route>}

          {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageVideos) &&
          <Route path="lessons">
            <Route index element={<VideoLessonsPage lessonType="lesson" />} />
            <Route index path="playlists" element={<VideoLessonsPage lessonType="playlist" />} />
            <Route index path="folder/:folderId" element={<VideoLessonsPage lessonType="lesson" />} />
          </Route>}
        </Route>

        {(includes(['owner', 'admin'], currentUser?.role) ||
        currentUser?.permissions?.canManageCourses) &&
        <Route path="/online-courses">
          <Route index element={<CoursesPage />} />
          <Route index path="create" element={<CourseEditPage />} />
          <Route index path=":courseId/edit" element={<CourseEditPage />} />
          <Route index path=":courseId/contents" element={<CourseContentPage />} />
          <Route index path=":courseId/batches" element={<CourseBatchPage />} />
          <Route index path=":courseId/students" element={<OnlineCoureseStudentsPages />} />
        </Route>}

        {(includes(['owner', 'admin'], currentUser?.role) ||
        currentUser?.permissions?.canManageCourses) &&
        <Route path="/courses">
          <Route path="online">
            <Route index element={<CoursesPage />} />
            <Route index path="create" element={<CourseEditPage />} />
            <Route index path=":courseId/edit" element={<CourseEditPage />} />
            <Route index path=":courseId/contents" element={<CourseContentPage />} />
            <Route index path=":courseId/batches" element={<CourseBatchPage />} />
            <Route index path=":courseId/students" element={<OnlineCoureseStudentsPages />} />
          </Route>
          <Route path="offline">
            <Route index element={<CoursesPage courseType="offline" />} />
            <Route path="create" element={<CourseEditPage courseType="offline" />} />
            <Route path=":courseId/edit" element={<CourseEditPage courseType="offline" />} />
            <Route path=":courseId/contents" element={<OfflineCourseContentPage />} />
            <Route path=":courseId/fees" element={<CourseTuitionFeePage />} />
          </Route>
        </Route>}

        {includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) &&
        <Route path="/reports">
          <Route index element={<PageNotFound />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="transactions" element={<TransactionsPage />} />
          <Route path="sms-history" element={<SmsHistoryPage />} />
        </Route>}

        {includes(['owner', 'admin'], currentUser?.role) &&
        <Route path="/programs">
          <Route index element={<ProgramsPage />} />
          <Route index path="create" element={<ProgramEditPage />} />
          <Route index path=":programId/edit" element={<ProgramEditPage />} />
          <Route index path=":programId/exams" element={<ProgramExamsPage />} />
        </Route>}

        {(includes(['owner', 'admin'], currentUser?.role) ||
        currentUser?.permissions?.canManageCoaching) &&
        <Route path="/branches">
          <Route index element={<BranchesPage />} />
          <Route index path="create" element={<BranchEditPage />} />
          <Route index path=":branchId/edit" element={<BranchEditPage />} />
        </Route>}

        {includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) &&
        <Route path="/branch">
          <Route index element={<PageNotFound />} />
          <Route path="users">
            <Route index element={<BranchUsersPage />} />
            <Route index path="create" element={<BranchUserCreatePage />} />
          </Route>
          <Route path="batches">
            <Route index element={<OfflineBatchesPage />} />
            <Route index path="create" element={<OfflineBatchEditPage />} />
            <Route index path=":batchId/edit" element={<OfflineBatchEditPage />} />
          </Route>
        </Route>}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
        currentUser?.permissions?.canManageStudentAdmissions) &&
        <Route path="students">
          <Route index element={<StudentsPage />} />
          <Route index path="registration" element={<StudentRegistrationPage />} />
          <Route index path=":studentId/edit" element={<StudentRegistrationPage />} />
          <Route index path="admission" element={<StudentAdmissionPage />} />
          <Route index path="admission/success" element={<StudentAdmissionSuccessPage />} />
          <Route index path=":studentId/enrolled-courses" element={<StudentCoursesPage />} />
          <Route index path="enrolled-courses/:enrolledCourseId/cancel" element={<StudentAdmissionCancelPage />} />
        </Route>}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
        currentUser?.permissions?.canManageStudentPayments) &&
        <Route path="/student-payments">
          <Route index path="due" element={<StudentInvoicesPage status="due" />} />
          <Route index path="paid" element={<StudentInvoicesPage status="paid" />} />
          <Route index path="collect" element={<StudentDuePaymentsPage />} />
          <Route index path="central" element={<CentralPaymentsPage />} />
          <Route index path=":invoiceId/payment" element={<CollectPaymentPage />} />
          <Route index path="payment/success" element={<StudentPaymentSuccessPage />} />
        </Route>}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
        currentUser?.permissions?.canManageResults) &&
        <Route path="results">
          <Route index element={<PageNotFound />} />
          <Route index path="upload" element={<ResultUploadPage />} />
          <Route index path="meritlist" element={<MeritlistBuilderPage />} />


          <Route index path="courses" element={<ResultsCoursesPage resultType="course" />} />
          <Route index path="course/:courseId" element={<CourseResultExamsPage />} />
          <Route index path="course/:courseId/meritlists" element={<CourseMeritListPage />} />
          <Route index path="course/:courseId/exam/:examId" element={<ExamResultPage />} />
        </Route>}

        {(includes(['owner', 'admin'], currentUser?.role) ||
        currentUser?.permissions?.canManageCompetitions ||
        currentBranch?.permissions?.canManageCompetitionParticipants ||
        currentUser?.permissions?.canManageCompetitionParticipants) &&
        <Route path="/competitions">
          <Route index element={<CompetitionsPage />} />
          {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageCompetitions) &&
          <>
            <Route index path="create" element={<CompetitionEditPage />} />
            <Route index path=":id/edit" element={<CompetitionEditPage />} />
          </>}
          <Route index path=":id/participants" element={<CompetitionParticipantsPage />} />
        </Route>}

        {includes(['owner', 'admin'], currentUser?.role) &&
        <Route path="/instructors">
          <Route index element={<InstructorsPage />} />
          <Route index path="create" element={<InstructorEditPage />} />
          <Route index path=":id/edit" element={<InstructorEditPage />} />
        </Route>}

        {includes(['owner', 'admin'], currentUser?.role) &&
        <Route path="/sms">
          <Route index element={<SmsSettingsPage />} />
          <Route index path="recharge" element={<SmsRechargePage />} />
          <Route index path="payment/success" element={<SmsPaymentSuccessPage />} />
        </Route>}

        {includes(['owner', 'admin'], currentUser?.role) &&
        <Route path="/settings">
          <Route index element={<PageNotFound />} />
          <Route index path="institute-info" element={<InstituteInfoPage />} />
          <Route index path="bkash-credentials" element={<BkashCredentialsPage />} />
          <Route index path="analytics" element={<AnalyticsConfigurationPage />} />
          <Route path="users">
            <Route index element={<InstituteUsersPage />} />
            <Route index path="create" element={<InstituteUserCreatePage />} />
          </Route>
        </Route>}

        {currentInstitute?.permissions?.websiteEnabled &&
        includes(['owner', 'admin'], currentUser?.role) &&
        <Route path="/website-contents">
          <Route index path="web-banners" element={<WebBannerPage platform="web" />} />
          <Route index path="mobile-banners" element={<WebBannerPage platform="mobile" />} />
          <Route index path="testimonials" element={<TestimonialsPage />} />
          <Route index path="testimonials/create" element={<TestimonialEditPage />} />
          <Route index path="testimonials/:id/edit" element={<TestimonialEditPage />} />
          <Route index path="about-us" element={<AboutUsContentPage />} />
          <Route index path="terms" element={<TermsContentPage />} />
        </Route>}

        <Route path="/user">
          <Route index path="profile" element={<UserProfilePage />} />
          <Route index path="change-password" element={<PasswordChangePage />} />
        </Route>

        {currentInstitute?.permissions?.websiteEnabled &&
        <>
          <Route path="/learn">
            <Route index path="competitions/:competitionSlug" element={<CompetitionStudentPage />} />
            
            {currentUser?.role === 'student' &&
            <>
              <Route index path="home" element={<LearnHomePage />} />
              <Route index path="routine" element={<StudentRoutinePage />} />
              <Route index path="results" element={<StudentResultPage />} />
              <Route index path="payments" element={<StudentPaymentPage />} />
              <Route index path="payments/success" element={<StudentInvoicePaymentSuccessPage />} />
              <Route index path="transactions" element={<StudentTransactionPage />} />

              <Route path="lessons">
                <Route index path=":courseContentId/topics/:topicIndex/course/:courseId" element={<PlaylistPage />} />
              </Route>

              <Route path="exam">
                <Route index path=":id/course/:courseId" element={<MCQExamPage />} />
                <Route index path=":id/result" element={<TakenExamResultPage />} />
              </Route>

              <Route path="ebooks">
                <Route index path=":id/view" element={<EbookViewPage />} />
              </Route>
            </>}


            <Route index path="courses" element={<CourseListPage />} />
            <Route index path="courses/:courseId/details" element={<CourseDetailsPage />} />

            <Route index path="branches" element={<BranchListPage />} />
            <Route index path="instructors" element={<InstructorsListPage />} />

            <Route index path="checkout/course/:courseId/branch/:branchId/batch/:batchId" element={<CourseCheckoutPage />} />
            <Route index path="checkout/course/:courseId/batch/:batchId" element={<CourseCheckoutPage />} />
            <Route index path="checkout/course/:courseId" element={<CourseCheckoutPage />} />
          </Route>

          <Route path="/payment-success">
            <Route index path="course/:id" element={<CourseEnrollSuccessPage />} />
          </Route>
        </>}

        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/terms" element={<TermsPage />} />
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Toaster position="bottom-right" toastOptions={{duration: 3000}} />
    </div>
  );
}

export default App;
