import http from '../helpers/http';


export const getTakenExams = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/taken-exams`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const addMarksheet = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams/marksheet`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};


export const publishOfflineExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams/publish`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createOnlineTakenExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams/online`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTakenExam = async (id) => {
  try {
    const { data } = await http.get(`/api/taken-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const checkUserTakenExam = async (params) => {
  try {
    params = params || {};
    const config = { params}
    const { data } = await http.get(`/api/taken-exams/user-check`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getMeritlist = async (params) => {
  try {
    params = params || {};
    const config = { params}
    const { data } = await http.get(`/api/taken-exams/meritlist`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const sendMeritlistSms = async (payload) => {
  try {
    const { data } = await http.post(`/api/taken-exams/meritlist/send-sms`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
