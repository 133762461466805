import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import { cloneDeep, includes, round } from 'lodash';
import { startOfToday, endOfDay, add, startOfMonth, endOfMonth } from 'date-fns';

import StackedLayout from '../../layouts/StackedLayout';
import { Select } from '../../components/forms/fields';
import { getEnrolledCourseCount } from '../../services/enrolledCourse';
import { getInvoicePaidAmount } from '../../services/invoice';
import { getTotalStudentDues } from '../../services/invoice';
import { getBranches } from '../../services/branch';



const sidebarNavigation = [
  { name: 'Dashboard', href: '/reports/dashboard' },
  { name: 'Transactions', href: '/reports/transactions' },
  { name: 'SMS history', href: '/reports/sms-history' },
];

const defaultStats = [
  { name: 'Transaction', stat: 0, previousStat: 0 },
  { name: 'Course Enrolled', stat: 0, previousStat: 0 },
  { name: 'Current dues', stat: 0, previousStat: 0 },
];

const durations = [
  { title: 'Today', value: 'today' },
  { title: 'Last 7 days', value: 'week' },
  { title: 'This month', value: 'month' },
  { title: 'All-time', value: -1 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function DashboardPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [stats, setStats] = useState(defaultStats);
  const [duration, setDuration] = useState('today');
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (includes(['owner', 'admin'], currentUser.role)) {
          const _branches = await getBranches({ status: 'active' });
          setBranches(_branches);
        } else {
          setSelectedBranch(currentBranch._id);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, [duration]);

  useEffect(() => {
    async function _fetchData() {
      try {
        await fetchData();
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, [
    duration,
    selectedBranch
  ]);

  const fetchData = async () => {
    try {
      let params = {};
      let previousParams = {};

      const today = startOfToday();
      if (duration === 'today') {
        params.fromDate = today;
        previousParams.fromDate = add(today, { days: -1 });
        previousParams.toDate = endOfDay(add(today, { days: -1 }));
      } else if (duration === 'week') {
        params.fromDate = add(today, { days: -6 })
        params.toDate = endOfDay(today);

        previousParams.fromDate = add(today, { days: -13 })
        previousParams.toDate = endOfDay(add(today, { days: -7 }));
      } else if (duration === 'month') {
        params.fromDate = startOfMonth(today);

        previousParams.fromDate = startOfMonth(add(today, { months: -1 }))
        previousParams.toDate = endOfMonth(add(today, { months: -1 }));
      }

      const _stats = cloneDeep(stats);

      let revenueQuery = cloneDeep(params);
      let previousRevenueQuery = cloneDeep(previousParams);

      if (selectedBranch) {
        params.branch = selectedBranch;
        previousParams.branch = selectedBranch;

        revenueQuery.paymentBranch = selectedBranch;
        previousRevenueQuery.paymentBranch = selectedBranch;
      }
      
      const _currentRevenue = await getInvoicePaidAmount(revenueQuery);
      _stats[0].stat = _currentRevenue.netCollection;

      const _previousRevenue = await getInvoicePaidAmount(previousRevenueQuery);
      _stats[0].previousStat = _previousRevenue.netCollection;

      const _currentCourseEnrollments = await getEnrolledCourseCount(params);
      _stats[1].stat = _currentCourseEnrollments.count;

      const _previousCourseEnrollments = await getEnrolledCourseCount(previousParams);
      _stats[1].previousStat = _previousCourseEnrollments.count;

      let dueParams = {};
      if (selectedBranch) { dueParams.branch = selectedBranch };

      const _currentStudentDues = await getTotalStudentDues(dueParams);
      _stats[2].stat = _currentStudentDues.totalDues;

      setStats(_stats);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Dashboard"
    >
      <div>
        {includes(['owner', 'admin'], currentUser.role) &&
        <div className="mx-2 grid grid-cols-4 pb-6">
          <p className="col-span-1">Select branch:</p>
          <Select col={2} value={selectedBranch} options={branches} onChange={(event) => setSelectedBranch(event.target.value)} />
        </div>}
        <div className="mx-2 flex flex-wrap items-center gap-6 sm:flex-nowrap">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Dashboard</h1>
          <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {durations.map((item, index) => (
              <span 
                key={`duration-index[${index}]`}
                className={`${duration === item.value ? 'text-green-600' : 'text-gray-700'} cursor-pointer`}
                onClick={() => setDuration(item.value)}
              >
                {item.title}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div>
        <dl className="mt-5 mx-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">{item.name}</dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-xl font-semibold text-green-600">
                  {round(item?.stat)?.toLocaleString()}
                  {item.name !== 'Current dues' && <span className="ml-2 text-xs font-medium text-gray-500">
                    from {round(item?.previousStat)?.toLocaleString()}
                  </span>}
                </div>

                {item.name !== 'Current dues' && item.previousStat > 0 && item.stat !== item.previousStat &&
                <div
                  className={classNames(
                    item.stat > item.previousStat ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline rounded-full px-1.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  {item.stat > item.previousStat ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                  {
                    item.stat > item.previousStat ?
                    round(((item.stat - item.previousStat) / (item.previousStat || 1)) * 100)
                    : round(((item.previousStat - item.stat) / (item.previousStat || 1)) * 100)
                  }%
                </div>}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </StackedLayout>
  )
}
