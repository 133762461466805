import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CheckIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import { SubmitButton, Button } from '../../components/buttons';
import { getInvoicePdf, getInvoices } from '../../services/invoice';
import { capitalize } from 'lodash';


const headers = ['Payment for', 'Branch', 'Amount', 'Status'];

export default function StudentPaymentSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const transactionId = location?.state?.transactionId;
  const invoiceId = location?.state?.invoiceId;
  const isBulkPayment = location?.state?.isBulkPayment;
  const invoiceIds = location?.state?.invoices || [];
  const paymentCircle = location?.state?.paymentCircle;
  const backUrl = location?.state?.backUrl || `/students`;
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      if (!transactionId || (isBulkPayment && invoiceIds.length === 0) || (!isBulkPayment && !invoiceId)) {
        return navigate(-1);
      }

      if (isBulkPayment) {
        try {
          const _invoices = await getInvoices({_id: invoiceIds});
          setInvoices(_invoices); 
        } catch (error) {
          toast.error(error.message);
        }
      }
    };
    _fetchData();
  }, []);

  const handlePrintInvoice = async (_invoiceId) => {
    try {
      _invoiceId = _invoiceId || invoiceId;
      const pdfUrl = await getInvoicePdf(_invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  return (
    <StackedLayout>
      <div className="flex flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Payment collected successfully.
            </h3>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 space-x-4">
          <SubmitButton
            label="Collect another"
            onClick={() => navigate(backUrl)}
          />
          {(!isBulkPayment || paymentCircle === 'month') &&
          <Button
            label="Download invoice"
            onClick={() => handlePrintInvoice(paymentCircle === 'month' ? invoices[0]._id : invoiceId)}
          />}
        </div>

        {isBulkPayment &&
        <div className="mt-5 sm:mt-6">
          <h2 className="text-xl text-center">Invoices</h2>

          <Table
            itemsCount={invoices.length}
            headers={headers}
          >
            {invoices.map((invoice, index) => (
              <tr key={invoice._id} className="text-center">
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
                  {invoice?.title || invoice?.course?.name}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {invoice.status === 'paid' ? invoice.paidAmount :invoice.amount}
                </td>
                <td 
                  className={`pr-3 py-4 text-sm ${invoice.status === 'overdue' ? 'text-red-500' : 'text-gray-500'}`}
                >
                  {capitalize(invoice?.status)}
                </td>
                {paymentCircle === 'onetime' &&
                <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <p
                    className="text-indigo-600 hover:text-indigo-500 underline cursor-pointer"
                    onClick={() => handlePrintInvoice(invoice._id)}
                  >
                    Print
                  </p>
                </td>}
              </tr>
            ))}
          </Table>
        </div>}
      </div>
    </StackedLayout>
  )
};