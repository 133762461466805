import { isEmpty } from 'lodash';


export default function Input(props) {
  const {
    label,
    placeholder,
    type,
    name,
    value,
    required,
    disabled,
    textRight,
    min,
    max,
    onChange,
    onKeyDown,
    helpText
  } = props;
  const col = props?.col?.toString() || '3';

  return (
    <div className={`sm:col-span-${col}`}>
      {label &&
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>}
      <div className={label && 'mt-2'}>
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-600">
          <input
            type={type}
            name={name}
            value={value}
            min={min}
            max={max}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            className={`block flex-1 w-full pl-2 ${textRight && 'text-right'} border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:opacity-30`}
            onChange={onChange}
          />
        </div>
        {!isEmpty(helpText) && <p className="mt-1 text-xs text-gray-500">{helpText}</p>}
      </div>
    </div>
  )
};
