import AsyncSelect from 'react-select/async';


export default function SelectAsync(props) {
  let {
    col,
    label,
    name,
    value,
    onSearch,
    onChange,
    placeholder
  } = props;
  col = col?.toString() || '3';
  placeholder = placeholder || 'Select...'

  return (
    <div className={`sm:col-span-${col}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className={label && 'mt-2'}>
        <AsyncSelect 
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          loadOptions={onSearch}
          className="block pr-2 w-full rounded-md border-0 text-gray-900 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  )
};
