import {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import { orderBy } from 'lodash';
import {add, startOfToday, startOfWeek, startOfMonth, endOfWeek} from 'date-fns';

import Loader from '../Loader';
import Calendar from '../CalendarBN';
import { getLiveClassesForUser } from '../../services/liveClass';


export default function StudentClassRoutine(props) {
  const { courseId, batchId } = props;
  const [loading, setLoading] = useState(true);
  const [liveClasses, setLiveClasses] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    }
    _fetchData();
  }, []);

  const fetchData = async (_startDate) => {
    try {
      _startDate = _startDate || startOfToday();
      const startDayCurrentMonth = startOfMonth(_startDate);
      const startDayNextMonth = add(startDayCurrentMonth, {months: 1});
      let params = {
        startDate: startOfWeek(startDayCurrentMonth, {weekStartsOn: 6}),
        endDate: endOfWeek(startDayNextMonth, {weekStartsOn: 6})
      };

      if (courseId && batchId) {
        params.course = courseId;
        params.batch = batchId;
      }

      const _liveClasses = await getLiveClassesForUser(params);
      setLiveClasses(orderBy(_liveClasses, 'startsAt'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = async (_startDate) => {
    await fetchData(_startDate);
  };

  if (loading) {
    return (
      <Loader />
    )
  }
  
  return (
    <Calendar
      events={liveClasses}
      onMonthChange={handleMonthChange}
    />
  )
};
