import { useState } from 'react';

import { Input, SelectDistrict, RadioGroupHorizontal } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';


const genderOptions = [
  {name: 'Male', value: 'male'},
  {name: 'Female', value: 'female'}
];

export default function AddCompetitionParticipantForm(props) {
  const {
    competition,
    onSave,
    onClose
  } = props;
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [gender, setGender] = useState('male');
  const [district, setDistrict] = useState('');
  const [institute, setInstitute] = useState('');
  const [updating, setUpdating] = useState(false);

  const handleRegistration = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        name,
        mobile,
        gender,
        district,
        institute
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !mobile || !gender || !district || !institute;
  };

  return (
    <Sidepanel
      title="Add participant"
      subTitle={competition.name}
      action="Save"
      onSave={handleRegistration}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="Name"
      >
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Mobile"
      >
        <Input value={mobile} onChange={(event) => setMobile(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Gender"
      >
        <RadioGroupHorizontal value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="District"
      >
        <SelectDistrict col={2} label="District" value={{label: district, value: district}} onChange={(item) => setDistrict(item.value)}/>
      </SidepanelField>

      <SidepanelField
        label="Institute"
      >
        <Input value={institute} onChange={(event) => setInstitute(event.target.value)} />
      </SidepanelField>
    </Sidepanel>
  );
};
