import { createSlice } from "@reduxjs/toolkit";

const instituteInitialState = { instituteInfo: null };

const instituteSlice = createSlice({
  name: "institute",
  initialState: instituteInitialState,
  reducers: {
    setInstituteInfo(state, action) {
      state.instituteInfo = action.payload;
    },
    resetInstituteInfo(state) {
      state.instituteInfo = null;
    },
  },
});

const instituteActions = instituteSlice.actions;
const instituteReducer = instituteSlice.reducer;

export { instituteActions, instituteReducer };
