import TeacherIcon from '../assets/teacher.png';
import ResultSMSIcon from '../assets/result-sms.png';
import ExamIcon from '../assets/exam.png';
import StudyMaterialIcon from '../assets/study-material.png';
import ActivityIcon from '../assets/activity.png';
import BranchIcon from '../assets/branch.png';


const features = [
  {
    name: 'অভিজ্ঞ শিক্ষক প্যানেল',
    description: 'আপনাকে শিক্ষার পরিপূর্ণ পরিবেশে পরিচিত করার জন্য একটি দক্ষ শিক্ষক প্যানেল',
    icon: TeacherIcon,
  },
  {
    name: 'Auto এসএমএস ফলাফল',
    description:
      'আপনার উন্নতি নিশ্চিত করতে স্বয়ংক্রিয় এসএমএস ফলাফল',
    icon: ResultSMSIcon,
  },
  {
    name: 'মানসম্মত স্টাডি ম্যাটেরিয়ালস',
    description:
      'সঠিক শিক্ষার মাধ্যমে আপনি আরো উন্নতি করুন ',
    icon: StudyMaterialIcon,
  },
  {
    name: 'অনলাইন/অফলাইন কার্যক্রম',
    description:
      'অনলাইন/অফলাইন কার্যক্রমের মাধ্যমে সঠিক দিক নির্দেশনা পান ',
    icon: ActivityIcon,
  },
  {
    name: 'সকল শাখায় একই সার্ভিস',
    description: 'শিক্ষা এখন আরও সহজ।',
    icon: BranchIcon,
  },
  {
    name: 'মাসিক/সাপ্তাহিক পরীক্ষা ',
    description:
      'মাসিক/সাপ্তাহিক পরীক্ষায় আপনার প্রগতি মূল্যায়ন করা।',
    icon: ExamIcon,
  },
];

export default function StudyMaterial(props) {
  const { currentInstitute } = props;
  const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;

  return (
    <section
      id="faqs"
      aria-label="Feature for investing all your money"

    >
      {/* <Container> */}
      <div className="bg-white py-12 sm:py-14">
        <div className="mx-auto w-full sm:max-w-7xl px-6 lg:px-8">
          <div className="mx-auto w-full grid sm:max-w-2xl grid-cols-1 sm:gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="space-y-3 sm:space-y-5 tracking-wide ">
              {/* <h2 className="text-base text-left font-semibold leading-6 text-blue-900">
                  কেন আমাদের নির্বাচন করবেন?
                </h2> */}
              <h2 className="text-3xl mt-2 text-left font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                কেন <span style={{ color: brandColor }}>"স্পন্দন"</span>  অনন্য?
              </h2>
              {/* <h2 className="text-lg mt-5 text-left font-normal text-gray-500">
                  শ্রেষ্ঠত্বের গ্যারান্টি সহ উচ্চতর শিক্ষার অভিজ্ঞতা নিন আমাদের উদ্ভাবনী পদ্ধতি শিক্ষার্থীদের একাডেমিক এবং পেশাগতভাবে সমৃদ্ধি নিশ্চিত করে।
                </h2> */}
              {/* <button
                  className="w-full  sm:w-fit flex justify-center items-center text-base leading-6 text-blue-800 font-medium bg-blue-100 py-3.5 px-6 rounded-[6px]"
                  onClick={() => window.location.href = `/learn/courses`}
                >
                  সকল কোর্স দেখুন
                  <ArrowTopRightOnSquareIcon className="ml-3 w-5 h-5 text-xl" />
                </button> */}
            </div>
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt className="space-y-6 text-lg font-semibold leading-7 text-gray-900 tracking-wide  text-left">
                    <div className="mb-6">
                      <img src={feature.icon} className="w-[84px]" />
                    </div>
                    {feature.name}
                  </dt>
                  {/* <dd className="mt-1 text-base leading-6 font-normal text-left text-gray-600 ">{feature.description}</dd> */}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </section>
  );
}
