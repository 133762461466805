import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';


export default function Pargination(props) {
  const pagingData = props.pagingData;
  const startNumber = (pagingData.limit * (pagingData.page - 1)) + 1;
  const endNumber = startNumber + pagingData.documentCount - 1;

  return (
    <nav
      className="flex items-center justify-between px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          disabled={!pagingData.hasPrevPage}
          className="inline-flex items-center pr-1 text-sm font-medium text-gray-500 hover:text-gray-700 disabled:text-gray-200 disabled:cursor-not-allowed"
          onClick={(event) => {props.onPageChange(event, 'prev')}}
        >
          <ArrowLongLeftIcon className={`ml-3 h-5 w-5 ${pagingData.hasPrevPage ? 'text-gray-500' : 'text-gray-200'}`} aria-hidden="true" />
          Previous
        </button>

        <p

          className="inline-flex ml-3 items-center pr-1 text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          {startNumber}-{endNumber} of {pagingData.totalDocs}
        </p>

        <button
          disabled={!pagingData.hasNextPage}
          className="inline-flex ml-3 items-center pl-1 text-sm font-medium text-gray-500 hover:text-gray-700 disabled:text-gray-200 disabled:cursor-not-allowed"
          onClick={(event) => {props.onPageChange(event, 'next')}}
        >
          Next
          <ArrowLongRightIcon className={`ml-3 h-5 w-5 ${pagingData.hasNextPage ? 'text-gray-500' : 'text-gray-200'}`} aria-hidden="true" />
        </button>
      </div>
    </nav>
  )
};
