import { useEffect, useState } from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from '../components/Container';
import { getWebsiteBanners } from '../../../../services/websiteContent';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function HeroSection() {
  const [webBanners, setWebBanners] = useState([]);
  const [mobileBanners, setMobileBanners] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      try {
        const websiteContent = await getWebsiteBanners({platform: 'web'});
        setWebBanners(websiteContent.contents);
      } catch (error) {
        setWebBanners([]);
      }

      try {
        const mobileContent = await getWebsiteBanners({platform: 'mobile'});
        setMobileBanners(mobileContent?.contents);
      } catch (error) {
        setMobileBanners([]);
      }
    };
    _fetchData();
  }, []);

  const handleBannerClick = (banner) => {
    if (banner.targetUrl) {
      window.location.href = banner.targetUrl;
    }
  };

  return (
    <div className="bg-white pt-14 sm:pt-20">
      <main>
        <div className="">
          <Container>
            <div className="sm:hidden">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper "
                breakpoints={{
                  300: {
                    slidesPerView: 1, // 1 slide for mobile devices
                  },
                  // 640: {
                  //   slidesPerView: 2, // 2 slides for tablets
                  // },
                  // 768: {
                  //   slidesPerView: 2, // 3 slides for desktop
                  // },
                  // 1024: {
                  //   slidesPerView: 3, // 4 slides for desktop
                  // },
                  // // 1536: {
                  // //     slidesPerView: 5, // 5 slides for desktop
                  // // },
                }}
              >
                
                {
                  (mobileBanners?.length > 0 ? mobileBanners : webBanners).map((item, index) => {
                    return (
                      <SwiperSlide key={`banner-index[${index}]`}>
                        <img className="w-full" src={item.url} alt="heroBanar" onClick={() => handleBannerClick(item)} />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </div>

            <div className="hidden sm:block">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  300: {
                    slidesPerView: 1, // 1 slide for mobile devices
                  },
                  // 640: {
                  //   slidesPerView: 2, // 2 slides for tablets
                  // },
                  // 768: {
                  //   slidesPerView: 2, // 3 slides for desktop
                  // },
                  // 1024: {
                  //   slidesPerView: 3, // 4 slides for desktop
                  // },
                  // // 1536: {
                  // //     slidesPerView: 5, // 5 slides for desktop
                  // // },
                }}
              >
                
                {
                  webBanners.map((item, index) => {
                    return (
                      <SwiperSlide key={`banner-index[${index}]`}>
                        <img className="w-full" src={item.url} alt="heroBanar" onClick={() => handleBannerClick(item)} />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </div>
          </Container>
        </div>
      </main>
    </div>
  )
}
