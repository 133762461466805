import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orderBy, capitalize, cloneDeep, forEach } from 'lodash';
import { parseISO, format } from 'date-fns';
import { UserIcon, ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../../layouts/StackedLayout';
import EmptyData from '../../../components/EmptyData';
import { CourseCardCompact } from '../../../components/courseCards';
import { getLiveClassesForUser } from '../../../services/liveClass';
import { getUserEnrolledCourses } from '../../../services/enrolledCourse';
import { getCompetitions } from '../../../services/competition';
import { convertToBanglaNumber, gradeLabelMapping, getBanglaTime } from '../../../utils';


const sidebarNavigation = [
  { name: 'হোম', href: '/learn/home' },
  { name: 'ক্লাস রুটিন', href: '/learn/routine' },
  { name: 'পরীক্ষার রেজাল্ট', href: '/learn/results' },
  { name: 'ফি পেমেন্ট', href: '/learn/payments' },
  { name: 'ট্রানজেকশন বিবরণী', href: '/learn/transactions' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function LearnHomePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const grade = currentUser?.preference?.grade;
  const [enrolledCourses, setEnrilledCourses] = useState([]);
  const [liveClasses, setLiveClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [navigations, setNavigations] = useState(sidebarNavigation);

  useEffect(() => {
    async function _fetchData() {
      await fetchLiveClasses();

      const _enrolledCourses = await getUserEnrolledCourses();
      setEnrilledCourses(_enrolledCourses);
      setLoading(false);
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function fetchCompetitions() {
      try {
        const competitions = await getCompetitions({status: 'active'});
        if (competitions.length > 0) {
          let _navigations = cloneDeep(navigations);
          forEach(competitions, c => _navigations.push({ name: c.name, href: `/learn/competitions/${c.slug}`}));
          setNavigations(_navigations);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchCompetitions();
  }, []);

  const fetchLiveClasses = async () => {
    try {
      const params = {
        isToday: true,
        status: ['scheduled', 'open']
      };
      const _liveClasses = await getLiveClassesForUser(params);
      setLiveClasses(orderBy(_liveClasses, 'startsAt', 'asc'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeNavigation = (event, index) => {
    event.preventDefault();
    const navItem = sidebarNavigation[index];
    if (navItem?.value === 'freeCourses') {
      navigate(`/courses/programs/${grade}/free`);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      hideFooter={true}
      page="learn"
      sidebarNavigation={navigations}
      currentSidebarNavigation="হোম"
      onNavigationChange={handleChangeNavigation}
    >
      <div className="space-y-8">
        {<LiveClassSection liveClasses={liveClasses.slice(0, 3)} liveClassCount={liveClasses.length} />}
        {<CourseSection courses={enrolledCourses} courseCount={enrolledCourses.length} />}
      </div>
      
    </StackedLayout>
  )
};

function CourseSection(props) {
  const { courses, courseCount } = props;

  return (
    <div>
      <div className="border-b border-gray-200 pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap justify-between">
          <div className="flex mt-2 justify-start items-center">
            <h3 className="ml-2 text-base font-semibold leading-6 text-gray-900">ভর্তিকৃত কোর্সসমূহ</h3>
            {courseCount > 0 &&
            <p className="ml-2 truncate text-sm text-gray-500">{`(${convertToBanglaNumber(courseCount)}টি কোর্স)`}</p>}
          </div>

          <div className="mt-2">
            <a
              href="/learn/courses"
              className="px-3 py-2 text-sm leading-6 text-green-600"
            >
              সকল কোর্স দেখুন <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      {courseCount === 0 &&
      <EmptyData
        title="আপনি এখনো কোন কোর্সে ভর্তি হননি"
        action="সকল কোর্স দেখুন"
        actionType="view"
        onView={() => window.location.href = `/learn/courses`}
      />}

      {courseCount > 0 &&
      <div className="mt-6 lg:col-span-2 xl:col-span-3">
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {courses?.map((course, index) => (
            <CourseCardCompact key={`my-course-card-index[${index}]`} course={course.course} />
          ))}
        </div>
      </div>}
    </div>
  )
};

function LiveClassSection(props) {
  const { liveClasses, liveClassCount } = props;

  const onJoinClass = (event, liveClass) => {
    event.preventDefault();
    if (liveClass.status !== 'open') { return; }
    if (liveClass.liveClassPlatform === 'zoom' && liveClass.zoomMeetingLink) {
      window.open(liveClass.zoomMeetingLink);
    } else if (liveClass.liveClassPlatform === 'facebook' && liveClass.facebookGroup) {
      window.open(liveClass.facebookGroup);
    }
  };

  return (
    <div>
      <div className="border-b border-gray-200 pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap justify-between">
          <div className="flex mt-2 justify-start items-center">
            <h3 className="ml-2 text-base font-semibold leading-6 text-gray-900">আজকের ক্লাস ও পরীক্ষা</h3>
            {liveClassCount > 0 &&
            <p className="ml-2 truncate text-sm text-gray-500">{`(${convertToBanglaNumber(liveClassCount)}টি ক্লাস/পরীক্ষা)`}</p>}
          </div>
          
          <div className="mt-2">
            <a
              href={`/learn/routine`}
              className="px-3 py-2 text-sm leading-6 text-green-600"
            >
              প্রতিদিনের রুটিন দেখুন <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      {liveClassCount === 0 &&
      <EmptyData
        title="আজকে কোন ক্লাস বা পরীক্ষা নেই"
        action="প্রতিদিনের রুটিন দেখুন"
        actionType="view"
        onView={() => window.location.href = `/learn/routine`}
      />}

      {liveClassCount > 0 &&
      <ul role="list" className="mt-6 grid grid-cols-1 sm:grid-cols-2 mx-2 gap-6 overflow-hidden">
        {liveClasses.map((liveClass, index) => (
          <li
            key={`event-index[${index}]`}  
            className="m-1 col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow cursor-pointer"
            onClick={(event) => onJoinClass(event, liveClass)}
          >
            <div className="flex space-x-6 py-2 sm:py-6 sm:mx-2 xl:static items-center">
              <div className="hidden sm:block">
                {liveClass?.instructor?.photo ?
                <img src={liveClass?.instructor?.photo} className="h-14 w-14 flex-none rounded-full" />
                :
                <UserIcon className="h-14 w-14 flex-none rounded-full text-gray-300" />
                }
              </div>

              <div className="flex-auto">
                <p className="text-purple-700">
                  {liveClass?.isFree ? 
                  <span className="text-indigo-600">ফ্রি ক্লাস | {gradeLabelMapping[liveClass?.grade]}</span> 
                  :
                  <span>{liveClass?.batch?.name || 'সকল ব্যাচ'} | {liveClass?.course?.name}</span>
                  }
                </p>
                <p className="mt-2 text-gray-900 xl:pr-0">
                  {`${liveClass?.subject?.name || 'টপিক'}: ${liveClass?.name}`}
                </p>
                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                  <div className={classNames(
                    'flex items-center space-x-1',
                    liveClass.status === 'scheduled' && 'text-orange-400',
                    liveClass.status === 'open' && 'text-green-600',
                  )}>
                    <dt className="mt-0.5">
                      <span className="sr-only">Date</span>
                      <ClockIcon className="h-5 w-5" aria-hidden="true" />
                    </dt>
                    <dd>
                      {liveClass.status === 'open' && <span>ক্লাস চলছে</span>}
                      {liveClass.status === 'finished' && <span>ক্লাস শেষ</span>}
                      {liveClass.status === 'scheduled' &&
                      <time dateTime={format(parseISO(liveClass.startsAt), 'yyyy-MM-dd')}>
                        {getBanglaTime(liveClass.startsAt)}
                      </time>}
                    </dd>
                  </div>
                  <div className="mt-2 flex items-center space-x-1 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt className="mt-0.5">
                      <span className="sr-only">Live Plaform</span>
                      <MapPinIcon className="h-5 w-5" aria-hidden="true" />
                    </dt>
                    <dd>{liveClass.courseType === 'offline' ? `${liveClass.branch.nameBN || liveClass.branch.name}` : capitalize(liveClass?.liveClassPlatform)}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </li>
        ))}
      </ul>}
    </div>
  )
};
