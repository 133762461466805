import {
  CheckCircleIcon,
  XCircleIcon,
  CircleStackIcon,
  ClockIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';
import { floor, round } from 'lodash';

import { convertToBanglaNumber } from '../../../../utils';


export default function ResultPageHeader(props) {
  const { exam, takenExam } = props;
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
          {exam?.name}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CircleStackIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            প্রাপ্ত নম্বর: {convertToBanglaNumber(takenExam.totalMarksObtained)}/{convertToBanglaNumber(takenExam?.totalMarks || (takenExam.totalMcqQuestions * takenExam.marksPerMcqQuestion))}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
              <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <span>সময় নিয়েছেন:</span>&nbsp;
              {takenExam.timeTaken < 60 && <span>{convertToBanglaNumber(takenExam.timeTaken)} সেকেন্ড</span>}
              {takenExam.timeTaken >= 60 && 
              <span>
                {convertToBanglaNumber(getTimeBreakdown(takenExam.timeTaken).minutes)} মিনিট{' '}
                {convertToBanglaNumber(getTimeBreakdown(takenExam.timeTaken).seconds)} সেকেন্ড
              </span>}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <QuestionMarkCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            সর্বমোট প্রশ্ন: {convertToBanglaNumber(exam?.totalQuestions)}
          </div>
          
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            সঠিক উত্তর: {convertToBanglaNumber(takenExam.mcqCorrectAnswers)}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            ভুল উত্তর: {convertToBanglaNumber(takenExam.mcqWrongAnswers)}
          </div>
        </div>
      </div>

      {/* <div className="mt-5 flex lg:ml-4 lg:mt-0 items-center">
        
        <span className="ml-3">
          <button
            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            // onClick={onEndExam}
          >
            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            সমাধান দেখুন
          </button>
        </span>
      </div> */}
    </div>
  )
};

function getTimeBreakdown(totalSeconds) {
  const minutes = floor(totalSeconds/60);
  const seconds = totalSeconds - (minutes * 60);
  return {minutes, seconds};
};
