import http from '../helpers/http';


export const getCreativeExam = async (id) => {
  try {
    const { data } = await http.get(`/api/creative-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCreativeExams = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/creative-exams`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createCreativeExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/creative-exams`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateCreativeExam = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/creative-exams/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteCreativeExam = async (id) => {
  try {
    const { data } = await http.delete(`/api/creative-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeCreativeExamStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/creative-exams/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const addCreativeExamQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/creative-exams/${id}/add-question`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const removeCreativeExamQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/creative-exams/${id}/remove-question`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
