import { sortBy, filter, includes, isEmpty, forEach } from 'lodash';
import { allDistict } from '@bangladeshi/bangladesh-address';

import SelectAsync from './SelectAsync';


export default function SelectDistrict(props) {
  const { col, label, placeholder, name, value, onChange } = props;
  const districts = sortBy(allDistict());

  const onSearch = async (queryString) => {
    if (isEmpty(queryString)) { return; }
    queryString = queryString.toLowerCase();
    const _districts = filter(districts, d => includes(d.toLowerCase(), queryString));
    let filteredDistricts = [];
    forEach(_districts, d => filteredDistricts.push({label: d, value: d}));
    return filteredDistricts;
  };

  return (
    <SelectAsync
      col={col}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
    />
  )
};
