import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {EnvelopeIcon} from '@heroicons/react/24/solid';
import { round } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { SubmitButton } from '../../components/buttons';
import { getInstituteSms } from '../../services/instituteSms';


export default function SmsSettingsPage() {
  const [instituteSms, setInstituteSms] = useState(null);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _instituteSms = await getInstituteSms();
        setInstituteSms(_instituteSms);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, []);

  return (
    <StackedLayout>
      <div className="px-4 py-10 sm:px-6 lg:px-8">
        <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
          <div className="flex items-center gap-x-6">
            <div className="flex mx-auto h-16 w-16 rounded-full items-center justify-center ring-1 ring-green-100">
              <EnvelopeIcon className="h-10 w-10 text-green-600" />
            </div>
            <h1>
              <div className="text-sm leading-6 text-gray-500">
                SMS balance
              </div>
              <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                BDT {round(instituteSms?.smsBalance, 2)}
              </div>
            </h1>
          </div>
          <div className="flex items-center gap-x-4 sm:gap-x-6">
            <SubmitButton
              label="Add balance"
              onClick={() => {
                window.location.href = `/sms/recharge`
              }}
            />
          </div>
        </div>
      </div>

      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">SMS Charges</h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Non-masking SMS rate</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">BDT {instituteSms?.smsCharges?.nonMasking}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Masking SMS rate</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">BDT {instituteSms?.smsCharges?.masking}</div>
            </dd>
          </div>
        </dl>
      </div>

      <div className="py-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">SMS templates</h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">OTP sms template</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">...</div>
            </dd>
          </div>
        </dl>
      </div>

    </StackedLayout>
  )
};
