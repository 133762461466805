import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, UploadWithDragDrop } from '../../components/forms/fields';
import { uploadPublicImage } from '../../services/upload';
import { getNormalizedDateTime } from '../../utils';
import { getCompetition, createCompetition, updateCompetition } from '../../services/competition';


export default function CompetitionEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const competitionId = params.id;
  const [competition, setCompetition] = useState(null);
  const [name, setName] = useState(null);
  const [menuName, setMenuName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [startsAt, setStartsAt] = useState(null);
  const [endsAt, setEndsAt] = useState(null);
  // const [currentRound, setCurrentRound] = useState(null);
  const [logo, setLogo] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (competitionId) {
          const _competition = await getCompetition(competitionId);
          setCompetition(_competition);
          setName(_competition.name);
          setMenuName(_competition.menuName);
          setSlug(_competition.slug);
          // setCurrentRound(_competition.currentRound);
          setStartsAt(_competition.startsAt);
          setEndsAt(_competition.endsAt);
          setLogo(_competition.logo);
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleLogoUpload = async (files) => {
    try {
      const data = new FormData();
      if (files.length > 0) {
        data.append('image', files[0]);
        const responseData = await uploadPublicImage(data);
        setLogo(responseData.imageUrl);
        return responseData.imageUrl
      }
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const saveCompetition = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        name,
        menuName,
        slug,
        startsAt: moment(startsAt).zone('+06:00').format(),
        endsAt: moment(endsAt).zone('+06:00').format(),
        logo
      };

      if (competitionId) {
        await updateCompetition(competitionId, payload);
        toast.success(`Competition updated successfully.`);
      } else {
        await createCompetition(payload);
        toast.success(`Competition created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !slug || !menuName || !startsAt;
  };

  const goBack = () => {
    navigate(`/competitions`);
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {competition?.name || 'Create new competition'}
          </h1>
          {competition &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this competition.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button action="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        disabled={isDisabled()}
        submitText={competition ? 'Save' : 'Create'}
        onSubmit={saveCompetition}
      >
        <FieldSet
          title="Basic Information"
        >
          <Input col={4} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input col={2} label="Menu name" value={menuName} onChange={(event) => setMenuName(event.target.value)} />
          <Input
            col={4}
            label="Slug"
            value={slug}
            onChange={(event) => setSlug(event.target.value)}
            helpText={`Use all lowercases and join words with '-'. ex: scholarship-program-2024`}
          />
          <span></span>
          <Input
            col={2}
            type="datetime-local"
            label="Start time"
            value={startsAt ? getNormalizedDateTime(startsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setStartsAt(event.target.value)}
          />
          <Input
            col={2}
            type="datetime-local"
            label="End time"
            value={endsAt ? getNormalizedDateTime(endsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setEndsAt(event.target.value)}
          />
          <UploadWithDragDrop
            label="Logo" 
            actionName="Upload a logo" 
            content={logo}
            onUpload={(files) => handleLogoUpload(files)}
          />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};
