export const formats = [
  'bold', 'italic', 'header',
  'underline', 'list', 'bullet',
  'font', 'link', 'image', 'formula',
  'script', 'align'];

export const mathQuillOperators = [
  ['\\pm', '\\plusminus'],
  ['\\leq', '\\leq'],
  ['\\geq', '\\geq'],
  ['\\neq', '\\neq'],
  ['\\sim', '\\sim'],
  ['\\sqrt[n]{x}', '\\nthroot'],
  ['\\frac{x}{y}','\\frac'],
  ['\\int_{x}^{y}', '\\integral'],
  ['\\sum', '\\sum'],
  ['\\prod', '\\prod'],
  ['\\lim', '\\lim'],
  ['\\log', '\\log'],
  ['\\log_{a}', '\\log_{a}'],
  ['\\binom{x}{y}', '\\binom'],
  ['\\cap', '\\cap'],
  ['\\cup', '\\cup'],
  ['\\rightarrow', '\\rightarrow'],
  ['\\leftarrow', '\\leftarrow'],
  ['\\longleftarrow', '\\longleftarrow'],
  ['\\longrightarrow', '\\longrightarrow'],
  ['\\longleftrightarrow', '\\longleftrightarrow'],
  ['\\underrightarrow{abc}', '\\underrightarrow{abc}'],
  ['\\overrightarrow{abc}', '\\overrightarrow'],
  ['\\vec{A}', '\\vec'],
  ['\\pi', '\\pi'],
  ['\\Theta', '\\theta'],
  ['\\Delta', '\\delta'],
  ['\\epsilon', '\\epsilon'],
  ['\\alpha', '\\alpha'],
  ['\\beta', '\\beta'],
  ['\\gamma', '\\gamma'],
  ['\\mu', '\\mu'],
  ['\\nu', '\\nu'],
  ['\\phi', '\\phi'],
  ['\\Phi', '\\Phi'],
  ['\\omega', '\\omega'],
  ['\\Omega', '\\Omega'],
  ['\\delta', '\\delta'],
  ['\\psi', '\\psi'],
  ['\\rho', '\\rho'],
  ['\\sigma', '\\sigma'],
  ['\\eta', '\\eta'],
  ['\\tau', '\\tau'],
  ['\\times', '\\times'],
  ['\\displaystyle\\lim_{n\\rightarrow\\infty}', '\\lim_{n\\rightarrow\\infty}'],
];
