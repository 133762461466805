import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PhoneIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../../layouts/StackedLayout';
import { getBranches } from '../../../services/branch';
import { convertToBanglaNumber } from '../../../utils';


export default function BranchListPage() {
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const _branches = await getBranches({branchType: ['principalBranch', 'branch'], status: 'active' });
      setBranches(_branches);

    };
    fetchData();
    setLoading(false);
  }, []);

  return (
    <StackedLayout
      loading={loading}
      allowAnonymous={true}
      hideFooter={true}
    >
      <div className="bg-white py-4 sm:py-6">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-3xl font-bold text-purple-700 sm:text-4xl">
                প্রধান কার্যালয়
              </h2>
              <p className="mt-1 text-lg leading-8 text-gray-600">{currentInstitute?.addressBN || currentInstitute?.address}</p>
              <p className="flex justify-center items-center mt-1 text-lg font-semibold leading-8 text-gray-600 space-x-1">
                <PhoneIcon className="h-4 w-4" />
                <span><a href={`tel:${currentInstitute?.helpline}`}>{convertToBanglaNumber(currentInstitute?.helpline)}</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {branches?.length > 0 &&
      <div className="bg-white py-4 sm:py-6">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                সকল শাখাসমূহ
              </h2>
            </div>
            <dl className={`mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-${branches.length >= 3 ? '3' : branches.length} lg:grid-cols-${branches.length >= 4 ? '4' : branches.length}`}>
              {branches?.map((branch) => (
                <div key={branch._id} className="flex flex-col bg-gray-400/5 p-8 space-y-2">
                  <dd className="text-2xl font-semibold leading-6 text-gray-600">{branch?.bnName || branch?.name}</dd>
                  <dd className="text-gray-900">{branch?.addressBN || branch?.address}</dd>
                  <dd className="flex items-center justify-center font-semibold text-gray-900 space-x-1">
                    <PhoneIcon className="h-4 w-4" />
                    <span><a href={`tel:${branch?.mobile}`}>{convertToBanglaNumber(branch?.mobile)}</a></span>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>}
    </StackedLayout>
  )
};