import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { includes } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';
import { Button } from '../../../components/buttons';
import Form from '../../../components/forms/FormLayout';
import FieldSet from '../../../components/forms/fieldSets/TwoColumnFieldSet';
import { Input, Select } from '../../../components/forms/fields';
import { getBranches } from '../../../services/branch';
import { getPrograms } from '../../../services/program';
import { getCoursesForBranch } from '../../../services/course';
import { getBatch, createBatch, updateBatch } from '../../../services/batch';
import { getNormalizedDateTime } from '../../../utils';


export default function OfflineBatchEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const batchId = params.batchId;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [branches, setBranches] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batch, setBatch] = useState(null);
  const [branch, setBranch] = useState(currentUser?.branch?._id);
  const [program, setProgram] = useState(null);
  const [course, setCourse] = useState(null);
  const [name, setName] = useState(null);
  const [batchTime, setBatchTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [capacity, setCapacity] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (batchId) {
          const _batch = await getBatch(batchId);
          setBatch(_batch);
          setBranch(_batch?.branch?._id);
          setProgram(_batch?.program?._id);
          setCourse(_batch?.course);
          setName(_batch.name);
          setBatchTime(_batch.batchTime);
          setStartDate(_batch.startDate);
          setCapacity(_batch.capacity);
          setStartMonth(_batch.startMonth || '');
          setEndMonth(_batch.endMonth || '');
        }
        await fetchPrograms();

        if (includes(['owner', 'admin'], currentUser.role)) {
          await fetchBranches();
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchCourses() {
      await fetchCourses();
    };
    _fetchCourses();
  }, [program, branch]);

  const fetchBranches = async () => {
    try {
      const _branches = await getBranches({ status: 'active' });
      setBranches(_branches);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchPrograms = async () => {
    try {
      const _programs = await getPrograms({ status: 'active' });
      setPrograms(_programs);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchCourses = async () => {
    try {
      if (!program || !branch) {
        setCourses([]);
        return;
      }
      const params = {program: program, status: 'active' };
      const _courses = await getCoursesForBranch(branch, params);
      setCourses(_courses);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveBatch = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        branch,
        program,
        course: course._id,
        batchTime, 
        startDate, 
        capacity,
        startMonth,
        endMonth
      };
      
      if (batchId) {
        await updateBatch(batchId, payload);
        toast.success(`Batch updated successfully.`);
      } else {
        if (currentUser.branch) {
          payload.branch = currentUser.branch._id;
        }
        await createBatch(payload);
        toast.success(`Batch created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !branch || !program || !course || (course?.program?.paymentCircle === 'month' && (!startMonth || !endMonth));
  };

  const goBack = () => {
    navigate(`/branch/batches`);
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {batch?.name || 'Create new batch'}
          </h1>
          {batch &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this batch.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={batch ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveBatch}
        onCancel={goBack}
      >
        <FieldSet
          title="Select course"
          subTitle="Set brach, program and course for  this batch."
        >
          <Select col={2} label="Program" value={program} options={programs} onChange={(event) => setProgram(event.target.value)} />
          {!currentUser?.branch && <Select col={2} label="Branch" value={branch} options={branches} onChange={(event) => setBranch(event.target.value)} />}
          <Select
            disabled={!program || !branch} label="Course" value={course?._id} options={courses} onChange={(event) => setCourse(courses[event.target.selectedIndex - 1])} />
        </FieldSet>

        <FieldSet
          title="Batch info"
          subTitle="Give a batch name."
        >
          <Input col={3} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          {/* <Input col={3} label="Name (in Bangla)" value={bnName} onChange={(event) => setBnName(event.target.value)} /> */}
          <Input label="Batch time" value={batchTime} onChange={(event) => setBatchTime(event.target.value)} />
          <Input col={1} type="number" label="Capacity" value={capacity} onChange={(event) => setCapacity(event.target.value)} />
          <Input col={2} type="date" label="Start date" value={getNormalizedDateTime(startDate, 'YYYY-MM-DD')} onChange={(event) => setStartDate(event.target.value)} />
        </FieldSet>

        {course?.program?.paymentCircle === 'month' &&
        <FieldSet
          title="Billing info"
          subTitle="Set billing start and end months."
        >
          <Input disabled={batchId} col={2} type="month" label="Start month" value={startMonth} onChange={(event) => setStartMonth(event.target.value)} />
          <Input disabled={batchId} col={2} type="month" label="End month" value={endMonth} onChange={(event) => setEndMonth(event.target.value)} />
        </FieldSet>}
      </Form>
    </StackedLayout>
  )
};
