import toast from 'react-hot-toast';
import { forEach } from 'lodash';

import { SelectAsync } from './forms/fields';
import { searchLessons } from '../services/lesson';


export default function LessonInputWithList(props) {
  let {
    col,
    lessons,
    onSelect,
    onRemove
  } = props;
  col= col ? col?.toString() : 'full';

  const getLabel = (lesson) => {
    let label = lesson.title;

    if (lesson?.subject) {
      label = `${label} - ${lesson?.subject?.name}`;
    }

    if (lesson?.grade) {
      label = `${label} - ${lesson?.grade}`;
    }

    return label;
  };

  const searchLesson = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let excludeLessons = [];
        forEach(lessons, lesson => excludeLessons.push(lesson._id));
        let params = { search: queryString };
        if (excludeLessons.length > 0) {
          params.excludeLessons = excludeLessons;
        }
        const _lessons = await searchLessons(params);
        let lessonOptions = [];
        forEach(_lessons, lesson => {
          lessonOptions.push({
            label: getLabel(lesson),
            value: lesson
          });
        });
        return lessonOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSecondaryLabel = (lesson) => {
    let secLabel = lesson?.subject?.name || '';

    if (lesson?.grade) {
      secLabel = `${secLabel} | ${lesson.grade}`;
    }
    return secLabel;
  };

  return (
    <>
      <SelectAsync
        col={col}
        placeholder="Search by name..."
        onSearch={searchLesson}
        onChange={onSelect}
      />

      {lessons.length > 0 &&
      <div className={`col-span-${col}`}>
        <ul className="divide-y divide-gray-100">
          {lessons.map((lesson, index) => (
            <li key={`lesson-index[${index}]`} className="flex sm:span-col-full items-center justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{lesson?.title}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">Status: {lesson.status}  | Duration: {lesson.formattedDuration}</p>
                </div>
              </div>
              <button
                className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                onClick={(event) => onRemove(event, index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>}
    </>
  )
};
