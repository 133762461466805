import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { capitalize, includes } from 'lodash';
import { CheckIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import { SubmitButton, Button } from '../../components/buttons';
import { getInvoicePdf } from '../../services/invoice';


const headers = ['Course', 'Batch', 'Course type', 'Branch', 'RollNo', 'Status', 'Remarks'];
export default function StudentAdmissionSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const enrolledCourses = location.state.enrolledCourses;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;

  useEffect(() => {
    if (!enrolledCourses) { return navigate(-1); }
  }, []);

  const handlePrintInvoice = async (invoiceId) => {
    try {
      const pdfUrl = await getInvoicePdf(invoiceId);

      if (pdfUrl?.publicUrl) {
        window.open(pdfUrl?.publicUrl);
      } else {
        toast.error('The invoice is being ready, please wait.')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout>
      <div className="flex flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Student successfully enrolled to {enrolledCourses.success} courses(s).
            </h3>
          </div>
        </div>

        {enrolledCourses.admissionFeeInvoice &&
        <div className="mt-5 sm:mt-6 space-x-4">
          <Button
            label="Download admission fee invoice"
            onClick={() => handlePrintInvoice(enrolledCourses.admissionFeeInvoice)}
          />
        </div>}

        <div className="mt-5 sm:mt-6">
          {/* <h2 className="text-xl text-center">Courses</h2> */}

          <Table
            itemsCount={enrolledCourses.courses.length}
            headers={headers}
          >
            {enrolledCourses.courses.map((course) => (
              <tr key={course.courseId} className="text-center">
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
                  {course.courseDetails.name}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">{course?.batchDetails?.name || '-'}</td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {course?.courseDetails?.courseType === 'exam' ? 'Online exam' : capitalize(course?.courseDetails?.courseType)}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {course?.branchDetails?.name || '-'}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {course?.rollNo || '-'}
                </td>
                <td className={`pr-3 py-4 text-sm ${course.status === 'failed' ? 'text-red-500' : 'text-green-600'}`}>
                  {capitalize(course?.status)}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">
                  {course?.status === 'failed' ? course.message : '-'}
                </td>
              </tr>
            ))}
          </Table>
        </div>

        <div className="mt-5 sm:mt-6 space-x-4">
          {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) || currentUser?.permissions?.canManageStudentPayments) &&
          <SubmitButton
            label="Collect course payments"
            onClick={() => navigate(`/student-payments/collect`, {
              state: {
                registrationNo: enrolledCourses.studentData.registrationNo,
                programId: enrolledCourses.programId
              }
            })}
          />}
          <Button
            label="Go back"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
    </StackedLayout>
  )
};