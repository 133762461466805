import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { orderBy, isEmpty } from 'lodash';

import { Input, Toggle, Select } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { getBatches } from '../../../services/batch';
import { getNormalizedDateTime } from '../../../utils';


export default function ChangeBatchForm(props) {
  const {
    enrolledCourse,
    onSave,
    onClose
  } = props;
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchBatches() {
      try {
        let params = {
          course: enrolledCourse.course._id,
          status: 'active'
        }
        if (enrolledCourse.branch) {
          params.branch = enrolledCourse.branch._id;
        }
        const _batches = await getBatches(params);
        setBatches(orderBy(_batches, 'name'));
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchBatches();
  }, []);

  const handleSaveCourse = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        newBatchId: selectedBatch._id
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !selectedBatch || selectedBatch?._id?.toString() === enrolledCourse?.batch?._id?.toString();
  };

  return (
    <Sidepanel
      title="Change batch"
      subTitle={`Course: ${enrolledCourse?.course?.name}`}
      onSave={handleSaveCourse}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="Branch"
      >
        <p>{enrolledCourse?.branch?.name}</p>
      </SidepanelField>

      <SidepanelField
        label="Current batch"
      >
        <p>{enrolledCourse?.batch?.name}</p>
        {!isEmpty(enrolledCourse.batch.endMonth) && <p className="text-xs text-gray-500">Last billing month: {getNormalizedDateTime(enrolledCourse.batch.endMonth, 'MMMM YYYY')}</p>}
      </SidepanelField>

      <SidepanelField
        label="New batch"
      >
        <Select
          value={selectedBatch?._id}
          options={batches}
          helpText={`${!isEmpty(selectedBatch?.endMonth) ? `Last billing month: ${getNormalizedDateTime(selectedBatch?.endMonth, 'MMMM YYYY')}` : ''}`}
          onChange={(event) => setSelectedBatch(batches[event.target.selectedIndex - 1])}
        />
        
      </SidepanelField>
    </Sidepanel>
  );
};
