import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, forEach, includes, orderBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import TextEditor from '../../components/TextEditor';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import {
  Input,
  Select, 
  TextArea,
  UploadWithDragDrop, 
  MultiSelect,
  InlineCheckbox,
} from '../../components/forms/fields';
import { PlusButton } from '../../components/buttons';
import InstructorInputWithList from '../../components/InstructorInputWithList';
import Badge from '../../components/Badge';
import ReasonField from './components/CourseReasonField';
import { getSubjects } from '../../services/subject';
import { handleUploadPublicImage } from '../../utils';
import { getPrograms } from '../../services/program';
import { getCourse, updateCourse, createCourse } from '../../services/course';
import ContentList from '../contents/components/ContentList';


const courseTypes = [
  {name: 'Recorded', value: 'recorded'},
  {name: 'Live', value: 'live'},
  {name: 'Model Test', value: 'exam'},
];

const grades = [
  {name: 'Class 1-5', value: 'primary'},
  {name: 'Class 6-8', value: 'juniorSecondary'},
  {name: 'SSC', value: 'ssc'},
  {name: 'HSC', value: 'hsc'},
  {name: 'Univeristy admission', value: 'admission'},
  {name: 'Cadet admission', value: 'cadetAdmission'},
  {name: 'Job preparation', value: 'job'},
  {name: 'Skill development', value: 'skill'},
];

const groups = [
  {name: 'All', value: 'all'},
  {name: 'Science', value: 'science'},
  {name: 'Business Studies', value: 'commerce'},
  {name: 'Humanities', value: 'humanities'}
];

export default function CourseEditPage(props) {
  const params = useParams();
  const navigate = useNavigate();
  const courseId = params.courseId;
  const [course, setCourse] = useState(null);
  const [name, setName] = useState(null);
  const [courseType, setCourseType] = useState(props.courseType);
  const [grade, setGrade] = useState(null);
  const [group, setGroup] = useState(null);
  const [program, setProgram] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [duration, setDuration] = useState(0);
  const [introVideoId, setIntroVideoId] = useState(null);
  const [reasons, setReasons] = useState(['']);
  const [description, setDescription] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [lectureCount, setLectureCount] = useState(0);
  const [liveCount, setLiveCount] = useState(0);
  const [recordCount, setRecordCount] = useState(0);
  const [examCount, setExamCount] = useState(0);
  const [noteCount, setNoteCount] = useState(0);
  const [bookCount, setBookCount] = useState(0);
  const [contents, setContents] = useState([]);
  const [books, setBooks] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [selectedBookIndex, setSelectedBookIndex] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (courseId) {
          const _course = await getCourse(courseId);
          setCourse(_course);
          setName(_course.name);
          setCourseType(_course.courseType);
          setGrade(_course.grade);
          setGroup(_course.group);
          setProgram(_course.program);
          setDuration(_course.duration);
          setIntroVideoId(_course.introVideoId);
          setReasons(_course.reasons);
          setDescription(_course.description);
          setThumbnail(_course.thumbnail);
          setInstructors(_course.instructors);
          setLectureCount(_course.lectureCount);
          setLiveCount(_course.liveCount);
          setRecordCount(_course.recordCount);
          setExamCount(_course.examCount);
          setNoteCount(_course.noteCount);
          setBookCount(_course.setBookCount);
          setContents(_course.contents);
          setBooks(_course.books);
          setIsFeatured(_course.isFeatured);
          setIsPublic(_course.isPublic);

          if (_course?.subjects) {
            let _subjects = [];
            forEach(_course.subjects, subject => {
              _subjects.push({ label: subject.name, value: subject._id});
            });
            setSubjects(_subjects);
          }
        }
        const _programs = await getPrograms({ status: 'active' });
        setPrograms(_programs);
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await formatSubjectOptions();
    };
    _fetchData();
  }, [grade, group, program]);

  const formatSubjectOptions = async () => {
    try {
      if (!grade) {
        setSubjectOptions([]);
        return;
      }
      // const _grade = program?.grade;
      const _group = group;
      let params = { grade: grade, status: 'active' };
      if (!includes(['job', 'skill'], grade)) {
        params.group = _group === 'all' ? _group : ['all', _group];
      }
      const _subjects = await getSubjects(params);
      let _subjectOptions = [];
      forEach(_subjects, s => _subjectOptions.push({label: s.name, value: s._id}));
      setSubjectOptions(orderBy(_subjectOptions, 'label')); 
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addReason = (event) => {
    event.preventDefault();
    const _reasons = cloneDeep(reasons);
    _reasons.push('');
    setReasons(() => _reasons);
  };

  const deleteReason = (event, index) => {
    event.preventDefault();
    const _reasons = cloneDeep(reasons);
    _reasons.splice(index, 1);
    setReasons(() => _reasons);
  };

  const handleReasonFieldChange = (event, index) => {
    event.preventDefault();
    const _reasons = cloneDeep(reasons);
    _reasons[index] = event.target.value;
    setReasons(_reasons);
  };

  const addContent = (event, content, contentType) => {
    event.preventDefault();
    const _contents = cloneDeep(contents);
    const newContent = { contentType: contentType, [contentType]: content };
    _contents.push(newContent);
    setContents(() => _contents);
  };

  const removeContent = (event, index) => {
    event.preventDefault();
    const _contents = cloneDeep(contents);
    _contents.splice(index, 1);
    setContents(() => _contents);
  };

  const saveCourse = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        grade, 
        group, 
        duration,
        description,
        introVideoId,
        courseType,
        thumbnail,
        reasons,
        lectureCount,
        liveCount,
        recordCount,
        examCount,
        noteCount,
        bookCount,
        contents,
        books,
        isFeatured,
        isPublic,
        program: program._id
      };

      if (includes(['job', 'skill'], grade)) {
        payload.group = null;
      }
      
      let instructorIds = [];
      forEach(instructors, instructor => instructorIds.push(instructor._id));
      payload.instructors = instructorIds;

      let _subjects = [];
      forEach(subjects, s => _subjects.push(s.value));
      payload.subjects = _subjects;
      
      if (courseId) {
        await updateCourse(courseId, payload);
        toast.success(`Course updated successfully.`);
      } else {
        await createCourse(payload);
        toast.success(`Course created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleThumbnailUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setThumbnail(imageUrl);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleIntructorSelect = (item) => {
    let _instructors = cloneDeep(instructors);
    _instructors.push(item.value);
    setInstructors(_instructors);
  };

  const removeInstructor = (event, index) => {
    event.preventDefault();
    let _instructors = cloneDeep(instructors);
    _instructors.splice(index, 1);
    setInstructors(_instructors);
  };

  const removeSubject = (event, index) => {
    event.preventDefault();
    let _subjects = cloneDeep(subjects);
    _subjects.splice(index, 1);
    setSubjects(_subjects);
  };

  const handleAddBookCover = (event) => {
    event.preventDefault();
    let _books = cloneDeep(books);
    _books.push({});
    setBooks(_books);
    setSelectedBookIndex(_books.length - 1);
  };

  const handleBookUpload = async (files, index) => {
    try {
      index = index || selectedBookIndex;
      const imageUrl = await handleUploadPublicImage(files);
      let _books = cloneDeep(books);
      _books[index].image = imageUrl;
      setBooks(_books);
      setSelectedBookIndex(null);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleCancelBookEdit = () => {
    if (!books[selectedBookIndex]?.image) {
      let _books = cloneDeep(books);
      const lastIndex = books.length - 1;
      _books.splice(lastIndex, 1);
      setBooks(_books);
    }
    setSelectedBookIndex(null);
  };

  const removeBookCover = async (event, index) => {
    let _books = cloneDeep(books);
    _books.splice(index, 1);
    setBooks(_books);
  };

  const isDisabled = () => {
    return updating || !name || !courseType || !program || !grade;
  };

  const goBack = () => {
    if (courseType === 'offline') {
      navigate(`/courses/offline`);
    } else {
      navigate(`/courses/online`);
    }
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {course?.name || 'Create new course'}
          </h1>
          {course &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this course.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={course ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveCourse}
        onCancel={goBack}
      >
        <FieldSet
          title="Course info"
          subTitle="Give a course name and set other basic information about this course."
        >
          <Input col={4} label="Name" name="name" value={name} onChange={(event) => setName(event.target.value)} />
          <span></span><span></span>

          {courseType !== 'offline' && <Select col={2} label="Course type" value={courseType} options={courseTypes} onChange={(event) => setCourseType(event.target.value)} />}
          <Select col={2} label="Program" value={program?._id} options={programs} onChange={(event) => setProgram(programs[event.target.selectedIndex-1])} />
          <Select col={2} label="Grade" value={grade} options={grades} onChange={(event) => setGrade(event.target.value)}/>
          <Select col={2}
            disabled={includes(['job', 'skill'], grade)}
            label="Group" value={group} options={groups}
            onChange={(event) => setGroup(event.target.value)}
          />
          
          <Input type="number" col={2} label="Duration (months)" name="duration" value={duration} onChange={(event) => setDuration(event.target.value)} />

          <MultiSelect
            col={4}
            label="Subjects"
            name="subjects"
            options={subjectOptions}
            value={subjects}
            controlShouldRenderValue={false}
            onChange={(values) => setSubjects(values)}
          />
          <div className="block-inline -mt-4 col-span-full space-x-4 space-y-2">
          {subjects.length > 0 && subjects.map((_subject, index) => (
            <Badge
              key={`subject-badge-index[${index}]`}
              label={_subject.label}
              index={index}
              showRemove={true}
              onRemove={removeSubject}
            />
          ))}
          </div>
        </FieldSet>
        <FieldSet
          title="Website configurations"
          subTitle="Configure to show/hide in website"
        >
          <InlineCheckbox
            label="Is featured?"
            description="Make this course featured to show in home page."
            checked={isFeatured}
            onChange={() => setIsFeatured(!cloneDeep(isFeatured))}
          />

          <InlineCheckbox
            label="Display in website?"
            description="You can show/hide this course in your website by enabling this."
            checked={isPublic}
            onChange={() => setIsPublic(!cloneDeep(isPublic))}
          />
        </FieldSet>

        <FieldSet
          title="Course contents"
          subTitle="Set counts for live classes, recorded classes, online exams and digital notes."
        >
          {courseType !== 'offline' && <Input col={1} type="number" label="Lives" value={liveCount} onChange={(event) => setLiveCount(event.target.value)} />}
          {courseType !== 'offline' && <Input col={1} type="number" label="Records" value={recordCount} onChange={(event) => setRecordCount(event.target.value)} />}
          {courseType === 'offline' && <Input col={1} type="number" label="Lectures" value={lectureCount} onChange={(event) => setLectureCount(event.target.value)} />}
          <Input col={1} type="number" label="Exams" value={examCount} onChange={(event) => setExamCount(event.target.value)} />
          <Input col={1} type="number" label="Notes" value={noteCount} onChange={(event) => setNoteCount(event.target.value)} />
          <Input col={1} type="number" label="Books" value={bookCount} onChange={(event) => setBookCount(event.target.value)} />

        </FieldSet>

        <FieldSet
          title="About course"
          subTitle="Give a course description for better undestanding of students."
        >
          {/* <Input label="Course intro video (youtube ID)" name="introVideoId" value={introVideoId} onChange={(event) => setIntroVideoId(event.target.value)}/> */}
          
          {/* <TextArea label="Description" name="description" value={description} onChange={(event) => setDescription(event.target.value)} /> */}
          <UploadWithDragDrop
            label="Thumbnail" 
            actionName="Upload an image" 
            content={thumbnail}
            contentType="image"
            onUpload={(files) => handleThumbnailUpload(files)}
          />
          <div className="col-span-full">
            <TextEditor
              id="course-description-editor"
              value={description}
              onChange={(value) => setDescription(value)}
            />
          </div>
        </FieldSet>

        <FieldSet
          title="Why should enroll this course?"
          subTitle="Set up reasons why learners should enroll to your course."
        >
          {reasons?.length > 0 && reasons.map((reason, index) => (
            <ReasonField
              key={`course-reason-index[${index}]`}
              reasonsCount={reasons.length} 
              reason={reason} 
              index={index}
              onDelete={deleteReason} 
              onFieldChange={handleReasonFieldChange}
            />
          ))}
          <div className="col-span-3">
            <PlusButton label="Add reason" onClick={addReason} />
          </div>
        </FieldSet>

        <FieldSet
          title="Intructors"
          subTitle="Set instructors for this course."
        >
          <InstructorInputWithList
            col={4}
            instructors={instructors}
            onSelect={handleIntructorSelect}
            onRemove={removeInstructor}
          />
        </FieldSet>
        
        <FieldSet
          title="Course contents (sample)"
          subTitle="Set sample videos, notes and other docuemnts that can attract a learner to buy your course."
        >
          <ContentList
            contents={contents}
            contentTypes={['lesson', 'note']}
            onAdd={addContent}
            onRemove={removeContent}
          />
        </FieldSet>

        <FieldSet
          title="Book covers"
          subtitle="Upload book covers that you want to show in course details page."
        >
          {books.map((book, index) => (
            <div key={`book-index[${index}]`} className="col-span-full">
              {selectedBookIndex !== index &&
              <div className="flex col-span-full justify-between">
                <div className="flex justify-start space-x-4">
                  <img width="50" src={book.image} />
                  <p>Book cover {index+1}</p>
                </div>

                <div className="justify-end space-x-4">
                  <button
                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setSelectedBookIndex(index)}
                  >
                    Change
                  </button>
                  <button
                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                    onClick={(event) => removeBookCover(event, index)}
                  >
                    Remove
                  </button>
                </div>
              </div>}

              {selectedBookIndex === index &&
              <UploadWithDragDrop
                contentType="image"
                content={book?.image} 
                label={`Book cover ${index+1}`}
                actionName="Upload an image"
                onUpload={(files) => handleBookUpload(files, index)}
                showCancelButton={(book?.image || books.length > 1) && selectedBookIndex === index}
                onCancel={handleCancelBookEdit}
              />}
            </div>
          ))}

          {!selectedBookIndex &&
          <div>
            <PlusButton label="Add book" onClick={handleAddBookCover} />
          </div>}
        </FieldSet>
      </Form>
      
    </StackedLayout>
  )
};
