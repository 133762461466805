import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex, forEach, orderBy } from 'lodash';

import { Sidepanel, SidepanelField } from '../Sidepanel';
import { Input, Select, SelectAsync } from '../forms/fields';
import { getCourses, getCoursesForBranch } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getInstructors } from '../../services/instructor';
import { getBranches } from '../../services/branch';
import { getPrograms } from '../../services/program';


const _liveClassPlatforms = [
  { name: 'Zoom', value: 'zoom' },
  { name: 'Facebook', value: 'facebook' },
  { name: 'Google meet', value: 'meet' },
];

const days = [
  {name: 'Sat', value: 'Saturday'},
  {name: 'Sun', value: 'Sunday'},
  {name: 'Mon', value: 'Monday'},
  {name: 'Tue', value: 'Tuesday'},
  {name: 'Wed', value: 'Wednesday'},
  {name: 'Thu', value: 'Thursday'},
  {name: 'Fri', value: 'Friday'},
]

export default function RecurringLiveClassForm(props) {
  const { liveClass, onSave, onClose } = props;
  const courseType = liveClass?.courseType || props?.courseType || props?.course?.courseType;
  const userBranch = props.userBranch;
  const [branches, setBranches] = useState([]);
  const [name, setName] = useState(liveClass?.name || '');
  const [branch, setBranch] = useState(liveClass?.branch || userBranch);
  const [course, setCourse] = useState(liveClass?.course || props.course);
  const [program, setProgram] = useState(liveClass?.program);
  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState(liveClass?.batch?._id || props?.batch);
  const [instructor, setInstructor] = useState(liveClass?.instructor?._id);
  const [subject, setSubject] = useState(liveClass?.subject?._id);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [classCount, setClassCount] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [liveClassPlatform, setLiveClassPlaform] = useState(liveClass?.liveClassPlatform || props?.batch?.liveClassPlatform);
  const [zoomMeetingLink, setZoomMeetingLink] = useState(liveClass?.zoomMeetingLink || props?.batch?.zoomMeetingLink);
  const [facebookGroup, setFacebookGroup] = useState(liveClass?.facebookGroup || props?.batch?.facebookGroup);
  const [googleMeetLink, setGoogleMeetLink] = useState(liveClass?.googleMeetLink || props?.batch?.googleMeetLink);
  const [category, setCategory] = useState(!liveClass ? 'single' : liveClass?.isFree ? 'free' : liveClass?.batch ? 'single' : 'all');
  const [subjects, setSubjects] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [liveClassPlatforms, setLiveClassPlatforms] = useState(_liveClassPlatforms);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchDate() {

      const _programs = await getPrograms({status: 'active'});
      setPrograms(_programs);

      if (courseType === 'offline' && !userBranch) {
        const _branches = await getBranches({status: 'active'});
        setBranches(orderBy(_branches, 'name'));
      }

      if (courseType === 'offline') {
        let _platforms = cloneDeep(liveClassPlatforms);
        _platforms.push({ name: 'Offline/Branch', value: 'branch' });
        setLiveClassPlatforms(_platforms);
      }

      if (course) {
        await formatOptions(course);
      }
    };
    _fetchDate();
  }, []);

  useEffect(() => {
    async function _fetchCourses() {
      if (program && (courseType !== 'offline' || branch)) {
        let params = {
          status: 'active',
          program: program._id,
          courseType: courseType === 'offline' ? 'offline' : ['live']
        };
        if (courseType === 'offline') {
          const _courses = await getCoursesForBranch(branch._id, params);
          setCourses(_courses);
        } else {
          const _courses = await getCourses(params);
          setCourses(_courses);
        }
      } else {
        setCourses([]);
      }
    };
    _fetchCourses();
  }, [program, branch]);

  useEffect(() => {
    async function _formatData() {
      if (course) {
        await formatOptions(course);
      } else {
        setBatches([]);
      }
    }
    _formatData();
  }, [course, branch]);

  const formatOptions = async (_course) => {
    try {
      let params = { course: _course._id }
      if (courseType === 'offline' && !liveClass) {
        params.branch = branch?._id || userBranch?._id;
      }
      const _batches = await getBatches(params);
      setBatches(orderBy(_batches, 'name'));

      const _subjects = _course.subjects;
      setSubjects(orderBy(_subjects, 'name'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSaveEvent = async (event) => {
    try {
      setUpdating(true);
      let updatedFileds = {
        name,
        subject,
        instructor,
        liveClassPlatform,
        zoomMeetingLink,
        facebookGroup,
        googleMeetLink,
        program: program?._id,
        startTime: startTime,
        endTime: endTime,
        startDate: startDate,
        course: course._id,
        batch: batch,
        courseType: course.courseType,
        classCount: classCount,
        selectedDays: selectedDays
      };
      
      if (courseType === 'offline') {
        updatedFileds.branch = userBranch?._id || branch?._id;
      }
      await onSave(event, updatedFileds);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const searchInstructor = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let params = {
          search: queryString,
          status: 'active'
        };
        const _instructors = await getInstructors(params);
        let instructorOptions = [];
        forEach(_instructors, instructor => {
          instructorOptions.push({
            label: `${instructor.name} ${instructor.designation}`,
            value: instructor
          });
        });
        return instructorOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelectBatch = (event) => {
    event.preventDefault();
    setBatch(event.target.value);
    setLiveClassPlaform(null);
  };

  const handleSelectLiveClassPlaform = (event) => {
    event.preventDefault();
    setLiveClassPlaform(event.target.value);
    const batchIndex = findIndex(batches, b => b._id.toString() === batch.toString());
    const _batch = props?.batch || batches[batchIndex];
    setZoomMeetingLink(liveClass?.zoomMeetingLink || props?.batch?.zoomMeetingLink || _batch?.zoomMeetingLink);
    setFacebookGroup(liveClass?.facebookGroup || props?.batch?.facebookGroup || _batch?.facebookGroup);
    setGoogleMeetLink(liveClass?.googleMeetLink || props?.batch?.googleMeetLink || _batch?.googleMeetLink);
  };

  const toggleDay = (day) => {
    let _selectedDays = cloneDeep(selectedDays);
    const dayIndex = findIndex(_selectedDays, d => d === day.value);
    if (dayIndex === -1) {
      _selectedDays.push(day.value);
    } else {
      _selectedDays.splice(dayIndex, 1);
    }
    setSelectedDays(_selectedDays);
  };

  const isDaySelected = (day) => {
    const dayIndex = findIndex(selectedDays, d => d === day.value);
    return dayIndex !== -1;
  }

  const isDisabled = () => {
    return updating || !name || !course || !batch || !startTime || !endTime || !startDate || parseInt(classCount) < 1 || !liveClassPlatform;
  };

  return (
    <Sidepanel
     title={`Recurring class form`}
     subTitle="Set up infromation to schedule recurring live classes."
     updating={updating}
     disabled={isDisabled()}
     onSave={handleSaveEvent}
     onClose={onClose}
    >
      {courseType === 'offline' && !userBranch &&
      <SidepanelField label="Branch">
        {liveClass &&
        <p className="text-sm text-gray-600">{liveClass?.branch?.name}</p>}
        
        {!liveClass &&
        <Select
          value={branch?._id}
          options={branches}
          onChange={(event) => setBranch(branches[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>}

      <SidepanelField label="Program">
        {liveClass &&
        <p className="text-sm text-gray-600">{liveClass?.program?.name}</p>}
        
        {!liveClass &&
        <Select
          value={program?._id}
          options={programs}
          onChange={(event) => setProgram(programs[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>

      {category !== 'free' &&
      <SidepanelField label="Course">
        {(liveClass || props.course) &&
        <p className="text-sm text-gray-600">{liveClass?.course?.name || props?.course?.name}</p>}
        {!liveClass && !props.course &&
        <Select
          value={course?._id}
          options={courses}
          onChange={(event) => setCourse(courses[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>}

      <SidepanelField label="Batch">
        <Select
          value={batch}
          options={batches}
          onChange={handleSelectBatch}
        />
      </SidepanelField>

      <SidepanelField label="Subject">
        <Select
          disabled={!course}
          value={subject}
          options={subjects}
          onChange={(event) => setSubject(event.target.value)}
        />
      </SidepanelField>

      <SidepanelField label="Class title">
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </SidepanelField>

      {courseType !== 'offline' &&
      <SidepanelField label="Instructor">
        <SelectAsync
          placeholder="Search by name..."
          onSearch={searchInstructor}
          value={{label: instructor?.name, value: instructor}}
          onChange={(item) => setInstructor(item.value)}
        />  
      </SidepanelField>}

      <SidepanelField label="Days">
        <ul className="flex gap-x-3">
          {days.map((day, index) => (
            <li key={`day-index[${index}]`} className="flex items-center gap-x-1">
              <div className="flex h-6 items-center">
                <input
                  type="checkbox"
                  checked={isDaySelected(day)}
                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                  onChange={() => toggleDay(day)}
                />
              </div>
              <div className="text-sm leading-6">
                <label className="font-medium text-gray-900">
                  {day.name}
                </label>
              </div>
            </li>
          ))}
        </ul>  
      </SidepanelField>

      <SidepanelField label="Class schedule">
        <div className="flex justify-between space-x-2">
          <Input
            col={1}
            label="Start time"
            type="time" 
            value={startTime}
            onChange={(event) => setStartTime(event.target.value)} 
          />
          <Input
            col={1}
            label="End time"
            disabled={!startTime}
            type="time" 
            min={startTime}
            value={endTime} 
            onChange={(event) => setEndTime(event.target.value)} 
          />
        </div>
      </SidepanelField>

      <SidepanelField label="Class schedule">
        <div className="flex justify-between space-x-2">
          <Input
            col={1}
            label="Starts from"
            type="date" 
            value={startDate}
            // value={endsAt ? getNormalizedDateTime(endsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setStartDate(event.target.value)} 
          />
          <Input
            col={1}
            label="Class count"
            type="number"
            value={classCount}
            // value={endsAt ? getNormalizedDateTime(endsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setClassCount(event.target.value)} 
          />
        </div>
      </SidepanelField>

      <SidepanelField label="Live class platform">
        <Select value={liveClassPlatform} options={liveClassPlatforms} onChange={handleSelectLiveClassPlaform} />
      </SidepanelField>

      {liveClassPlatform === 'zoom' &&
      <SidepanelField label="Zoom meeting link">
        <Input value={zoomMeetingLink} onChange={(event) => setZoomMeetingLink(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'facebook' &&
      <SidepanelField label="Facebook group link">
        <Input value={facebookGroup} onChange={(event) => setFacebookGroup(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'meet' &&
      <SidepanelField label="Google meet link">
        <Input value={googleMeetLink} onChange={(event) => setGoogleMeetLink(event.target.value)} />
      </SidepanelField>}

    </Sidepanel>
  )
};
