import http from '../helpers/http';


export const getExam = async (id) => {
  try {
    const { data } = await http.get(`/api/exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getExamWithQuestions = async (id) => {
  try {
    const { data } = await http.get(`/api/exams/${id}/questions`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getExams = async (params) => {
  try {
    const config = {params};
    const { data } = await http.get(`/api/exams`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/exams`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateExam = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/exams/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteExam = async (id) => {
  try {
    const { data } = await http.delete(`/api/exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeExamStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/exams/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const addQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/exams/${id}/add-question`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};


export const removeQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/exams/${id}/remove-question`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

