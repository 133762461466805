import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import Latex from 'react-latex';
import { forEach, cloneDeep, findIndex, max, sortBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import EmptyData from '../../components/EmptyData';
import { PlusButton } from '../../components/buttons';
import QuestionTypeTabs from '../../components/ContentTabs';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import Breadcrumb from '../../components/Breadcrumb';
import ContentPicker from '../../components/ContentPicker';
import ExamQuestionView from './components/ExamQuestionView';
import { getExamWithQuestions, addQuestion, removeQuestion } from '../../services/exam';



export default function MCQExamQuestionsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const examId = params.id;
  const [subjectNames, setSubjectNames] = useState([]);
  const [exam, setExam] = useState(null);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(0);
  const [selectedSubjectName, setSelectedSubjectName] = useState(null);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [questionSetForSubject, setQuestionSetForSubject] = useState([]);
  const [questionsForType, setQuestionsForType] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState('A');
  const [newQuestionType, setNewQuestionType] = useState(null);
  const [selectedQuestion, setSelectdQuestion] = useState(null);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const [openContentPicker, setOpenContentPicker] = useState(false);
  const [openQuestionView, setOpenQuestionView] = useState(false);
  const [showRemoveModel, setShowRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function _fetchInitialData() {
      try {
        const _exam = await getExamWithQuestions(examId);
        if (!_exam) { return navigate(-1); }

        let _subjectNames = []
        forEach(_exam.subjects, subject => {
          _subjectNames.push(subject.name);
        });
        const _selectedSubjectName = _subjectNames[selectedSubjectIndex];

        setExam(_exam);
        setSubjectNames(_subjectNames);
        setSelectedSubjectName(_selectedSubjectName);

        const listUrl = _exam.folder ? `/contents/exams/mcq/folder/${_exam.folder}` : `/contents/exams/mcq`;
        setBreadcrumbPages([
          {name: 'Exams', href: listUrl, current: false},
          {name: _exam?.name, current: true},
          {name: 'Questions', current: true}
        ]);
      } catch (error) {
        navigate(-1);
      }
    }
    _fetchInitialData();
    setLoading(false);
  }, []);

  useEffect(() => {
    async function _formatData() {
      const _questionSetForSubject = exam?.subjects[selectedSubjectIndex]?.questionSet || [];
      setQuestionSetForSubject(_questionSetForSubject);

      const _types = _questionSetForSubject?.length > 0 ? sortBy(_questionSetForSubject.map(q => q.type)) : ['A'];
      setSelectedQuestionType(_types[0]);
    }
    _formatData();
  }, [
    exam,
    selectedSubjectName,
  ]);

  useEffect(() => {
    const typeIndex = findIndex(questionSetForSubject, qs => qs.type === selectedQuestionType);
    setQuestionsForType(() => questionSetForSubject[typeIndex]?.questions || []);
    formatTabs(questionSetForSubject);
  }, [
    selectedQuestionType,
    questionSetForSubject
  ])

  const formatTabs = (_questionSetForSubject) => {
    let _tabs = [];
    const _types = questionSetForSubject?.length > 0 ? sortBy(questionSetForSubject.map(q => q.type)) : ['A'];
    forEach(_types, (_type) => {
      const typeIndex = findIndex(questionSetForSubject, qs => qs.type === _type);
      const questionsCount = questionSetForSubject[typeIndex]?.questions?.length || 0;
      _tabs.push({name: _type, count: questionsCount});
    });

    setTabs(_tabs);
    setQuestionTypes(_types);
  };

  const handleSubjectSelect = (event, index) => {
    event.preventDefault();
    const _selectedSubjectName = subjectNames[index];
    setSelectedSubjectIndex(index);
    setSelectedSubjectName(_selectedSubjectName);
  };

  const handleTypeChange = (tabIndex) => {
    const selectedTab = tabs[tabIndex];
    const selectedType = selectedTab.name;
    setSelectedQuestionType(selectedType);
  }

  const handleAddQuestion = async (event, question) => {
    try {
      event.preventDefault();
      const _questionType = newQuestionType || selectedQuestionType || 'A';
      const payload = {
        subject: selectedSubjectName,
        questionType: _questionType,
        questionId: question._id
      };
      const updatedExam = await addQuestion(exam._id, payload);
      setSelectedQuestionType(_questionType);
      setNewQuestionType(null);
      setQuestionSetForSubject(updatedExam.questionSet);
      toast.success('Question added successfully.');

      let _exam = cloneDeep(exam);
      _exam.subjects[selectedSubjectIndex].questionSet = updatedExam.questionSet;
      setExam(_exam);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleRemoveQuestion = async (event) => {
    try {
      event.preventDefault();
      const payload = {
        subject: selectedSubjectName,
        questionType: selectedQuestionType,
        questionId: selectedQuestion._id
      };
      const updatedExam = await removeQuestion(exam._id, payload);

      const currentTypeIndex = findIndex(questionTypes, t => t === selectedQuestionType);
      if (tabs[currentTypeIndex].count === 1) {
        const totalTypes = questionTypes.length;
        const nextTypeIndex = currentTypeIndex === totalTypes - 1 ? currentTypeIndex - 1 : currentTypeIndex + 1;
        setSelectedQuestionType(questionTypes[nextTypeIndex]);
      }
      setQuestionSetForSubject(updatedExam.questionSet);
      toast.success('Question removed successfully.');

      let _exam = cloneDeep(exam);
      _exam.subjects[selectedSubjectIndex].questionSet = updatedExam.questionSet;
      setExam(_exam);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const addNewType = () => {
    const lastType = max(questionTypes);
    const nextType = String.fromCharCode(lastType.charCodeAt(0) + 1);
    setNewQuestionType(nextType);
    setOpenContentPicker(true);
  };

  const getSelectedQuestions = () => {
    let selectedQuestions = [];
    forEach(questionSetForSubject, qs => {
      forEach(qs.questions, q => selectedQuestions.push(q._id));
    });
    return selectedQuestions
  };
  
  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={subjectNames}
      currentSidebarNavigation={selectedSubjectName}
      onNavigationChange={handleSubjectSelect}
    >
      <Breadcrumb pages={breadcrumbPages} />
      <div>
        {openContentPicker &&
        <ContentPicker
          title="Select questions"
          contentType="mcq"
          selectedContents={getSelectedQuestions()}
          onSelect={handleAddQuestion}
          onCancel={() => {
            setOpenContentPicker(false);
            setNewQuestionType(null);
          }}
        />}

        {openQuestionView &&
        <ExamQuestionView
          question={selectedQuestion}
          onClose={() => {
            setSelectdQuestion(null);
            setOpenQuestionView(false);
          }}
        />}

        {showRemoveModel &&
        <ConfirmModal
          title="Remove question"
          description="Are you sure to remove this question from this exam?"
          onConfirm={handleRemoveQuestion}
          onCancel={() => {
            setSelectdQuestion(null);
            setShowRemoveModal(false);
          }}
        />}

        {questionSetForSubject.length > 0 &&
        <QuestionTypeTabs
          tabs={tabs}
          contentType="question"
          selectedTab={selectedQuestionType}
          showAddTypeButton={questionsForType?.length > 0}
          onTabChange={handleTypeChange}
          onAddTab={addNewType}
        />}

        {questionsForType.length === 0 &&
        <EmptyData
          title="No questions"
          subTitle="Add questions to this subject."
          action="Add question"
          onCreate={() => setOpenContentPicker(true)}
        />
        }

        <ul className="mx-2 divide-y divide-gray-100">
          {questionsForType.map((question, index) => (
            <li key={question._id || `question-index[${index}]`} 
              className="flex justify-between gap-x-6 py-5 cursor-pointer"
            >
              <div className="flex w-2/3 gap-x-4">
                <span className="flex-none text-sm text-gray-900">
                  {`${index+1}. `}
                </span>
                <div className="min-w-0 flex-auto">

                  <span className="questions-answer-wrapper text-md leading-6 text-gray-900">
                    {question?.question?.includes("$$") ? 
                    <Latex>{question?.question}</Latex>
                    : <span dangerouslySetInnerHTML={{ __html: question?.question }}/>}
                  </span>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">{question?.answers?.length || 0} options</p>
                </div>
                <ActionItems
                  question={question}
                  onView={() => {
                    setSelectdQuestion(question);
                    setOpenQuestionView(true);
                  }}
                  onRemove={() => {
                    setSelectdQuestion(question);
                    setShowRemoveModal(true);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        {questionsForType.length > 0 &&
        <PlusButton
          label="Add question"
          onClick={() => setOpenContentPicker(true)}
        />}
      </div>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { question, onView, onRemove } = props;

  const folderDestination = question.folder ? `/contents/questions/mcq/folder/${question.folder}` : `/contents/questions/mcq`
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="View" onClick={onView} />
      </div>

      <div className="py-1">
        <MenuItem label="Go to folder" href={folderDestination} />
      </div>
      
      <div className="py-1">
        <MenuItem label="Remove" isDanger={true} onClick={onRemove} />
      </div>
    </ActionMenu>
  )
};
