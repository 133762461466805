import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';
import { PhotoIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/StackedFieldSet';
import { Input, UploadWithDragDrop } from '../../components/forms/fields';
import { PlusButton } from '../../components/buttons';
import { createWebsiteContent, updateWebsiteContent, getWebsiteBanners } from '../../services/websiteContent';
import { handleUploadPublicImage } from '../../utils';



const sidebarNavigation = [
  { name: 'Web banners', href: '/website-contents/web-banners' },
  { name: 'Mobile banners', href: '/website-contents/mobile-banners' },
  { name: 'Testimonials', href: '/website-contents/testimonials' },
  { name: 'About us', href: '/website-contents/about-us' },
  { name: 'Terms & Privacy', href: '/website-contents/terms' },
];

export default function WebBannerPage(props) {
  const { platform } = props;
  const [websiteContent, setWebsiteContent] = useState(null);
  const [banners, setBanners] = useState([{}]);
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
  const [originalTargetUrl, setOriginalTargetUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _websiteContent = await getWebsiteBanners({platform});
        setWebsiteContent(_websiteContent);
        setBanners(_websiteContent.contents);
      } catch (error) {
        setBanners([{}]);
        setSelectedBannerIndex(0);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleBannerUpload = async (files, index) => {
    try {
      index = index || selectedBannerIndex;
      const imageUrl = await handleUploadPublicImage(files);
      let _banners = cloneDeep(banners);
      _banners[index].url = imageUrl;
      setBanners(_banners);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleBannerTargetUrlChange = (event, index) => {
    event.preventDefault();
    index = index || selectedBannerIndex;
    let _banners = cloneDeep(banners);
    _banners[index].targetUrl = event.target.value;
    setBanners(_banners);
  };

  const saveBanners = async (event) => {
    try {
      event.preventDefault();
      let payload = {
        contents: banners
      };
      if (websiteContent) {
        const updatedWebsiteContent = await updateWebsiteContent(websiteContent._id, payload);
        setWebsiteContent(updatedWebsiteContent);
        setBanners(updatedWebsiteContent.contents);
      } else {
        payload.contentType = platform === 'web' ? 'banner' : 'mobileBanner';
        const newWebsiteContent = await createWebsiteContent(payload);
        setWebsiteContent(newWebsiteContent);
        setBanners(newWebsiteContent.contents);
      }
      setOriginalTargetUrl('');
      setSelectedBannerIndex(null);
      toast.success('Banner updated successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddBanner = (event) => {
    event.preventDefault();
    let _banners = cloneDeep(banners);
    _banners.push({});
    setBanners(_banners);
    setSelectedBannerIndex(_banners.length - 1);
  };

  const handleCancelEdit = () => {
    let _banners = cloneDeep(banners);
    if (!banners[selectedBannerIndex]?.url) {
      const lastIndex = banners.length - 1;
      _banners.splice(lastIndex, 1);
    } else {
      _banners[selectedBannerIndex].targetUrl = originalTargetUrl;
    }
    setBanners(_banners);
    setSelectedBannerIndex(null);
  };

  const removeBanner = async (event, index) => {
    try {
      event.preventDefault();
      let _banners = cloneDeep(banners);
      _banners.splice(index, 1);
      const payload = { contents: _banners };
      const updatedWebsiteContent = await updateWebsiteContent(websiteContent._id, payload);
      setWebsiteContent(updatedWebsiteContent);
      setBanners(updatedWebsiteContent.contents);
      toast.success('Banner removed successfully.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={platform === 'web' ? 'Web banners' : 'Mobile banners'}
    >
      <Form
        onSubmit={saveBanners}
      >

        <FieldSet
          title="Upload web banners"
          subTitle="Upload images for your website banners"
        >
          {banners.map((banner, index) => (
            <div key={`banner-index[${index}]`} className="col-span-full">
              {selectedBannerIndex !== index &&
              <div className="flex col-span-full justify-between">
                <div className="flex justify-start space-x-4">
                  <img width={platform === 'web' ? '100' : '70'} src={banner.url} />
                  <p>Banner {index+1}</p>
                </div>

                <div className="justify-end space-x-4">
                  <button
                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setSelectedBannerIndex(index);
                      setOriginalTargetUrl(banner?.targetUrl)
                    }}
                  >
                    Change
                  </button>
                  <button
                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                    onClick={(event) => removeBanner(event, index)}
                  >
                    Remove
                  </button>
                </div>
              </div>}

              {selectedBannerIndex === index &&
              <UploadWithDragDrop
                contentType="image"
                content={banner?.url} 
                label={`Banner ${index+1}`}
                actionName="Upload an image"
                onUpload={(files) => handleBannerUpload(files, index)}
                showCancelButton={(banner?.url || banners.length > 1) && selectedBannerIndex === index}
                onCancel={handleCancelEdit}
              />}

              {selectedBannerIndex === index &&
              <Input col={4} placeholder="Target URL" value={banner.targetUrl} onChange={(event) => handleBannerTargetUrlChange(event, index)} />}
            </div>
          ))}
          {!selectedBannerIndex &&
          <div>
            <PlusButton label="Add banner" onClick={handleAddBanner} />
          </div>}
        </FieldSet>
      </Form>

    </StackedLayout>
  )
};