import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { capitalize } from 'lodash';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/24/solid';
import { ClockIcon, MapPinIcon, PhotoIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
  startOfWeek
} from 'date-fns';
import { SubmitButton, Button } from './buttons';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function Calendar(props) {
  const { 
    events,
    courseType,
    onAddEvent,
    onAddRecurringEvent,
    onEditEvent, 
    onEventStatusChange, 
    onMonthChange,
    onDelete
  } = props;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today)
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  let days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth, {weekStartsOn: 6}),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth), {weekStartsOn: 6}),
  })

  const previousMonth = () =>  {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    setSelectedDay(isSameMonth(firstDayNextMonth, today) ? today : firstDayNextMonth);
    onMonthChange(firstDayNextMonth);
  }

  const  nextMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    setSelectedDay(isSameMonth(firstDayNextMonth, today) ? today : firstDayNextMonth);
    onMonthChange(firstDayNextMonth);
  };

  const selectedDayEvents = events.filter((event) =>
    isSameDay(parseISO(event.startsAt), selectedDay)
  );

  return (
    <div className="m-2">
      <h2 className="text-base font-semibold leading-6 text-gray-900">
        Scheduled classes for{' '}
        <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'dd MMMM, yyyy (EEEE)')}</time>
      </h2>
      <div className="mt-4 sm:mt-0 lg:grid lg:grid-cols-12 lg:gap-x-16">
        <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={previousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {format(firstDayCurrentMonth, 'MMMM yyyy')}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={nextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
          </div>
          <div className="mt-2 grid grid-cols-7 gap-px rounded-lg bg-white text-sm shadow ring-1 ring-gray-200">
          {days.map((day, dayIdx) => (
                <div
                  key={`calendar-day-index[${dayIdx}]`}
                  // className={classNames(
                  //   dayIdx === 0 && colStartClasses[getDay(day)],
                  //   'py-1.5'
                  // )}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    className={classNames(
                      isEqual(day, selectedDay) && 'text-white',
                      !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        'text-green-500',
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-900',
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        'text-gray-400',
                      isEqual(day, selectedDay) && isToday(day) && 'bg-green-500',
                      isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        'bg-gray-900',
                      !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                      (isEqual(day, selectedDay) || isToday(day)) &&
                        'font-semibold',
                      'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                    )}
                  >
                    <time dateTime={format(day, 'yyyy-MM-dd')}>
                      {format(day, 'd')}
                    </time>
                  </button>

                  <div className="w-1 h-1 mx-auto mt-1">
                    {events.some((event) =>
                      isSameDay(parseISO(event.startsAt), day)
                    ) && (
                      <div className="w-1 h-1 rounded-full bg-purple-500"></div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {(courseType === 'offline' || currentBranch?.permissions?.canManageOnlineRoutines) &&
          <SubmitButton className="mt-8 w-full" label="Add single class" onClick={onAddEvent} />}
          {(courseType === 'offline' || currentBranch?.permissions?.canManageOnlineRoutines) &&
          <Button className="mt-4 w-full" label="Add recurring classes" onClick={onAddRecurringEvent} />}
        </div>
        <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {selectedDayEvents.length > 0 ? (
            selectedDayEvents.map((event, index) => (
              <EventData
                key={`calendar-event-index[${index}]`}
                event={event}
                onEditEvent={onEditEvent}
                onDelete={onDelete}
                onEventStatusChange={onEventStatusChange}
              />
            ))
          ) : (
            <p>No classes found.</p>
          )}
        </ol>
      </div>
    </div>
  )
};

function EventData(props) {
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const { event, onEditEvent, onEventStatusChange, onDelete } = props;
  const startDateTime = parseISO(event.startsAt);
  const today = startOfToday();


  return (
    <li key={event._id} className="relative flex space-x-6 py-6 xl:static">
      <div className="hidden sm:block">
        {event.courseType === 'offline' &&
        (event?.course?.thumbnail ? <img src={event.course.thumbnail} className="w-14 flex-none" />
        :<PhotoIcon className="h-14 w-14 flex-none rounded-full text-gray-300" />)}

        {event.courseType !== 'offline' &&
        (event?.instructor?.photo ? <img src={event?.instructor?.photo || event?.course?.thumbnail} className="h-14 w-14 rounded-full flex-none" />
        :<UserCircleIcon className="h-14 w-14 flex-none rounded-full text-gray-300" />)}
      </div>
      <div className="flex-auto">
        <p className="text-purple-700">
          {event?.isFree ? 
          <span className="text-indigo-600">Free class | {event?.grade}</span> 
          :
          <span>{event?.batch?.name || 'All bacthes'} | {event?.course?.name}</span>
          }
        </p>
        <p className="mt-2 text-gray-900 xl:pr-0">
          {`${event?.subject?.name || 'Topic'}: ${event?.name}`}
        </p>
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div className={classNames(
            'flex items-center space-x-1',
            event.status === 'scheduled' && 'text-orange-400',
            event.status === 'open' && 'text-green-600',
          )}>
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <ClockIcon className="h-5 w-5" aria-hidden="true" />
            </dt>
            <dd>
              {event.status === 'open' && <span>Started</span>}
              {event.status === 'finished' && <span>Ended</span>}
              {event.status === 'scheduled' &&
              <time dateTime={format(startDateTime, 'yyyy-MM-dd')}>
                {format(startDateTime, 'hh:mm a')}
              </time>}
            </dd>
          </div>
          <div className="mt-2 flex items-center space-x-1 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Live Plaform</span>
              <MapPinIcon className="h-5 w-5" aria-hidden="true" />
            </dt>
            <dd>{event.courseType === 'offline' ? `${event?.branch?.name}` : capitalize(event?.liveClassPlatform)}</dd>
          </div>
          {event.courseType !== 'offline' &&
          <div className="mt-2 flex items-center space-x-1 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
            <dt className="mt-0.5">
              <span className="sr-only">Instructor</span>
              <UserCircleIcon className="h-5 w-5" aria-hidden="true" />
            </dt>
            <dd>{event?.instructor?.name}</dd>
          </div>}
        </dl>
      </div>

      {(event.courseType === 'offline' || currentBranch?.permissions?.canManageOnlineRoutines) &&
      <Menu as="div" className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center">
        <div>
          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
            {event.status === 'finished' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Attendance
                  </a>
                )}
              </Menu.Item>}
              {event.status !== 'finished' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={() => onEditEvent(event)}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>}
              {isSameDay(startDateTime, today) && event.status === 'scheduled' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={(e) => onEventStatusChange(e, event._id, 'open')}
                  >
                    Start class
                  </a>
                )}
              </Menu.Item>}
              {event.status === 'open' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={(e) => onEventStatusChange(e, event._id, 'scheduled')}
                  >
                    Stop class
                  </a>
                )}
              </Menu.Item>}

              {event.status === 'open' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={(e) => onEventStatusChange(e, event._id, 'finished')}
                  >
                    End class
                  </a>
                )}
              </Menu.Item>}

              {event.status === 'scheduled' &&
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={(e) => onDelete(e, event._id)}
                  >
                    Delete
                  </a>
                )}
              </Menu.Item>}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>}
    </li>
  )
};

