import StackedLayout from '../../layouts/StackedLayout';


export default function BranchPermissionDeniedPage() {
  return (
    <StackedLayout>
      <div className="grid min-h-full place-items-center bg-white px-6 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-red-600">Sorry!</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Permissions denied</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Please contact your admin to enable access for your branch.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* <a
              href="/supports"
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Contact support
            </a> */}
            {/* <a href="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a> */}
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};
