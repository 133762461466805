import { LuExternalLink } from "react-icons/lu";
import { lightenColor } from "../../../../utils";


export function LinkButton(props) {
  const { brandColor, actionName, linkTo } = props;
  const lightenBrandColor = lightenColor(brandColor, 0.8);

  return (
    <button
      style={{ backgroundColor: lightenBrandColor, color: brandColor }}
      className="mt-10 w-fit text-base leading-6 flex justify-center items-center font-medium hover:bg-opacity-80 py-3.5 px-6 rounded-[6px]"
      onClick={() => window.location.href = linkTo}
    >
      {actionName}
      <LuExternalLink className="ml-3 text-xl" />
    </button>
  )
}
