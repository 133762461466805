import { SubmitButton } from "./buttons";

export default function SectionHeader(props) {
  const { title, subTitle, createButtonText, onCreate } = props;

  return (
    <div className="mx-2 flex justify-between pb-4">
      <div>
        <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        <p className="mt-2 text-base text-gray-500">
          {subTitle}
        </p>
      </div>
      
      {onCreate ?
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <SubmitButton label={createButtonText || 'Create new'} onClick={onCreate} />
      </div>
      : 
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        {props.children}
      </div>}
    </div>
  )
};
