import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { forEach } from 'lodash';

import { SelectAsync, Select } from './forms/fields';
import { Button } from './buttons';
import { getCourses } from '../services/course';
import { getBatches } from '../services/batch';


export default function CourseInputWithList(props) {
  let {
    col,
    courses,
    onAdd,
    onRemove
  } = props;
  col= col ? col?.toString() : 'full';
  const [batches, setBatches] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchBatches() {
      try {
        if (selectedCourse) {
          const _batches = await getBatches({course: selectedCourse._id});
          setBatches(_batches);
        } else {
          setBatches([]);
        }
        setSelectedBatch(null);
      } catch (error) {
        toast.error(error.message);
        setBatches([]);
        setSelectedBatch(null);
      }
    };
    _fetchBatches();
  }, [selectedCourse]);

  const getLabel = (course) => {
    return `(${course.courseType}) ${course.name}`;
  };

  const searchCourse = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let excludeCourses = [];
        forEach(courses, course => excludeCourses.push(course._id));
        let params = { search: queryString, courseType: ['live', 'recorded', 'exam'] };
        if (excludeCourses.length > 0) {
          params.excludeCourses = excludeCourses;
        }
        const _courses = await getCourses(params);
        let courseOptions = [];
        forEach(_courses, course => {
          courseOptions.push({
            label: getLabel(course),
            value: course
          });
        });
        return courseOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddCourse = (event) => {
    setUpdating(true);
    let payload = { course: selectedCourse };
    if (selectedCourse.courseType === 'live') {
      payload.batch = selectedBatch;
    }
    onAdd(event, payload);
    setSelectedCourse(null);
    setSelectedCourse(null);
    setBatches([]);
    setUpdating(false);
  }

  const isDisabled = () => {
    return updating || !selectedCourse || (selectedCourse.courseType === 'live' && !selectedBatch);
  };

  return (
    <>
      <SelectAsync
        col={col}
        placeholder="Search by course name..."
        onSearch={searchCourse}
        onChange={(item) => setSelectedCourse(item.value)}
      />

      {selectedCourse?.courseType === 'live' &&
      <Select
        col={2}
        value={selectedBatch?._id}
        options={batches}
        onChange={(event) => setSelectedBatch(batches[event.target.selectedIndex-1])}
      />}

      <div>
        <Button disabled={isDisabled()} label="Add" onClick={handleAddCourse} />
      </div>

      {courses.length > 0 &&
      <div className={`sm:col-span-3`}>
        <ul className="divide-y divide-gray-100">
          {courses.map((course, index) => (
            <li key={`course-index[${index}]`} className="flex sm:span-col-full items-center justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{course?.course?.name}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    <span>{course?.course?.courseType} | {course?.course?.grade}</span>
                    {course?.course?.courseType === 'live' && <span> | Batch: {course?.batch?.name}</span>}
                    
                  </p>
                </div>
              </div>
              <button
                className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                onClick={(event) => onRemove(event, index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>}
    </>
  )
};
