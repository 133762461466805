import http from '../helpers/http';


export const getBranches = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/branches`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranch = async (id) => {
  try {
    const { data } = await http.get(`/api/branches/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createBranch = async (payload) => {
  try {
    const { data } = await http.post(`/api/branches`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateBranch = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/branches/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeBranchStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/branches/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const makePrincipalBranch = async (id) => {
  try {
    const { data } = await http.put(`/api/branches/${id}/make-principal`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCurrentBranch = async () => {
  try {
    const { data } = await http.get(`/api/branches/current`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
