import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { images } from '../../../constants';
import Footer from '../../../layouts/Footer';


export default function AuthLayout(props) {
  const { title, subTitle } = props;
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;

  useEffect(() => {
    if (currentUser) {
      const url = currentUser.role === 'student' ? `/learn/home` : `services`; 
      return navigate(url); 
    }
  }, []);

  return (
    <>
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="mb-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto max-h-10 cursor-pointer"
              src={currentInstitute?.logo || images.Logo}
              alt="Your Company"
              onClick={() => window.location.href = '/'}
            />
            <div className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <p>{title}</p>
            </div>
            
            {subTitle &&
            <div className="my-4 text-center text-sm font-medium text-gray-400">
              <p>{subTitle}</p>
            </div>}
          </div>

          {props.children}
        </div>
      </div>

      {/* <Footer /> */}
    </>
  )
};
