import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { forEach, cloneDeep, range, reverse, findIndex, map } from 'lodash';
import {
  ChevronRightIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/solid';

import StackedLayout from '../../../layouts/StackedLayout';
import Loader from '../../../components/Loader';
import PlaceholderLoader from '../../../components/PlaceholderLoader';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { SubmitButton } from '../../../components/buttons';
import QuestionSection from './components/QuestionSection';
import QuestionPageHeader from './components/QuestionPageHeader';
import SubjectTabs from './components/SubjectTabs';
import { getQuestionSet } from '../../../utils/question';
import { getExamWithQuestions } from '../../../services/exam';
import { getUserEnrolledCourse } from '../../../services/enrolledCourse';
import { convertToBanglaNumber } from '../../../utils';
import { createOnlineTakenExam, checkUserTakenExam } from '../../../services/takenExam';


export default function MCQExamPage() {
  const params = useParams();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const examId = params.id;
  const courseId = params.courseId;
  const [subjects, setSubjects] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [comprehensions, setComprehensions] = useState([]);
  const [exam, setExam] = useState(null);
  const [examStarted, setExamStarted] = useState(false);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedComprehension, setSelectedComprehension] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [unasnweredQuestionIndices, setUnasnweredQuestionIndices] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [timeRemianing, setTimeRemianing] = useState(null);
  const [duration, setDuration] = useState(null);
  const [formatting, setFormatting] = useState(false);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [openConfirmModal, setOpenConfirmModel] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [examEnded, setExamEnded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) { return navigate(-1); }
    async function _fetchData() {
      try {
        const enrolledCourse = await getUserEnrolledCourse(courseId);
        if (!enrolledCourse) { return navigate(-1); }

        const takenExam = await checkUserTakenExam({exam: examId, course: courseId});
        if (takenExam?.isTaken) {
          window.opener.location.href = `/learn/exam/${takenExam._id}/result`;
          window.close();
          return;
        }
        const _exam = await getExamWithQuestions(examId);
        setExam(_exam);
        setLoading(false);
      } catch (error) {
        return navigate(-1);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    if (questionsAnswered === parseInt(exam?.totalQuestions) || timeRemianing === 0) {
      setExamEnded(true);
      handleEndExam();
    }
  }, [questionsAnswered, timeRemianing]);

  const startExam = (event) => {
    event.preventDefault();
    setFormatting(true);
    const formattedQuestions = getQuestionSet(exam.subjects, selectedSubjects);
    const _questionSet = formattedQuestions.questionSet;
    const _subjects = formattedQuestions.subjects;
    const questionsForSubject = _questionSet[_subjects[0].name].questions;
    setQuestions(_questionSet);
    setSubjects(_subjects);
    setSelectedQuestionIndex(0);
    const _selectedQuestion = questionsForSubject[0];
    setSelectedQuestion(_selectedQuestion);

    const comprehensionsForSubject = _questionSet[_subjects[0].name].comprehensions;
    setComprehensions(comprehensionsForSubject);
    const comprehensionIndex = findIndex(comprehensionsForSubject, c => c.type === _selectedQuestion.type);
    setSelectedComprehension(comprehensionIndex === -1 ? null: comprehensionsForSubject[comprehensionIndex].value);

    const _unasnweredQuestionIndices = range(0, questionsForSubject.length);
    setUnasnweredQuestionIndices(_unasnweredQuestionIndices);
    startTimer(parseInt(exam?.duration?.value || 0) * 60);
    setFormatting(false);
    setExamStarted(true);
  };

  const startTimer = (duration) => {
    let hour, minutes, seconds;

    const timer = setInterval(() => {
      hour = parseInt(duration / (60 * 60), 10);
      minutes = parseInt((duration % (60 * 60)) / 60, 10);
      seconds = parseInt(duration % 60, 10);

      hour = hour < 10 ? '0' + hour : hour;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setTimeRemianing(duration);
      setDuration({ hour, minutes, seconds });

      if (--duration < 0) {
        clearInterval(timer);
      }
    }, 1000);
  };

  const onAcceptAnswer = (event) => {
    event.preventDefault();
    setSubmittingAnswer(true);
    let _questions = cloneDeep(questions);
    let _subjects = cloneDeep(subjects);
    const subjectName = _subjects[selectedSubjectIndex].name;

    _questions[subjectName].questions[selectedQuestionIndex].isAnswered = true;
    _questions[subjectName].questions[selectedQuestionIndex].comprehension = selectedComprehension;
    _questions[subjectName].questions[selectedQuestionIndex].isCorrect = selectedAnswer.isCorrect;
    _questions[subjectName].questions[selectedQuestionIndex].answeredOption = selectedAnswer.value;
    setQuestions(_questions);
    setSelectedAnswer(null);

    _subjects[selectedSubjectIndex].answered = _subjects[selectedSubjectIndex].answered + 1;
    setSubjects(() => _subjects);

    let _unasnweredQuestionIndices = [];
    map(_questions[subjectName].questions, (q, index) => !q.isAnswered && _unasnweredQuestionIndices.push(index));
    setUnasnweredQuestionIndices(_unasnweredQuestionIndices);

    const _nextQuestionIndex = getNextQuestionIndex(_unasnweredQuestionIndices);
    setSelectedQuestionIndex(_nextQuestionIndex, _nextQuestionIndex);
    const _selectedQuestion = _questions[subjectName].questions[_nextQuestionIndex];
    setSelectedQuestion(_selectedQuestion);

    const comprehensionIndex = findIndex(comprehensions, c => c.type === _selectedQuestion?.type);
    setSelectedComprehension(comprehensionIndex === -1 ? null: comprehensions[comprehensionIndex].value);
    setSubmittingAnswer(false);

    const _questionsAnswered = cloneDeep(questionsAnswered) + 1;
    setQuestionsAnswered(_questionsAnswered);
  };

  const onNextQuestion = (event) => {
    event.preventDefault();
    let _questions = cloneDeep(questions);
    const subjectName = subjects[selectedSubjectIndex].name;
    
    const _nextQuestionIndex = getNextQuestionIndex();
    setSelectedQuestionIndex(_nextQuestionIndex);
    const _selectedQuestion = _questions[subjectName].questions[_nextQuestionIndex];
    setSelectedQuestion(_selectedQuestion);
    const comprehensionIndex = findIndex(comprehensions, c => c.type === _selectedQuestion?.type);
    setSelectedComprehension(comprehensionIndex === -1 ? null: comprehensions[comprehensionIndex].value);
    setSelectedAnswer(null);
  };

  const onPreviousQuestion = (event) => {
    event.preventDefault();
    let _questions = cloneDeep(questions);
    const subjectName = subjects[selectedSubjectIndex].name;
    
    const _previousQuestionIndex = getPreviousQuestionIndex();
    setSelectedQuestionIndex(_previousQuestionIndex);
    const _selectedQuestion = _questions[subjectName].questions[_previousQuestionIndex];
    setSelectedQuestion(_selectedQuestion);
    const comprehensionIndex = findIndex(comprehensions, c => c.type === _selectedQuestion?.type);
    setSelectedComprehension(comprehensionIndex === -1 ? null: comprehensions[comprehensionIndex].value);
    setSelectedAnswer(null);
  };

  const getNextQuestionIndex = (_unasnweredQuestionIndices) => {
    _unasnweredQuestionIndices = _unasnweredQuestionIndices || unasnweredQuestionIndices;
    let nextQuestionIndex;
    forEach(_unasnweredQuestionIndices, index => {
      if (index > selectedQuestionIndex) {
        nextQuestionIndex = index;
        return false;
      }
    });
    return nextQuestionIndex || _unasnweredQuestionIndices[0];
  };

  const getPreviousQuestionIndex = () => {
    const _unasnweredQuestionIndices = reverse(cloneDeep(unasnweredQuestionIndices));
    let previousQuestionIndex;
    forEach(_unasnweredQuestionIndices, index => {
      if (index < selectedQuestionIndex) {
        previousQuestionIndex = index;
        return false;
      }
    });
    return previousQuestionIndex;
  };

  const handleSubjectChange = (subjectName) => {
    setFormatting(true);
    const _selectedSubjectIndex = findIndex(subjects, s => s.name === subjectName);
    setSelectedSubjectIndex(_selectedSubjectIndex);
    const questionsForSubject = questions[subjects[_selectedSubjectIndex].name].questions;

    let _unasnweredQuestionIndices = [];
    map(questions[subjectName].questions, (q, index) => !q.isAnswered && _unasnweredQuestionIndices.push(index));
    setUnasnweredQuestionIndices(_unasnweredQuestionIndices);

    const _selectedQuestionIndex = _unasnweredQuestionIndices[0];
    setSelectedQuestionIndex(_selectedQuestionIndex);
    setSelectedQuestion(questionsForSubject[_selectedQuestionIndex]);

    const comprehensionsForSubject = questions[subjectName].comprehensions;
    setComprehensions(comprehensionsForSubject);
    const comprehensionIndex = findIndex(comprehensionsForSubject, c => c.type === questionsForSubject[_selectedQuestionIndex]?.type);
    setSelectedComprehension(comprehensionIndex === -1 ? null: comprehensionsForSubject[comprehensionIndex].value);

    setSelectedAnswer(null);
    setFormatting(false);
  };

  const handleSelectSubject = (event) => {
    const subjectName = event.target.value;
    const _selectedSubjects = cloneDeep(selectedSubjects);
    const subjectIndex = findIndex(_selectedSubjects, s => s === subjectName);
    if (subjectIndex === -1) {
      _selectedSubjects.push(subjectName);
    } else {
      _selectedSubjects.splice(subjectIndex, 1);
    }
    setSelectedSubjects(_selectedSubjects);
  };

  const handleEndExam = async (event) => {
    try {
      setExamEnded(true);
      event?.preventDefault();
      setLoading(true);
      const payload = {
        examId,
        courseId,
        questions,
        timeTaken: (parseInt(exam?.duration?.value || 0) * 60) - timeRemianing
      };
      const takenExam = await createOnlineTakenExam(payload);
      toast.success('আপনার উত্তরপত্র সফলভাবে জমা হয়েছে');
      window.opener.location.href = `/learn/exam/${takenExam._id}/result`;
      window.close();
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      setOpenConfirmModel(false);
    }
  };


  return (
    <StackedLayout
      loading={loading}
      hideHeader={true}
      hideFooter={true}
    >
      {openConfirmModal &&
      <ConfirmModal
        title="উত্তরপত্র জমা দিন"
        description={`আপনি কি উত্তরপত্র জমা দিতে চান? মনে রাখবেন, একটি পরীক্ষা কেবলমাত্র একবারই অংশগ্রহণ করা যায়।`}
        actionName="জমা দিন"
        cancelText="বাতিল"
        onConfirm={handleEndExam}
        onCancel={() => setOpenConfirmModel(false)}
      />}
      <div>
        <QuestionPageHeader
          exam={exam}
          examStarted={examStarted}
          duration={duration}
          onEndExam={() => setOpenConfirmModel(true)}
        />

        {formatting && <PlaceholderLoader />}

        {!examStarted && !formatting &&
        <div className="flex flex-col py-6 sm:mt-6">
          <h3 className="flex text-sm font-semibold">
            {exam?.noOfSubjectsToAnswer < exam?.subjects?.length ?
            `যেকোনো ${convertToBanglaNumber(exam?.noOfSubjectsToAnswer)}টি বিষয় সিলেক্ট করুন` : 'বিষয়সমূহ'}
          </h3>
          <ul role="list" className="flex flex-col divide-y divide-gray-100 w-full xs:w-1/2 sm:w-1/3">
            {exam?.subjects?.map((subject, index) => (
              <li key={`subject-index[${index}]`} className="flex flex-1 py-4">
                <div className="flex flex-1 justify-between items-center gap-x-3">
                  <div className="flex justify-start space-x-2">
                    {exam?.noOfSubjectsToAnswer < exam?.subjects?.length &&
                    <div>
                      <div className="flex h-6 items-center">
                        <input
                          type="checkbox"
                          value={subject.name}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={handleSelectSubject}
                        />
                      </div>
                    </div>}
                    <h3 className="flex-auto truncate text-sm leading-6 text-gray-900">{subject.name}</h3>
                  </div>
                  <p className="text-xs text-gray-500">
                    {convertToBanglaNumber(subject.noOfQuestions)}টি প্রশ্ন
                  </p>
                </div>
              </li>
            ))}
          </ul>

          <div className="flex mt-6 w-full sm:w-1/3">
            <SubmitButton
              className="w-full"
              disabled={(exam?.noOfSubjectsToAnswer < exam?.subjects?.length) && (exam?.noOfSubjectsToAnswer !== selectedSubjects.length)}
              label="পরীক্ষা শুরু করুন"
              onClick={startExam}
            />
          </div>        
        </div>}

        {examStarted &&
        <div className="mt-4 w-full md:w-2/3">
          <SubjectTabs subjects={subjects} subjectIndex={selectedSubjectIndex} onChange={handleSubjectChange} />

          <div className="w-full mx-2">
            <QuestionSection
              question={selectedQuestion}
              questionIndex={selectedQuestionIndex}
              comprehension={selectedComprehension}
              onSelectAnswer={setSelectedAnswer}
              submittingAnswer={submittingAnswer}
            />
          </div>

          {selectedQuestionIndex >= 0 &&
          <div className="flex mt-6 mx-2 space-x-3 justify-between sm:justify-start items-center">
            <span>
              <button
                disabled={submittingAnswer || unasnweredQuestionIndices[0] === selectedQuestionIndex}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-30"
                onClick={onPreviousQuestion}
              >
                <ChevronLeftIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                পূর্ববর্তী প্রশ্ন
              </button>
            </span>

            <span>
              <SubmitButton
                disabled={!selectedAnswer || submittingAnswer}
                label="উত্তর সাবমিট করুন"
                onClick={onAcceptAnswer}
              />
            </span>

            <span >
              <button
                disabled={submittingAnswer || unasnweredQuestionIndices[unasnweredQuestionIndices.length-1] === selectedQuestionIndex}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-30"
                onClick={onNextQuestion}
              >
                পরবর্তী প্রশ্ন
                <ChevronRightIcon className="-mr-0.5 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </span>
          </div>}
        </div>}
      </div>

    </StackedLayout>
  )
};
