import Fb from '../assets/fb.png';
import insta from '../assets/instagram.png';
import player from '../assets/youtube.png';


export default function ContactSection() {
    return (
        <div  id="contact"  style={{ background: '#10B981' }} className="px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-4xl font-bold tracking-tight text-white ">যোগাযোগ করুন</h2>
                <p className="mt-6 text-xl leading-8 text-white">
                    আমদের সাথে যোগাযোগ করুন, আপনার শিক্ষার পথে আরও এগিয়ে যান।
                </p>
            </div>

            <div className="flex items-center justify-center">
                <ul role="list" className="mt-6 flex gap-x-6">
                    <li>
                        <a href={""} className="text-gray-400 hover:text-gray-500">
                            <img width={100} height={100} className='w-8 ' src={Fb} alt='Fbicon' />
                        </a>
                    </li>
                    <li>
                        <a href={""} className="text-gray-400 hover:text-gray-500">
                            <img className='w-8 ' src={insta} alt='Fbicon' />
                        </a>
                    </li>
                    <li>
                        <a href={""} className="text-gray-400 hover:text-gray-500">
                            <img  className='w-8 ' src={player} alt='Fbicon' />
                        </a>
                    </li>

                </ul>
            </div>

        </div>
    )
}
