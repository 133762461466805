export default function StackedFieldSet(props) {
  const title = props.title;
  const subTitle = props.subTitle;
  
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">{subTitle}</p>

      <div className="mt-10 grid grid-cols-6 gap-x-6 gap-y-8">
        {props.children}
      </div>
    </div>
  )
}
