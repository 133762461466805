import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import { SubmitButton, Button } from '../../components/buttons';
import { getTransaction } from '../../services/transaction';


export default function SmsPaymentSuccessPage() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const transactionId = queryParams.get('transactionId');
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    async function _fetchData() {
      try {
        const transaction = await getTransaction(transactionId);
        setTransaction(transaction);
        if (transaction.status !== 'success') {
          navigate(`/sms/recharge`);
        }
      } catch (error) {
        navigate(`/sms/recharge`);
      }
    };
    _fetchData();
  }, []);

  return (
    <StackedLayout>
      <div className="flex flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              BDT {transaction?.paidAmount} added to your SMS balance successfully.
            </h3>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 space-x-4">
          {/* <SubmitButton
            label="Collect payment"
            onClick={() => navigate(`/invoices/${invoiceId}/payment`)}
          /> */}
          <Button
            label="Go back"
            onClick={() => navigate(`/sms`)}
          />
        </div>
      </div>
    </StackedLayout>
  )
};