import { useSelector } from "react-redux";


export default function LoginButton() {
  const institutteState = useSelector((state) => state.institute);
  const currentInstitute = institutteState?.instituteInfo;
  const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;

  return (
    <button
      style={{ color: brandColor, borderColor: brandColor }}
      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-green-600 shadow-sm border-2 hover:opacity-80  disabled:opacity-30 disabled:cursor-not-allowed"
      onClick={() => {
        window.location.href = '/auth/login';
      }}
    >
      লগ ইন
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-1 w-5 h-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
      </svg>

    </button>
  )
};
