import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { omit } from 'lodash';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import { Select } from '../../components/forms/fields';
import { ButtonWithIcon } from '../../components/buttons';
import DownloadMeriList from '../../components/modals/DownloadMeritList';
import { getBranches } from '../../services/branch';
import { getCourse } from '../../services/course';
import { getCourseContents } from '../../services/courseContent';
import { getTakenExams } from '../../services/takenExam';


const headers = ['Student ID', 'Name', 'Branch', 'MCQ', 'Written', 'Total', 'Branch Position', 'Central Position'];

export default function ExamResultPage() {
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.courseId;
  const examId = params.examId;
  const [course, setCourse] = useState(null);
  const [courseContent, setCourseContent] = useState(null);
  const [takenExams, setTakenExams] = useState([]);
  const [pagingData, setPagingData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [branchFilters, setBranchFilters] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const _branches = await getBranches({status: 'active'});
        setBranches(_branches);

        const _courseContents = await getCourseContents({
          course: courseId,
          contentType: 'offlineExam'
        });

        if (_courseContents.length === 0 || _courseContents[0].topics.length === 0) {
          return navigate(-1);
        }
        let topicIndex;
        let contentIndex;
        for(let i = 0; i < _courseContents[0].topics.length; i++) {
          if (topicIndex && contentIndex) { break; }
          const topic = _courseContents[0].topics[i];
          for (let j = 0;j < topic?.contents?.length; j++) {
            const content = topic.contents[j];
            if (content?.offlineExam?._id?.toString() === examId.toString()) {
              topicIndex = i;
              contentIndex = j;
              break;
            }
          }
        }
        const content = _courseContents[0].topics[topicIndex].contents[contentIndex];
        setCourseContent(content);

        const _course = await getCourse(courseId);
        setCourse(_course)

        setBreadcrumbPages([
          { name: 'Courses', href: `/results/courses` },
          { name: _course?.name, href: `/results/course/${courseId}` },
          { name: content?.offlineExam?.name, current: true },
        ])
      } catch (error) {
        navigate(-1);
      }
    };
    fetchInitialData();
    setLoading(false);
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
  }, [
    currentPage,
    selectedBranch
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        course: courseId,
        offlineExam: examId,
        sortBy: branchFilters.length === 1 ? 'branchPosition' : 'centralPosition'
      };
      if (selectedBranch) {
        params.branch = selectedBranch;
      }
      const data = await getTakenExams(params);
      setTakenExams(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  return (
    <StackedLayout
      loading={loading}
      breadcrumbPages={breadcrumbPages}
    >
      {showDownloadModal &&
      <DownloadMeriList
        course={course}
        exam={courseContent?.offlineExam}
        onCancel={() => setShowDownloadModal(false)}
      />}

      <SectionHeader
        title="Exam result"
        subTitle={`Exam: ${courseContent?.offlineExam?.name}`}
      >
        <div>
          <div className="flex space-x-4"> 
            <ButtonWithIcon label="Download results" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadModal(true)} />
          </div>
        </div>
      </SectionHeader>

      <div>
        <div className="sm:w-1/4">
          <Select label="Filter by branch" value={selectedBranch} options={branches} onChange={(event) => setSelectedBranch(event.target.value)} />
        </div>
      </div>

      <Table
        headers={headers}
        itemsCount={takenExams.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >
        {takenExams.map((takenExam) => (
          <tr
            key={takenExam._id}
            className="text-center text-sm text-gray-500"
          >
            <td className="py-4 pl-4 pr-3 font-medium sm:pl-0 text-left">
              {takenExam?.user.registrationNo}
            </td>
            <td className="pr-3 py-4">{takenExam?.user?.name}</td>
            <td className="pr-3 py-4">{takenExam?.branch?.name}</td>
            <td className="pr-3 py-4">{takenExam?.mcqMarksObtained || '-'}</td>
            <td className="pr-3 py-4">{takenExam?.cqMarksObtained || '-'}</td>
            <td className="pr-3 py-4">{takenExam?.totalMarksObtained || '-'}</td>
            <td className="pr-3 py-4">{takenExam?.branchPosition || '-'}</td>
            <td className="pr-3 py-4">{takenExam?.centralPosition || '-'}</td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};