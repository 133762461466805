import http from '../helpers/http';


export const getOfflineExam = async (id) => {
  try {
    const { data } = await http.get(`/api/offline-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getOfflineExams = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/offline-exams`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createOfflineExam = async (payload) => {
  try {
    const { data } = await http.post(`/api/offline-exams`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateOfflineExam = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/offline-exams/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteOfflineExam = async (id) => {
  try {
    const { data } = await http.delete(`/api/offline-exams/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
