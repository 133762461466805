import { useState } from "react";

import EmptyData from "../EmptyData";
import PaginationTop from '../PaginationTop';


export default function Table(props) {
  const {
    title,
    subTitle,
    itemsCount,
    headers,
    onSearch, 
    onPageChange,
    searchPlaceholder
  } = props;
  const pagingData = props.pagingData || {};
  pagingData.documentCount = itemsCount;
  const [queryString, setQueryString] = useState(props.queryString);

  return (
    <div className="mx-2">
      {title &&
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{title}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {subTitle}
          </p>
        </div>
      </div>}
      <div className="flow-root">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className={`flex ${title && 'mt-2'} justify-between items-center`}>
              <div className="flex w-1/2">
                {onSearch &&
                <div className="flex w-full">
                  <input
                    type="text"
                    name="queryString"
                    value={queryString || ''}
                    className="block pl-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    placeholder={searchPlaceholder || 'Search by name'}
                    onChange={(event) => setQueryString(event.target.value)}
                  />
                  <button
                    className="block ml-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    onClick={(event) => onSearch(event, queryString)}
                  >
                    Search
                  </button>
                </div>}
              </div>
              
              {itemsCount > 0 && onPageChange &&
              <PaginationTop
                pagingData={pagingData}
                onPageChange={onPageChange}
              />}
            </div>
            
            {itemsCount > 0 ?
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {headers.map((item, index) => (
                  <th
                    key={item}
                    scope="col"
                    className={`py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0 ${index === 0 ? 'text-left' : 'text-center'}`}
                  >
                    {item}
                  </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {props.children}
              </tbody>
            </table>
            : <EmptyData hideNewButton={true} />}
          </div>
        </div>
      </div>
    </div>
  )
};
