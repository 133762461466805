import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit, capitalize, isEmpty, includes } from 'lodash';
import { ArrowDownTrayIcon, UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import { ButtonWithIcon, SubmitButton } from '../../components/buttons';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import DownloadStudentsModal from '../../components/modals/DownloadStudentsModal';
import ConfirmModal from '../../components/modals/ConfirmModal';
import InputModal from '../../components/modals/InputModal';
import { getCoachingStudents, changeStudentStatus, changeStudentMobile } from '../../services/student';


const sidebarNavigation = [
  {name: 'Student list', href: '/students'},
  {name: 'Student admission', href: '/students/admission'}
];
const headers = ['Name', 'Registration no.', 'Mobile', 'Branch', 'Status'];

export default function StudentsPage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryString, setQueryString] = useState(null);
  const [pagingData, setPagingData] = useState({});
  const [statusFilters, setStatusFilters] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchStudents();
      setLoading(false);
    };
    _fetchData();
  }, [
    statusFilters,
    currentPage,
    queryString
  ]);

  const fetchStudents = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: queryString
      };

      if (statusFilters.length > 0) {
        params.status = statusFilters.length === 1 ? statusFilters[0] : statusFilters;
      }

      const data = await getCoachingStudents(params);
      setStudents(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeStudentStatus(selectedStudent._id);
      await fetchStudents();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMobileChange = async (event, newMobile) => {
    try {
      event.preventDefault();
      await changeStudentMobile(selectedStudent._id, {newMobile});
      await fetchStudents();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setLoading(true);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Student list"
    >
      {showStatusModal &&
      <ConfirmModal
        title={selectedStudent?.status === 'active' ? 'Disable student' : 'Enable student'} 
        description={`Are you sure to ${selectedStudent?.status === 'active' ? 'disable' : 'enable'} this student? Please double check before performing this action.`}
        actionName={selectedStudent?.status === 'active' ? 'Disable' : 'Enable'}
        onConfirm={handleStatusChange}
        onCancel={() => {
          setSelectedStudent(null);
          setShowStatusModal(false);
        }}
      />}

      {showDownloadModal &&
      <DownloadStudentsModal
        currentUser={currentUser}
        onCancel={() => setShowDownloadModal(false)}
      />}

      {showInputModal &&
      <InputModal
        label="Mobile number"
        actionName="Change"
        currentValue={selectedStudent.mobile}
        onCancel={() => {
          setSelectedStudent(null);
          setShowInputModal(false);
        }}
        onSubmit={handleMobileChange}
      />
      }

      <SectionHeader
        title="Students"
        subTitle="List of all students of your institution."
      >
        <div>
          <div className="flex space-x-4"> 
            <ButtonWithIcon label="Download" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadModal(true)} />
            <SubmitButton label="New student" onClick={() => window.location.href = '/students/registration'} />
          </div>
        </div>
      </SectionHeader>

      <Table
        headers={headers}
        items={students}
        itemsCount={students.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
        queryString={queryString}
        onSearch={onSearch}
        searchPlaceholder="Search by registration, mobile or name"
      >
        {students?.map((student) => (
          <tr key={student._id} className="text-center">
            <td className="py-5 pl-4 pr-3 text-sm text-left sm:pl-0">
              <div className="flex items-center">
                <div className="h-8 w-8 flex-shrink-0">
                  {student.profilePicture ?
                  <img className="h-8 w-8 rounded-full" src={student.profilePicture} alt="" />
                  : <UserIcon className="h-6 w-6 rounded-full" />}
                </div>
                <div className="ml-4">
                  <div className="font-medium text-gray-900">{student.name}</div>
                </div>
              </div>
            </td>
            {/* <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {student.name}
            </td> */}
            <td className="pr-3 py-4 text-sm text-gray-500">{student.registrationNo}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{student.mobile}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{student?.branch?.name || 'Online'}</td>
            <td 
              className={`pr-3 py-4 text-sm ${student.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(student?.status)}
            </td>

            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems
                currentUser={currentUser}
                student={student}
                onAdmission={() => {
                  navigate('/students/admission', { state: { registrationNo: student.registrationNo } });
                }}
                onCollectPayment={() => {
                  navigate('/student-payments/collect', { state: { registrationNo: student.registrationNo } });
                }}
                onStatusChange={() => {
                  setSelectedStudent(student);
                  setShowStatusModal(true);
                }}
                onMobileChange={() => {
                  setSelectedStudent(student);
                  setShowInputModal(true);
                }}
              />
            </td>
          </tr>
        ))}
      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    currentUser,
    student,
    onAdmission,
    onCollectPayment,
    onStatusChange,
    onMobileChange
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/students/${student._id}/edit`} />
        {student.profilePicture &&
        <MenuItem label="Download photo" href={student.profilePicture} />}
      </div>

      {includes(['owner', 'admin'], currentUser.role) &&
      <div className="py-1">
        <MenuItem label="Change mobile" onClick={onMobileChange}  />
      </div>}

      <div className="py-1">
        <MenuItem label="Add new course" onClick={onAdmission}  />
      </div>

      {(includes(['owner', 'admin', 'branchAdmin'], currentUser.role) ||
      currentUser?.permissions?.canManageStudentPayments) &&
      <div className="py-1">
        <MenuItem label="Collect payments" onClick={onCollectPayment} />
      </div>}
      <div className="py-1">
        <MenuItem label="Manage enrolled courses" href={`/students/${student._id}/enrolled-courses`} />
      </div>

      <div className="py-1">
        <MenuItem label={student?.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>

    </ActionMenu>
  )

};
