import http from '../helpers/http';


export const getInstituteSmsBalance = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/balance`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInstituteSms = async () => {
  try {
    const { data } = await http.get(`/api/institutesms/institute`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
