import { Document, Page, Text, View, Font, Canvas, StyleSheet } from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";

import ShulekhaT from '../fonts/SulekhaT/SulekhaT.ttf';
import SutonnyMJ from '../fonts/SutonnyMJ/SutonnyMJ.ttf';
import VillageII from '../fonts/VillageII/VILLIEB_.TTF';
import Nikosh from '../fonts/Nikosh/Nikosh.ttf';
import Gilroy from '../fonts/Gilroy/Gilroy-Regular.ttf';

// The 'theme' object is your Tailwind theme config
const tw = createTw({
  theme: {
    fontFamily: {
      default: ['Nikosh', 'Gilroy'],
    },
    extend: {},
  },
});

Font.register({ family: 'Nikosh', src: Nikosh });
Font.register({ family: 'Gilroy', src: Gilroy });
Font.register({ family: 'SutonnyMJ', src: SutonnyMJ });
Font.register({ family: 'ShulekhaT', src: ShulekhaT });
Font.register({ family: 'VillageII', src: VillageII });

export default function PdfMeritList(props) {
  const { data, onLoadSuccess } = props;
  return (
    <Document onLoadSuccess={onLoadSuccess}>
      <Page size="A4" style={tw("p-12 font-default")}>
        <View style={tw("p-20 bg-gray-100")}>
          <Text style={tw("text-gray-900 text-2xl")}>Merit List</Text>
        </View>

        <View style={tw("flex flex-row justify-between items-center py-4 text-sm border-b border-gray-900")}>
          <Text style={tw("pr-4")}>ID</Text>
          <Text style={tw("pr-4")}>Name</Text>
          <Text style={tw("pr-4")}>Branch</Text>
          <Text style={tw("pr-4")}>MCQ</Text>
          <Text style={tw("pr-4")}>Wittent</Text>
          <Text style={tw("pr-4")}>Total</Text>
          <Text style={tw("pr-4")}>BP</Text>
          <Text style={tw("pr-4")}>CP</Text>
        </View>

        {data.map((item) => (
          <View key={item?._id} style={tw("flex flex-row justify-between items-center py-4 text-sm border-b border-gray-900")}>
            <Text style={tw("pr-4")}>{item?.user?.registrationNo}</Text>
            <Text style={tw("pr-4")}>{item?.user?.name}</Text>
            <Text style={tw("pr-4")}>{item?.branch?.name}</Text>
            <Text style={tw("pr-4")}>{item?.mcqMarksObtained || '-'}</Text>
            <Text style={tw("pr-4")}>{item?.cqMarksObtained || '-'}</Text>
            <Text style={tw("pr-4")}>{item?.marksObtained || '-'}</Text>
            <Text style={tw("pr-4")}>{item?.branchPosition || '-'}</Text>
            <Text style={tw("pr-4")}>{item?.centralPosition || '-'}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
}