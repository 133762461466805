import toast from 'react-hot-toast';
import { forEach } from 'lodash';

import { SelectAsync } from './forms/fields';
import { getBranches } from '../services/branch';


export default function BranchInputWithList(props) {
  let {
    col,
    branches,
    onSelect,
    onRemove
  } = props;
  col= col ? col?.toString() : 'full';

  const getLabel = (branch) => {
    let label = branch.name;

    if (branch?.bnName) {
      label = `${label} (${branch.bnName})`
    }

    return label;
  };

  const searchBranch = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let excludeBranches = [];
        forEach(branches, branch => excludeBranches.push(branch._id));
        let params = { search: queryString, branchType: ['principalBranch', 'branch'] };
        if (excludeBranches.length > 0) {
          params.excludeBranches = excludeBranches;
        }
        const _branches = await getBranches(params);
        let branchOptions = [];
        forEach(_branches, branch => {
          branchOptions.push({
            label: getLabel(branch),
            value: branch
          });
        });
        return branchOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <SelectAsync
        col={col}
        placeholder="Search by name..."
        onSearch={searchBranch}
        onChange={onSelect}
      />

      {branches.length > 0 &&
      <div className={`sm:col-span-full`}>
        <ul className="divide-y divide-gray-100">
          {branches.map((branch, index) => (
            <li key={`branch-index[${index}]`} className="flex items-center justify-between gap-x-6 py-5">
              <div className="flex gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" /> */}
                <div className="flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{branch?.name}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{branch?.district}</p>
                </div>
              </div>
              <button
                className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                onClick={(event) => onRemove(event, index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>}
    </>
  )
};
