import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { filter, cloneDeep, forEach, orderBy, findIndex, includes } from 'lodash';
import {
  ClipboardDocumentListIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import SectionHeader from '../../components/SectionHeader';
import ContentPicker from '../../components/ContentPicker';
import Badge from '../../components/Badge';
import { Select, MultiSelect } from '../../components/forms/fields';
import { ActionButton, ButtonWithIcon, Button } from '../../components/buttons';
import { getPrograms } from '../../services/program';
import { getBranches } from '../../services/branch';
import { getMeritlist, sendMeritlistSms } from '../../services/takenExam';


const sidebarNavigation = [
  { name: 'Upload result', href: '/results/upload' },
  { name: 'Meritlist builder', href: '/results/meritlist' }
];

const actionItems = [
  { name: 'Add online exams', value: 'exam', Icon: ClipboardDocumentListIcon  },
  { name: 'Add offline exams', value: 'offlineExam', Icon: ClipboardDocumentListIcon  }
];

const headers = ['Exam', 'Type', 'Marks'];

export default function MeritlistBuilderPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [contents, setContents] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [contentType, setContentType] = useState(null);
  const [meritlist, setMeritlist] = useState(null);
  const [openContentPicker, setOpenContentPicker] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _programs = await getPrograms({});
        let _programOptions = [];
        forEach(_programs, p => _programOptions.push({label: p.name, value: p._id}));
        setProgramOptions(orderBy(_programOptions, 'label'));

        const _branches = await getBranches({branchType: ['principalBranch', 'branch']});
        setBranches(_branches);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, []);

  const getSelectedContents = () => {
    let selectedContents = [];
    const _contents = filter(contents, content => content.contentType === contentType);
    forEach(_contents, content => {
      if (content.contentType === 'exam') {
        selectedContents.push(content.exam._id);
      } if (content.contentType === 'offlineExam') {
        selectedContents.push(content.offlineExam._id);
      }
    });
    return selectedContents;
  };

  const handleAddContent = (event, content, contentType) => {
    event.preventDefault();
    const _contents = cloneDeep(contents);
    const newContent = { contentType: contentType, [contentType]: content };
    _contents.push(newContent);
    setContents(() => _contents);
  };

  const removeContent = (index) => {
    let _contents = cloneDeep(contents);
    _contents.splice(index, 1);
    setContents(_contents);
  };

  const removeProgram = (event, index) => {
    event.preventDefault();
    let _selectedPrograms = cloneDeep(selectedPrograms);
    _selectedPrograms.splice(index, 1);
    setSelectedPrograms(_selectedPrograms);
  };

  const handleGenerateMeritlist = async () => {
    try {
      const params = {
        programs: selectedPrograms,
        branch: selectedBranch,
        exams: contents
      };
      const _meritlist = await getMeritlist(params);
      setMeritlist(_meritlist);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSendMeritlistSms = async () => {
    try {
      const payload = {
        programs: selectedPrograms,
        branch: selectedBranch,
        exams: contents
      };
      await sendMeritlistSms(payload);
      toast.success('Your request is being processed.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getMeritPosition = (marksObtained) => {
    const markIndex = findIndex(meritlist, item => item.totalMarksObtained === marksObtained);
    return markIndex + 1;
  };

  const exportExcel = async () => {
    try {
      let excelData = [];
      for (let i = 0; i < meritlist?.length; i++) {
        const resultData = meritlist[i];
        excelData.push({
          'Roll': resultData?._id?.user?.registrationNo,
          'Name': resultData?._id.user?.name,
          'Program': resultData?._id?.program?.name,
          'Course': resultData?._id?.course?.name,
          'Branch': resultData?._id?.branch?.name,
          'MCQ Obtained': resultData?.mcqMarksObtained,
          'Written Obtained': resultData?.cqMarksObtained,
          'Total Obtained': resultData?.totalMarksObtained,
          'Position': getMeritPosition(resultData?.totalMarksObtained)
        });
      };

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [['Merit List']], { origin: 'A1' });
      // XLSX.utils.sheet_add_aoa(ws, [['Course', course?.name]], { origin: 'A2' });
      // XLSX.utils.sheet_add_aoa(ws, [['Branch', selectedBranch ? excelData[0]?.Branch : 'All']], { origin: 'A3' });
      
      XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A6' });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Merit List");
      XLSX.writeFile(wb, `MeritList.xlsx`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Meritlist builder"
    >
      {(!meritlist || meritlist.length === 0) &&
      <>
        {openContentPicker&&
        <ContentPicker
          title={`Select ${contentType === 'exam' ? 'online' : 'offline'} exams`}
          contentType={contentType}
          selectedContents={getSelectedContents()}
          onSelect={handleAddContent}
          onCancel={() => {
            setContentType(null);
            setOpenContentPicker(false);
          }}
        />}

        <SectionHeader
          title="Meritlist builder"
          subTitle="Build your meritlist as per your need."
        />

        <div className="grid grid-cols-6 gap-x-2">
          <Select
            col={2}
            label="Branch"
            value={selectedBranch}
            options={branches}
            onChange={(event) => setSelectedBranch(event.target.value)}
          />
          <MultiSelect
            col={2}
            label="Programs"
            options={programOptions}
            value={selectedPrograms}
            controlShouldRenderValue={false}
            onChange={(values) => setSelectedPrograms(values)}
          />
        </div>

        
        <div className="block-inline mt-2 space-x-4 space-y-2">
          {selectedPrograms.length > 0 && selectedPrograms.map((_program, index) => (
          <Badge
            key={`program-index[${index}]`}
            label={_program.label}
            index={index}
            showRemove={true}
            onRemove={removeProgram}
          />
          ))}
        </div>

        <div className="flex items-center space-x-4">
          <ActionButton
            label="Select exams"
            items={actionItems}
            onClick={(item) => {
              setContentType(item.value);
              setOpenContentPicker(true);
            }}
          />

          <Button
            disabled={contents.length === 0}
            label="Generate meritlist"
            onClick={handleGenerateMeritlist}
          />
        </div>

        <Table
          headers={headers}
          itemsCount={contents.length}
        >
          {contents.map((content, index) => (
            <tr
              key={`content-index[${index}]`}
              className="text-center"
            >
              <td
                className="py-4 pl-4 pr-3 text-sm font-medium text-left text-gray-900 sm:pl-0 text-ellipsis"
              >
                {index+1}{'. '}{content?.exam?.name || content?.offlineExam?.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{content.contentType === 'exam' ? 'Online exam' : 'Offline exam'}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {content?.exam?.totalMarks || content?.offlineExam?.totalMarks}
              </td>
              <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <div className="flex justify-end items-center space-x-2">
                  <p
                    className="text-red-600 hover:text-red-500 underline cursor-pointer"
                    onClick={() => removeContent(index)}
                  >
                    Remove
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </>}

      {meritlist?.length > 0 &&
      <>
        <SectionHeader
          title="Meritlist"
        >
          <div>
            <div className="flex space-x-4">
              <Button label="Reset" onClick={() => setMeritlist(null)} />
              <ButtonWithIcon
                label="Download"
                Icon={ArrowDownTrayIcon}
                onClick={exportExcel}
              />
              {includes(['owner', 'admin'], currentUser.role) &&
              <Button label="Send SMS" onClick={handleSendMeritlistSms} />}
            </div>
          </div>

        </SectionHeader>
        <Table
          headers={['Roll', 'Name', 'Branch', 'Written', 'MCQ', 'Total obtained', 'Position']}
          itemsCount={meritlist.length}
        >
          {meritlist.map((item, index) => (
            <tr
              key={`meritlist-index[${index}]`}
              className="text-center"
            >
              <td
                className="py-4 pl-4 pr-3 text-sm font-medium text-left text-gray-900 sm:pl-0 text-ellipsis"
              >
                {item?._id?.rollNo || '-'}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item?._id?.user?.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item?._id?.branch?.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item.cqMarksObtained}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item.mcqMarksObtained}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item.totalMarksObtained}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {getMeritPosition(item.totalMarksObtained)}
              </td>
              {/* <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <div className="flex justify-end items-center space-x-2">
                  <p
                    className="text-red-600 hover:text-red-500 underline cursor-pointer"
                    onClick={() => removeContent(index)}
                  >
                    Remove
                  </p>
                </div>
              </td> */}
            </tr>
          ))}

        </Table>
      </>}
      
    </StackedLayout>
  )
};