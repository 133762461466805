import { DangerButton, SubmitButton } from '../buttons';


export default function StackedForm(props) {
  const {
    disabled,
    submitText,
    cancelText,
    onCancel,
    onSubmit,
    onDelete,
    hideCancelButton,
    showDeleteButton
  } = props;

  return (
    <form>
      <div className="space-y-12">
        {props.children}
      </div>

      <div className="mt-6 flex items-center justify-between gap-x-6">
        <div>
          {showDeleteButton && onDelete && <DangerButton onClick={onDelete} />}

        </div>
        <div className="justify-end space-x-4">
          {!hideCancelButton && onCancel &&
          <button 
            type="button" 
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={onCancel}
          >
            {cancelText || 'Cancel'}
          </button>}
          {onSubmit && <SubmitButton disabled={disabled} label={submitText || 'Save'} onClick={onSubmit} />}
        </div>
      </div>
    </form>
  )
}
