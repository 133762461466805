import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex, orderBy, forEach } from 'lodash';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';

import StackedLayout from '../../../layouts/StackedLayout';
import EmptyData from '../../../components/EmptyData';
import { CourseCardGrid } from '../../../components/courseCards';
import { getCourses } from '../../../services/course';
import { getPrograms } from '../../../services/program';


const defaultFilters = [
  {
    fieldName: 'isFree',
    name: 'ফি টাইপ',
    options: [
      { value: true, label: 'ফ্রি কোর্স' }
    ],
  },
  {
    fieldName: 'courseType',
    name: 'কোর্সের ধরণ',
    options: [
      { value: 'offline', label: 'অফলাইন' },
      { value: 'online', label: 'অনলাইন' },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function CourseListPage(props) {
  const params = useParams();
  const grade = params?.grade;
  const freeCoursePage = props.isFree;
  const [courses, setCourses] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchPrograms() {
      const programs = await getPrograms({ status: 'active' });
      let programFilterOptions = [];
      forEach(programs, program => programFilterOptions.push({
        label: program.nameBN || program.name,
        value: program._id.toString()
      }));
      if (programFilterOptions.length > 0) {
        let _filters = cloneDeep(filters);
        _filters.push({
          name: 'প্রোগ্রামসমূহ',
          fieldName: 'program',
          options: programFilterOptions
        });
        setFilters(_filters);
      }
    };
    _fetchPrograms();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchCourses();
      setLoading(false);
    };
    _fetchData();
  }, [
    selectedPrograms,
    selectedTypes,
    isFree
  ]);

  const fetchCourses = async () => {
    try {
      let params = { isOnsite: true, status: 'active' };
      if (selectedPrograms.length > 0) {
        params.program = selectedPrograms.length === 0 ? selectedPrograms[0] : selectedPrograms;
      }
      if (selectedTypes.length === 1) {
        params.courseType = selectedTypes[0] === 'offline' ? 'offline' : ['live', 'recorded', 'exam'];
      }
      if (isFree) { params.isFree = true; }

      const _courses = await getCourses(params);
      setCourses(() => orderBy(_courses, 'createdAt', 'desc'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFilterChanges = async (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    let _programs = cloneDeep(selectedPrograms);
    let _courseTypes = cloneDeep(selectedTypes);
    let _isFree = cloneDeep(isFree);

    if (fieldName === 'program') {
      const valueIndex = findIndex(_programs, p => p === fieldValue);
      if (valueIndex === -1) {
        _programs.push(fieldValue)
      } else {
        _programs.splice(valueIndex, 1);
      }
      setSelectedPrograms(_programs);
    } else if (fieldName === 'courseType') {
      const valueIndex = findIndex(_courseTypes, t => t === fieldValue);
      if (valueIndex === -1) {
        _courseTypes.push(fieldValue)
      } else {
        _courseTypes.splice(valueIndex, 1);
      }
      setSelectedTypes(_courseTypes);
    } else if (fieldName === 'isFree') {
      _isFree = _isFree ? false : true;
      setIsFree(_isFree);
    }
  };

  const showFilter = (fieldName) => {
    if (fieldName === 'grade' && grade) {
      return false;
    } else if (fieldName === 'isFree' && freeCoursePage) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <StackedLayout
      loading={loading}
      allowAnonymous={true}
      hideFooter={true}
    >
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    {filters.map((section) => {
                      if (!showFilter(section.fieldName)) { return; }
                       return (
                        <Disclosure as="div" key={section.name} className="border-t border-gray-200 pb-4 pt-4">
                          {({ open }) => (
                            <fieldset>
                              <legend className="w-full px-2">
                                <Disclosure.Button className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                  <span className="text-sm font-medium text-gray-900">{section.name}</span>
                                  <span className="ml-6 flex h-7 items-center">
                                    <ChevronDownIcon
                                      className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Disclosure.Button>
                              </legend>
                              <Disclosure.Panel className="px-4 pb-2 pt-4">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                      <input
                                        id={`${section.fieldName}-${optionIdx}-mobile`}
                                        name={`${section.fieldName}`}
                                        value={option.value}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                                        onChange={handleFilterChanges}
                                      />
                                      <label
                                        htmlFor={`${section.fieldName}-${optionIdx}-mobile`}
                                        className="ml-3 text-sm text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </fieldset>
                          )}
                        </Disclosure>
                      )
                    })}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div>
          <div className="border-b border-gray-200 pb-10">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">সকল কোর্স</h1>
            <p className="mt-4 text-base text-gray-500">
              আপনার সেরা প্রস্তুতি নিতে এখনি ভর্তি হোন দেশসেরা অফলাইন/অনলাইন কোর্সে
            </p>
          </div>

          <div className="pt-6 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">Filters</span>
                <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </button>

              <div className="hidden lg:block">
                <form className="space-y-6 divide-y divide-gray-200">
                  {filters.map((section, sectionIdx) => {
                    if (!showFilter(section.fieldName)) { return; }
                    return (
                      <div key={section.name} className={sectionIdx === 0 ? null : 'pt-6'}>
                        <fieldset>
                          <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                          <div className="space-y-3 pt-6">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                <input
                                  id={`${section.fieldName}-${optionIdx}`}
                                  name={`${section.fieldName}`}
                                  value={option.value}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                                  onChange={handleFilterChanges}
                                />
                                <label htmlFor={`${section.fieldName}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    )
                  })}
                </form>
              </div>
            </aside>

            {/* Product grid */}
            <div className="mt-6 mx-2 lg:col-span-2 lg:mt-0 xl:col-span-3">
              {courses.length > 0 ?
              <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                {courses.map((course, index) => (
                  <CourseCardGrid key={`course-card-index[${index}]`} course={course} />
                ))}
              </div>
              : <EmptyData title="দুঃখিত, কোন কোর্স খুঁজে পাওয়া যায়নি" subTitle="" /> }
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};
