import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ReactPlayer from 'react-player';

import PlaceholderLoader from './PlaceholderLoader';
import { getVideoPayableUrl } from '../utils/video';
import { includes } from 'lodash';


export default function VideoPlayerPopup(props) {
  const { lesson, onClose } = props;
  const [uri, setUri] = useState(null)
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _uri = await getVideoPayableUrl(lesson);
        setUri(_uri);
        setLoading(false);
      } catch (error) {
        handleClose();
      }
    };
    _fetchData()
  });

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {loading && <PlaceholderLoader />}
                {!loading &&
                  <div className="flex relative w-full items-start">
                    <ReactPlayer
                      url={uri}
                      onEnded={handleClose}
                      controls
                      playing
                      config={{
                        youtube: {
                          playerVars: {
                            autoplay: 1,            // Allow autoplay
                            controls: 1,           // Show the controls
                            modestbranding: 1,      // Remove YouTube logo
                            rel: 0,                 // Hide related videos
                            iv_load_policy: 3,      // Disable annotations
                            fs: 0,                  // Hide fullscreen button
                            cc_load_policy: 0,      // Disable captions by default
                            disablekb: 1,           // Disable keyboard controls
                          },
                        },
                      }}
                    />
                    {includes(uri, 'youtube.com') &&
                      <>
                        <div className="absolute top-0 left-0 w-full h-20 bg-black opacity-0"></div>
                        <div className="absolute bottom-0 right-0 w-1/2 h-20 bg-black opacity-0"></div>
                      </>
                    }
                  </div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}