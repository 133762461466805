import { useEffect, useState, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import toast from 'react-hot-toast';
import katex from 'katex';
import mathquill4quill from 'mathquill4quill';

import { formats, mathQuillOperators } from './config';
import { uploadQuestionImage } from '../../services/upload';


window.katex = katex;
const Font = Quill.import('formats/font');
Font.whitelist = ['', 'sutonnymj', 'sulekhaT','villageII'];
Quill.register(Font, true);

const toolbarContainer = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'font': Font.whitelist }],
  ['bold', 'italic', 'underline'],
  [{ 'align': [] }, {'list': 'ordered'}, {'list': 'bullet'}],
  [{ 'script': 'sub'}, { 'script': 'super' }],
  ['link', 'image', 'formula'],
];

export default function Editor(props) {
  let reactQuillRef = useRef();
  const {
    editorId,
    onChange,
    placeholder
  } = props;
  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(
      reactQuillRef.current.editor,
      { operators: mathQuillOperators }
    );
  }, []);

  const handleChanges = (value) => {
    setValue(value);
    onChange(value);
  };

  const imageHandler = () => {
    const editor = reactQuillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const imageUrl = await uploadImage(file);
        editor.insertEmbed(editor.getSelection(), "image", imageUrl);
      } else {
        toast.error('You could only upload images.');
      }
    };
  }

  const uploadImage = async (file) => {
    try {
      const data = new FormData();
      data.append('image', file);
      const responseData = await uploadQuestionImage(data);
      return responseData.imageUrl;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const modules = useMemo(() => ({
    formula: true,
    toolbar: {
      container: toolbarContainer,
      handlers: {
        'image': imageHandler
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }), []);

  return (
    <div className={`richTextEditor ${editorId}`}>
      <ReactQuill
        ref={reactQuillRef}
        id={editorId}
        value={value}
        formats={formats}
        modules={modules}
        onChange={(value) => handleChanges(value)}
        placeholder={placeholder || 'Start writing here...'}
        theme="snow"
      />
    </div>
  );
}
