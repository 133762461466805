import { XMarkIcon } from '@heroicons/react/24/outline';


export default function Badge(props) {
  const { label, showRemove, onRemove, index } = props;
  const color = props.color || 'gray';

  return (
    <span className={`inline-flex items-center gap-x-0.5 rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-600`}>
      {label}
      {showRemove &&
      <button 
        className="group -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
        onClick={(event) => onRemove(event, index)}
      >
        <span className="sr-only">Remove</span>
        <XMarkIcon className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"/>
        {/* <span className="absolute -inset-1" /> */}
      </button>}
    </span>
  )
};