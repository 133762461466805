import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import { InlineCheckbox } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { getInstituteUserPermission, updateUserPermission } from '../../../services/permission';


export default function InstituteUserPermissionForm(props) {
  const { user, onClose } = props;
  const [permission, setPermission] = useState(null);
  const [role, setRole] = useState(user?.role);
  const [canManageCoaching, setCanManageCoaching] = useState(false);
  const [canManageVideos, setCanManageVideos] = useState(false);
  const [canManageNotes, setCanManageNotes] = useState(false);
  const [canManageQuestions, setCanManageQuestions] = useState(false);
  const [canManageExams, setCanManageExams] = useState(false);
  const [canManageCourses, setCanManageCourses] = useState(false);
  const [canManageBilling, setCanManageBilling] = useState(false);
  const [canManageCompetitions, setCanManageCompetitions] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setUpdating(true);
        const userPermission = await getInstituteUserPermission(user._id);
        setPermission(userPermission);
        setCanManageCoaching(userPermission.canManageCoaching);
        setCanManageVideos(userPermission.canManageVideos);
        setCanManageNotes(userPermission.canManageNotes);
        setCanManageQuestions(userPermission.canManageQuestions);
        setCanManageExams(userPermission.canManageExams);
        setCanManageCourses(userPermission.canManageCourses);
        setCanManageBilling(userPermission.canManageBilling);
        setCanManageCompetitions(userPermission.canManageCompetitions);
        setUpdating(false);
      } catch (error) {
        toast.error(error.message);
        onClose();
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCanManageCoaching(false);
    setCanManageVideos(false);
    setCanManageNotes(false);
    setCanManageQuestions(false);
    setCanManageExams(false);
    setCanManageCourses(false);
    setCanManageBilling(false);
    setCanManageCompetitions(false);
  }, [role]);

  const handleSavePermission = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        role,
        canManageCoaching,
        canManageVideos,
        canManageNotes,
        canManageQuestions,
        canManageExams,
        canManageCourses,
        canManageBilling,
        canManageCompetitions
      };
      const updatedPermission = await updateUserPermission(permission?._id, payload);
      setPermission(updatedPermission);
      toast.success('Permission updated successfully.');
      onClose();
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  return (
    <Sidepanel
      title="User permissions"
      subTitle={user.name}
      onSave={handleSavePermission}
      onClose={onClose}
      updating={updating}
      disabled={updating}
    >
      <SidepanelField
        label="Permissions"
        col={3}
      >
        <div className="space-y-4">
          <InlineCheckbox
              label="Admin"
              description="An admin can perform all actions like an owner but can't remove the owner."
              checked={role === 'admin'}
              onChange={() => setRole(role === 'admin' ? null : 'admin')}
            />

            <InlineCheckbox
              label="Manage videos"
              description="Can create/edit video lessons."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageVideos}
              onChange={() => setCanManageVideos(!cloneDeep(canManageVideos))}
            />

            <InlineCheckbox
              label="Manage notes"
              description="Can create/edit pdf notes/ebooks."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageNotes}
              onChange={() => setCanManageNotes(!cloneDeep(canManageNotes))}
            />

            <InlineCheckbox
              label="Manage questions"
              description="Can create/edit all type of questions."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageQuestions}
              onChange={() => setCanManageQuestions(!cloneDeep(canManageQuestions))}
            />

            <InlineCheckbox
              label="Manage exams"
              description="Can create/edit all type of exams."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageExams}
              onChange={() => setCanManageExams(!cloneDeep(canManageExams))}
            />

            <InlineCheckbox
              label="Manage courses"
              description="Can create/edit online/offline courses and their contents."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageCourses}
              onChange={() => setCanManageCourses(!cloneDeep(canManageCourses))}
            />

            <InlineCheckbox
              label="Manage coaching settings"
              description="Can add/edit coaching branches, programs, courses, batches, etc."
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageCoaching}
              onChange={() => setCanManageCoaching(!cloneDeep(canManageCoaching))}
            />

            <InlineCheckbox
              label="Manage Billing"
              description="Can manage billing related activities"
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageBilling}
              onChange={() => setCanManageBilling(!cloneDeep(canManageBilling))}
            />
            <InlineCheckbox
              label="Manage competitions"
              description="Can add/edit competitions and it's participants"
              disabled={role === 'admin'}
              checked={role === 'admin' || canManageCompetitions}
              onChange={() => setCanManageCompetitions(!cloneDeep(canManageBilling))}
            />
        </div>
      </SidepanelField>
    </Sidepanel>
  );
};
