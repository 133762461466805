import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { BanknotesIcon, CurrencyBangladeshiIcon } from '@heroicons/react/24/outline';
import { capitalize, includes } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Input, Select } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { images } from '../../constants';
import { getInvoice, payInvoice } from '../../services/invoice';
import { getNormalizedDateTime } from '../../utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const paymentMethods = [
  { title: 'Cash', value: 'cash' },
  { title: 'bKash', value: 'bkash', icon: images.Bkash },
  { title: 'Nagad', value: 'nagad', icon: images.Nagad },
  { title: 'Credit card', value: 'card', icon: images.CreditCard },
  { title: 'Cheque', value: 'cheque' },
];
const breadcrumbPages = [
  { name: 'Due payments', href: '/student-payments/due' },
  { name: 'Collection', current: true },
];

export default function CollectPaymentPage() {
  const navigate = useNavigate();
  const params = useParams();
  const invoiceId = params.invoiceId;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [invoice, setInvoice] = useState(null);
  const [paidAmount, setPaidAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [dueDate, setDueDate] = useState(null);
  const [merchantNumber, setMerchantNumber] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [cardNo, setCardNo] = useState(null);
  const [depositedBank, setDepositedBank] = useState(null);
  const [chequeNo, setChequeNo] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _invoice = await getInvoice(invoiceId);
        if (_invoice.status === 'paid') {
          return navigate(-1);
        }
        setInvoice(_invoice);
        setPaidAmount(_invoice.amount);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handlePaymentMethodFieldChange = (event) => {
    const filedName = event.target.name;
    const fieldValue = event.target.value;

    const stateMethods = {
      merchantNumber: setMerchantNumber,
      transactionId: setTransactionId,
      cardNo: setCardNo,
      depositedBank: setDepositedBank,
      chequeNo: setChequeNo,
      bankName: setBankName
    };
    stateMethods[filedName](fieldValue)
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setMerchantNumber(null);
    setTransactionId(null);
    setCardNo(null);
    setDepositedBank(null);
    setChequeNo(null);
    setBankName(null);
  };

  useEffect(() => {
    setDueAmount((invoice?.amount || 0) - paidAmount);
  }, [invoice, paidAmount]);

  const handlePayInvoice = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const paymentMethod = selectedPaymentMethod.value;
      let payload = {
        invoice: invoiceId,
        paidAmount: paidAmount,
        dueAmount: dueAmount,
        dueDate: dueDate,
        paymentMethod: paymentMethod,
        paymentBranch: currentBranch._id,
      };
      if (paymentMethod === 'bkash') {
        payload.bkashMerchant = merchantNumber;
        payload.bkashTransactionId = transactionId;
      } else if (paymentMethod === 'nagad') {
        payload.nagadMerchant = merchantNumber;
        payload.nagadTransactionId = transactionId;
      } else if (paymentMethod === 'card') {
        payload.cardNumber = cardNo;
        payload.depositedBank = depositedBank;
      } else if (paymentMethod === 'cheque') {
        payload.chequeNumber = chequeNo;
        payload.bankName = bankName;
      }

      const data = await payInvoice(invoiceId, payload);
      navigate(`/student-payments/payment/success`, {
        state: {
          transactionId: data.transactionId,
          invoiceId: data.invoiceId
        }
      })
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || paidAmount <= 0 || (dueAmount > 0 && !dueDate) ||
    (includes(['bkash', 'nagad'], selectedPaymentMethod.value) && (!merchantNumber || !transactionId)) ||
    (selectedPaymentMethod.value === 'card' && (!cardNo || !depositedBank)) ||
    (selectedPaymentMethod.value === 'cheque' && (!chequeNo || !bankName));
  };

  return (
    <StackedLayout
      loading={loading}
      breadcrumbPages={breadcrumbPages}
    >
      {showConfirmModal &&
      <ConfirmModal
        title="Confirm payment"
        description="Are you sure confirm this payment? Please double check before performing this action."
        actionName="Confirm"
        onConfirm={handlePayInvoice}
        onCancel={() => setShowConfirmModal(false)}
      />
      }

      <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div>
            <h2 className="text-lg font-medium text-gray-900">Information</h2>
            <dl className="mt-6 space-y-4 border-t border-gray-200 text-sm leading-6">
              <div className="pt-4 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Student registration number</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">{invoice?.user?.registrationNo}</div>
                </dd>
              </div>
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Student name</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">{invoice?.user?.name}</div>
                </dd>
              </div>
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Program</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">{invoice?.program?.name}</div>
                </dd>
              </div>
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Payment for</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    {invoice?.title || invoice?.course?.name}
                  </div>
                </dd>
              </div>
              {invoice?.invoiceFor === 'tuitionFee' &&
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Course</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    {invoice?.course?.name}
                  </div>
                </dd>
              </div>}
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Billing date</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    {getNormalizedDateTime(invoice?.billingDate || invoice?.createdAt, 'DD MMM YYYY')}
                  </div>
                </dd>
              </div>
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Due date</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    {getNormalizedDateTime(invoice?.dueDate, 'DD MMM YYYY')}
                  </div>
                </dd>
              </div>
              <div className="sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Status</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">
                    {capitalize(invoice.status)}
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="border-gray-200 pt-6">
            <PaymentMethods
              currentBranch={currentBranch}
              selectedPaymentMethod={selectedPaymentMethod}
              merchantNumber={merchantNumber}
              transactionId={transactionId}
              cardNo={cardNo}
              depositedBank={depositedBank}
              chequeNo={chequeNo}
              bankName={bankName}
              onSelectPaymentMethod={handlePaymentMethodChange}
              onFieldChange={handlePaymentMethodFieldChange}
            />
          </div> 
        </div>

        {invoice &&
        <div className="mt-10 lg:mt-0">
          <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
          <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">

              {includes(['course', 'onlineCourse'], invoice?.invoiceFor) &&
              <>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Course fee</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{invoice.originalFee.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Promotional discount(-)</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{invoice.discount.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Special discount(-)</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{invoice.specialDiscount.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold">Sub-total</dt>
                  <dd className="text-sm font-semibold text-gray-900">৳{invoice.fee.toLocaleString()}</dd>
                </div>
                
                <div className="flex items-center justify-between">
                  <dt className="text-sm">VAT(+)</dt>
                  <dd className="text-sm text-gray-900">৳{invoice.vat.toLocaleString()}</dd>
                </div>
              </>}

              {invoice?.invoiceFor === 'tuitionFee' &&
              <>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Tuition fee</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{invoice.fee.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Scholarship (-)</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{invoice.monthlyScholarship.toLocaleString()}</dd>
                </div>
              </>}

              <div className={`flex items-center justify-between ${invoice.invoiceFor !== 'duePayment' && 'border-t border-gray-200 pt-6'}`}>
                <dt className="text-base font-medium">{invoice?.invoiceFor === 'duePayment' ? 'Due amount' : 'Total'}</dt>
                <dd className="text-base font-medium text-gray-900">৳{invoice.amount.toLocaleString()}</dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-sm">Paid amount</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="number" textRight value={paidAmount} onChange={(event) => setPaidAmount(event.target.value)} />
                </dd>
              </div>
              <div className="flex items-center justify-between text-red-700">
                <dt className="text-sm">Due amount</dt>
                <dd className="text-sm font-medium">
                ৳{dueAmount.toLocaleString()}
                </dd>
              </div>
              
              {dueAmount > 0 &&
              <div className="flex items-center justify-between">
                <dt className="text-sm">Due date</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="date" value={dueDate} onChange={(event) => setDueDate(event.target.value)} />
                </dd>
              </div>}
            </dl>

            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <SubmitButton
                disabled={isDisabled()}
                className="w-full"
                label="Collect payment"
                onClick={() => setShowConfirmModal(true)}
              />
            </div>
          </div>
        </div>}
      </div>
    </StackedLayout>
  )
};

function PaymentMethods(props) {
  const {
    currentBranch,
    merchantNumber,
    transactionId,
    cardNo,
    depositedBank,
    chequeNo,
    bankName,
    selectedPaymentMethod,
    onSelectPaymentMethod,
    onFieldChange
  } = props;

  return (
    <div className="pb-10">
      <RadioGroup value={selectedPaymentMethod} onChange={onSelectPaymentMethod}>
        <RadioGroup.Label className="text-lg font-medium text-gray-900">Payment method</RadioGroup.Label>
        <div className="mx-2 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {paymentMethods.map((paymentMethod, index) => (
            <RadioGroup.Option
              key={`payment-method-index[${index}]`}
              value={paymentMethod}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'ring-2 ring-green-500' : '',
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="flex items-center space-x-1 text-sm font-medium text-gray-900">
                        {paymentMethod.value === 'cash' &&
                        <CurrencyBangladeshiIcon className="h-6 w-6" />}
                        {paymentMethod.value === 'cheque' &&
                        <BanknotesIcon className="h-6 w-6" />}
                        {paymentMethod.icon &&
                        <img className="h-6 text-green-600" src={paymentMethod.icon} alt="" />}
                        <span>{paymentMethod.title}</span>
                      </RadioGroup.Label>
                      {/* <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500"
                      >
                        No offers available now
                      </RadioGroup.Description> */}
                    </span>
                  </span>
                  {checked ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                  ) : null}
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-green-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {selectedPaymentMethod.value !== 'cash' &&
      <div>
        <dl className="mt-6 space-y-4 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          {selectedPaymentMethod.value === 'bkash' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Merchant number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Select name="merchantNumber" value={merchantNumber} options={currentBranch?.bkashNumbers} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'nagad' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Merchant number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Select name="merchantNumber" value={merchantNumber} options={currentBranch?.nagadNumbers} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {includes(['bkash', 'nagad'], selectedPaymentMethod.value) &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Transaction ID</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Input name="transactionId" value={transactionId || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'card' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Card number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="cardNo" value={cardNo || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'card' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Deposited bank</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="depositedBank" value={depositedBank || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'cheque' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Cheque number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Input name="chequeNo" value={chequeNo || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'cheque' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Bank name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="bankName" value={bankName || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
        </dl>
      </div>}
    </div>
  )
};