import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { isEmpty } from 'lodash';

import Layout from './components/Layout';
import { SubmitButton } from '../../components/buttons';
import { userActions } from '../../store/reducers/userReducers';
import { verifyOTP, resendOTP } from '../../services/userOTP';
import { getCurrentUser } from '../../services/user';


export default function OTPInput() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId;
  const mobile = location?.state?.mobile;
  const otpFor = location?.state?.otpFor || 'registration';
  const redirectUrl = location?.state?.redirectUrl;
  const [code, setCode] = useState(null);
  const [countDown, setCountDown] = useState(60);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!userId || !mobile || !otpFor) { return navigate(-1); }

    let seconds = countDown;
    let timer = setInterval(() => {
      if (--seconds - 1 <= 0) {
        clearInterval(timer);
        setShowResendOtp(true);
      }
      if (seconds > 0) {
        setCountDown(seconds - 1);
      }
    }, 1000);
  }, []);

  const handleVerifyOTP = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        otp: code,
        otpFor: otpFor
      };
      const data = await verifyOTP(userId, payload);

      if (otpFor === 'registration') {
        localStorage.setItem('currentUser', JSON.stringify(data));
        const currentUser = await getCurrentUser();
        dispatch(userActions.setUserInfo(currentUser));
        if (redirectUrl) {
          navigate(redirectUrl)
        } else {
          navigate('/learn/home');
        }
      } else if (otpFor === 'password') {
        navigate(`/auth/reset-password`, {
          state: {
            userId: userId,
            token: data.token,
            redirectUrl: redirectUrl
          }
        });
      }
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  const handleResendOTP = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      setShowResendOtp(false);
      setCode(null);
      const payload = {
        otpFor: otpFor
      };
      await resendOTP(userId, payload);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || isEmpty(code) || code.length < 6;
  };

  return (
    <Layout
      title="কোড ভেরিফিকেশন"
      subTitle={`${mobile} নাম্বারে পাঠানো কোডটি প্রবেশ করুন`}
    >
      <div className="justify-center sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <div className="flex flex-col items-center space-y-16">
            <div>
              <OtpInput
                value={code}
                onChange={setCode}
                numInputs={6}
                renderSeparator={<span style={{width: "8px"}}></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  border: "1px solid #e5e7eb",
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  fontSize: "16px",
                  color: "#07074E",
                  fontWeight: "400",
                  caretColor: "blue"
                }}
                focusStyle={{
                  border: "1px solid #62D499",
                  outline: "none"
                }}
              />
            </div>

            <div className="flex flex-col w-full space-y-5">
              <SubmitButton
                className="w-full"
                disabled={isDisabled()}
                label="যাচাই করুন"
                onClick={handleVerifyOTP}
              />

              <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                {countDown > 0 &&
                <p>পুনরায় কোড পাঠানোর অনুরোধ করতে <b>{countDown}</b> সেকেন্ড অপেক্ষা করুন</p>}
                
                {showResendOtp &&
                <p>কোড এখনো পাননি?{' '}
                  <span className="text-green-600 cursor-pointer" onClick={handleResendOTP}>পুনরায় পাঠান</span>
                </p>}
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
};
