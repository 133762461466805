import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import { Input } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import PaymentMethods from '../../components/PaymentMethods';
import { getInstituteSmsBalance } from '../../services/instituteSms';
import { bkashCheckout } from '../../services/payment';


export default function SmsRechargePage() {
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const queryParams = new URLSearchParams(useLocation().search);
  const status = queryParams.get('status');
  const message = queryParams.get('message');
  const [smsBalance, setSmsBalance] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    async function _fetchData() {
      try {
        const instituteSms = await getInstituteSmsBalance();
        setSmsBalance(instituteSms?.balance || 0);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchData();
  }, []);

  const pay = async () => {
    try {
      setProcessing(true);
      if (selectedPaymentMethod === 'bkash') {
        const payload = {
          amount: rechargeAmount,
          transactionType: 'sms'
        };
        const bkashData = await bkashCheckout(payload);
        if (bkashData?.transactionStatus === 'Initiated') {
          window.location.href = bkashData.bkashURL;
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <StackedLayout>
      {status === 'failed' &&
      <ErrorAlert message={message} />}

      <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div>
            <h2 className="text-lg font-medium text-gray-900">Add SMS balance</h2>
            <dl className="mt-6 space-y-4 border-t border-gray-200 text-sm leading-6">
              <div className="pt-4 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Institute</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">{currentInstitute?.name}</div>
                </dd>
              </div>
              <div className="pt-4 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Current balance</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-600">{smsBalance}</div>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-10 lg:mt-0">
          <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
          <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div className="flex items-center justify-between">
                <dt className="text-sm">Amount</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="number" textRight value={rechargeAmount} onChange={(event) => setRechargeAmount(event.target.value)} />
                </dd>
              </div>
            </dl>

            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <PaymentMethods onSelect={(method) => setSelectedPaymentMethod(method)} />
            </div>

            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <SubmitButton
                disabled={!rechargeAmount || rechargeAmount < 10 || !selectedPaymentMethod || processing}
                className="w-full"
                label="Pay now"
                onClick={() => pay('bkash')}
              />
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};