import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { orderBy, isEmpty } from 'lodash';

import { Input, Toggle, Select } from '../../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../../components/Sidepanel';
// import { getBatches } from '../../../services/batch';
import { getNormalizedDateTime } from '../../../../utils';
import { format, startOfMonth, startOfToday } from 'date-fns';


export default function ChangeBatchScheduleForm(props) {
  const {
    batch,
    schedulePoint,
    onSave,
    onClose
  } = props;
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [updating, setUpdating] = useState(false);
  const currentMonth = format(startOfMonth(startOfToday()), 'yyyy-MM');

  useEffect(() => {
    
  }, []);

  const handleSaveCourse = async (event) => {
    try {
      setUpdating(true);
      let payload = {};
      if (schedulePoint === 'startMonth') {
        payload.newStartMonth = startMonth;
      } else {
        payload.newEndMonth = endMonth;
      }
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating;
  };

  return (
    <Sidepanel
      title={`Change batch ${schedulePoint === 'startMonth' ? 'start month' : 'end month'}`}
      subTitle={`Batch: ${batch?.name}`}
      onSave={handleSaveCourse}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >

      <SidepanelField
        label={`Current ${schedulePoint === 'startMonth' ? 'start' : 'end'} month`}
      >
        <p>{schedulePoint === 'startMonth' ? getNormalizedDateTime(batch.startMonth, 'MMMM YYYY') : getNormalizedDateTime(batch.endMonth, 'MMMM YYYY')}</p>

      </SidepanelField>
      <SidepanelField
        label={`New ${schedulePoint === 'startMonth' ? 'start' : 'end'} month`}
      >
        {schedulePoint === 'startMonth' ?
        <Input type="month" min={currentMonth} value={startMonth} onChange={(event) => setStartMonth(event.target.value)} />
        :<Input type="month" min={currentMonth} value={endMonth} onChange={(event) => setEndMonth(event.target.value)} />}

      </SidepanelField>
    </Sidepanel>
  );
};
