import http from '../helpers/http';


export const getProgramAdmissions = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/program-admissions`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getAdmittedPrograms = async (studentId) => {
  try {
    const { data } = await http.get(`/api/program-admissions/student/${studentId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getAdmittedProgramForProgram = async (programId, params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/program-admissions/program/${programId}`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const regenerateProgramRoll = async (id) => {
  try {
    const { data } = await http.put(`/api/program-admissions/${id}/roll`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
