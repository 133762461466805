import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex, forEach, orderBy } from 'lodash';

import { Sidepanel, SidepanelField } from '../Sidepanel';
import { Input, Select, SelectAsync } from '../forms/fields';
import { getCourses, getCoursesForBranch } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getInstructors } from '../../services/instructor';
import { getSubjects } from '../../services/subject';
import { getBranches } from '../../services/branch';
import { getPrograms } from '../../services/program';
import { getNormalizedDateTime } from '../../utils';


const eventCategories = [
  {name: 'Single batch', value: 'single'},
  {name: 'All batches for a course', value: 'all'},
  // {name: 'Free class', value: 'free'},
];

const courseEventCategories = [
  {name: 'Single batch', value: 'single'},
  {name: 'All batches for a course', value: 'all'},
];


const eventTypes = [
  {name: 'Lecture', value: 'lecture'},
  {name: 'exam', value: 'exam'},
];

const gradeOptions = [
  {name: 'SSC', value: 'ssc'},
  {name: 'HSC', value: 'hsc'},
  {name: 'Admission', value: 'admission'},
  {name: 'Job preparation', value: 'job'},
];

const groupOptions = [
  {name: 'All', value: 'all'},
  {name: 'Science', value: 'science'},
  {name: 'Business studies', value: 'commerce'},
  {name: 'Humanities', value: 'humanities'},
];

const _liveClassPlatforms = [
  { name: 'Zoom', value: 'zoom' },
  { name: 'Facebook', value: 'facebook' },
  { name: 'Google meet', value: 'meet' },
];

export default function LiveClassForm(props) {
  const { liveClass, onSave, onClose } = props;
  const courseType = liveClass?.courseType || props?.courseType || props?.course?.courseType;
  const userBranch = props.userBranch;
  const [branches, setBranches] = useState([]);
  const [name, setName] = useState(liveClass?.name || '');
  const [branch, setBranch] = useState(liveClass?.branch || userBranch);
  const [course, setCourse] = useState(liveClass?.course || props.course);
  const [program, setProgram] = useState(liveClass?.program);
  const [batches, setBatches] = useState([]);
  const [batch, setBatch] = useState(liveClass?.batch?._id || props?.batch);
  const [grade, setGrade] = useState(liveClass?.grade);
  const [group, setGroup] = useState(liveClass?.group || 'all');
  const [instructor, setInstructor] = useState(liveClass?.instructor?._id);
  const [subject, setSubject] = useState(liveClass?.subject?._id);
  const [startsAt, setStartsAt] = useState(liveClass?.startsAt);
  const [endsAt, setEndsAt] = useState(liveClass?.endsAt);
  const [liveClassPlatform, setLiveClassPlaform] = useState(liveClass?.liveClassPlatform || props?.batch?.liveClassPlatform);
  const [zoomMeetingLink, setZoomMeetingLink] = useState(liveClass?.zoomMeetingLink || props?.batch?.zoomMeetingLink);
  const [facebookGroup, setFacebookGroup] = useState(liveClass?.facebookGroup || props?.batch?.facebookGroup);
  const [googleMeetLink, setGoogleMeetLink] = useState(liveClass?.googleMeetLink || props?.batch?.googleMeetLink);
  const [category, setCategory] = useState(!liveClass ? 'single' : liveClass?.isFree ? 'free' : liveClass?.batch ? 'single' : 'all');
  const [subjects, setSubjects] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [liveClassPlatforms, setLiveClassPlatforms] = useState(_liveClassPlatforms);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function _fetchDate() {

      const _programs = await getPrograms({status: 'active'});
      setPrograms(_programs);

      if (courseType === 'offline' && !userBranch) {
        const _branches = await getBranches({status: 'active'});
        setBranches(orderBy(_branches, 'name'));
      }

      if (courseType === 'offline') {
        let _platforms = cloneDeep(liveClassPlatforms);
        _platforms.push({ name: 'Offline/Branch', value: 'branch' });
        setLiveClassPlatforms(_platforms);
      }

      if (grade) {
        await fetchSubjects(grade);
      }
      if (course) {
        await formatOptions(course);
      }
    };
    _fetchDate();
  }, []);

  useEffect(() => {
    async function _fetchCourses() {
      if (program && (courseType !== 'offline' || branch)) {
        let params = {
          status: 'active',
          program: program._id,
          courseType: courseType === 'offline' ? 'offline' : ['live']
        };
        if (courseType === 'offline') {
          const _courses = await getCoursesForBranch(branch._id, params);
          setCourses(_courses);
        } else {
          const _courses = await getCourses(params);
          setCourses(_courses);
        }
      } else {
        setCourses([]);
      }
    };
    _fetchCourses();
  }, [program, branch]);

  useEffect(() => {
    async function _formatData() {
      if (course) {
        await formatOptions(course);
      } else {
        setBatches([]);
      }
    }
    _formatData();
  }, [course, branch]);

  const formatOptions = async (_course) => {
    try {
      let params = { course: _course._id }
      if (courseType === 'offline' && !liveClass) {
        params.branch = branch?._id || userBranch?._id;
      }
      const _batches = await getBatches(params);
      setBatches(orderBy(_batches, 'name'));

      const _subjects = _course.subjects;
      setSubjects(orderBy(_subjects, 'name'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSaveEvent = async (event) => {
    try {
      setUpdating(true);
      let updatedFileds = {
        name,
        subject,
        instructor,
        liveClassPlatform,
        zoomMeetingLink,
        facebookGroup,
        googleMeetLink,
        program: program?._id,
        startsAt: moment(startsAt).zone('+06:00').format(),
        endsAt: moment(endsAt).zone('+06:00').format(),
      };
      updatedFileds.isFree = category === 'free';
      updatedFileds.grade = category === 'free' ? grade : null;
      updatedFileds.group = category === 'free' && grade !== 'job' ? group : null;
      updatedFileds.course = category !== 'free' ? course : null;
      updatedFileds.batch = category === 'single' ? batch : null;
      updatedFileds.courseType = category === 'free' ? null : course.courseType;
      if (!liveClass && courseType === 'offline' && !userBranch) {
        updatedFileds.branch = branch;
      }
      await onSave(event, updatedFileds);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const searchCourse = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let params = { search: queryString };
        let _courses = [];
        if (courseType === 'online'){
          params.courseType = 'live';
          _courses = await getCourses(params);
        } else {
          params.courseType = 'offline';
          _courses = await getCoursesForBranch(branch._id, params);
        }
        let courseOptions = [];
        forEach(_courses, course => {
          courseOptions.push({
            label: `${course.name}`,
            value: course
          });
        });
        return courseOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const searchInstructor = async (queryString) => {
    try {
      if (queryString?.length > 2) {
        let params = {
          search: queryString,
          status: 'active'
        };
        const _instructors = await getInstructors(params);
        let instructorOptions = [];
        forEach(_instructors, instructor => {
          instructorOptions.push({
            label: `${instructor.name} ${instructor.designation}`,
            value: instructor
          });
        });
        return instructorOptions;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleGradeChange = async (e) => {
    const _grade = e.target.value;
    setGrade(_grade);
    if (_grade === 'job') { setGroup(null); }
    await fetchSubjects(_grade);
  };

  const handleGroupChange = async (e) => {
    const _group = e.target.value;
    setGroup(_group);
    await fetchSubjects(grade, _group);
  };

  const fetchSubjects = async (_grade, _group) => {
    try {
      _grade = _grade || grade;
      _group = _group || group;

      let params = {
        grade: _grade,
        status: 'active'
      };
      if (_grade !== 'job' && _group) {
        params.group = _group === 'all' ? _group : ['all', _group];
      }
      const _subjects = await getSubjects(params);
      setSubjects(orderBy(_subjects, 'name'));  
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelectBatch = (event) => {
    event.preventDefault();
    setBatch(event.target.value);
    setLiveClassPlaform(null);
  };

  const handleSelectLiveClassPlaform = (event) => {
    event.preventDefault();
    setLiveClassPlaform(event.target.value);
    const batchIndex = findIndex(batches, b => b?._id?.toString() === batch?.toString());
    const _batch = props?.batch || batches[batchIndex];
    setZoomMeetingLink(liveClass?.zoomMeetingLink || props?.batch?.zoomMeetingLink || _batch?.zoomMeetingLink);
    setFacebookGroup(liveClass?.facebookGroup || props?.batch?.facebookGroup || _batch?.facebookGroup);
    setGoogleMeetLink(liveClass?.googleMeetLink || props?.batch?.googleMeetLink || _batch?.googleMeetLink);
  };

  const handleCategoryChange = (event) => {
    const _category = event.target.value;
    if (_category === 'single') {
      setGrade(null);
      setGroup(null);
      setSubject(null);
    } else if (_category === 'all') {
      setGrade(null);
      setGroup(null);
      setSubject(null);
      setBatch(null);
    } else {
      setCourse(null);
      setBatch(null);
    }
    setCategory(_category);
  };

  const isDisabled = () => {
    if (category === 'free') {
      return updating || !name || !grade || !startsAt || !endsAt || !liveClassPlatform;
    } else if (category === 'single') {
      return updating || !name || !course || !batch || !startsAt || !endsAt || !liveClassPlatform;
    } else {
      return updating || !name || !course || !startsAt || !endsAt || !liveClassPlatform;
    }
  };

  return (
    <Sidepanel
     title={`Live class ${liveClass?._id || ''}`}
     subTitle="Set up infromation to schedule live class."
     updating={updating}
     disabled={isDisabled()}
     onSave={handleSaveEvent}
     onClose={onClose}
    >
      {courseType === 'offline' && !userBranch &&
      <SidepanelField label="Branch">
        {liveClass &&
        <p className="text-sm text-gray-600">{liveClass?.branch?.name}</p>}
        
        {!liveClass &&
        <Select
          value={branch?._id}
          options={branches}
          onChange={(event) => setBranch(branches[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>}

      <SidepanelField label="Program">
        {liveClass &&
        <p className="text-sm text-gray-600">{liveClass?.program?.name}</p>}
        
        {!liveClass &&
        <Select
          value={program?._id}
          options={programs}
          onChange={(event) => setProgram(programs[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>

      {category !== 'free' &&
      <SidepanelField label="Course">
        {(liveClass || props.course) &&
        <p className="text-sm text-gray-600">{liveClass?.course?.name || props?.course?.name}</p>}
        {!liveClass && !props.course &&
        <Select
          value={course?._id}
          options={courses}
          onChange={(event) => setCourse(courses[event.target.selectedIndex - 1])}
        />}
      </SidepanelField>}

      <SidepanelField label="Category">
        <Select
          disabled={liveClass || (category === 'single' && props?.batch)}
          value={category}
          options={(courseType === 'offline' || props.course) ? courseEventCategories : eventCategories}
          onChange={handleCategoryChange}
        />
      </SidepanelField>

      {!props?.batch && category === 'single' &&
      <SidepanelField label="Batch">
        {(liveClass || props.batch) &&
        <p className="text-sm text-gray-600">{liveClass?.batch?.name || props?.batch?.name}</p>}

        {!liveClass && !props.batch &&
        <Select
          value={batch}
          options={batches}
          onChange={handleSelectBatch}
        />}
      </SidepanelField>}

      {category === 'free' &&
      <SidepanelField label="Grade">
        <Select value={grade} options={gradeOptions} onChange={handleGradeChange} />
      </SidepanelField>}

      {category === 'free' &&
      <SidepanelField label="Group">
        <Select disabled={!grade || grade === 'job'} value={group} options={groupOptions} onChange={handleGroupChange} />
      </SidepanelField>}

      <SidepanelField label="Subject">
        <Select
          disabled={category === 'free' ? !grade : !course}
          value={subject}
          options={subjects}
          onChange={(event) => setSubject(event.target.value)}
        />
      </SidepanelField>

      <SidepanelField label="Class title">
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </SidepanelField>

      {courseType !== 'offline' &&
      <SidepanelField label="Instructor">
        <SelectAsync
          placeholder="Search by name..."
          onSearch={searchInstructor}
          value={{label: instructor?.name, value: instructor}}
          onChange={(item) => setInstructor(item.value)}
        />  
      </SidepanelField>}

      <SidepanelField label="Class schedule">
        <div className="flex justify-between space-x-2">
          <Input
            col={1}
            label="Starts from"
            type="datetime-local" 
            name="startsAt" 
            value={startsAt ? getNormalizedDateTime(startsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setStartsAt(event.target.value)} 
          />
          <Input
            col={1}
            label="Ends at"
            disabled={!startsAt}
            type="datetime-local" 
            min={getNormalizedDateTime(startsAt, 'yyyy-MM-DDTHH:mm')}
            name="endsAt" 
            value={endsAt ? getNormalizedDateTime(endsAt, 'yyyy-MM-DDTHH:mm') : ''} 
            onChange={(event) => setEndsAt(event.target.value)} 
          />
        </div>
      </SidepanelField>

      <SidepanelField label="Live class platform">
        <Select value={liveClassPlatform} options={liveClassPlatforms} onChange={handleSelectLiveClassPlaform} />
      </SidepanelField>

      {liveClassPlatform === 'zoom' &&
      <SidepanelField label="Zoom meeting link">
        <Input value={zoomMeetingLink} onChange={(event) => setZoomMeetingLink(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'facebook' &&
      <SidepanelField label="Facebook group link">
        <Input value={facebookGroup} onChange={(event) => setFacebookGroup(event.target.value)} />
      </SidepanelField>}

      {liveClassPlatform === 'meet' &&
      <SidepanelField label="Google meet link">
        <Input value={googleMeetLink} onChange={(event) => setGoogleMeetLink(event.target.value)} />
      </SidepanelField>}

    </Sidepanel>
  )
};
