import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function SubmitButton(props) {
  const { label, disabled, onClick } = props;
  const institutteState = useSelector((state) => state.institute);
  const currentInstitute = institutteState?.instituteInfo;
  const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;

  return (
    <button
      disabled={disabled}
      style={{ backgroundColor: brandColor }}
      className={classNames(
        props.className || '',
        `inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-30 disabled:cursor-not-allowed`
      )}
      onClick={onClick}
    >
      {label || 'Add more'}
    </button>
  )
};
