import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { Input } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { instituteActions } from '../../store/reducers/instituteReducer';
import { getCurrentInstitute, updateInstitute } from '../../services/institute';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

const defaultUpdateStates = {
  googleTagManagerId: false,
  facebookPixelId: false
};

export default function AnalyticsConfigurationPage() {
  const dispatch = useDispatch();
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const [googleTagManagerId, setGtmId] = useState(currentInstitute?.googleTagManagerId);
  const [facebookPixelId, setFbPixelId] = useState(currentInstitute?.facebookPixelId);
  const [updateStates, setUpdateState] = useState(defaultUpdateStates)

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'googleTagManagerId') { setGtmId(currentInstitute.googleTagManagerId); }
    else if (fieldName === 'facebookPixelId') { setFbPixelId(currentInstitute.facebookPixelId); }
  };

  const onSave = async (event, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {};
      payload[fieldName] = value;
      await saveInstitute(payload);
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveInstitute = async (payload) => {
    await updateInstitute(currentInstitute._id, payload);
    const _currentInstitute = await getCurrentInstitute();
    dispatch(instituteActions.setInstituteInfo(_currentInstitute));
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Analytics configuration"
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Analytics configuration</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Set your credentials for analytics
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Google tag manager ID</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.googleTagManagerId && <div className="text-gray-900">{currentInstitute?.googleTagManagerId || 'Empty'}</div>}
              {updateStates.googleTagManagerId &&
              <div className="w-full">
                <Input value={googleTagManagerId} onChange={(event) => setGtmId(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'googleTagManagerId', googleTagManagerId)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'googleTagManagerId')} />
                </div>
              </div>}
              
              {!updateStates.googleTagManagerId &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'googleTagManagerId')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Facebook pixel ID</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.facebookPixelId && <div className="text-gray-900">{currentInstitute?.facebookPixelId || 'Empty'}</div>}
              {updateStates.facebookPixelId &&
              <div className="w-full">
                <Input value={facebookPixelId} onChange={(event) => setFbPixelId(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'facebookPixelId', facebookPixelId)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'facebookPixelId')} />
                </div>
              </div>}
              
              {!updateStates.facebookPixelId &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'facebookPixelId')}
              >
                Update
              </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
