import { configureStore, } from '@reduxjs/toolkit';
import { userReducer } from './reducers/userReducers';
import { instituteReducer } from './reducers/instituteReducer';
import { branchReducer } from './reducers/branchReducer';
import { getCurrentUser } from '../services/user';
import { getCurrentInstitute } from '../services/institute';
import { getCurrentBranch } from '../services/branch';


const _getCurrentUser = async () => {
  try {
    const data = await getCurrentUser();
    return data;
  } catch {
    return null;
  }
};

const _getCurrentInstitute = async () => {
  try {
    const data = await getCurrentInstitute();
    return data;
  } catch {
    return null;
  }
};

const _getCurrentBranch = async () => {
  try {
    const data = await getCurrentBranch();
    return data;
  } catch {
    return null;
  }
};

const userInfoFromStorage = await _getCurrentUser();
const instituteInfoFromStorage = await _getCurrentInstitute();
const branchInfoFromStorage = await _getCurrentBranch();

const initialState = {
  user: { userInfo: userInfoFromStorage },
  institute: { instituteInfo: instituteInfoFromStorage },
  branch: { branchInfo: branchInfoFromStorage },
};

const store = configureStore({
  reducer: {
    user: userReducer,
    institute: instituteReducer,
    branch: branchReducer,
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

export default store;
