import { keys, round } from 'lodash';

import { convertToBanglaNumber } from '../../../../utils';


const headers = ['বিষয়', 'মোট প্রশ্ন', 'সঠিক উত্তর', 'ভুল উত্তর', 'প্রাপ্ত নম্বর'];

export default function ResultCard(props) {
  const { takenExam } = props;
  return (
    <div className="py-6 w-full sm:w-2/3">
      <h3 className="text-sm font-semibold">
        বিষয়ভিত্তিক নম্বরপত্র
      </h3>

      <table className="text-sm text-gray-600 divide-y divide-gray-300">
        <thead>
          <tr>
            {headers.map((item, index) => (
              <th
                key={`header-index[${index}]`}
                scope="col"
                className="pr-3 py-4 pl-4 font-normal text-sm text-gray-900"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-300">
          {keys(takenExam.questions).map((subject, index) => (
            <tr key={`subject-index[${index}]`}>
              <td className="pr-3 py-4 text-sm text-gray-500">{subject}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.questions[subject].noOfQuestions)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.questions[subject].correctAnswers)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.questions[subject].wrongAnswers)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">
                {convertToBanglaNumber(takenExam.questions[subject].marksObtained)} ({convertToBanglaNumber(round((takenExam.questions[subject].marksObtained * 100)/(takenExam.questions[subject].noOfQuestions*takenExam.marksPerQuestion), 2))}%)
              </td>
            </tr>
          ))}
          <tr className="font-semibold">
              <td className="pr-3 py-4 text-sm text-gray-500">মোট</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.totalMcqQuestions)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.mcqCorrectAnswers)}</td>
              <td className="pr-3 py-4 text-sm text-gray-500 text-center">{convertToBanglaNumber(takenExam.mcqWrongAnswers)}</td>
              <td className="pr-3 py-4 text-sm text-gray-700 text-center">
                {convertToBanglaNumber(takenExam.totalMarksObtained)} ({convertToBanglaNumber(round((takenExam.totalMarksObtained * 100)/(takenExam.totalMcqQuestions*takenExam.marksPerMcqQuestion), 2))}%)
              </td>
            </tr>
        </tbody>
      </table>   
    </div>
  )
};