import { useState } from 'react';

import { Input, Select } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';


const feeTypes = [
  {name: 'Admission fee', value: 'admissionFee'},
  {name: 'Tuition fee', value: 'tuitionFee'},
  {name: 'Exam fee', value: 'examFee'},
];

export default function TuitionFeeForm(props) {
  const {
    tuitionFee,
    onSave,
    onClose
  } = props;
  const [feeType, setFeeType] = useState(tuitionFee?.feeType);
  const [name, setName] = useState(tuitionFee?.name);
  const [fee, setFee] = useState(tuitionFee?.fee || 0);
  const [billingDate, setBillingDate] = useState(tuitionFee?.billingDate);
  const [dueDate, setDueDate] = useState(tuitionFee?.dueDate);
  const [updating, setUpdating] = useState(false);

  const handleSaveTuitionFee = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        feeType,
        name,
        fee,
        billingDate,
        dueDate
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !name || !feeType || !fee || fee <= 0;
  };

  return (
    <Sidepanel
      title="Tuition fee"
      subTitle="Set up tuition fee"
      onSave={handleSaveTuitionFee}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="Fee type"
      >
        <Select
          disabled={tuitionFee}
          value={feeType}
          options={feeTypes}
          onChange={(event) => setFeeType(event.target.value)}
        />
      </SidepanelField>

      <SidepanelField
        label="Fee name"
      >
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Amount"
      >
        <Input type="number" value={fee} onChange={(event) => setFee(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Billing date"
      >
        <Input type="date" value={billingDate} onChange={(event) => setBillingDate(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Due date"
      >
        <Input type="date" value={dueDate} onChange={(event) => setDueDate(event.target.value)} />
      </SidepanelField>
    </Sidepanel>
  );
};
