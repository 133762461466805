import { useEffect, useState, useRef } from 'react';
import { forEach, cloneDeep } from 'lodash';

import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { Input } from '../../../components/forms/fields';
import { PlusButton } from '../../../components/buttons';
import ContentPicker from '../../../components/ContentPicker';
import InstructorInputWithList from '../../../components/InstructorInputWithList';
import LessonInputWithList from '../../../components/LessonInputWithList';
import VideoPlayer from '../../../components/VideoPlayer';
import { getVideo } from '../../../services/video';



export default function AddYoutubeVideoForm(props) {
  const {
    lesson,
    lessonType,
    onClose,
    onSave
  } = props;
  const [youtubeId, setYoutubeId] = useState(lesson?.youtubeId || '');
  const [duration, setDuration] = useState(lesson?.formattedDuration || '');
  const [updating, setUpdating] = useState(false);
  const playerRef = useRef();

  const handleSaveChanges = async (event) => {
    try {
      setUpdating(true);
      let payload = { youtubeId, formattedDuration: duration };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || !youtubeId;
  };

  return (
    <Sidepanel
      title={`Add youtube video`}
      subTitle={`Set up youtube video information.`}
      onClose={onClose}
      onSave={handleSaveChanges}
      disabled={isDisabled()}
      updating={updating}
    >

      <SidepanelField
        label="Youtube video ID"
      >
        <Input name="title" value={youtubeId} onChange={(event) => setYoutubeId(event.target.value)} />
      </SidepanelField>

      <SidepanelField
        label="Duration"
      >
        <Input name="title" value={duration} onChange={(event) => setDuration(event.target.value)} />
      </SidepanelField>

      {youtubeId &&
        <SidepanelField label="Video">
          <div className="rounded-md overflow-hidden" ref={playerRef}>
            <VideoPlayer uri={`https://www.youtube.com/embed/${youtubeId}`} playerRef={playerRef} />
          </div>
        </SidepanelField>}
    </Sidepanel>
  )
};
