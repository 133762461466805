import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pdfjs, Document, Page } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { cloneDeep } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();



export default function EbookViewPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location?.state?.url;
  const name = location?.state?.name;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url) { return navigate(-1); }
  }, []);

  const onLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onLoadError = () => {
    navigate(-1);
  };

  const onPageChange = (actionName) => {
    const currentPage = cloneDeep(pageNumber);
    const newPage = actionName === 'next' ? currentPage + 1 : currentPage - 1;
    setPageNumber(newPage);
  };

  return (
    <StackedLayout
      hideFooter={true}
    >
      <div className="flex justify-center">
        <h3 className="text-xl">{name}</h3>
      </div>
      <div className="flex flex-col justify-center items-center">
        {!loading &&
        <div className="flex mb-2 items-center space-x-4 text-sm text-gray-600">
          <button
            disabled={pageNumber === 1}
            className="cursor-pointer disabled:opacity-30"
            onClick={() => onPageChange('prev')}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <p className="">Page {pageNumber} of {numPages}</p>
          <button
            disabled={pageNumber === numPages}
            className="cursor-pointer disabled:opacity-30"
            onClick={() => onPageChange('next')}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>}

        <div className={`flex justify-center p-2 ${!loading && 'border-4 border-gray-200'}`}>
          <Document file={url} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError}>
            <Page pageNumber={pageNumber} renderAnnotationLayer={true} renderTextLayer={false} />
          </Document>
        </div>       
      </div> 
    </StackedLayout>
  )
};
