import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import StackedLayout from '../../../layouts/StackedLayout';
import Table from '../../../components/tables/StackedTable';
import { MenuItem, ActionMenu } from '../../../components/ActionMenu';
import { Select } from '../../../components/forms/fields';
import { getAdmittedPrograms } from '../../../services/programAdmission';
import { getCourses } from '../../../services/course';
import { getTakenExams } from '../../../services/takenExam';
import { convertToBanglaNumber } from '../../../utils';


const sidebarNavigation = [
  { name: 'হোম', href: '/learn/home' },
  { name: 'ক্লাস রুটিন', href: '/learn/routine' },
  { name: 'পরীক্ষার রেজাল্ট', href: '/learn/results' },
  { name: 'ফি পেমেন্ট', href: '/learn/payments' },
  { name: 'ট্রানজেকশন বিবরণী', href: '/learn/transactions' }
];

const headers = ['পরীক্ষার নাম', 'কোর্স', 'স্থান', 'লিখিত প্রাপ্ত নম্বর', 'এমসিকিউ প্রাপ্ত নম্বর', 'মোট প্রাপ্ত নম্বর', 'পূর্ণমান']

export default function StudentResultPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [takenExams, setTakenExams] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const _programs = await getAdmittedPrograms(currentUser._id);
        setPrograms(_programs);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCourses() {
      try {
        if (!selectedProgram || selectedProgram.paymentCircle === 'onetime') {
          setCourses([]);
        } else {
          const _courses = await getCourses({program: selectedProgram._id});
          setCourses(_courses);
        }
        
      } catch (error) {
        toast.error(error.message);
        setCourses([]);
      }
    };
    fetchCourses();
  }, [selectedProgram]);

  useEffect(() => {
    async function fetchResults() {
      if (!selectedProgram) {
        setTakenExams([]);
        return;
      }
  
      try {
        let params = {
          user: currentUser._id,
          program: selectedProgram._id
        };
        if (selectedCourse) {
          params.course = selectedCourse;
        }
        const _takenExams = await getTakenExams(params);
        setTakenExams(_takenExams);
      } catch (error) {
        toast.error(error.message);
      }
    }
    fetchResults();
  }, [
    selectedProgram,
    selectedCourse
  ])


  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="পরীক্ষার রেজাল্ট"
    >
      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            প্রোগ্রাম
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <Select
              options={programs}
              value={selectedProgram?._id}
              onChange={(event) => setSelectedProgram(programs[event.target.selectedIndex - 1])}
            />
          </dd>
        </div>

        {selectedProgram?.paymentCircle === 'month' &&
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            কোর্স
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <Select
              options={courses}
              value={selectedCourse}
              onChange={(event) => setSelectedCourse(event.target.value)}
            />
          </dd>
        </div>}
      </dl>

      <Table
          headers={headers}
          itemsCount={takenExams.length}
        >
          {takenExams.map((takenExam, index) => (
            <tr
              key={`taken-exam-index[${index}]`}
              className="text-center"
            >
              <td
                className="py-4 pl-4 pr-3 text-sm font-medium text-left text-gray-900 sm:pl-0 text-ellipsis"
              >
                {takenExam?.exam?.name || takenExam?.offlineExam?.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {takenExam?.enrolledCourse?.course?.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {takenExam.examType}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {convertToBanglaNumber(takenExam.cqMarksObtained)}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {convertToBanglaNumber(takenExam.mcqMarksObtained)}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {convertToBanglaNumber(takenExam.totalMarksObtained)}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                {convertToBanglaNumber(takenExam.totalMarks)}
              </td>
              <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <ActionItems
                  takenExam={takenExam}
                />
              </td>
            </tr>
          ))}
        </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    takenExam
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="মেধাস্থান দেখুন" onClick={() => {}} />
      </div>
      {takenExam?.examType === 'online' &&
      <div className="py-1">
        <MenuItem label="উত্তর ও সমাধান দেখুন" href={`/learn/exam/${takenExam._id}/result`} />
      </div>}
    </ActionMenu>
  )
};