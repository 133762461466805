import http from '../helpers/http';


export const publishExamMeritList = async (payload) => {
  try {
    const { data } = await http.post(`/api/meritlists/exam/publish`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getMeritLists = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/meritlists`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
