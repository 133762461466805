import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep, findIndex } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Form from '../../components/forms/FormLayout';
import Table from '../../components/tables/StackedTable';
import FieldsSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Input } from '../../components/forms/fields';
import { getEnrolledCourse, getEnrolledCourses, cancelAdmission } from '../../services/enrolledCourse';
import { getInvoices } from '../../services/invoice';
import { getNormalizedDateTime } from '../../utils';


const invoiceHeaders = ['Due date', 'status', 'Amount'];
const enrolledCourseHeaders = ['Name', 'Batch', 'Course fee'];

export default function StudentAdmissionCancelPage() {
  const navigate = useNavigate();
  const params = useParams();
  const enrolledCourseId = params.enrolledCourseId;
  const [enrolledCourse, setEnrolledCourse] = useState(null);
  const [dueInvoices, setDueInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [otherEnrolledCourses, setOtherEnrolledCourses] = useState([]);
  const [monthlyScholarship, setMonthlyScholarship] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _enrolledCourse = await getEnrolledCourse(enrolledCourseId);
        setEnrolledCourse(_enrolledCourse);

        let invoiceQuery = {
          enrolledCourse: _enrolledCourse._id,
          status: ['due', 'overdue']
        };
        if (_enrolledCourse.program.paymentCircle === 'month') {
          invoiceQuery.isCurrentDues = true;
        }
        const _invoices = await getInvoices(invoiceQuery);
        setDueInvoices(_invoices);

        if (_enrolledCourse.program.paymentCircle === 'month') {
          const query = {
            user: _enrolledCourse.user._id,
            program: _enrolledCourse.program._id,
            status: ['active', 'suspended'],
            excludeIds: [_enrolledCourse._id]
          }
          const _otherEnrolledCourses = await getEnrolledCourses(query);
          setOtherEnrolledCourses(_otherEnrolledCourses);
        }


        
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleToggleInvoice = (invoice) => {
    let _selectedInvoices = cloneDeep(selectedInvoices);
    const invoiceIndex = findIndex(_selectedInvoices, _invoice => _invoice?.toString() === invoice?._id?.toString());
    if (invoiceIndex === -1) {
      _selectedInvoices.push(invoice._id);
      setSelectedInvoices(_selectedInvoices);
    } else {
      _selectedInvoices.splice(invoiceIndex, 1);
      setSelectedInvoices(_selectedInvoices);
    }
  };

  const invoiceChecked = (invoice) => {
    return findIndex(selectedInvoices, _invoice => _invoice?.toString() === invoice?._id?.toString()) !== -1;
  }

  const handleCancelAdmission = async (event) => {
    try {
      event.preventDefault();
      const payload = {
        invoicesToDelete: selectedInvoices,
        newMonthlyScholarship: monthlyScholarship || 0
      };
      await cancelAdmission(enrolledCourse._id, payload);
      toast.success('Admission cancelled successfully');
      navigate(-1);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
    >
      {showConfirmModal &&
      <ConfirmModal
        title="Cancel Admission"
        description={`Are you sure to cancel admission? Student will loose all access for this course immediately.`}
        actionName="Confirm"
        onConfirm={handleCancelAdmission}
        onCancel={() => setShowConfirmModal(false)}
      />}

      <SectionHeader
        title="Cancel admission"
      />

      <Form
        cancelText="Go back"
        submitText="Cancel admission"
        onSubmit={(event) => {
          event.preventDefault();
          setShowConfirmModal(true)
        }}
        onCancel={() => navigate(-1)}
      >
        <FieldsSet
          title="Infomation"
        >
          <div className="col-span-full text-sm text-gray-600">
            <p><span className="font-semibold">Student:</span> {enrolledCourse?.user?.name}</p>
            <p><span className="font-semibold">Roll:</span> {enrolledCourse?.rollNo}</p>
            <p><span className="font-semibold">Course:</span> {enrolledCourse?.course?.name}</p>
            <p><span className="font-semibold">Batch:</span> {enrolledCourse?.batch?.name}</p>
            <p><span className="font-semibold">Program:</span> {enrolledCourse?.program?.name}</p>
            <p><span className="font-semibold">Branch:</span> {enrolledCourse?.branch?.name}</p>
          </div>
        </FieldsSet>

        <FieldsSet
          title="Due invoices"
          subTitle="Select the invoice you want to delete for this course."
        >
          <div className="col-span-full">
            <Table
              headers={invoiceHeaders}
              itemsCount={dueInvoices.length}
            >
              {dueInvoices.map((invoice) => (
              <tr key={invoice._id} className="text-center">
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
                  {getNormalizedDateTime(invoice.dueDate, 'DD MMM YYYY')}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">{invoice.status}</td>
                <td className="pr-3 py-4 text-sm text-gray-500">{invoice.amount.toLocaleString()}</td>
                <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <input
                    type="checkbox"
                    // disabled={selectedProgram?.paymentCircle === 'month'}
                    checked={invoiceChecked(invoice)}
                    className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                    onChange={() => handleToggleInvoice(invoice)}
                  />
                </td>
              </tr>
              ))}

            </Table>
          </div>
        </FieldsSet>

        {enrolledCourse?.program?.paymentCircle === 'month' && otherEnrolledCourses.length > 0 &&
        <FieldsSet
          title="Other enrolled courses"
          subTitle="Reset monthly scholarship for other enrolled courses."
        >
          <div className="col-span-full">
            <Table
              headers={enrolledCourseHeaders}
              itemsCount={otherEnrolledCourses.length}
            >
              {otherEnrolledCourses.map((_enrolledCourse) => (
              <tr key={_enrolledCourse._id} className="text-center">
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
                  {_enrolledCourse.course.name}
                </td>
                <td className="pr-3 py-4 text-sm text-gray-500">{_enrolledCourse.batch.name}</td>
                <td className="pr-3 py-4 text-sm text-gray-500">{_enrolledCourse?.isFree ? 'Free' : _enrolledCourse.courseFee.toLocaleString()}</td>
              </tr>
              ))}

            </Table>
          </div>
          <Input col={2} disabled type="number" label="Current monthly scholarship" value={enrolledCourse?.programAdmission?.monthlyScholarship} />
          <Input col={2} type="number" label="New monthly scholarship" value={monthlyScholarship} onChange={(event) => setMonthlyScholarship(event.target.value)} />
        </FieldsSet>}

      </Form>
    </StackedLayout>
  )
};