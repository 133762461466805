import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import { Input } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { changePassword } from '../../services/user';


const sidebarNavigationEN = [
  {name: 'Profile', href: '/user/profile' },
  {name: 'Change Password', href: '/user/change-password' },
];
const sidebarNavigationBN = [
  {name: 'প্রোফাইল', href: '/user/profile' },
  {name: 'পাসওয়ার্ড পরিবর্তন', href: '/user/change-password' },
];

export default function PasswordChangePage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [updating, setUpdating] = useState(false);
  const language = currentUser?.role === 'student' ? 'bn' : 'en';
  const sidebarNavigation = language === 'bn' ? sidebarNavigationBN : sidebarNavigationEN;

  const handleChangePassword = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      await changePassword({newPassword: newPassword});
      toast.success('Password changed successfully');
      setNewPassword(null);
      setConfirmNewPassword(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || isEmpty(newPassword) || isEmpty(confirmNewPassword) || newPassword !== confirmNewPassword;
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={language === 'bn' ? 'পাসওয়ার্ড পরিবর্তন' : 'Change Password'}
    >
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {language === 'bn' ? 'পাসওয়ার্ড পরিবর্তন' : 'Change password'}
        </h3>
      </div>
      <div className="flex flex-col w-full space-y-6 sm:w-1/3">
        <Input type="password" label={language === 'bn' ? 'নতুন পাসওয়ার্ড' : 'New password'} value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
        <Input type="password" label={language === 'bn' ? 'পাসওয়ার্ড পুনরায় লিখুন' : 'Confirm new password'} value={confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)} />
        
        <div className="w-full">
          <SubmitButton disabled={isDisabled()} label={language === 'bn' ? 'সাবমিট করুন' : 'Change password'} onClick={handleChangePassword} />
        </div>
      </div>
    </StackedLayout>
  )

};
