import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Button, SubmitButton, DangerButton } from './buttons';


export const Sidepanel = (props) => {
  const {
    title,
    subTitle,
    action,
    onClose,
    onSave,
    onDelete,
    disabled,
    updating,
    maxWidth,
    showDeleteButton,
    hideActions,
    cancelText
  } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose} >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className={`pointer-events-auto w-screen max-w-${maxWidth || '2xl'}`}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              {subTitle}
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={handleClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {props.children}
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex justify-between border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div>
                        {showDeleteButton && 
                        <DangerButton 
                          disabled={updating}
                          label="Delete"
                          onClick={onDelete}
                        />}
                      </div>
                      {!hideActions &&
                      <div className="flex justify-end space-x-3">
                        <Button  disabled={updating} label={cancelText || 'Cancel'} onClick={onClose} />
                        <SubmitButton disabled={disabled} label={action || 'Save'} onClick={onSave} />
                      </div>}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};

export const SidepanelField = (props) => {
  let { col, label, children } = props;
  col = col ? col?.toString() : '2';
  return (
    <div className="space-y-2 px-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
      <div>
        <label
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      </div>
      <div className={`sm:col-span-${col}`}>
        {children}
      </div>
    </div>
  )
};
