import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {omit, isEmpty, capitalize, cloneDeep, forEach } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import ContentPicker from '../../components/ContentPicker';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import {
  getProgram,
  updateProgram,
  getProgramExams,
  removeProgramExam
} from '../../services/program';


const sidebarNavigation = [
  { name: 'Offline exams', examType: 'offline' },
  { name: 'Online exams', examType: 'online' },
];
const headers = ['Name', 'Type', 'Marks'];

export default function ProgramExamsPage() {
  const params = useParams();
  const programId = params.programId;
  const [program, setProgram] = useState(null);
  const [examType, setExamType] = useState(sidebarNavigation[0].examType);
  const [exams, setExams] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [currentSidebar, setCurrentSidebar] = useState(sidebarNavigation[0]);
  const [openContentPicker, setOpenContentPicker] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        const _program = await getProgram(programId);
        setProgram(_program);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchExams() {
      await fetchExams(programId, examType);
    };
    _fetchExams();
  }, [examType]);

  const fetchExams = async (_programId, _examType) => {
    try {
      const _exams = await getProgramExams(_programId, _examType);
      setExams(_exams);

      let _selectedContents = [];
      forEach(_exams, exam => _selectedContents.push(exam._id));
      setSelectedContents(_selectedContents);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onNavigationChange = (event, index) => {
    event.preventDefault();
    const _currentNavigation = sidebarNavigation[index];
    setExamType(_currentNavigation.examType);
    setCurrentSidebar(_currentNavigation);
  };

  const handleSelectExam = async (event, item) => {
    try {
      event.preventDefault();
      let _exams = cloneDeep(exams);
      _exams.push(item);
      let payload = {};
      if (examType === 'offline') {
        payload.offlineExams = _exams;
      } else {
        payload.onlineExams = _exams;
      }
      await updateProgram(programId, payload);
      setExams(_exams);

      let _selectedContents = cloneDeep(selectedContents);
      _selectedContents.push(item._id);
      setSelectedContents(_selectedContents);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRemoveExam = async (event) => {
    try {
      event.preventDefault();
      await removeProgramExam(programId, examType, selectedExam._id);
      toast.success('Exam removed successfully.');
      await fetchExams(programId, examType);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={currentSidebar.name}
      onNavigationChange={onNavigationChange}
    >
      {openContentPicker &&
      <ContentPicker
        title={`${capitalize(examType)} exams`}
        contentType="offlineExam"
        selectedContents={selectedContents}
        onSelect={handleSelectExam}
        onCancel={() => setOpenContentPicker(false)}
      />}

      {showConfirmModal &&
      <ConfirmModal
        title="Remove exam"
        description="Are you sure to remove this exam? Please double check before performing this action."
        actionName="Remove"
        onConfirm={handleRemoveExam}
        onCancel={() => {
          setSelectedExam(null);
          setShowConfirmModal(false);
        }}
      />}
      <SectionHeader
        title={`${program?.name}: ${examType} exams`}
        subTitle={`List of ${examType} exams for this program`}
        createButtonText="Add exams"
        onCreate={() => setOpenContentPicker(true)}
      />

      <Table
        headers={headers}
        items={exams}
        itemsCount={exams.length}
      >
        {exams.map((exam) => (
        <tr key={exam._id} className="text-center">
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
            {exam.name}
          </td>

          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
            {examType === 'offline' ? exam.questionType : 'mcq'}
          </td>

          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
            {exam.totalMarks}
          </td>

          <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <ActionItems 
              onRemove={() => {
                setSelectedExam(exam);
                setShowConfirmModal(true);
              }}
            />
          </td>
        </tr>
      ))}
      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    onRemove
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Remove" onClick={onRemove} />
      </div>
    </ActionMenu>
  )

};
