import { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import Latex from 'react-latex';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

import { convertToBanglaNumber } from '../../../../utils';
import { getQuestionSolution } from '../../../../services/question';
import { findIndex, isEmpty } from 'lodash';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const optionLabels = ['ক', 'খ', 'গ', 'ঘ', 'ঙ'];

export default function AnswerSection(props) {
  const { question, questionIndex } = props;
  const [solution, setSolution] = useState('');

  useEffect(() => {
    async function _fetchData() {
      const questionSolution = await getQuestionSolution(question._id);
      setSolution(questionSolution.solution);
    };
    _fetchData();
  }, [question, questionIndex]);

  if (!question) {
    return (
      <div className="mt-4 text-gray-600">
        <p>এই বিষয়ের সকল প্রশ্নের উত্তর দেওয়া হয়েছে।</p>
      </div>
    )
  }

  return (
    <div className="mt-2 text-base">
      {question?.comprehension &&
      <p className="pb-2 text-sm text-gray-600">
        <TextPanel text={question?.comprehension} />
      </p>}

      <p className="flex flex-1 space-x-1">
        <span>{convertToBanglaNumber(questionIndex+1)}{'.'}</span>
        <TextPanel text={question.question} />
      </p>

      <RadioGroup>
        <div className="mt-2 space-y-2">
          {question.answers.map((answer, index) => (
            <RadioGroup.Option
              key={`question-index[${index}]`}
              value={answer}
              disabled={true}
              defaultChecked={answer.isCorrect}
              className="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
            >
              {({ active, checked }) => (
                <>
                  <span className="flex w-full justify-between items-center">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="font-medium text-gray-900">
                        <p className="flex flex-1 items-center space-x-1">
                          <span>{optionLabels[index]}{')'}</span>
                          <TextPanel text={answer.value} />
                        </p>
                      </RadioGroup.Label>
                    </span>
                    <span>
                      <RadioGroup.Description
                        as="span"
                        className="flex sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                      >
                        {isCorrectMarked(question, index) &&
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-600"
                          aria-hidden="true"
                        />}

                        {isWrongMarked(question, index) &&
                        <XCircleIcon
                          className="h-5 w-5 text-red-600"
                          aria-hidden="true"
                        />}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      answer.isCorrect && 'border-2 border-green-600',
                      isWrongMarked(question, index) && 'border-2 border-red-600',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {!isEmpty(solution) &&
      <div className="mt-6">
        <h3 className="py-2 font-semibold">সমাধান:</h3>
        <p className="text-sm text-gray-600">
          <TextPanel text={solution} />
        </p>
      </div>}
    </div>
  )
};

function isCorrectMarked(question, answerIndex) {
  if (!question.isAnswered) { return false; }
  const markedAnserIndex = findIndex(question.answers, a => a.value === question.answeredOption);
  return question.isCorrect && markedAnserIndex === answerIndex;
};

function isWrongMarked(question, answerIndex) {
  if (!question.isAnswered) { return false; }
  const markedAnserIndex = findIndex(question.answers, a => a.value === question.answeredOption);
  return !question.isCorrect && markedAnserIndex === answerIndex;
};

function TextPanel(props) {
  const { text } = props;
  return (
    <span className="flex questions-answer-wrapper leading-6 text-gray-900 line-clamp-2">
      {text.includes("$$") ? 
      <Latex>{text}</Latex> : <span dangerouslySetInnerHTML={{ __html: text }}/>}
    </span>
  )
};