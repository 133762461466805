
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const navigations = [
  ['সকল কোর্স', '/learn/courses'],
  ['যোগাযোগ', '/learn/branches'],
  ['আমাদের সম্পর্কে', '/about-us'],
];

export function NavLinks(props) {
  const { competitions } = props;
  let [hoveredIndex, setHoveredIndex] = useState(null);
  let timeoutRef = useRef(null);

  return (
    <>
      {navigations.map(([label, href], index) => (
        <Link
          key={label}
          to={href}
          className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm lg:text-base text-gray-500 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
          onMouseEnter={() => {
            if (timeoutRef.current) {
              window.clearTimeout(timeoutRef.current)
            }
            setHoveredIndex(index)
          }}
          onMouseLeave={() => {
            timeoutRef.current = window.setTimeout(() => {
              setHoveredIndex(null)
            }, 200)
          }}
        >
          <AnimatePresence>
            {hoveredIndex === index && (
              <motion.span
                className="absolute inset-0 rounded-lg bg-gray-200"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.15 } }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15 },
                }}
              />
            )}
          </AnimatePresence>
          <span className="relative z-10 font-medium text-gray-500">{label}</span>
        </Link>
      ))}

      {competitions?.map((item, index) => (
        <Link
          key={item._id}
          to={`/learn/competitions/${item.slug}`}
          className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm lg:text-base text-gray-500 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
          onMouseEnter={() => {
            if (timeoutRef.current) {
              window.clearTimeout(timeoutRef.current)
            }
            setHoveredIndex(navigations.length + index)
          }}
          onMouseLeave={() => {
            timeoutRef.current = window.setTimeout(() => {
              setHoveredIndex(null)
            }, 200)
          }}
        >
          <AnimatePresence>
            {hoveredIndex === (navigations.length + index) && (
              <motion.span
                className="absolute inset-0 rounded-lg bg-gray-200"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.15 } }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15 },
                }}
              />
            )}
          </AnimatePresence>
          <span className="relative z-10 font-medium text-gray-500">{item.menuName}</span>
        </Link>
      ))}
    </>
  )
}
