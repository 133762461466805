import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { UserIcon } from '@heroicons/react/24/outline';
import { omit, capitalize, isEmpty } from 'lodash';

import StackedLayout from '../../../layouts/StackedLayout';
import SectionHeader from '../../../components/SectionHeader';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../../components/ActionMenu';
import Table from '../../../components/tables/StackedTable';
import { getTestimonials, changeTestimonialStatus } from '../../../services/testimonial';


const sidebarNavigation = [
  { name: 'Web banners', href: '/website-contents/web-banners' },
  { name: 'Mobile banners', href: '/website-contents/mobile-banners' },
  { name: 'Testimonials', href: '/website-contents/testimonials' },
  { name: 'About us', href: '/website-contents/about-us' },
  { name: 'Terms & Privacy', href: '/website-contents/terms' },
];
const headers = [ 'Name', 'Session', 'Display order', 'Status' ];

export default function TestimonialsPage() {
  const [testimonials, setTestimonials] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [queryString, setQueryString] = useState(null);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
    setLoading(false);
  }, [
    currentPage,
    queryString
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        search: queryString,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      const testimonials = await getTestimonials(params);
      setTestimonials(testimonials.docs);
      setPagingData(omit(testimonials, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeTestimonialStatus(selectedTestimonial._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Testimonials"
    >
      <SectionHeader
        title="Testimonials"
        subTitle="List of all testimonials for your institution."
        createButtonText="New testimonial"
        onCreate={() => window.location.href = `/website-contents/testimonials/create`}
      />

      {showStatusModal &&
      <ConfirmModal
        title={selectedTestimonial.status === 'active' ? 'Disable testimonial' : 'Enable testimonial'} 
        description={`Are you sure to ${selectedTestimonial?.status === 'active' ? 'disable' : 'enable'} this testimonial? Please double check before performing this action.`}
        actionName={selectedTestimonial?.status === 'active' ? 'Disable' : 'Enable'}
        onConfirm={handleStatusChange}
        onCancel={() => {
          setSelectedTestimonial(null);
          setShowStatusModal(false);
        }}
      />}
      <Table
        headers={headers}
        items={testimonials}
        itemsCount={testimonials.length}
        pagingData={pagingData}
        queryString={queryString}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {testimonials.map((testimonial) => (
          <tr key={testimonial._id} className="text-center">
            <td className="py-5 pl-4 pr-3 text-sm text-left sm:pl-0">
              <div className="flex items-center">
                <div className="h-8 w-8 flex-shrink-0">
                  {testimonial.photo ?
                  <img className="h-8 w-8 rounded-full" src={testimonial.photo} alt="" />
                  : <UserIcon className="h-6 w-6 rounded-full" />}
                </div>
                <div className="ml-4">
                  <div className="font-medium text-gray-900">{testimonial.name}</div>
                  {/* <div className="mt-1 text-gray-500">{capitalize(testimonial?.designation || '')}</div> */}
                </div>
              </div>
            </td>
            <td className="pr-3 py-5 text-sm text-gray-500">{capitalize(testimonial?.session || '')}</td>
            <td className="pr-3 py-5 text-sm text-gray-500">{testimonial?.displayOrder || '-'}</td>
            
            <td className={`pr-3 py-5 text-sm text-gray-500`}>
              <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${testimonial.status === 'active' ? "bg-green-50 text-green-700 ring-green-600/20" : "bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/20"} ring-1 ring-inset`}>
                {capitalize(testimonial.status)}
              </span>
            </td>
            <td className="py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems 
                testimonial={testimonial}
                onStatusChange={() => {
                  setSelectedTestimonial(testimonial);
                  setShowStatusModal(true);
                }}
              />
            </td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { testimonial, onStatusChange } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/website-contents/testimonials/${testimonial._id}/edit`} />
      </div>
      <div className="py-1">
        <MenuItem label={testimonial.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )
};
