import moment from 'moment';
import { round } from 'lodash';

import { uploadLocal, uploadPublicImage } from '../services/upload';
import { images } from '../constants';


export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const getNormalizedDateTime = (datetimeString, format) => {
  format = format || 'DD-MM-YYYY hh:mm A';
  return moment.utc(moment(datetimeString)).local().format(format);
};

export const handleUploadPublicImage = async (files) => {
  try {
    const data = new FormData();
    if (files.length > 0) {
      data.append('image', files[0]);
      const responseData = await uploadPublicImage(data);
      return responseData.imageUrl;
    } else {
      throw new Error('No file attached');
    }
  } catch (error) {
    throw error;
  }
};

export const handleUploadLocalFile = async (files) => {
  try {
    const data = new FormData();
    if (files.length > 0) {
      data.append('file', files[0]);
      const responseData = await uploadLocal(data);
      return responseData.filePath;
    } else {
      throw new Error('No file attached');
    }
  } catch (error) {
    throw error;
  }
};

export const getDiscountedFee = (course) => {
  const originalFee = course?.fee || 0;
  const discount = course?.discount || 0;
  let discountedFee = 0;
  if (course?.discountType === 'flat') {
    discountedFee = originalFee - discount;
  } else {
    discountedFee = round(originalFee - ((originalFee * discount) / 100));
  }
  return discountedFee;
};

export const getDiscount = (course) => {
  const originalFee = course.fee;
  const discount = course.discount || 0;
  return course.discountType === 'flat' ? discount : round((originalFee * discount) / 100);
};

export const getVat = (amount, includeVat) => {
  if (!includeVat) { return 0; }
  return round((amount * 15 / 100), 2);
};

export const getFileSize = (bytes) => {
  if (bytes < 1000) {
    return `${bytes} bytes`
  } else if (bytes >= 1000 && bytes < 1000000) {
    return `${round(bytes / 1000)} KB`;
  } else if (bytes >= 1000000 && bytes < 1000000000) {
    return `${round(bytes / 1000000, 1)} MB`;
  } else {
    return `${round(bytes / 1000000000, 2)} GB`;
  }
};

export const programIconMapping = {
  ssc: images.SSCIcon,
  hsc: images.HSCIcon,
  admission: images.AdmissionIcon,
  job: images.JobIcon
};

export const gradeLabelMapping = {
  ssc: 'এসএসসি একাডেমিক',
  hsc: 'এইচএসসি একাডেমিক',
  admission: 'বিশ্ববিদ্যালয় ভর্তি প্রস্তুতি',
  job: 'চাকরি প্রস্তুতি',
  skill: 'দক্ষতা উন্নয়ন',
};

export const groupLabelMapping = {
  science: 'বিজ্ঞান',
  commerce: 'ব্যবসায় শিক্ষা',
  humanities: 'মানবিক'
};

export const courseTypeLabelMapping = {
  offline: 'অফলাইন',
  live: 'লাইভ',
  recorded: 'রেকর্ডেড',
  exam: 'অনলাইন মডেল টেস্ট'
};

export const gatewayLabelMapping = {
  cash: 'ক্যাশ',
  bkash: 'বিকাশ',
  nagad: 'নগদ',
  card: 'ডেবিট/ক্রেডিট কার্ড',
  bank: 'ব্যাংক',
};

export const statusLabelMapping = {
  success: 'সফল হয়েছে',
  failed: 'ব্যর্থ হয়েছে',
  failure: 'ব্যর্থ হয়েছে',
  cancelled: 'বাতিল',
  processing: 'প্রক্রিয়াধীন',
  inprogress: 'প্রক্রিয়াধীন',
  initiated: 'শুরু হয়েছে',
};

export const statusColorMapping = {
  success: 'green',
  failed: 'red',
  failure: 'red',
  cancelled: 'gray',
  processing: 'orange',
  inprogress: 'orange',
  initiated: 'gray'
};

let numberMapping = {
  '0': '০',
  '1': '১',
  '2': '২',
  '3': '৩',
  '4': '৪',
  '5': '৫',
  '6': '৬',
  '7': '৭',
  '8': '৮',
  '9': '৯',
  '.': '.',
  ',': ',',
  ':': ':',
  '-': '-'
};

export const convertToBanglaNumber = (input) => {
  input = input || 0;
  input = input?.toLocaleString();
  let output = [];
  for (let i = 0; i < input.length; i++) {
    output.push(numberMapping[input[i]]);
  }
  return output.join('');
};

const months = {
  January: 'জানুয়ারি',
  February: 'ফেব্রুয়ারি',
  March: 'মার্চ',
  April: 'এপ্রিল',
  May: 'মে',
  June: 'জুন',
  July: 'জুলাই',
  August: 'অগাস্ট',
  September: 'সেপ্টেম্বর',
  October: 'অক্টোবর',
  November: 'নভেম্বর',
  December: 'ডিসেম্বর'
};

const days = {
  Saturday: 'শনিবার',
  Sunday: 'রবিবার',
  Monday: 'সোমবার',
  Tuesday: 'মঙ্গলবার',
  Wednesday: 'বুধবার',
  Thursday: 'বৃহস্পতিবার',
  Friday: 'শুক্রবার',
};

export const getBanglaDate = (datetimeString) => {
  const date = moment.utc(moment(datetimeString)).local().format('DD');
  const month = moment.utc(moment(datetimeString)).local().format('MMMM');
  const year = moment.utc(moment(datetimeString)).local().format('YYYY');
  return `${convertToBanglaNumber(date)} ${months[month]}, ${convertToBanglaNumber(year)}`;
};

export const getBanglaDateWithDay = (datetimeString) => {
  const date = moment.utc(moment(datetimeString)).local().format('DD');
  const month = moment.utc(moment(datetimeString)).local().format('MMMM');
  const year = moment.utc(moment(datetimeString)).local().format('YYYY');
  const day = moment.utc(moment(datetimeString)).local().format('dddd');
  return `${convertToBanglaNumber(date)} ${months[month]}, ${convertToBanglaNumber(year)} (${days[day]})`;
};

export const getBanglaDay = (datetimeString) => {
  const day = moment.utc(moment(datetimeString)).local().format('dddd');
  return days[day];
};

export const getBanglaTime = (datetimeString) => {
  const meridium = moment.utc(moment(datetimeString)).local().format('A');
  const time = moment.utc(moment(datetimeString)).local().format('hh:mm');
  const hour = parseInt(moment.utc(moment(datetimeString)).local().format('hh'));

  let daytime = 'সকাল';
  if (meridium === 'PM') {
    if (hour === 12 || (hour >= 1 && hour < 3)) {
      daytime = 'দুপুর';
    } else if (hour >= 3 && hour < 6) {
      daytime = 'বিকাল';
    } else if (hour >= 6 && hour < 8) {
      daytime = 'সন্ধ্যা';
    } else {
      daytime = 'রাত';
    }
  }
  return `${daytime} ${convertToBanglaNumber(time)}`;
};

export const lightenColor = (color, percent) => {
  // Convert hex to RGB
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  // Lighten each component
  r = Math.min(255, Math.floor(r + (255 - r) * percent));
  g = Math.min(255, Math.floor(g + (255 - g) * percent));
  b = Math.min(255, Math.floor(b + (255 - b) * percent));

  // Convert back to hex
  const colorCode = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  return colorCode;
}
