import { isEmpty, forEach } from 'lodash';
import { searchAcademicInstitutions } from '../../../services/academicInstitution';

import SelectAsync from './SelectAsync';


export default function SelectAcademicInstitutions(props) {
  const { col, label, placeholder, name, value, institutionType, onChange } = props;

  const onSearch = async (queryString) => {
    if (isEmpty(queryString) || queryString.length < 3) { return; }
    queryString = queryString.toLowerCase();
    const params = {
      queryString,
      institutionType
    };

    const academicInstitutions = await searchAcademicInstitutions(params);
    let formattedItems = [];
    forEach(academicInstitutions, ac => formattedItems.push({label: `${ac.name}${ac.eiin ? ` (EIIN: ${ac.eiin})` : ''}`, value: ac._id}));
    return formattedItems;
  };

  return (
    <SelectAsync
      col={col}
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
    />
  )
};
