import Latex from 'react-latex';


export default function LatexText(props) {
  const { text } = props;
  return (
    <span className="flex questions-answer-wrapper leading-6 text-gray-900 line-clamp-2">
      {text.includes("$$") ? 
      <Latex>{text}</Latex> : <span dangerouslySetInnerHTML={{ __html: text }}/>}
    </span>
  )
};
