import React, { useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {omit, isEmpty, capitalize } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import {
  getPrograms,
  changeProgramStatus
} from '../../services/program';


const sidebarNavigation = [
  { name: 'Programs', href: '/coaching/programs' },
];
const headers = ['Name (English)', 'Name (Bangla)', 'Payment circle', 'Code', 'Status'];

export default function ProgramsPage() {
  const [programs, setPrograms] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    }
    _fetchData();
  }, [
    queryString,
    currentPage
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        sortBy: 'name',
        search: queryString
      };

      const data = await getPrograms(params);
      setPrograms(data.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeProgramStatus(selectedProgram._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Programs"
    >
      <SectionHeader
        title="Programs"
        subTitle="List of all programs for your offline coaching."
        createButtonText="New program"
        onCreate={() => window.location.href = `/programs/create`}
      />

      {showStatusModal &&
      <ConfirmModal
        title={selectedProgram?.status === 'active' ? 'Disable program' : 'Enable program'} 
        description={`Are you sure to ${selectedProgram?.status === 'active' ? 'disable' : 'enable'} this program? Please double check before performing this action.`}
        actionName={selectedProgram?.status === 'active' ? 'Disable' : 'Enable'}
        onConfirm={handleStatusChange}
        onCancel={() => {
          setSelectedProgram(null);
          setShowStatusModal(false);
        }}
      />}

      <Table
        headers={headers}
        items={programs}
        itemsCount={programs.length}
        queryString={queryString}
        pagingData={pagingData}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {programs.map((program) => (
        <tr key={program._id} className="text-center">
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
            {program.name}
          </td>
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
            {program.nameBN}
          </td>
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
            {program.paymentCircle}
          </td>
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
            {program.programRollCode || '-'}
          </td>
          <td 
            className={`px-3 py-4 text-sm ${program.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
          >
            {capitalize(program.status)}
          </td>

          <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <ActionItems 
              program={program}
              onStatusChange={() => {
                setSelectedProgram(program);
                setShowStatusModal(true);
              }}
            />
          </td>
        </tr>
      ))}
      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const {
    program,
    onStatusChange
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/programs/${program._id.toString()}/edit`} />
      </div>
      <div className="py-1">
        <MenuItem label="Manage exams" href={`/programs/${program._id.toString()}/exams`} />
      </div>
      <div className="py-1">
        <MenuItem label={program?.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )

};
