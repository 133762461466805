import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import { includes, omit, orderBy, round } from 'lodash';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import DownloadTransactionModal from '../../components/modals/DownloadTransactionModal';
import { Button, ButtonWithIcon } from '../../components/buttons';
import { Input, Select } from '../../components/forms/fields';
import { getInvoicePaidAmount, getInvoices } from '../../services/invoice';
import { getBranches } from '../../services/branch';
import { getNormalizedDateTime } from '../../utils';
import { getPrograms } from '../../services/program';
import { getCourses, getCoursesForBranch } from '../../services/course';


const sidebarNavigation = [
  { name: 'Dashboard', href: '/reports/dashboard' },
  { name: 'Transactions', href: '/reports/transactions' },
  { name: 'SMS history', href: '/reports/sms-history' },
];

const headers = ['Student ID', 'Name', 'Branch', 'Program', 'Course', 'Paid amount', 'Paid at', 'Payment branch', 'Collected by'];

export default function TransactionsPage() {
  const defaultFromDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const defaultToDate = getNormalizedDateTime(new Date(), 'YYYY-MM-DD');
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [branches, setBranches] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [netCollection, setNetCollection] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDownloadModal, setShowDownloadModal] = useState(false);


  useEffect(() => {
    async function _fetchBranches() {
      try {
        if (includes(['owner', 'admin'], currentUser.role)) {
          const _branches = await getBranches({status: 'active'});
          setBranches(_branches);
        } else {
          setSelectedBranch(currentBranch._id);
        }

        const _programs = await getPrograms();
        setPrograms(_programs);
      } catch (error) {
        toast.error(error.message);
      }
    };
    _fetchBranches();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    selectedBranch,
    selectedProgram,
    selectedCourse,
    fromDate,
    toDate
  ]);

  useEffect(() => {
    if (selectedProgram) {
      async function _fetchCourses() {
        try {
          let params = { program: selectedProgram };
          let _courses = [];
          if (selectedBranch) {
            _courses = await getCoursesForBranch(selectedBranch, params);
          } else {
            _courses = await getCourses(params);
          }
          setCourses(orderBy(_courses, 'name'));
        } catch (error) {
          toast.error(error.message);
        }
      };
      _fetchCourses();
    } else {
      setCourses([]);
    }
    setSelectedCourse(null);
  }, [selectedProgram]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 20,
        page: currentPage,
        sortBy: 'paymentDate',
        sortOrder: 'desc',
        status: 'paid'
      }
      let transactionQuery = { status: 'paid' };

      if (selectedBranch) {
        params.paymentBranch = selectedBranch;
        transactionQuery.paymentBranch = selectedBranch;
      }
      if (selectedProgram) {
        params.program = selectedProgram;
        transactionQuery.program = selectedProgram;
      }

      if (selectedCourse) {
        params.course = selectedCourse;
        transactionQuery.course = selectedCourse;
      }
      if (fromDate) {
        const _fromDate = moment(fromDate).startOf('day').zone('+06:00').format();
        params.fromDate = _fromDate;
        transactionQuery.fromDate = _fromDate;
      }
      if (toDate) {
        const _toDate = moment(toDate).endOf('day').zone('+06:00').format();
        params.toDate = _toDate;
        transactionQuery.toDate = _toDate;
      }
      const data = await getInvoices(params);
      setInvoices(data?.docs || []);
      setPagingData(omit(data, 'docs'));

      
      const transactionData = await getInvoicePaidAmount(transactionQuery);
      setNetCollection(transactionData?.netCollection || 0);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Transactions"
    >
      {showDownloadModal &&
      <DownloadTransactionModal
        currentUser={currentUser}
        onCancel={() => setShowDownloadModal(false)}
      />}
      <SectionHeader
        title="Transactions"
        subTitle="See all transactions for your institute."
      >
        <div>
          <div className="flex space-x-4">
            <Button label={`BDT ${round(netCollection).toLocaleString()}`} onClick={() => {}} />
            <ButtonWithIcon label="Download" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadModal(true)} />
          </div>
        </div>
      </SectionHeader>

      <div className="mx-2 grid max-w-7xl grid-cols-3 gap-x-4 text-sm md:gap-x-6">
        {includes(['owner', 'admin'], currentUser.role) &&
        <div className="grid auto-rows-min grid-cols-1 gap-y-10">
          <fieldset>
            <Select label="Payment branch" value={selectedBranch} options={branches} onChange={(event) => setSelectedBranch(event.target.value)} />
          </fieldset>
        </div>}
        <div className="grid auto-rows-min grid-cols-1 gap-y-10">
          <fieldset>
            <Select label="Program" value={selectedProgram} options={programs} onChange={(event) => setSelectedProgram(event.target.value)} />
          </fieldset>
        </div>
        <div className="grid auto-rows-min grid-cols-1 gap-y-10">
          <fieldset>
            <Select label="Course" value={selectedCourse} options={courses} onChange={(event) => setSelectedCourse(event.target.value)} />
          </fieldset>
        </div>
        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
          <fieldset>
            <Input type="date" label="From date" value={fromDate || ''} onChange={(event) => setFromDate(event.target.value)} />
          </fieldset>
          <fieldset>
            <Input type="date" label="To date" value={toDate || ''} onChange={(event) => setToDate(event.target.value)} />
          </fieldset>
        </div>
        <div>
          <div className="mt-8">
            <Button
              label="Clear filters"
              onClick={() => {
                setSelectedBranch(null);
                setSelectedProgram(null);
                setSelectedCourse(null);
                setFromDate(defaultFromDate);
                setToDate(defaultToDate);
              }}
            />
          </div>
        </div>
      </div>

      <Table
        headers={headers}
        itemsCount={invoices?.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >      
        {invoices?.map((invoice, index) => (
          <tr key={`invoice-index[${index}]`} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {invoice?.user?.registrationNo}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.user?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.program?.name || '-'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.course?.name || '-'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.paidAmount.toLocaleString()}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(invoice.paymentDate)}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.paymentBranch?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.paymentBy?.name || '-'}</td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};