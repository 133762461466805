import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import { InlineCheckbox } from '../../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../../components/Sidepanel';
import { getBranchUserPermission, updateUserPermission } from '../../../../services/permission';


export default function BranchUserPermissionForm(props) {
  const { user, onClose } = props;
  const [permission, setPermission] = useState(null);
  const [role, setRole] = useState(user?.role);
  const [canViewContents, setCanViewContents] = useState(false);
  const [canManageRoutines, setCanManageRoutines] = useState(false);
  const [canManageStudentAdmissions, setCanManageStudentAdmissions] = useState(false);
  const [canManageStudentPayments, setCanManageStudentPayments] = useState(false);
  const [canManageCompetitionParticipants, setCanManageCompetitionParticipants] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setUpdating(true);
        const userPermission = await getBranchUserPermission(user.branch._id, user._id);
        setPermission(userPermission);
        setCanViewContents(userPermission.canViewContents);
        setCanManageRoutines(userPermission.canManageRoutines);
        setCanManageStudentAdmissions(userPermission.canManageStudentAdmissions);
        setCanManageStudentPayments(userPermission.canManageStudentPayments);
        setCanManageCompetitionParticipants(userPermission?.canManageCompetitionParticipants);
        setUpdating(false);
      } catch (error) {
        toast.error(error.message);
        onClose();
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCanViewContents(false);
    setCanManageRoutines(false);
    setCanManageStudentAdmissions(false);
    setCanManageStudentPayments(false);
    setCanManageCompetitionParticipants(false);
  }, [role]);

  const handleSavePermission = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      const payload = {
        role,
        canViewContents,
        canManageRoutines,
        canManageStudentAdmissions,
        canManageStudentPayments,
        canManageCompetitionParticipants
      };
      const updatedPermission = await updateUserPermission(permission?._id, payload);
      setPermission(updatedPermission);
      toast.success('Permission updated successfully.');
      onClose();
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  return (
    <Sidepanel
      title="User permissions"
      subTitle={user.name}
      onSave={handleSavePermission}
      onClose={onClose}
      updating={updating}
      disabled={updating}
    >
      <SidepanelField
        label="Permissions"
        col={3}
      >
        <div className="space-y-4">
          <InlineCheckbox
            label="Branch admin"
            description="Can create batches, users, view reports and all activities for this branch."
            checked={role === 'branchAdmin'}
            onChange={() => setRole(role === 'branchAdmin' ? null : 'branchAdmin')}
          />

          <InlineCheckbox
            label="View contents"
            description="Can view questins, exams, lessons, live classes, ebooks."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canViewContents}
            onChange={() => setCanViewContents(!cloneDeep(canViewContents))}
          />

          <InlineCheckbox
            label="Manage class routines"
            description="Can add/edit online and offline class routines."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageRoutines}
            onChange={() => setCanManageRoutines(!cloneDeep(canManageRoutines))}
          />

          <InlineCheckbox
            label="Manage student admissions"
            description="Can add new students and assign new courses to the students."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageStudentAdmissions}
            onChange={() => setCanManageStudentAdmissions(!cloneDeep(canManageStudentAdmissions))}
          />

          <InlineCheckbox
            label="Manage student payments"
            description="Can collect payments, manage dues and print invoices."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageStudentPayments}
            onChange={() => setCanManageStudentPayments(!cloneDeep(canManageStudentPayments))}
          />

          <InlineCheckbox
            label="Manage competition participants"
            description="Can add new participants and download participant lists for competitions."
            disabled={role === 'branchAdmin'}
            checked={role === 'branchAdmin' || canManageCompetitionParticipants}
            onChange={() => setCanManageCompetitionParticipants(!cloneDeep(canManageCompetitionParticipants))}
          />
        </div>
      </SidepanelField>
    </Sidepanel>
  );
};
