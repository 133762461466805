import http from '../helpers/http';


export const getSmsLog = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/sms/log`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
