import { useState } from 'react';
import { includes } from 'lodash';

import { Input, Toggle, Select } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';


const discountTypes = [
  {name: 'Flat', value: 'flat'},
  {name: 'Paercentage', value: 'percentage'}
];

export default function CourseFeePanel(props) {
  const {
    course,
    onSave,
    onClose
  } = props;
  const paymentCircle = course?.program?.paymentCircle;
  const [isFree, setIsFree] = useState(course?.isFree || false);
  const [fee, setFee] = useState(course?.fee || 0);
  const [discount, setDiscount] = useState(course?.discount || 0);
  const [discountType, setDiscountType] = useState(course.discountType || 'flat');
  const [validity, setValidty] = useState(course?.validity || 0);
  const [updating, setUpdating] = useState(false);

  const handleSaveCourse = async (event) => {
    try {
      setUpdating(true);
      const payload = {
        fee,
        discount,
        discountType,
        isFree,
        validity
      };
      await onSave(event, payload);
      onClose();
    } catch (error) {
      setUpdating(false);
    }
  };

  const isDisabled = () => {
    return updating || (!isFree && (!fee || fee <= 0));
  };

  return (
    <Sidepanel
      title="Course fee"
      subTitle={`Set up fee and discounts for the course: ${course?.name}`}
      onSave={handleSaveCourse}
      onClose={onClose}
      updating={updating}
      disabled={isDisabled()}
    >
      <SidepanelField
        label="Is Fee?"
      >
        <Toggle enabled={isFree} onChange={(value) => setIsFree(value)} />
      </SidepanelField>

      <SidepanelField
        label="Payment circle"
      >
        <p>{paymentCircle}</p>
      </SidepanelField>

      <SidepanelField
        label="Course fee (BDT)"
      >
        <Input type="number" name="fee" value={fee} onChange={(event) => setFee(event.target.value)} />
      </SidepanelField>

      {paymentCircle !== 'month' &&
      <SidepanelField
        label="Discount value"
      >
        <Input type="number" name="discount" value={discount} onChange={(event) => setDiscount(event.target.value)} />
      </SidepanelField>}

      {paymentCircle !== 'month' &&
      <SidepanelField
        label="Discount type"
      >
        <Select
          disabled={course.courseType === 'offline'}
          name="discountType"
          value={discountType}
          options={discountTypes}
          onChange={(event) => setDiscountType(event.target.value)}
        />
      </SidepanelField>}

      {includes(['recorded', 'exam'], course.courseType) &&
      <SidepanelField
        label="Course validity (years)"
      >
        <Input type="number" name="validity" value={validity} onChange={(event) => setValidty(event.target.value)} />
      </SidepanelField>}
    </Sidepanel>
  );
};
