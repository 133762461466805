import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { omit, isEmpty, capitalize } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import { getInstructors, changeInstrutorStatus } from '../../services/instructor';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';


const headers = [ 'Name', 'Designation', 'Display order', 'Status' ];

export default function InstructorsPage() {
  const [instructors, setInstructors] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusFilters, setStatusFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentpage,
    queryString
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentpage,
        search: queryString,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      const instructors = await getInstructors(params);
      setInstructors(instructors.docs);
      setPagingData(omit(instructors, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeInstrutorStatus(selectedInstructor._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      // sidebarNavigation={sidebarNavigation}
      // currentSidebarNavigation="Instructors"
    >
      <SectionHeader
        title="Intructors"
        subTitle="List of all instructors for your institution."
        createButtonText="New instructor"
        onCreate={() => window.location.href = `/instructors/create`}
      />

      {showStatusModal &&
      <ConfirmModal
        title={selectedInstructor.status === 'active' ? 'Disable instructor' : 'Enable instructor'} 
        description={`Are you sure to ${selectedInstructor?.status === 'active' ? 'disable' : 'enable'} this instructor? Please double check before performing this action.`}
        actionName={selectedInstructor?.status === 'active' ? 'Disable' : 'Enable'}
        onConfirm={handleStatusChange}
        onCancel={() => {
          setSelectedInstructor(null);
          setShowStatusModal(false);
        }}
      />}
      <Table
        headers={headers}
        items={instructors}
        itemsCount={instructors.length}
        pagingData={pagingData}
        queryString={queryString}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {instructors.map((instructor) => (
          <tr key={instructor._id} className="text-center">
            <td className="py-5 pl-4 pr-3 text-sm text-left sm:pl-0">
              <div className="flex items-center">
                <div className="h-8 w-8 flex-shrink-0">
                  {instructor.photo ?
                  <img className="h-8 w-8 rounded-full" src={instructor.photo} alt="" />
                  : <UserIcon className="h-6 w-6 rounded-full" />}
                </div>
                <div className="ml-4">
                  <div className="font-medium text-gray-900">{instructor.name}</div>
                  {/* <div className="mt-1 text-gray-500">{capitalize(instructor?.designation || '')}</div> */}
                </div>
              </div>
            </td>
            <td className="pr-3 py-5 text-sm text-gray-500">{capitalize(instructor?.designation || '')}</td>
            <td className="pr-3 py-5 text-sm text-gray-500">{instructor?.displayOrder || '-'}</td>
            
            <td className={`pr-3 py-5 text-sm text-gray-500`}>
              <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${instructor.status === 'active' ? "bg-green-50 text-green-700 ring-green-600/20" : "bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/20"} ring-1 ring-inset`}>
                {capitalize(instructor.status)}
              </span>
            </td>
            <td className="py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems 
                instructor={instructor}
                onStatusChange={() => {
                  setSelectedInstructor(instructor);
                  setShowStatusModal(true);
                }}
              />
            </td>
          </tr>
        ))}

      </Table>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { instructor, onStatusChange } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/instructors/${instructor._id}/edit`} />
      </div>
      <div className="py-1">
        <MenuItem label={instructor.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )
};
