import http from '../helpers/http';


export const uploadPublicImage = async (payload) => {
  try {
    const { data } = await http.post(`/api/uploads/public/image`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const uploadQuestionImage = async (payload) => {
  try {
    const { data } = await http.post(`/api/uploads/public/question-image`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const uploadEbook = async (payload) => {
  try {
    const { data } = await http.post(`/api/uploads/private/ebook`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const uploadPrivateDocument = async (payload) => {
  try {
    const { data } = await http.post(`/api/uploads/private/document`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const uploadLocal = async (payload) => {
  try {
    const { data } = await http.post(`/api/uploads/local`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
