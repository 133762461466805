import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';

import { InlineCheckbox } from '../../../components/forms/fields';
import { Sidepanel, SidepanelField } from '../../../components/Sidepanel';
import { getBranchPermission, createPermission, updateBranchPermission } from '../../../services/permission';


export default function BranchPermissionForm(props) {
  const { branch, onClose } = props;
  const [permission, setPermission] = useState(null);
  const [canViewContents, setCanViewContents] = useState(false);
  const [canUploadResults, setCanUploadResults] = useState(false);
  const [canEnrollOnlineCourses, setCanEnrollOnlineCourses] = useState(false);
  const [canManageOnlineRoutines, setCanManageOnlineRoutines] = useState(false);
  const [canAdmitCentralStudents, setCanAdmitCentralStudents] = useState(false);
  const [canCollectCentralPayments, setCanCollectCentralPayments] = useState(false);
  const [canManageCompetitionParticipants, setCanManageCompetitionParticipants] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setUpdating(true);
        const branchPermission = await getBranchPermission(branch._id);
        setPermission(branchPermission);
        setCanViewContents(branchPermission?.canViewContents);
        setCanUploadResults(branchPermission?.canUploadResults);
        setCanEnrollOnlineCourses(branchPermission?.canEnrollOnlineCourses);
        setCanManageOnlineRoutines(branchPermission?.canManageOnlineRoutines);
        setCanAdmitCentralStudents(branchPermission?.canAdmitCentralStudents);
        setCanCollectCentralPayments(branchPermission?.canCollectCentralPayments);
        setCanManageCompetitionParticipants(branchPermission?.canManageCompetitionParticipants);
        setUpdating(false);
      } catch (error) {
        toast.error(error.message);
        onClose();
      }
    };
    fetchData();
  }, []);

  const handleSavePermission = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        canViewContents,
        canUploadResults,
        canEnrollOnlineCourses,
        canManageOnlineRoutines,
        canAdmitCentralStudents,
        canCollectCentralPayments,
        canManageCompetitionParticipants
      };

      let updatedPermission;
      if (permission?._id) {
        updatedPermission = await updateBranchPermission(permission?._id, payload);
      } else {
        payload.permissionType = 'branch';
        payload.branch = branch._id;
        updatedPermission = await createPermission(payload);
      }
      setPermission(updatedPermission);
      toast.success('Permission updated successfully.');
      onClose();
    } catch (error) {
      toast.error(error.message);
      setUpdating(false);
    }
  };

  return (
    <Sidepanel
      title="Branch permissions"
      subTitle={branch.name}
      onSave={handleSavePermission}
      onClose={onClose}
      updating={updating}
      disabled={updating}
    >
      <SidepanelField
        label="Permissions"
        col={3}
      >
        <div className="space-y-4">
          <InlineCheckbox
            label="View contant"
            description="Allow this branch to view notes and exams."
            checked={canViewContents}
            onChange={() => setCanViewContents(!cloneDeep(canViewContents))}
          />
          <InlineCheckbox
            label="Upload result"
            description="Allow this branch to upload results."
            checked={canUploadResults}
            onChange={() => setCanUploadResults(!cloneDeep(canUploadResults))}
          />
          <InlineCheckbox
            label="Enroll online courses"
            description="Allow this branch to enroll students to online courses."
            checked={canEnrollOnlineCourses}
            onChange={() => setCanEnrollOnlineCourses(!cloneDeep(canEnrollOnlineCourses))}
          />

          <InlineCheckbox
            label="Manage routines for online courses"
            description="Allow this branch to manange online course class routines."
            checked={canManageOnlineRoutines}
            onChange={() => setCanManageOnlineRoutines(!cloneDeep(canManageOnlineRoutines))}
          />

          <InlineCheckbox
            label="Manage competition participants"
            description="Allow this branch to add and view competition perticipants."
            checked={canManageCompetitionParticipants}
            onChange={() => setCanManageCompetitionParticipants(!cloneDeep(canManageCompetitionParticipants))}
          />

          <InlineCheckbox
            label="Admit students centrally"
            description="Allow this branch to admit students for other branches."
            checked={canAdmitCentralStudents}
            onChange={() => setCanAdmitCentralStudents(!cloneDeep(canAdmitCentralStudents))}
          />

          <InlineCheckbox
            label="Collect payments centrally"
            description="Allow this branch to collect payments for other branches."
            checked={canCollectCentralPayments}
            onChange={() => setCanCollectCentralPayments(!cloneDeep(canCollectCentralPayments))}
          />
        </div>
      </SidepanelField>
    </Sidepanel>
  );
};
