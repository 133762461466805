import { LuExternalLink } from "react-icons/lu";
import { orderBy } from 'lodash';

import { getInstructors } from '../../../../services/instructor';
import { useEffect, useState } from "react";


export default function Teachers() {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    async function _fetchData() {
      const _instructors = await getInstructors({ status: 'active' });
      setInstructors(orderBy(_instructors, 'displayOrder').slice(0, 6));
    };
    _fetchData();
  }, []);
  return (
    <div className="bg-gray-50 py-12 sm:py-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 space-y-4 ">
          <h2 className="text-base font-semibold leading-6 text-left text-blue-600">
            ইন্সট্রাক্টরস
          </h2>
          <h2 className="text-3xl text-left font-extrabold leading-10 tracking-tight text-gray-900 sm:text-4xl">
            দেশ সেরা ইন্সট্রাক্টরদের সাথে শুরু হোক আপনার পথচলা
          </h2>
        </div>
        

        <div className="flex justify-end">
        <button
          className="mt-6 lg:-mt-12  w-full text-base leading-6 sm:w-fit flex justify-center items-center text-blue-900 font-medium bg-blue-100 hover:bg-blue-200 py-3.5 px-6 rounded-[6px]"
          onClick={() => window.location.href = `/learn/instructors`}
        >
           সকল ইন্সট্রাক্টর
          <LuExternalLink className="ml-3 text-xl" />
        </button>
        </div>

      

        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {instructors.map((instructor) => (
            <li key={instructor.name} className="bg-white rounded-2xl overflow-hidden shadow-md">
              <div className="flex justify-center">
                <img src={instructor.photo} alt="" className="" />
              </div>
              
              <div className="px-4 pb-4">
                <h3 className="mt-6 text-lg font-medium	 text-left leading-7 tracking-tight text-gray-900 ">{instructor.name}</h3>
                <p className="text-sm	 leading-6 text-gray-500 text-left">{instructor.designation}</p>
                <p className="text-sm	 leading-6 text-gray-500 text-left">{instructor.educations[0]}</p>
                <p className="text-sm	 leading-6 text-gray-500 text-left">{instructor.experiences[0]}</p>
                <ul role="list" className="mt-3 flex gap-x-4">
                  {instructor.facebookUrl &&
                  <li>
                    <a href={instructor.facebookUrl} target="_blank">
                      <span className="sr-only">Facebook</span>
                      <svg className="h-5 w-5" aria-hidden="true" fill="#316FF6" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>}
                  {instructor.linkedinUrl &&
                  <li>
                  <a href={instructor.linkedinUrl} target="_blank">
                    <span className="sr-only">LinkedIn</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="#0a66c2" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
