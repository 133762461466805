import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { BanknotesIcon, CurrencyBangladeshiIcon } from '@heroicons/react/24/outline';
import { includes, orderBy, capitalize, cloneDeep, findIndex, forEach, isEmpty, round } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Input, Select } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { images } from '../../constants';
import { payBulkInvoices, payInvoice, getInvoices } from '../../services/invoice';
import { getStudentByRegistrationNumber } from '../../services/student';
import { getAdmittedProgramForProgram, getAdmittedPrograms } from '../../services/programAdmission';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  { name: 'Collect payment', href: '/student-payments/collect' },
  { name: 'All due payments', href: '/student-payments/due' },
  { name: 'All paid payments', href: '/student-payments/paid' },
  { name: 'Other branch payments', href: '/student-payments/central' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};
const headers = ['Program', 'Course', 'Branch', 'Amount', 'Status', 'Due Date'];

const paymentMethods = [
  { title: 'Cash', value: 'cash' },
  { title: 'bKash', value: 'bkash', icon: images.Bkash },
  { title: 'Nagad', value: 'nagad', icon: images.Nagad },
  { title: 'Card', value: 'card', icon: images.CreditCard },
  { title: 'Cheque', value: 'cheque' },
];
// const breadcrumbPages = [
//   { name: 'Student due payments', href: '/student-payments/due' },
//   { name: 'Collection', current: true },
// ];

export default function StudentDuePaymentsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const programId = location?.state?.programId;
  const [registrationNo, setRegistrationNo] = useState(location?.state?.registrationNo || '');
  const [student, setStudent] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [totalDue, setTotalDue] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [additionalDiscount, setAdditionalDiscount] = useState(0);
  const [reference, setReference] = useState('');
  const [dueAmount, setDueAmount] = useState(0);
  const [dueDate, setDueDate] = useState(null);
  const [startMonth, setStartMonth] = useState('');
  const [billingMonth, setBillingMonth] = useState('');
  const [merchantNumber, setMerchantNumber] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [cardNo, setCardNo] = useState(null);
  const [depositedBank, setDepositedBank] = useState(null);
  const [chequeNo, setChequeNo] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [programAdmission, setProgramAdmission] = useState(null);
  const [monthlyScholarship, setMonthlyScholarship] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      if (registrationNo) {
        await fetchData();
      }
    };
    _fetchData();
    setLoading(false);
  }, []);

  const fetchData = async () => {
    try {
      const _student = await getStudentByRegistrationNumber(registrationNo);
      setStudent(_student);
    } catch (error) {
      setStudent(null);
      setInvoices([]);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    async function fetchPrograms() {
      setInvoices([]);
      setStartMonth('');
      setBillingMonth('');
      setMonthlyScholarship(0);
      setAdditionalDiscount(0);
      setReference('');
      setPrograms([]);
      setSelectedProgram(null);
      setSelectedInvoices([]);
      try {
        if (student) {
          const _programs = await getAdmittedPrograms(student._id);
          setPrograms(_programs);
          if (programId) {
            const programIndex = findIndex(_programs, p => p._id.toString() === programId.toString());
            setSelectedProgram(_programs[programIndex]);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchPrograms();
  }, [student]);

  useEffect(() => {
    async function fetchDuePayments() {
      setSelectedInvoices([]);
      setInvoices([]);
      setStartMonth('');
      setBillingMonth('');
      setMonthlyScholarship(0);
      setAdditionalDiscount(0);
      setReference('');

      if (!selectedProgram) {
        setInvoices([]);
        setProgramAdmission(null);
        setStartMonth('');
        setBillingMonth('');
        return;
      }
      try {
        if (selectedProgram?.paymentCircle === 'onetime') {
          const params = {
            user: student._id,
            program: selectedProgram._id,
            status: ['due', 'overdue'],
          };
          const _invoices = await getInvoices(params);
          setInvoices(orderBy(_invoices, 'dueDate'));
        } else {
          const programAdmission = await getAdmittedProgramForProgram(selectedProgram._id, {studentId: student._id});
          const _startMonth = programAdmission.startMonth;
          setProgramAdmission(programAdmission);
          setStartMonth(getNormalizedDateTime(_startMonth, 'YYYY-MM'));
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchDuePayments();
  }, [selectedProgram]);

  useEffect(() => {
    async function fetchDuesForMonth() {
      if (!selectedProgram || isEmpty(billingMonth) ||selectedProgram?.paymentCircle === 'onetime') {
        setInvoices([]);
        return;
      }
      try {
        const params = {
          user: student?._id,
          program: selectedProgram?._id,
          status: ['due', 'overdue'],
          billingMonth: billingMonth
        };
        const _invoices = await getInvoices(params);
        setInvoices(orderBy(_invoices, 'dueDate'));
        setSelectedInvoices(_invoices);

        if (_invoices.some(invoice => invoice.invoiceFor === 'duePayment')) {
          setMonthlyScholarship(0);
        } else {
          const minCourseForScholarship = programAdmission.scholarshipMinSubjectCount - programAdmission.freeCourseCount;
          const _monthlyScholarship = _invoices.length >= minCourseForScholarship ? programAdmission.monthlyScholarship : 0;
          setMonthlyScholarship(_monthlyScholarship || 0);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchDuesForMonth();
  }, [billingMonth]);

  const searchStudent = async (event) => {
    event.preventDefault();
    await fetchData();
  };

  const handlePaymentMethodFieldChange = (event) => {
    const filedName = event.target.name;
    const fieldValue = event.target.value;

    const stateMethods = {
      merchantNumber: setMerchantNumber,
      transactionId: setTransactionId,
      cardNo: setCardNo,
      depositedBank: setDepositedBank,
      chequeNo: setChequeNo,
      bankName: setBankName
    };
    stateMethods[filedName](fieldValue)
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setMerchantNumber(null);
    setTransactionId(null);
    setCardNo(null);
    setDepositedBank(null);
    setChequeNo(null);
    setBankName(null);
  };

  useEffect(() => {
    let _totalDues = 0;
    forEach(selectedInvoices, invoice => _totalDues = _totalDues + round(invoice.amount, 2));
    setTotalDue(_totalDues);
  }, [selectedInvoices]);

  useEffect(() => {
    const _dueAmount = totalDue - parseFloat(paidAmount || 0) - (monthlyScholarship || 0) - parseFloat(additionalDiscount || 0);
    setDueAmount(_dueAmount);
  }, [totalDue, paidAmount, additionalDiscount, monthlyScholarship]);

  const handlePayment = async (event) => {
    try {
      event.preventDefault();
      if (selectedInvoices.length === 0) { return; }
      setUpdating(true);

      if (selectedInvoices.length === 1) {
        await handlePaySingleInvoice(selectedInvoices[0]._id);
      } else {
        await handleBulkPayment();
      }
      
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleBulkPayment = async () => {
    try {
      const paymentMethod = selectedPaymentMethod.value;
      let selectedInvoiceIds = [];
      forEach(selectedInvoices, invoice => selectedInvoiceIds.push(invoice._id));
      let payload = {
        userId: student._id,
        selectedInvoices: selectedInvoiceIds,
        paidAmount: parseFloat(paidAmount),
        paymentMethod: paymentMethod,
        paymentBranch: currentBranch._id,
        monthlyScholarship: monthlyScholarship,
        billingMonth: billingMonth,
        paymentCircle: selectedProgram.paymentCircle,
        additionalDiscount: parseFloat(additionalDiscount),
        reference: reference
      };
      if (paymentMethod === 'bkash') {
        payload.bkashMerchant = merchantNumber;
        payload.bkashTransactionId = transactionId;
      } else if (paymentMethod === 'nagad') {
        payload.nagadMerchant = merchantNumber;
        payload.nagadTransactionId = transactionId;
      } else if (paymentMethod === 'card') {
        payload.cardNumber = cardNo;
        payload.depositedBank = depositedBank;
      } else if (paymentMethod === 'cheque') {
        payload.chequeNumber = chequeNo;
        payload.bankName = bankName;
      }

      const data = await payBulkInvoices(payload);
      navigate(`/student-payments/payment/success`, {
        state: {
          transactionId: data.transactionId,
          invoices: data.invoices,
          isBulkPayment: true,
          paymentCircle: data.paymentCircle,
          backUrl: `/students`
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const handlePaySingleInvoice = async (invoiceId) => {
    try {
      const paymentMethod = selectedPaymentMethod.value;
      let payload = {
        invoice: invoiceId,
        paidAmount: parseFloat(paidAmount),
        dueAmount: dueAmount,
        dueDate: dueDate,
        paymentMethod: paymentMethod,
        paymentBranch: currentBranch._id,
        monthlyScholarship: monthlyScholarship,
        billingMonth: billingMonth,
        paymentCircle: selectedProgram.paymentCircle,
        additionalDiscount: parseFloat(additionalDiscount),
        reference: reference
      };
      if (paymentMethod === 'bkash') {
        payload.bkashMerchant = merchantNumber;
        payload.bkashTransactionId = transactionId;
      } else if (paymentMethod === 'nagad') {
        payload.nagadMerchant = merchantNumber;
        payload.nagadTransactionId = transactionId;
      } else if (paymentMethod === 'card') {
        payload.cardNumber = cardNo;
        payload.depositedBank = depositedBank;
      } else if (paymentMethod === 'cheque') {
        payload.chequeNumber = chequeNo;
        payload.bankName = bankName;
      }

      const data = await payInvoice(invoiceId, payload);
      navigate(`/student-payments/payment/success`, {
        state: {
          transactionId: data.transactionId,
          invoiceId: data.invoiceId,
          backUrl: `/students`
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const handleToggleInvoice = (invoice) => {
    let _selectedInvoices = cloneDeep(selectedInvoices);
    const invoiceIndex = findIndex(_selectedInvoices, _invoice => _invoice?._id?.toString() === invoice?._id?.toString());
    if (invoiceIndex === -1) {
      _selectedInvoices.push(invoice);
      setSelectedInvoices(_selectedInvoices);
    } else {
      _selectedInvoices.splice(invoiceIndex, 1);
      setSelectedInvoices(_selectedInvoices);
    }
  };

  const invoiceChecked = (invoice) => {
    return findIndex(selectedInvoices, _invoice => _invoice?._id?.toString() === invoice?._id?.toString()) !== -1;
  }

  const isDisabled = () => {
    const _paidAmount = parseFloat(paidAmount);
    const _additionalDiscount = parseFloat(additionalDiscount);

    return updating || totalDue === 0 || _paidAmount === 0 || dueAmount < 0 ||
    (selectedProgram?.paymentCircle === 'onetime' && selectedInvoices.length > 1 && dueAmount > 0) ||
    (dueAmount > 0 && !dueDate) ||
    (_additionalDiscount > 0 && (dueAmount > 0 || isEmpty(reference))) ||
    (includes(['bkash', 'nagad'], selectedPaymentMethod.value) && (!merchantNumber || !transactionId)) ||
    (selectedPaymentMethod.value === 'card' && (!cardNo || !depositedBank)) ||
    (selectedPaymentMethod.value === 'cheque' && (!chequeNo || !bankName));
  };

  return (
    <StackedLayout
      loading={loading}
      // breadcrumbPages={breadcrumbPages}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Collect payment"
    >
      {showConfirmModal &&
      <ConfirmModal
        title="Confirm payment"
        description="Are you sure confirm this payment? Please double check before performing this action."
        actionName="Confirm"
        onConfirm={handlePayment}
        onCancel={() => setShowConfirmModal(false)}
      />}

      <div className="sm:grid sm:grid-cols-7 lg:gap-x-12 xl:gap-x-16">
        <div className="mb-20 sm:col-span-4">
          <div>
            <h2 className="text-lg font-medium text-gray-900">Information</h2>
            <dl className="mt-6 space-y-4 border-t border-gray-200 text-sm leading-6">
              <div className="mt-4 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Registration number</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="flex w-full space-x-4">
                    <Input placeholder="Registration no." value={registrationNo} onChange={(event) => setRegistrationNo(event.target.value)} />
                    <Button label="Search" onClick={searchStudent} />
                  </div>
                </dd>
              </div>

              {student &&
              <>
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-600">{student?.name}</div>
                  </dd>
                </div>
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Mobile</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-600">{student?.mobile}</div>
                  </dd>
                </div>
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Program</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-600">
                      <Select value={selectedProgram?._id} options={programs} onChange={(event) => setSelectedProgram(programs[event.target.selectedIndex-1])} />
                    </div>
                  </dd>
                </div>

                {selectedProgram?.paymentCircle === 'month' &&
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Month</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-600">
                      <Input type="month" min={startMonth} value={billingMonth} onChange={(event) => setBillingMonth(event.target.value)} />
                    </div>
                  </dd>
                </div>}
              </>}
            </dl>
          </div>

          <div className="mb-20 pb-20">
            {invoices?.length > 0 &&
            <Table
              itemsCount={invoices.length}
              headers={headers}
            >
              {invoices.map((invoice) => (
                <tr key={invoice._id} className="text-center">
                  <td className="py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 text-ellipsis text-left">
                    {invoice?.program?.name}
                  </td>
                  <td className="pr-3 py-4 text-sm text-gray-500">
                    {invoice?.course?.name || (invoice?.courses?.length === 1 ? invoice?.courses[0].name : `${invoice.courses.length} courses`)}
                  </td>
                  <td className="pr-3 py-4 text-sm text-gray-500">{invoice?.branch?.name}</td>
                  <td className="pr-3 py-4 text-sm text-gray-500">
                    {invoice.amount.toLocaleString()}
                  </td>
                  <td 
                    className={`pr-3 py-4 text-sm ${invoice.status === 'overdue' ? 'text-red-500' : 'text-gray-500'}`}
                  >
                    {capitalize(invoice?.status)}
                  </td>
                  <td className="pr-3 py-4 text-sm text-gray-500">
                    {getNormalizedDateTime(invoice?.paymentDate || invoice?.dueDate, 'DD MMM YYYY')}
                  </td>
                  <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <input
                      type="checkbox"
                      disabled={selectedProgram?.paymentCircle === 'month'}
                      checked={invoiceChecked(invoice)}
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                      onChange={() => handleToggleInvoice(invoice)}
                    />
                  </td>
                </tr>
              ))}
            </Table>}
          </div>
        </div>
        
        <div className="mt-10 sm:mt-0 sm:col-span-3">
          <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
          <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div className="flex items-center justify-between">
                <dt className="text-sm">Total dues</dt>
                <dd className="text-sm font-medium text-gray-900">৳{totalDue?.toLocaleString()}</dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-sm">Monthly scholarship(-)</dt>
                <dd className="text-sm font-medium text-gray-900">৳{monthlyScholarship?.toLocaleString()}</dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-sm">Additional discount(-)</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="number" textRight value={additionalDiscount} onChange={(event) => setAdditionalDiscount(event.target.value)} />
                </dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-sm">Paid amount</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="number" textRight value={paidAmount} onChange={(event) => setPaidAmount(event.target.value)} />
                </dd>
              </div>
              
              <div className="flex items-center justify-between text-red-700">
                <dt className="text-sm">Due amount</dt>
                <dd className="text-sm font-medium">
                ৳{dueAmount?.toLocaleString()}
                </dd>
              </div>

              {parseFloat(dueAmount) > 0 &&
              <div className="flex items-center justify-between">
                <dt className="text-sm">Due date</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input type="date" value={dueDate} onChange={(event) => setDueDate(event.target.value)} />
                </dd>
              </div>}

              {parseFloat(additionalDiscount) > 0 &&
              <div className="flex items-center justify-between">
                <dt className="text-sm">Reference</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <Input value={reference} onChange={(event) => setReference(event.target.value)} />
                </dd>
              </div>}
            </dl>

            <div className="mx-2 border-gray-200 pt-6">
              <PaymentMethods
                currentBranch={currentBranch}
                selectedPaymentMethod={selectedPaymentMethod}
                merchantNumber={merchantNumber}
                transactionId={transactionId}
                cardNo={cardNo}
                depositedBank={depositedBank}
                chequeNo={chequeNo}
                bankName={bankName}
                onSelectPaymentMethod={handlePaymentMethodChange}
                onFieldChange={handlePaymentMethodFieldChange}
              />
            </div> 

            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <SubmitButton
                disabled={isDisabled()}
                className="w-full"
                label="Collect payment"
                onClick={() => setShowConfirmModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};

function PaymentMethods(props) {
  const {
    currentBranch,
    merchantNumber,
    transactionId,
    cardNo,
    depositedBank,
    chequeNo,
    bankName,
    selectedPaymentMethod,
    onSelectPaymentMethod,
    onFieldChange
  } = props;

  return (
    <div className="pb-10">
      <RadioGroup value={selectedPaymentMethod} onChange={onSelectPaymentMethod}>
        <RadioGroup.Label className="text-lg font-medium text-gray-900">Payment method</RadioGroup.Label>
        <div className="mx-2 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {paymentMethods.map((paymentMethod, index) => (
            <RadioGroup.Option
              key={`payment-method-index[${index}]`}
              value={paymentMethod}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'ring-2 ring-green-500' : '',
                  'relative flex cursor-pointer rounded-lg border bg-white p-2 shadow-sm focus:outline-none'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="flex items-center space-x-1 text-sm font-medium text-gray-900">
                        {paymentMethod.value === 'cash' &&
                        <CurrencyBangladeshiIcon className="h-6 w-6" />}
                        {paymentMethod.value === 'cheque' &&
                        <BanknotesIcon className="h-6 w-6" />}
                        {paymentMethod.icon &&
                        <img className="h-6 text-green-600" src={paymentMethod.icon} alt="" />}
                        <span>{paymentMethod.title}</span>
                      </RadioGroup.Label>
                      {/* <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500"
                      >
                        No offers available now
                      </RadioGroup.Description> */}
                    </span>
                  </span>
                  {checked ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                  ) : null}
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-green-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {selectedPaymentMethod.value !== 'cash' &&
      <div>
        <dl className="mt-6 space-y-4 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          {selectedPaymentMethod.value === 'bkash' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Merchant number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Select name="merchantNumber" value={merchantNumber} options={currentBranch?.bkashNumbers} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'nagad' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Merchant number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Select name="merchantNumber" value={merchantNumber} options={currentBranch?.nagadNumbers} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {includes(['bkash', 'nagad'], selectedPaymentMethod.value) &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Transaction ID</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Input name="transactionId" value={transactionId || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'card' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Card number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="cardNo" value={cardNo || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'card' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Deposited bank</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="depositedBank" value={depositedBank || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'cheque' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Cheque number</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">
                <Input name="chequeNo" value={chequeNo || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
          {selectedPaymentMethod.value === 'cheque' &&
          <div className="pt-4 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6">Bank name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600 w-full">
                <Input name="bankName" value={bankName || ''} onChange={onFieldChange}  />
              </div>
            </dd>
          </div>}
        </dl>
      </div>}
    </div>
  )
};