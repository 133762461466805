import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { ChevronRightIcon, DocumentTextIcon, FolderIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { forEach, includes, isEmpty, orderBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import FolderBreadcrumb from '../../components/FolderBreadcrumb';
import { ButtonWithIcon } from '../../components/buttons';
import InputModal from '../../components/modals/InputModal';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import EbookStoreForm from './components/EbookStoreForm';
import { getFolder, createFolder, getFolders, updateFolder, deleteFolder } from '../../services/folder';
import { getEbooks, updateEbook, deleteEbook } from '../../services/ebook';


const sidebarNavigation = [
  { name: 'Documents', href: '/contents/documents' },
];
const headers = ['Name', 'Created By', 'Pages'];

export default function EbooksPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const params = useParams();
  const navigate = useNavigate();
  const folderId = params?.folderId;
  const folderType = 'ebook';
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentNaviation, setCurrentNavigation] = useState(sidebarNavigation[0]);
  const [queryString, setQueryString] = useState(null);
  const [selectedEbook, setSelectedEbook] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openStoreForm, setOpenStoreForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitialData() {
      if (folderId) {
        try {
          const _folder = await getFolder(folderId);
          setCurrentFolder(_folder);
        } catch (error) {
          navigate(-1);
        }
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    queryString,
    currentFolder
  ]);

  const fetchData = async () => {
    try {
      const ebooks = await fetchEbooks();
      let folders = await fetchFolders();
      forEach(folders, folder => folder.isFolder = true);
      const _items = folders.concat(ebooks);
      setItems(_items);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchEbooks = async () => {
    try {
      let params = {
        search: queryString
      };

      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      const data = await getEbooks(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFolders = async () => {
    try {
      let params = {
        contentType: folderType,
        search: queryString,
      };

      if (currentFolder) {
        params.parentFolder = currentFolder._id;
      } else {
        params.isRoot = true;
      }
      const data = await getFolders(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveEbook = async (event, payload) => {
    try {
      event.preventDefault();
      await updateEbook(selectedEbook._id, payload);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleDeleteEbook = async (event) => {
    try {
      event.preventDefault();
      await deleteEbook(selectedEbook._id);
      toast.success('Ebook deleted successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString : null;
    setQueryString(q);
    setLoading(true);
  };

  const saveFolder = async (event, name) => {
    try {
      event.preventDefault();
      if (selectedFolder) {
        const payload = { name: name };
        await updateFolder(selectedFolder._id, payload);
        toast.success('Folder updated successfully.');
      } else {
        let payload = { name: name, contentType: folderType };
        if (currentFolder) { payload.parentFolder = currentFolder._id };
        await createFolder(payload);
        toast.success('Folder created successfully');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleDeleteFolder = async (event) => {
    try {
      event.preventDefault();
      await deleteFolder(selectedFolder._id);
      toast.success('Folder deleted successfully');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedFolder(null);
    }
  };

  const handleCreateNew = (itemName) => {
    if (itemName === 'folder') {
      setOpenFolderModal(true);
    } else {
      navigate(`/contents/documents/create`, {
        state: {
          folderId: folderId
        }
      });
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={currentNaviation?.name}
    >
      {openFolderModal &&
        <InputModal
          label={selectedFolder ? 'Rename folder' : 'New folder name'}
          currentValue={selectedFolder?.name}
          actionName={selectedFolder ? 'Rename' : 'Create'}
          onSubmit={saveFolder}
          onCancel={() => setOpenFolderModal(false)}
        />}

      {showFolderDeleteModal &&
        <ConfirmModal
          title="Delete folder"
          description={`Are you sure to delete folder? Please note that all the child folders and contents for this folder will be deleted as well.`}
          actionName="Delete"
          onConfirm={handleDeleteFolder}
          onCancel={() => {
            setSelectedFolder(null);
            setShowFolderDeleteModal(false);
          }}
        />}

      {showDeleteModal &&
        <ConfirmModal
          title="Delete ebook"
          description={`Are you sure to delete this ebook? Please double check before performing this action.`}
          actionName="Delete"
          onConfirm={handleDeleteEbook}
          onCancel={() => {
            setSelectedEbook(null);
            setShowDeleteModal(false);
          }}
        />}

      {openStoreForm &&
        <EbookStoreForm
          ebook={selectedEbook}
          onSave={saveEbook}
          onClose={() => {
            setSelectedEbook(null);
            setOpenStoreForm(false);
          }}
        />}

      {!currentUser.branch &&
        <div className="mx-2 flex justify-end space-x-4">
          <ButtonWithIcon Icon={FolderIcon} label="New folder" onClick={() => handleCreateNew('folder')} />
          <ButtonWithIcon Icon={DocumentTextIcon} label="New document" onClick={() => handleCreateNew('document')} />
        </div>}

      {currentFolder &&
        <div className="px-4 sm:px-6 lg:px-8">
          <FolderBreadcrumb currentFolder={currentFolder} homeUrl={currentNaviation.href} />
        </div>}

      <div className="flex flex-col">
        <Table
          itemsCount={items.length}
          headers={headers}
          queryString={queryString}
          onSearch={onSearch}
        >
          {items?.map((item) => (
            <tr key={item._id}>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
                <div className="flex items-center">
                  <div>
                    {item.isFolder ?
                      <FolderIcon className="h-5 w-5" />
                      : <DocumentTextIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      <a
                        href={item.isFolder ? `/contents/documents/folder/${item._id}` : `/contents/documents/${item._id}/edit`}
                        className="hover:underline"
                      >
                        {item?.name}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
                <div className="flex items-center">
                  <div>
                    {item?.createdBy?.profilePicture ?
                      <img className="h-5 w-5 rounded-full" src={item.createdBy.profilePicture} />
                      : <UserCircleIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item?.createdBy?.name.split(' ').slice(0, 2).join(' ')}
                    </div>
                  </div>
                </div>
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{item?.pages || '-'}</td>
              {/* <td className="pr-3 py-4 text-sm text-gray-500">{item?.publishingStatus || '-'}</td> */}

              {currentUser.branch && item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-sm font-medium sm:pr-0">
                  <div className="flex justify-end">
                    <a href={`/contents/ebooks/folder/${item._id}`}>
                      <ChevronRightIcon className="h-4 w-4" />
                    </a>
                  </div>
                </td>}

              {!currentUser.branch && item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <FolderActionItems
                    currentUser={currentUser}
                    onRename={() => {
                      setSelectedFolder(item);
                      setOpenFolderModal(true);
                    }}
                    onDelete={() => {
                      setSelectedFolder(item);
                      setShowFolderDeleteModal(true);
                    }}
                  />
                </td>}

              {!item.isFolder &&
                <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <ActionItems
                    currentUser={currentUser}
                    ebook={item}
                    onEdit={() => {
                      navigate(`/contents/documents/${item._id}/edit`, {
                        state: {
                          folderId: folderId
                        }
                      })
                    }}
                    onStoreSetup={() => {
                      setSelectedEbook(item);
                      setOpenStoreForm(true);
                    }}
                    onDelete={() => {
                      setSelectedEbook(item);
                      setShowDeleteModal(true);
                    }}
                  />
                </td>}
            </tr>
          ))}
        </Table>
      </div>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { currentUser, ebook, onStoreSetup, onDelete } = props;

  return (
    <ActionMenu>
      {!currentUser.branch &&
        <>
          <div className="py-1">
            <MenuItem label="Edit" href={`/contents/documents/${ebook._id}/edit`} />
          </div>
          {/* <div className="py-1">
          <MenuItem label="Online store settings" onClick={onStoreSetup} />
        </div> */}
        </>}

      <div className="py-1">
        <MenuItem label="Download" onClick={() => { }} />
      </div>

      {!currentUser.branch && ebook.publishingStatus !== 'published' &&
        <div className="py-1">
          <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
        </div>}
    </ActionMenu>
  )
};

function FolderActionItems(props) {
  const { currentUser, onRename, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Rename" onClick={onRename} />
      </div>

      {includes(['owner', 'admin'], currentUser?.role) &&
        <div className="py-1 text-red-600">
          <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
        </div>}
    </ActionMenu>
  )
};
