import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import BranchPermissionDeniedPage from '../accessControlPages/BranchPermissionDenied';
import SectionHeader from '../../components/SectionHeader';
import { Select, UploadWithDragDrop } from '../../components/forms/fields';
import { getPrograms, getProgramExams } from '../../services/program';
import { Button, SubmitButton } from '../../components/buttons';
import { handleUploadLocalFile } from '../../utils';
import { addMarksheet } from '../../services/takenExam';
import { includes } from 'lodash';


const sidebarNavigation = [
  { name: 'Upload result', href: '/results/upload' },
  { name: 'Meritlist builder', href: '/results/meritlist' }
];

const examTypes = [
  { name: 'MCQ', value: 'mcq' },
  { name: 'Written/CQ', value: 'cq' }
];

export default function ResultUploadPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const _programs = await getPrograms();
        setPrograms(_programs);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    async function fetchExams() {
      try {
        if (!selectedProgram) {
          setExams([]);
        } else {
          const _exams = await getProgramExams(selectedProgram, 'offline');
          setExams(_exams);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSelectedExam(null);
      }
    };
    fetchExams();
  }, [selectedProgram]);

  useEffect(() => {
    if (!selectedExam || selectedExam.questionType === 'mcqCq') {
      setSelectedQuestionType(null);
    } else {
      setSelectedQuestionType(selectedExam.questionType);
    }
  }, [selectedExam]);

  const handleSelectFile = async (files) => {
    setSelectedFiles(files);
    return files[0];
  };

  const handleUploadMarksheet = async () => {
    try {
      setUpdating(true);
      const filePath = await handleUploadLocalFile(selectedFiles);
      const payload = {
        programId: selectedProgram,
        examId: selectedExam._id,
        filePath: filePath,
        questionType: selectedQuestionType
      };
      await addMarksheet(payload);
      toast.success('Marksheet is being proccessed.');
      setSelectedProgram(null);
      setSelectedExam(null);
      setSelectedQuestionType(null);
      setSelectedFiles([]);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const cancelUpload = () => {
    setSelectedProgram(null);
    setSelectedFiles([]);
  };

  const isDisabled = () => {
    return updating || !selectedProgram || !selectedExam || !selectedQuestionType || selectedFiles.length === 0;
  };

  if (!includes(['owner', 'admin'], currentUser?.role) && !currentBranch?.permissions?.canUploadResults) {
    return (
      <BranchPermissionDeniedPage/>
    )
  }


  return (
    <StackedLayout
      loadin={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Upload result"
    >
      <SectionHeader
        title="Upload results"
        subTitle="Upload student result from excel file."
      />

      <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Program
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <Select
              options={programs}
              value={selectedProgram}
              onChange={(event) => setSelectedProgram(event.target.value)}
            />
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Exam
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <Select
              options={exams}
              value={selectedExam?._id}
              onChange={(event) => setSelectedExam(exams[event.target.selectedIndex - 1])}
            />
          </dd>
        </div>

        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Exam type
          </dt>
          {selectedExam?.questionType === 'mcqCq' ?
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <Select
              options={examTypes}
              value={selectedQuestionType}
              onChange={(event) => setSelectedQuestionType(event.target.value)}
            />
          </dd>
          :
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            {selectedQuestionType}
          </dd>}
        </div>

        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
            Upload excel
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto w-full">
            <div className="w-full">
              <UploadWithDragDrop
                actionName="Upload marksheet"
                contentType="excel"
                content={selectedFiles?.length > 0 ? selectedFiles[0] : null}
                onUpload={handleSelectFile}
              />
            </div>
          </dd>
        </div>
      </dl>
      <div className="flex justify-end space-x-4">
        <Button
          label="Clear"
          onClick={cancelUpload}
        />
        <SubmitButton
          label="Upload result"
          disabled={isDisabled()}
          onClick={handleUploadMarksheet}
        />

      </div>
    </StackedLayout>
  )
};
