import http from '../helpers/http';


export const createPermission = async (payload) => {
  try {
    const { data } = await http.post(`/api/permissions`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchPermission = async (branchId) => {
  try {
    const { data } = await http.get(`/api/permissions/branches/${branchId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInstituteUserPermission = async (userId) => {
  try {
    const { data } = await http.get(`/api/permissions/users/${userId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchUserPermission = async (branchId, userId) => {
  try {
    const { data } = await http.get(`/api/permissions/branches/${branchId}/users/${userId}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateUserPermission = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/permissions/${id}/user`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateBranchPermission = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/permissions/${id}/branch`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
