import http from '../helpers/http';


export const getInvoice = async (id) => {
  try {
    const { data } = await http.get(`/api/invoices/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInvoicePdf = async (id) => {
  try {
    const { data } = await http.get(`/api/invoices/${id}/pdf`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInvoices = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/invoices`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getInvoicePaidAmount = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/invoices/paid-amount`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const searchInvoices = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/invoices/search`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const payInvoice = async (id, payload) => {
  try {
    const { data } = await http.post(`/api/invoices/${id}/pay`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createInvoicePdf = async (id) => {
  try {
    const { data } = await http.post(`/api/invoices/${id}/create-pdf`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const payBulkInvoices = async (payload) => {
  try {
    const { data } = await http.post(`/api/invoices/bulk-pay`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTotalStudentDues = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/invoices/students/dues`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getDuesGroupedByStudent = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/invoices/combined-dues`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getDuesForMonthlyPrograms = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/invoices/monthly-dues`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
