import http from '../helpers/http';


export const getTransactions = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/transactions`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTransactionsCount = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/transactions/count`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getTransaction = async (id) => {
  try {
    const { data } = await http.get(`/api/transactions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
