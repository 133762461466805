import http from '../helpers/http';


export const getBatches = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/batches`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getOfflineBatches = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/batches/offline`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBatch = async (id) => {
  try {
    const { data } = await http.get(`/api/batches/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createBatch = async (payload) => {
  try {
    const { data } = await http.post(`/api/batches`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateBatch = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/batches/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeBatchStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/batches/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeBatchStartMonth = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/batches/${id}/change-start-month`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeBatchEndMonth = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/batches/${id}/change-end-month`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
