import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import { includes } from 'lodash';
import {
  CheckCircleIcon,
  PlayIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon
} from '@heroicons/react/24/solid';

import StackedLayout from '../../../layouts/StackedLayout';
import VideoPlayer from '../../../components/VideoPlayer';
import { convertToBanglaNumber, getBanglaDate, getBanglaTime } from '../../../utils';
import { getVideoPayableUrl } from '../../../utils/video';
import { getUserEnrolledCourse } from '../../../services/enrolledCourse';
import { getTopic } from '../../../services/courseContent';
import { getCourse } from '../../../services/course';
import { getDownloadableLink } from '../../../services/download';


export default function PlaylistPage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const courseContentId = params.courseContentId;
  const topicIndex = parseInt(params?.topicIndex);
  const courseId = params.courseId;
  const [course, setCourse] = useState(null);
  const [contentIndex, setContentIndex] = useState(location?.state?.contentIndex || 0);
  const [topic, setTopic] = useState([]);
  const [contents, setContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [uri, setUri] = useState(null);
  const [loading, setLoading] = useState(true);
  const playerRef = useRef();

  useEffect(() => {
    if (topicIndex === NaN) { return navigate(-1); }

    async function _fetchData() {
      try {
        const _course = await getCourse(courseId);
        setCourse(_course);
        const enrolledCourse = await getUserEnrolledCourse(courseId);
        if (!enrolledCourse) { return navigate(`courses/${courseId}/details`); }
        const data = await getTopic(courseContentId, { topicIndex: topicIndex, course: courseId });
        setTopic(data?.topic);
        const _contents = data.contentType === 'playlist' ? data.topic?.playlist?.lessons : data.topic?.contents;
        setContents(_contents);

        const _selectedContent = _contents[contentIndex];
        setSelectedContent(_selectedContent);
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchContent() {
      if ((selectedContent?.endTime && moment(selectedContent.endTime) < moment()) ||
        (selectedContent?.startTime && moment(selectedContent.startTime) > moment())) {
        return;
      } else if (selectedContent?.contentType === 'lesson') {
        try {
          const _uri = await getVideoPayableUrl(selectedContent.lesson);
          setUri(_uri);
        } catch (error) {
          toast.error(error.message);
        }
      } else if (selectedContent?.contentType === 'exam') {
        if (selectedContent.isTaken) {
          const url = `/learn/exam/${selectedContent.takenExamId}/result`;
          window.location.href = url;
        } else {
          const url = `/learn/exam/${selectedContent?.exam?._id}/course/${courseId}`;
          window.open(url, '_blank', 'location=no');
        }
      } else if (includes(['ebook', 'note'], selectedContent?.contentType)) {
        try {
          const responseData = await getDownloadableLink({ privateLink: selectedContent.note.pdf });
          navigate(`/learn/ebooks/${selectedContent.note._id}/view`, {
            state: {
              url: responseData.publicLink,
              name: selectedContent.title || selectedContent.note.name
            }
          });
        } catch (error) {
          toast.error(error.message);
        }
      }
    };
    _fetchContent();
  }, [selectedContent]);

  return (
    <StackedLayout
      loading={loading}
      hideFooter={true}
    >
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-purple-700 sm:text-3xl hover:text-purple-600">
            <a href={`/learn/courses/${courseId}/details`}>{course?.name}</a>
          </h2>
        </div>
      </div>

      <div className="mx-auto sm:py-6 lg:max-w-7xl">
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Video player */}
          <div className="lg:col-span-4 lg:row-end-1">
            {!uri &&
              <div className="flex w-full py-10 justify-center items-center">
                <p className="text-sm text-center">This video is not available yet.</p>
              </div>}

            {uri &&
              <div ref={playerRef} className="flex aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                <VideoPlayer uri={uri} playerRef={playerRef} />
              </div>}
            <p className="p-2 text-base text-gray-600">{selectedContent?.title}</p>

            {selectedContent?.lesson?.instructors?.length > 0 &&
              <div className="mt-4 border-t border-gray-200 pt-4">
                <h3 className="text-sm font-medium text-gray-900">ক্লাসটি নিয়েছেন</h3>
                <ul role="list" className="divide-y text-sm">
                  {(selectedContent?.instructors || selectedContent?.lesson?.instructors)?.map((instructor, index) => (
                    <li key={`instructor-index[${index}]`} className="flex sm:span-col-full items-center justify-between gap-x-6 py-2">
                      <div className="flex min-w-0 gap-x-4">
                        <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={instructor?.photo} alt="" />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">{instructor?.name}</p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{instructor?.designation}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>}

          </div>

          {/* Playlist */}
          <div className="mx-2 mt-6 sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="overflow-hidden bg-white rounded-lg shadow">
                <div className="flex flex-1 border-b border-gray-200 justify-between items-center bg-white px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-purple-700">
                    {topic?.name}
                  </h3>
                </div>

                <ul role="list" className="divide-y divide-gray-200">
                  {contents?.map((content, index) => {
                    const contentType = content?.contentType || content?.lessonType;
                    return (
                      <li
                        key={`accordion-content-index[${index}]`}
                        className={contentIndex === index ? 'bg-green-50' : ''}
                        onClick={() => {
                          setContentIndex(index);
                          setSelectedContent(content);
                        }}
                      >
                        <div className="block hover:bg-gray-50 cursor-pointer">
                          <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <div className="flex w-3/4 text-ellipsis text-sm items-center space-x-1 font-medium text-gray-600">
                                {includes(['lesson', 'video', 'playlist'], contentType) ?
                                  <PlayIcon className="h-5 w-5 text-indigo-600" />
                                  :
                                  (
                                    contentType === 'exam' ?
                                      <ClipboardDocumentListIcon className="h-4 w-4 text-purple-600" />
                                      : <DocumentTextIcon className="h-4 w-4 text-yellow-600" />
                                  )}
                                <p className="flex space-x-2 items-center">
                                  <span>
                                    {content.contentType === 'lesson' ? content.lesson.title : content.contentType === 'exam' ? content.exam.name : content.note.name}
                                  </span>
                                  {content.isTaken && <CheckCircleIcon className="h-4 w-4 text-green-600" />}
                                </p>
                              </div>
                              <div className="ml-2 flex space-x-1 items-center text-sm text-gray-500">
                                {contentType === 'lesson' && content?.formattedDuration &&
                                  <p>{convertToBanglaNumber(content?.formattedDuration)}</p>}

                                {contentType === 'exam' &&
                                  <p>পূর্ণমানঃ {convertToBanglaNumber(content?.exam?.totalMarks || (content?.exam?.totalQuestions * content?.exam?.marksPerQuestion))}</p>}

                                {contentType === 'note' &&
                                  <p>{convertToBanglaNumber(content?.note?.pages || '0')} পৃষ্ঠা</p>}
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div className="sm:flex">
                                {contentType === 'exam' &&
                                  <div className="mt-2 flex items-center text-sm text-gray-500">
                                    {content?.isTaken ?
                                      <span>প্রাপ্ত নম্বর: {convertToBanglaNumber(content?.marksObtained)}</span>
                                      : <span>{convertToBanglaNumber(content?.exam?.totalQuestions)}টি প্রশ্ন &bull; সময় {convertToBanglaNumber(content?.exam?.duration?.value || '0')} মিনিট</span>
                                    }
                                  </div>}
                              </div>
                            </div>
                            {contentType === 'exam' && !content?.isTaken && (moment(content.startTime) > moment() || content.endTime) &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  {moment(content.startTime) > moment() && <span>পরীক্ষা শুরু {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)}</span>}
                                  {content.endTime && moment(content.endTime) > moment() && <span>{moment(content.startTime) > moment() ? <span> &bull; </span> : ''}পরীক্ষা শেষ {getBanglaDate(content.endTime)} {getBanglaTime(content.endTime)}</span>}
                                  {content.endTime && moment(content.endTime) <= moment() && <span>এই পরীক্ষাটিতে অংশগ্রহণের সময় অতিবাহিত হয়েছে</span>}
                                </span>
                              </div>}
                            {contentType === 'lesson' && moment(content.startTime) > moment() &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  <span>ক্লাসটি {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)} এর পর হতে দেখা যাবে </span>
                                </span>
                              </div>}
                            {contentType === 'note' && moment(content.startTime) > moment() &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  <span>এই নোটটি {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)} এর পর হতে পড়া যাবে </span>
                                </span>
                              </div>}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* lesson creds */}
          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};
