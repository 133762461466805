import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { omit, capitalize, isEmpty } from 'lodash';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Table from '../../components/tables/StackedTable';
import SectionHeader from '../../components/SectionHeader';
import { getCourses } from '../../services/course';


const sidebarNavigation = [
  { name: 'Results by courses', href: '/results/courses' },
  { name: 'Results for student', href: '/results/student' },
];
const headers = ['Course', 'Course type', 'Stundets enrolled', 'Status'];

export default function ResultsPage() {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitalData() {
      await fetchCourses();
    };
    fetchInitalData();
    setLoading(false);
  }, []);

  const fetchCourses = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        search: queryString,
        sortBy: 'status'
      };
      const data = await getCourses(params);
      setCourses(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
      setCourses([]);
      setPagingData(null);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  return (
    <StackedLayout
      loadin={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Results by courses"
    >
      <SectionHeader
        title="Results"
        subTitle="Choose a course from the list to see results."
      />

      <Table
        headers={headers}
        itemsCount={courses.length}
        pagingData={pagingData}
        queryString={queryString}
        onPageChange={onPageChange}
        onSearch={onSearch}
      >
        {courses.map((course) => (
          <tr
            key={course._id}
            className="text-center"
          >
            <td
              className="py-4 pl-4 pr-3 text-sm font-medium text-left text-gray-900 sm:pl-0 text-ellipsis hover:underline cursor-pointer"
              onClick={() => window.location.href = `/results/course/${course._id}`}
            >
              {course.name}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{course?.courseType}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{course?.enrolledCount}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">
              {capitalize(course.status)}
            </td>
            <td className="py-2 pl-3 pr-4 text-sm font-medium sm:pr-0">
              <div className="flex justify-end">
                <a href={`/results/course/${course._id}`}>
                  <ChevronRightIcon className="h-4 w-4" />
                </a>
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </StackedLayout>
  )
};
