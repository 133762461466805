import React, { useState, useEffect } from 'react';

import JoykolyCustomImage1 from './assets/joykoly-section-1.jpeg'
import JoykolyCustomImage2 from './assets/joykoly-section-2.jpeg'
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/Hero';
import StudyCategory from './components/StudyCategory';
import StatsSection from './components/Stats';
import CardSection from './components/CardSection';
import StudyMaterial from './components/StudyMaterial';
import Teachers from './components/Teachers';
import ContactSection from './components/Contact';
import Testimonials from './components/Testimonials'
import { includes } from 'lodash';



export default function CustomHome1(props) {
  const currentInstitute = props.currentInstitute;




  return (
    <div>
      <Header currentInstitute={currentInstitute} />
      <HeroSection />

      {includes(['spondon', 'demo-dev'], currentInstitute?.subdomain) &&
        <StudyCategory currentInstitute={currentInstitute} />}

      <CardSection currentInstitute={currentInstitute} />

      {includes(['joykoli'], currentInstitute?.subdomain) &&
        <div className="flex max-w-full justify-center object-contain">
          <img src={JoykolyCustomImage1} alt="" />
        </div>}

      {includes(['spondon', 'demo-dev'], currentInstitute?.subdomain) &&
        <StudyMaterial currentInstitute={currentInstitute} />}

      {/* <StatsSection /> */}
      {/* <CardSection /> */}
      <Teachers currentInstitute={currentInstitute} />

      {includes(['joykoli'], currentInstitute?.subdomain) &&
        <div className="flex max-w-full justify-center object-contain">
          <img src={JoykolyCustomImage2} alt="" />
        </div>}
      <Testimonials currentInstitute={currentInstitute} />
      {/* <ContactSection /> */}
      <Footer />
    </div>
  )
};