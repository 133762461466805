import http from '../helpers/http';


export const createQuestion = async (payload) => {
  try {
    const { data } = await http.post(`/api/questions`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getQuestion = async (id) => {
  try {
    const { data } = await http.get(`/api/questions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateQuestion = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/questions/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteQuestion = async (id) => {
  try {
    const { data } = await http.delete(`/api/questions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getQuestions = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/questions`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getQuestionSolution = async (id) => {
  try {
    const { data } = await http.get(`/api/questions/${id}/solution`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
