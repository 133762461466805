import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { UserIcon } from '@heroicons/react/24/outline';
import { isEmpty, omit } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import InstituteUserPermissionForm from './components/InstituteUserPermissionForm';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { getInstituteUsers, changeUserStatus } from '../../services/user';
import { getNormalizedDateTime } from '../../utils';


const sidebarNavigation = [
  // { name: 'Institute Information', href: 'settings/institute-info' },
  { name: 'General users', href: '/settings/users' },
  { name: 'Branch users', href: '/branch/users' },
];
const headers = ['Name', 'Mobile', 'Role', 'Status', 'Created At'];

export default function InstituteUsersPage() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPermissionForm, setOpenPermissionForm] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    };
    _fetchData();
    setLoading(false);
  }, [
    currentPage,
    queryString
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        search: queryString,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      const data = await getInstituteUsers(params);
      setUsers(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeUserStatus(selectedUser._id);
      toast.success('User updated successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="General users"
    >
      <SectionHeader
        title="Users"
        subTitle="List of users excluding the owner and yourself."
        createButtonText="New user"
        onCreate={() => window.location.href = `/settings/users/create`}
      />

      {openPermissionForm &&
      <InstituteUserPermissionForm
        user={selectedUser}
        onClose={async () => {
          setSelectedUser(null);
          setOpenPermissionForm(false);
          await fetchData();
        }}
      />}
      {showStatusModal &&
      <ConfirmModal
        title={selectedUser.status === 'active' ? 'Deactivate user' : 'Activate user'}
        description={selectedUser.status === 'active' ? `Are you to deactive this user. The user will loose all access from your institution.` : `Are you sure to re-activate this user?`}
        actionName={selectedUser.status === 'active' ? 'Deactivate' : 'Activate'}
        onConfirm={handleStatusChange}
        onCancel={async () => {
          setSelectedUser(null);
          setShowStatusModal(false);
        }}
      />}
      <Table
        headers={headers}
        itemsCount={users.length}
        pagingData={pagingData}
        queryString={queryString}
        onSearch={onSearch}
        onPageChange={onPageChange}
        searchPlaceholder="Search by name or mobile number"
      >
        {users.map((user) => (
          <tr key={user._id}>
            <td className="py-4 pl-4 pr-3 text-sm sm:pl-0">
              <div className="flex items-center">
                {user.profilePicture ?
                <img className="h-6 w-6 rounded-full" src={user.profilePicture} alt="" />
                : <UserIcon className="h-5 w-5 rounded-full" />}
                <div className="ml-2 font-medium text-gray-900">{user.name}</div>
              </div>
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{user.mobile}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{user.role}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{user.status}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(user.createdAt, 'DD MMM YYYY')}</td>
            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems 
                user={user}
                onManagePermissions={() => {
                  setSelectedUser(user);
                  setOpenPermissionForm(true);
                }}
                onStatusChange={() => {
                  setSelectedUser(user);
                  setShowStatusModal(true);
                }}
              />
            </td>
          </tr>
        ))}

      </Table>

    </StackedLayout>
  )
};

function ActionItems(props) {
  const { user, onManagePermissions, onStatusChange } = props;

  return (
    <ActionMenu>
      {user.role !== 'owner' && user.status === 'active' &&
      <div className="py-1">
        <MenuItem label="Manage permissions" onClick={onManagePermissions} />
      </div>}

      {user.role !== 'owner' &&
      <div className="py-1">
        <MenuItem
          label={user.status === 'active' ? 'Deactivate' : 'Activate'}
          isDanger={user.status === 'active'}
          onClick={onStatusChange}
        />
      </div>}
    </ActionMenu>
  )
};
