import http from '../helpers/http';


export const getCompetitions = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/competitions`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCompetition = async (id) => {
  try {
    const { data } = await http.get(`/api/competitions/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createCompetition = async (payload) => {
  try {
    const { data } = await http.post(`/api/competitions`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateCompetition = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/competitions/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCompetitionParticipant = async (id) => {
  try {
    const { data } = await http.get(`/api/competitions/${id}/participant/current-user`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const registerCurrentParticipant = async (id, payload) => {
  try {
    const { data } = await http.post(`/api/competitions/${id}/register/current-user`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const addCompetitionParticipant = async (id, payload) => {
  try {
    const { data } = await http.post(`/api/competitions/${id}/add-participant`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCompetitionParticipants = async (id, params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/competitions/${id}/participants`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeCompetitionStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/competitions/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};