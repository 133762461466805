import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import RemoteSources from '@uppy/remote-sources';
import GoldenRetriever from '@uppy/golden-retriever';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import { Sidepanel } from './Sidepanel';
import { addLessonVideo, updateLesson } from '../services/lesson';
import { getVideo } from '../services/video';


const libraryId = process.env.REACT_APP_GOTIPATH_LIBRARY_ID;
const defaultCollectionId = process.env.REACT_APP_GOTIPATH_SHARED_COLLECTION_ID;
const headers = {
  'X-Auth-ClientId': process.env.REACT_APP_GOTIPATH_CLIENT_ID,
  'X-Auth-LibraryId': libraryId,
  'X-Auth-ApiKey': process.env.REACT_APP_GOTIPATH_API_KEY
};

export default function VideoUploader(props) {
  const {
    lesson,
    collectiontId,
    onUploadStart,
    onUploadError,
    onUploadComplete,
    onClose
  } = props;
  const title = lesson.title;
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    async function _createVideoId() {
      try {
        if (lesson?.videoId) {
          const videoData = await getVideo(lesson.videoId);
          if (videoData.status === 'uploading') {
            setVideoId(lesson.videoId);
          } else {
            const payload = { status: videoData.status };
            await updateLesson(lesson._id, payload);
            onClose();
          }
        } else {
          const _lesson = await addLessonVideo(lesson._id);
          setVideoId(_lesson.videoId);
        }
      } catch (error) {
        onClose();
      }
    };
    _createVideoId();
  }, []);

  const uppy = new Uppy({
    id: videoId,
    autoProceed: true,
    debug: false,
    inline: true,
    allowMultipleUploads: false,
    allowMultipleUploadBatches: true,
    proudlyDisplayPoweredByUppy: false,
    showProgressDetails: true,
    browserBackButtonClose: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['video/*']
    }
  });

  uppy.use(AwsS3, {
    shouldUseMultipart: true,
    companionUrl: 'https://apistream.gotipath.com/v1/uploads/',
    companionHeaders: {
      'uppy-auth-token': JSON.stringify(headers),
    }
  });
  
  // uppy.use(RemoteSources, {
  //   companionUrl: 'https://companion.uppy.io',
  //   sources: ['GoogleDrive', 'Dropbox', 'Facebook', 'OneDrive', 'Zoom', 'Url']
  // });
  uppy.use(GoldenRetriever, {
    indexedDB: { maxFileSize: Infinity, maxTotalSize: Infinity }
  });

  uppy.on('file-added', (file) => {
    uppy.setFileMeta(file.id, {
      video_id: videoId,
      library_id: libraryId,
      collection_id: collectiontId || defaultCollectionId,
    });
  });

  uppy.on('upload', (data) => {
    onUploadStart(lesson._id, videoId, title, uppy);
    onClose();
  });

  uppy.on('upload-error', (file, error, response) => {
    onUploadError(lesson._id, videoId);
    toast.error(error.message);
  });

  uppy.on('complete', result => {
    if (result.failed.length === 0) {
      onUploadComplete(lesson._id, videoId);
      toast.success('Video upload completed.');
    } else {
      onUploadError(lesson._id, videoId);
      toast.error('Video upload failed.');
    }
  });

  return (
    <Sidepanel
      title="Video upload"
      subTitle={title}
      hideActions={true}
      onClose={onClose}
    >
      {videoId ? <Dashboard uppy={uppy} /> : <p className="text-xs text-gray-500">Processing data for upload...</p> }
    </Sidepanel>
  )
}